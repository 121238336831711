import React from 'react';

export const RightLastPageIcon = () => (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <g mask="url(#mask0_21477_70962)">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.117157 7.86983C-0.039053 7.69626 -0.039053 7.41485 0.117157 7.24129L3.03431 4L0.117156 0.758714C-0.0390536 0.585148 -0.0390536 0.303742 0.117156 0.130175C0.273366 -0.0433915 0.526632 -0.0433915 0.682842 0.130175L3.88284 3.68573C4.03905 3.8593 4.03905 4.1407 3.88284 4.31427L0.682842 7.86983C0.526633 8.04339 0.273366 8.04339 0.117157 7.86983Z" fill="inherit"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 8C7.22386 8 7 7.81149 7 7.57895L7 0.421053C7 0.188512 7.22386 6.78525e-08 7.5 4.37114e-08C7.77614 1.95703e-08 8 0.188512 8 0.421052L8 7.57895C8 7.81149 7.77614 8 7.5 8Z" fill="inherit"/>
        </g>
    </svg>

);
