import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { chain, map } from 'lodash'
import styles from 'components/Metrics/CustomTooltip.module.css';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const monthDesignation = ['SundayShortcut', 'MondayShortcut', 'TuesdayShortcut', 'WednesdayShortcut', 'ThursdayShortcut', 'FridayShortcut', 'SaturdayShortcut'];

const formattedValue = (value, type) => {
    if (type === '%') {
        return `${value}%`
    } else if (type === 'K' && value) {
        return value.toLocaleString();
    }
    return value
};

export const CustomTooltip = ({ payload, tooltipParams }) => {
    const { t: translator } = useTranslation();
    //get date and formatted
    const date = payload && payload[0]?.payload.date;
    const weekDay = new Date(date).getDay();

    const formattedDate = monthDesignation[weekDay] ? translator(`default:${monthDesignation[weekDay]}`) + ` ${chain(date).split('-').reverse().join('/').value()}` : null

    if (payload) {
        return (
            <div className={styles.container}>
                <p className={styles.date}>{formattedDate}</p>
                {map(payload, (mark, index) => (
                    <div className={styles.valueContainer} key={`${tooltipParams.titles[index].title}-${index}`}>
                        <span style={{background: tooltipParams.colorMarks[index]}}/>
                        <div className={cx(styles.valueField, {'columnValueBlock' : tooltipParams.columnValueBlock})}>
                            {tooltipParams.prefix? (
                                <p>{translator(`metrics:${tooltipParams.prefix}`)} {tooltipParams.titles[index].title}</p>
                            ) : (
                                <p>{translator(`metrics:${tooltipParams.titles[index].title}`)}</p>
                            )}
                            <p className={styles.value}>{formattedValue(mark.value, tooltipParams.valueType)}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    return null;
};

CustomTooltip.propTypes = {
    payload: PropTypes.array,
    tooltipParams: PropTypes.shape({
        titles: PropTypes.arrayOf(PropTypes.object),
        colorMarks: PropTypes.arrayOf(PropTypes.string),
        columnValueBlock: PropTypes.bool,
        valueType: PropTypes.string,
        translate: PropTypes.bool
    })
};
