import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SingleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/SingleChainActionIcon';
import { SqlTriggerIcon } from 'assets/icons/SqlTriggerIcon';
import { SqlTriggerIconAlt } from 'assets/icons/SqlTriggerIconAlt';
import { toggleSideBar } from 'store/actions/scenariosActions.action';

export const SqlTrigger = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const actionIconConditions = {
        base: {
            icon: SqlTriggerIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#9088DF'
            }
        },
        opened: {
            icon: SqlTriggerIconAlt,
            iconProps: { borderColor: '#9088DF' }
        },
        warning: {
            icon: SqlTriggerIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    const alternativeEditHandler = () => {
        dispatch(toggleSideBar({ open: true, type: actionType, id: id }));
        navigate('sql-trigger', { state: { shallow: true }});
    };

    return <SingleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
        alternativeEditHandler,
    }} />
}
