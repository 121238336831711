import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearTemplatesList, getTemplatesList } from 'store/actions/templates.action';
import { EmailCard } from 'components/Cards/CreativeCards/EmailCard';
import { ViberCard } from 'components/Cards/CreativeCards/ViberCard';
import { SmsCard } from 'components/Cards/CreativeCards/SmsCard';
import { WebsiteBuilderCard } from 'components/Cards/CreativeCards/WebsiteBuilderCard';
import { MobilePushCard } from 'components/Cards/CreativeCards/MobilePushCard';
import { WebPushCard } from 'components/Cards/CreativeCards/WebPushCard';
import { isArray } from 'lodash';

const getRequestedTemplateTypeSettings = type => {
    switch (type) {
        case 'email':
            return { type: 'Email', creativeCard: EmailCard, title: 'TabsTemplatesEmailTitle' };
        case 'viber':
            return { type: 'Viber', creativeCard: ViberCard, title: 'TabsTemplatesViberTitle' };
        case 'sms':
            return { type: 'SMS', creativeCard: SmsCard, title: 'TabsTemplatesSmsTitle' };
        case 'website':
            return { type: 'PersonalPage', creativeCard: WebsiteBuilderCard, title: 'TabsTemplatesWebsiteBuilderTitle' };
        case 'mobpushnotify':
            return { type: 'MobPushNotify', creativeCard: MobilePushCard, title: 'TabsTemplatesMobilePushTitle' };
        case 'webpushnotify':
            return { type: 'WebPushNotify', creativeCard: WebPushCard, title: 'TabsTemplatesWebPushTitle' };
        default:
            return { type: '', creativeCard: null, title: '' };
    }
};

export const useTemplates = (
    actionTemplateType = null
) => {
    const dispatch = useDispatch();
    const { templateType: routerTemplateType } = useParams();

    const requestedTemplateSettings = getRequestedTemplateTypeSettings(actionTemplateType || routerTemplateType);

    const isLoadingTemplates = useSelector(store => store.api.isLoadingTemplates);
    const templatesList = useSelector(store => store.templates.list[requestedTemplateSettings.type]);
    const currentEditTemplate = useSelector(store => store.templates.currentEditTemplate);

    const CardTemplate = requestedTemplateSettings.creativeCard;

    useEffect(() => {
        if (routerTemplateType || actionTemplateType) {
            if (isArray(templatesList) && !templatesList.length) {
                dispatch(getTemplatesList(requestedTemplateSettings.type))
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerTemplateType, actionTemplateType, requestedTemplateSettings.type]);

    useEffect(() => {
        return () => dispatch(clearTemplatesList());
    }, [dispatch])

    return {
        title: requestedTemplateSettings.title,
        type: requestedTemplateSettings.type,
        CardTemplate,
        templatesList,
        isLoadingTemplates,
        currentEditTemplate
    }
};