export default function validateLogin(values) {
    let errors = {};

    if (!values.username) {
        errors.username = 'InputValidationRequiredMessage';
    }

    if (!values.password) {
        errors.password = 'InputValidationRequiredMessage';
    } else if (values.password.length < 8) {
        errors.password = 'PasswordValidationMessage';
    }

    return errors;
};
