import { chain, filter, find, findIndex, forEach, includes, join, some } from 'lodash';
import { CommonMethods } from 'lib/CommonMethods';
import { apiRequestAction } from 'store/actions/api.action';
import { getScenariosStatusExtension } from 'data/scenariosStatusExtension';
import { DateUse } from 'lib/DateUse';
import { ArrayUse } from 'lib/ArrayUse';
import {
    CLEAR_SCENARIO_DATA,
    FETCH_CURRENT_SCENARIO_START,
    FETCH_CURRENT_SCENARIO_STOP,
    FETCH_LIBRARY_SCENARIOS_LIST,
    FETCH_SCENARIO,
    FETCH_SCENARIO_COPY,
    FETCH_SCENARIO_CREATE,
    FETCH_SCENARIO_DELETE,
    FETCH_SCENARIO_METRIC,
    FETCH_SCENARIO_NAME_UPDATE,
    FETCH_SCENARIO_START,
    FETCH_SCENARIO_STOP,
    FETCH_SCENARIO_UPDATE,
    FETCH_SCENARIOS_FILTERED,
    FETCH_SCENARIOS_LIST,
    FETCH_SCENARIOS_METRICS,
    FETCH_SCENARIOS_SEARCHED,
    FETCH_SILENT_SCENARIO_UPDATE,
    SET_CURRENT_SCENARIO_START,
    SET_CURRENT_SCENARIO_STOP,
    SET_LIBRARY_SCENARIOS_LIST,
    SET_SCENARIO,
    SET_SCENARIO_COPY,
    SET_SCENARIO_CREATE,
    SET_SCENARIO_DELETE,
    SET_SCENARIO_METRIC,
    SET_SCENARIO_NAME_UPDATE,
    SET_SCENARIO_START,
    SET_SCENARIO_STOP,
    SET_SCENARIO_UPDATE,
    SET_SCENARIOS_FILTERED,
    SET_SCENARIOS_LIST,
    SET_SCENARIOS_METRICS,
    SET_SCENARIOS_SEARCHED
} from 'store/types';

const setScenariosList = (data, { emptyScenariosCallback }) => {

    emptyScenariosCallback(data.scenarios)

    return {
        type: SET_SCENARIOS_LIST,
        payload: {
            data: data.scenarios
        }
    }
};

export const getScenariosList = (data, emptyScenariosCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('scenarios/list'),
        method: 'POST',
        data: data,
        extraOptions: { emptyScenariosCallback },
        onSuccess: setScenariosList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIOS_LIST
    })
);
//This is needs for sidebar crate new scenario
export const getLibraryScenariosList = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('scenarios/list'),
        method: 'POST',
        data: { use_library: true },
        onSuccess: setLibraryScenariosList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_LIBRARY_SCENARIOS_LIST
    })
);
const setLibraryScenariosList = data => ({
    type: SET_LIBRARY_SCENARIOS_LIST,
    payload: filter(data.scenarios, { is_library: true })
});
const setScenario = data => dispatch => {
    const { scenario, scenario: { scenario_name, scenario_status, scenario_status: { scenario_status_id } } } = data;
    const statusExtension = getScenariosStatusExtension(scenario_status_id);

    dispatch({
        type: SET_SCENARIO,
        payload: {
            data: {
                ...scenario,
                scenario_status: {
                    ...scenario_status,
                    ...statusExtension
                },
            },
            name: scenario_name
        }
    })
};

export const getScenario = (scenarioId, redirectCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenarioId}`),
        method: 'GET',
        onSuccess: setScenario,
        onFailure: data => {
            redirectCallback();
            console.log('%cRequest failure:', 'color:indianred', data)
        },
        label: FETCH_SCENARIO
    })
);
export const clearCurrentScenarioData = () => dispatch =>
    dispatch({
        type: CLEAR_SCENARIO_DATA,
        payload: {
            defaultCurrentScenario: {},
            defaultCurrentScenarioName: '',
            defaultCurrentScenarioMetrics: [],
            defaultCurrentScenarioTemplates: {
                actionsList: [],
                metrics: {},
            },
            defaultModifiedScenarioMetrics: {
                monthMetrics: {},
                totalMetricsForPeriod: {},
            }
        }
    });

const setScenariosMetrics = (data, scenarioId) => ({
    type: SET_SCENARIOS_METRICS,
    payload: { metrics: {...data, scenario_unique_id: scenarioId }}
});

export const getScenariosMetrics = ({ scenario_unique_id }) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenario_unique_id}/metrics`),
        method: 'POST',
        extraOptions: scenario_unique_id,
        data: {
            conversion_window: 2
        },
        onSuccess: setScenariosMetrics,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIOS_METRICS
    })
);

const setScenarioMetric = ({ scenario_metrics }) => {
    const totalMetricsForPeriod = {
        clients_entered: 0,
        clients_converted: 0,
        conversion_rate: 0,
        revenue: 0,
        trend_revenue: 0,
        trend_clients_entered: 0
    };
    const monthMetrics = {};

    const regressionTrend = {
        trend_revenue: [],
        trend_clients_entered: []
    };

    forEach(scenario_metrics, (metricsObject, index) => {
        totalMetricsForPeriod.clients_entered += metricsObject.clients_entered;
        totalMetricsForPeriod.clients_converted += metricsObject.clients_converted;
        totalMetricsForPeriod.revenue += metricsObject.revenue;
        //created name for array year-month and just current day of month
        const [year_month, day] = chain(metricsObject.date).split('-').chunk(2).map(arr => join(arr, '-')).value();

        const requiredFields = {
            day,
            conversion_rate: metricsObject.clients_entered > 0 ? +(metricsObject.clients_converted / metricsObject.clients_entered * 100).toFixed(2) : 0,
        };

        //collecting an array for calculating the regression
        regressionTrend.trend_revenue.push([index + 1, metricsObject.revenue]);
        regressionTrend.trend_clients_entered.push([index + 1, metricsObject.clients_entered]);

        if (year_month in monthMetrics) {
            monthMetrics[year_month].push({
                ...metricsObject,
                ...requiredFields
            })
        } else {
            monthMetrics[year_month] = [{
                ...metricsObject,
                ...requiredFields
            }]
        }
    });

    // Trend calculation
    totalMetricsForPeriod.trend_revenue = CommonMethods.lineRegressionTrend(regressionTrend.trend_revenue);
    totalMetricsForPeriod.trend_clients_entered = CommonMethods.lineRegressionTrend(regressionTrend.trend_clients_entered);

    if (totalMetricsForPeriod.clients_entered && totalMetricsForPeriod.clients_converted) {
        totalMetricsForPeriod.conversion_rate = +(totalMetricsForPeriod.clients_converted / totalMetricsForPeriod.clients_entered * 100).toFixed(2);
    }

    return {
        type: SET_SCENARIO_METRIC,
        payload: {
            scenario_metrics,
            modifiedScenarioMetrics: {
                totalMetricsForPeriod,
                monthMetrics,
            },
        }
    };
};

export const getScenarioMetric = (scenario_unique_id, startDate, endDate) => {
    const data = {
        conversion_window: 2,
        date_from: `${DateUse.checkValidFormatYearMonthDay(startDate)} 00:00:01`,
        date_to: `${DateUse.checkValidFormatYearMonthDay(endDate)} 23:59:59`,
    };

    return apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenario_unique_id}/metrics`),
        method: 'POST',
        data,
        onSuccess: setScenarioMetric,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_METRIC,
    });
};

const setScenarioStatusStop = data => (dispatch, getStore) => {
    const scenariosList = getStore().scenarios.list
    const { scenario } = data;

    console.log('[All Scenarios Server]: Stop:', scenario);

    dispatch({
        type: SET_SCENARIO_STOP,
        payload: {
            list: ArrayUse.replace(scenariosList, findIndex(scenariosList, { scenario_unique_id: scenario.scenario_unique_id }), scenario)
        }
    })
};

export const stopScenario = (id, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}/terminate`),
        method: 'PUT',
        onSuccess: setScenarioStatusStop,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_STOP
    })
);

const setCurrentScenarioStatusStop = data => {
    const {
        scenario,
        scenario: {
            scenario_status,
            scenario_status: { scenario_status_id }
        }
    } = data;

    const scenarioStatusColor = getScenariosStatusExtension(scenario_status_id);

    console.log('[All Scenarios Server]: Stop:', scenario);

    return {
        type: SET_CURRENT_SCENARIO_STOP,
        payload: {
            scenario: {
                ...scenario,
                scenario_status: {
                    ...scenario_status,
                    ...scenarioStatusColor
                }
            }
        }
    }
};

export const stopCurrentScenario = id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}/terminate`),
        method: 'PUT',
        onSuccess: setCurrentScenarioStatusStop,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CURRENT_SCENARIO_STOP
    })
);

const setScenarioStatusStart = data => (dispatch, getStore) => {
    const scenariosList = getStore().scenarios.list;
    const { scenario } = data;

    console.log('[All Scenarios Server]: Start:', scenario);

    dispatch({
        type: SET_SCENARIO_START,
        payload: {
            list: ArrayUse.replace(scenariosList, findIndex(scenariosList, { scenario_unique_id: scenario.scenario_unique_id }), scenario)
        }
    })
};

export const startScenario = (id, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}/start`),
        method: 'POST',
        onSuccess: setScenarioStatusStart,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_START
    })
);

const setCurrentScenarioStatusStart = data => {
    const {
        scenario,
        scenario: {
            scenario_status,
            scenario_status: { scenario_status_id }
        }
    } = data;

    const scenarioStatusColor = getScenariosStatusExtension(scenario_status_id);

    console.log('[All Scenarios Server]: Start:', scenario);

    return {
        type: SET_CURRENT_SCENARIO_START,
        payload: {
            scenario: {
                ...scenario,
                scenario_status: {
                    ...scenario_status,
                    ...scenarioStatusColor
                }
            }
        }
    }
};

export const startCurrentScenario = (id, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}/start`),
        method: 'POST',
        onSuccess: setCurrentScenarioStatusStart,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CURRENT_SCENARIO_START
    })
);

const setScenarioCopy = (data, id) => (dispatch, getStore) => {
    const scenariosList = getStore().scenarios.list;
    const { scenario } = data;

    dispatch({
        type: SET_SCENARIO_COPY,
        payload: {
            list: ArrayUse.insert(scenariosList, findIndex(scenariosList, { scenario_unique_id: id }), scenario)
        }
    })
};

export const copyScenario = id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}/copy`),
        method: 'POST',
        extraOptions: id,
        onSuccess: setScenarioCopy,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_COPY
    })
);

const setScenarioDelete = id => (dispatch, getStore) => {
    const scenariosList = getStore().scenarios.list;

    dispatch({
        type: SET_SCENARIO_DELETE,
        payload: {
            list: ArrayUse.remove(scenariosList, find(scenariosList, { scenario_unique_id: id }))
        }
    })
};

export const deleteScenario = id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${id}`),
        method: 'DELETE',
        extraOptions: id,
        onSuccess: setScenarioDelete,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_DELETE
    })
);

const setChangedScenarioName = data => {
    console.log('%c[API Scenarios Update]: Response:', 'color:forestgreen', data);

    const { scenario, scenario: { scenario_status, scenario_status: { scenario_status_id } } } = data;
    const statusExtension = getScenariosStatusExtension(scenario_status_id);

    return {
        type: SET_SCENARIO_NAME_UPDATE,
        payload: { data: {
                ...scenario,
                scenario_status: {
                    ...scenario_status,
                    ...statusExtension
                },
            }
        }
    }
};

export const changeScenarioName = (scenario_unique_id, data, callback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenario_unique_id}`),
        method: 'PUT',
        data: { scenario: { ...data } },
        onSuccess: setChangedScenarioName,
        onAfterSuccess: callback,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_NAME_UPDATE
    })
);

const setSearchedScenariosList = (scenariosList, { searchQuery, onAfterSearchCallback }) => {
    const searched = filter(scenariosList.scenarios, ({ scenario_name }) => {
        return includes(scenario_name.toLowerCase(), searchQuery)
    });

    onAfterSearchCallback(searched);

    return {
        type: SET_SCENARIOS_SEARCHED,
        payload: { data: searched }
    }
};
export const searchScenarios = (searchQuery, data, onAfterSearchCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('scenarios/list'),
        method: 'POST',
        extraOptions: { searchQuery, onAfterSearchCallback },
        data: data,
        onSuccess: setSearchedScenariosList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIOS_SEARCHED
    })
);

const setFilteredScenariosList = (scenariosList, { options, onAfterFilteredCallback }) => {
    const filtered = filter(scenariosList.scenarios, elem => {
        const { scenario_status: { scenario_status_name } } = elem;

        return some(options, { id: scenario_status_name });
    });

    onAfterFilteredCallback(filtered);

    return {
        type: SET_SCENARIOS_FILTERED,
        payload: { data: filtered }
    }
};

export const filterScenarios = (options, data, onAfterFilteredCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('scenarios/list'),
        method: 'POST',
        extraOptions: { options, onAfterFilteredCallback },
        data: data,
        onSuccess: setFilteredScenariosList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIOS_FILTERED
    })
);

const setScenarioCreate = (data, { redirectCallback, librarySettings = null } ) => (dispatch) => {
    const { scenario, scenario: { scenario_unique_id, last_modified_at, scenario_name } } = data;

    redirectCallback(scenario_unique_id);

    if (librarySettings) {
        const newScenarioWithLibrarySettings = {
            ...librarySettings,
            is_library: false,
            last_modified_at,
            scenario_unique_id,
            scenario_name
        };

        return dispatch(updateScenario(scenario_unique_id, newScenarioWithLibrarySettings));
    }

    return {
        type: SET_SCENARIO_CREATE,
        payload: { scenario }
    }
};

export const createScenario = (data, librarySettings, redirectCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('scenarios'),
        method: 'POST',
        extraOptions: { librarySettings, redirectCallback },
        data: data,
        onSuccess: setScenarioCreate,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_CREATE
    })
);

const setScenarioUpdate = data => {
    console.log('%c[API Scenarios Update]: Response:', 'color:forestgreen', data);

    const { scenario, scenario: { scenario_status, scenario_status: { scenario_status_id } } } = data;
    const statusExtension = getScenariosStatusExtension(scenario_status_id);

    return {
        type: SET_SCENARIO_UPDATE,
        payload: { data: {
                ...scenario,
                scenario_status: {
                    ...scenario_status,
                    ...statusExtension
                },
            }
        }
    }
};

export const updateScenario = (scenario_unique_id, data, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenario_unique_id}`),
        method: 'PUT',
        data: { scenario: { ...data } },
        onSuccess: setScenarioUpdate,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_UPDATE
    })
);

export const silentUpdateScenario = (scenario_unique_id, data, onAfterSuccess = () => {}) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`scenarios/${scenario_unique_id}`),
        method: 'PUT',
        data: { scenario: { ...data } },
        onSuccess: setScenarioUpdate,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SILENT_SCENARIO_UPDATE
    })
);





