import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { eq, isEmpty, has } from 'lodash';
import { ComparisonOperatorSelectOptions } from 'components/SelectOptions/ComparisonOperatorSelectOptions';
import { Label } from 'common/_Label/Label';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { useTranslation } from 'react-i18next';
import { ConditionSegmentationFilterTags } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/advanced/ConditionSegmentationFilterTags';
import { StringUse } from 'lib/StringUse';
import { useSelector } from 'react-redux';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

const parsedErrors = (errors, conditionIndex, segmentationFilterType) => {
    const isConditionHasErrors = !isEmpty(errors) && has(errors, 'conditionsErrors');

    if (isConditionHasErrors) {
        const { conditionsErrors } = errors;
        const { selectedOptionsType, validation } = conditionsErrors[conditionIndex];

        return {
            attributeName: {
                hasError: eq(selectedOptionsType, segmentationFilterType) && !!validation.firstErrors?.attributeName,
                message: StringUse.falseyReturnEmptyString(validation.firstErrors?.attributeName)
            },
            comparisonValue: {
                hasError: eq(selectedOptionsType, segmentationFilterType) && !!validation.firstErrors?.comparisonValue,
                message: StringUse.falseyReturnEmptyString(validation.firstErrors?.comparisonValue)
            },
            comparisonOperator: {
                hasError: eq(selectedOptionsType, segmentationFilterType) && !!validation.firstErrors?.comparisonOperator,
                message: StringUse.falseyReturnEmptyString(validation.firstErrors?.comparisonOperator)
            }
        }
    }

    return {
        attributeName: {
            hasError: false
        },
        comparisonValue: {
            hasError: false
        },
        comparisonOperator: {
            hasError: false
        },
    }
}

export const ConditionSegmentationFilter = ({ data, conditionId, conditionIndex, segmentationFilterKey, segmentationFilterType }) => {
    const { t: translator } = useTranslation();

    const dropdownFilters = useSelector(store => store.segments.dropdownFilters);

    const {
        onChangeConditionSegmentationFilterInput,
        errors,
        setCondition,
        onChangeConditionSegmentationFilterDropdown,
    } = useContext(SideBarContext);

    const { attributeName, comparisonValue, comparisonOperator } = parsedErrors(errors, conditionIndex, segmentationFilterType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeConditionSegmentationFilterInput(conditionId, setCondition, segmentationFilterKey), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeConditionSegmentationFilterDropdown(setCondition, conditionId, segmentationFilterKey), []);

    return (
        <div className={styles.clientAttributeValuesContainer}>
            <CustomSelectDropdown
                dropdownName='attributeName'
                dropdownLabel={translator(`actionSidebars:${data.attributeName.name}`)}
                defaultDropdownValue={data.attributeName.value}
                dropdownOptions={
                    has(dropdownFilters, segmentationFilterType)
                        ? dropdownFilters[segmentationFilterType]
                        : []
                }
                onChangeDropdown={onChangeDropdownCached}
                isErrorExist={attributeName.hasError}
                isRequired={data.attributeName.required}
            />

            <Label htmlFor="comparisonValue" text={translator(`actionSidebars:${data.comparisonValue.name}`)}/>
            <ConditionSegmentationFilterTags
                conditionId={conditionId}
                inputTagValues={data.comparisonValue.value}
                inputTagName='comparisonValue'
                setState={setCondition}
                objKey={segmentationFilterKey}
                error={comparisonValue.hasError}
            />
            <ErrorMessageField active={comparisonValue.hasError} message={comparisonValue.message}/>

            <div className={styles.clientAttributeValuesContainer}>
                <ComparisonOperatorSelectOptions
                    translator={translator}
                    item={data}
                    onChangeHandler={onChangeCached}
                    error={comparisonOperator.hasError}
                    comparisonOperatorType='attribute'
                    nonDropdownComparisonOperatorType={segmentationFilterType}
                />
            </div>
            <ErrorMessageField active={comparisonOperator.hasError} message={comparisonOperator.message}/>
        </div>
    )
}
ConditionSegmentationFilter.propTypes = {
    data: PropTypes.object.isRequired,
    conditionId: PropTypes.number.isRequired,
    conditionIndex: PropTypes.number.isRequired,
    segmentationFilterKey: PropTypes.string.isRequired,
    segmentationFilterType: PropTypes.string.isRequired
}

