import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScenarioMetric } from 'store/actions/scenarios.action';
import { getScenarioTemplateMetrics, setScenarioTemplatesList, clearCurrentScenarioTemplates } from 'store/actions/templates.action';
import classNames from 'classnames/bind';
import { ScenarioMetricsTable } from 'components/ScenarioSetup/ScenarioMetricsTable/ScenarioMetricsTable';
import { forEach } from 'lodash';
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import {
    configChartConversionsScenario,
    configChartRevenueScenario,
    configChartEnteredIntoScenario
} from 'data/configChart'
import { ChartMetrics } from 'components/Metrics/ChartMetrics'
import styles from 'components/Metrics/ChartMetrics.module.css';
import { useTranslation } from 'react-i18next';
import { RangeCalendarMetrics } from 'components/Metrics/RangeCalendarMetrics';
import { closeRangeCalendarMetrics } from 'store/actions/calendars.action';
import { TotalMetrics } from 'common/Metrics/TotalMetrics';

const cx = classNames.bind(styles);

const conversionActions = ['MetricsConversionRate', 'MetricsCompletedPurchases', 'MetricsAllClients'];

export const ScenarioMetricsPage = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();

    const monthMetrics = useSelector(store => store.scenarios.modifiedScenarioMetrics.monthMetrics);
    const { modifiedScenarioMetrics: { totalMetricsForPeriod }, currentScenario: { actions } } = useSelector(store => store.scenarios);
    const { metrics: templateMetrics, actionsList: templateActionsList } = useSelector(store => store.templates.currentScenarioTemplates);
    const { startDate, endDate } = useSelector(store => store.calendars.rangeCalendarMetrics);
    const loading = useSelector(store => store.api.isLoadingScenariosMetrics);

    const { id } = useParams();

    const trendCards = [
        {
            action: 'MetricsRevenue',
            percent: totalMetricsForPeriod.trend_revenue,
            count: totalMetricsForPeriod.revenue,
        },
        {
            action: 'MetricsAllClients',
            percent: totalMetricsForPeriod.trend_clients_entered,
            count: totalMetricsForPeriod.clients_entered,
        },
    ];

    useEffect(() => {
        dispatch(setScenarioTemplatesList(actions))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions]);

    useEffect(() => {
        if (startDate && endDate) {
            dispatch(getScenarioMetric(id, startDate, endDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            forEach(templateActionsList, item => item.template_unique_id && dispatch(getScenarioTemplateMetrics(id, item, startDate, endDate)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateActionsList, startDate, endDate]);

    useEffect(() => () => {
        dispatch(clearCurrentScenarioTemplates());
        dispatch(closeRangeCalendarMetrics())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { conversion_rate, clients_entered, clients_converted } = totalMetricsForPeriod;

    return (
        <div className={cx(styles.container, 'customGreenScrollbar')}>
            <div className={styles.totalMetricsHeader}>
                <SkeletonWrapper isShow={!loading} width={165} height={15}>
                    <h2>{translator('metrics:ScenarioMetricsTitle')}</h2>
                </SkeletonWrapper>

                <RangeCalendarMetrics loading={loading}/>
            </div>

            <TotalMetrics
                loading={loading}
                trendCards={trendCards}
                conversionActions={conversionActions}
                conversionRate={conversion_rate}
                leftCircleParams={clients_converted}
                rightCircleParams={clients_entered}
            />

            <SkeletonWrapper isShow={!loading} height={50}>
                <div className={styles.metricsTemplate}>
                    <h2>{translator('metrics:CommunicationsMetricsTitle')}</h2>
                    <ScenarioMetricsTable metrics={templateMetrics}/>
                </div>
            </SkeletonWrapper>

            <ChartMetrics config={configChartEnteredIntoScenario} loading={loading} monthMetrics={monthMetrics}/>

            <ChartMetrics config={configChartRevenueScenario} loading={loading} monthMetrics={monthMetrics}/>

            <ChartMetrics config={configChartConversionsScenario} loading={loading} monthMetrics={monthMetrics}/>
        </div>
    );
};
