import { defaultScheduleTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultScheduleTriggerSettings';
import { actionConditionDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/utils/actionConditionDecompiler';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';

const { atTheDay, time, values: { maxAudienceSize } } = defaultScheduleTriggerSettings();
const { _selectedTimeInterval: { _intervalType } } = conditionExample;

export const scheduleTriggerDecompiler = actionOptions => {

    const {
        datetime,
        max_audience_size,
        customer_segment,
        segment_id
    } = actionOptions;

    const localDate = datetime
        ? DateUse.convertUTCToLocal(datetime, 'YYYY-MM-DD HH:mm', 'DD/MM/YYYY')
        : null
    const localTime = datetime
        ? DateUse.convertUTCToLocal(datetime, 'YYYY-MM-DD HH:mm', 'HH:mm')
        : time

    const conditionConverter = actionConditionDecompiler(customer_segment, _intervalType);

    return {
        atTheDay: {
            ...atTheDay,
            value: localDate
        },
        time: localTime,
        values: {
            maxAudienceSize: {
                ...maxAudienceSize,
                value: StringUse.falseyReturnEmptyString(max_audience_size, 'string')
            }
        },
        condition: [
            ...conditionConverter,
        ],
        segmentId: segment_id
    }
}
