import React from 'react';
import PropTypes from 'prop-types';
import styles from 'layouts/_SelectContentLayout/SelectContentLayout.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const SelectContentLayout = ({
    sectionTitle,
    isNeededSectionTitle,
    childrenArrayList,
    actionButton,
    isNeededActionButton,
    className,
}) => (
    <section className={cx(styles.selectContentLayout, 'customGreenScrollbar', className)}>
        {isNeededSectionTitle
            ? <p className={styles.selectContentLayoutTitle}>{sectionTitle}</p>
            : null
        }

        <div className={styles.selectContentItems}>
            {childrenArrayList}
        </div>

        {isNeededActionButton
            ? actionButton
            : null
        }
    </section>
);
SelectContentLayout.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    isNeededSectionTitle: PropTypes.bool,
    childrenArrayList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]),
    actionButton: PropTypes.element,
    isNeededActionButton: PropTypes.bool,
    className: PropTypes.string
};
SelectContentLayout.defaultProps = {
    sectionTitle: '',
    isNeededSectionTitle: true,
    childrenArray: [],
    isNeededActionButton: true,
    className: ''
};
