import React, { useContext } from 'react';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { useTranslation } from 'react-i18next';
import { SideBarContext, } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map } from 'lodash';
import { UrlsMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Urls/UrlsMapped';

export const UrlsDropdown = () => {
    const {
        onClickCreateNewDropdown,
        urls,
        setUrls,
        urlsExample
    } = useContext(SideBarContext);

    const { t: translator } = useTranslation();

    return (
        <>
            <AsideDropdownButton
                name={translator('actionSidebars:NewUrlButton')}
                onClick={onClickCreateNewDropdown(setUrls, urlsExample)}
            />
            <ul className={styles.filter}>
                {map(urls, (item, index) => <UrlsMapped key={item.id} item={item} index={index}/>)}
            </ul>
        </>
    )
}
