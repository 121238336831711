import React from 'react';
import 'assets/global/table.css'
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import { map, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PaginationWrapper } from 'common/_Pagination/PaginationWrapper';
import { NcLoader } from 'common/_NcLoader/NcLoader';

const columns = [
    {
        name: 'TableColumnActionName',
        selector: row => row.action_name,
        sortable: false,
    },
    {
        name: 'TableColumnTemplateName',
        selector: row => row.template_name,
        sortable: false,
    },
    {
        name: 'TableColumnMsgSend',
        selector: row => row.msg_sent,
        sortable: false,
    },
    {
        name: 'TableColumnDelivered',
        selector: row => row.msg_delivered,
        sortable: false,
    },
    {
        name: 'TableColumnOpened',
        selector: row => row.msg_opened,
        sortable: false,
    },
    {
        name: 'TableColumnClicked',
        selector: row => row.msg_clicked,
        sortable: false,
    },
    {
        name: 'TableColumnPurchased',
        selector: row => row.purchases,
        sortable: false,
    },
    {
        name: 'TableColumnConversion',
        selector: row => row.msg_conversion,
        sortable: false,
    },
    {
        name: 'TableColumnRevenue',
        selector: row => row.revenue,
        sortable: false,
    },
];

export const ScenarioMetricsTable = ({ metrics }) => {
    const { t: translator } = useTranslation();

    return (
      <div className='tableWrapper tableWrapperMetrics'>
          <DataTable
              keyField='id'
              columns={map(columns, column => ({ ...column, name: translator(`metricsTable:${column.name}`) }) )}
              data={values(metrics)}
              noHeader
              highlightOnHover
              pointerOnHover
              pagination
              noDataComponent={translator('metricsTable:TableNoDataMessage')}
              paginationComponent={PaginationWrapper}
              paginationPerPage={20}
              progressComponent={<NcLoader/>}
          />
       </div>
  )
};

ScenarioMetricsTable.propTypes = {
    metrics: PropTypes.object.isRequired,
};
