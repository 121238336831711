import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { attributeFilterCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/attributeFilterCompiler';
import { StringUse } from 'lib/StringUse';

export const triggerEventCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        position,
        actionParams,
        settings: {
            values: {
                actionName,
                actionSubName
            },
            attributeFilter
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const attributeFilterCompiled = attributeFilterCompiler(attributeFilter);

    return {
        trigger_id: id,
        trigger_type: 'on_event',
        trigger_params: actionParams,
        coordinates: position,
        trigger_options: {
            event_name: StringUse.recast(actionName?.value, { accepted: 'string', rejected: null }),
            event_subname: StringUse.recast(actionSubName?.value, { accepted: 'string', rejected: null }),
            conditions: [
                ...attributeFilterCompiled
            ],
        },
        next_actions_ids: [
            ...nextActionIdsCompiled
        ]
    }
}
