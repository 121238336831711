import { map, includes } from 'lodash';
import { defaultRepeatTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultRepeatTriggerSettings';
import { actionConditionDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/utils/actionConditionDecompiler';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';

const { dayOfWeek, time, values: { maxAudienceSize } } = defaultRepeatTriggerSettings();
const { _selectedTimeInterval: { _intervalType } } = conditionExample;

export const repeatTriggerDecompiler = actionOptions => {

    const {
        schedule: {
            days_of_the_week,
            time: scheduleTime = time
        },
        max_audience_size,
        customer_segment,
        segment_id
    } = actionOptions;

    const scheduleDays = map(dayOfWeek, (item, index) => includes(days_of_the_week, index) ? {...item, isChecked: true} : {...item, isChecked: false});

    const conditionConverter = actionConditionDecompiler(customer_segment, _intervalType);

    const localTime = DateUse.convertUTCToLocal(scheduleTime, 'HH:mm', 'HH:mm');

    return {
        dayOfWeek: scheduleDays || dayOfWeek,
        time: localTime,
        values: {
            maxAudienceSize: {
                ...maxAudienceSize,
                value: StringUse.falseyReturnEmptyString(max_audience_size, 'string')
            }
        },
        condition: [
            ...conditionConverter,
        ],
        segmentId: segment_id
    }
}
