import React, { useCallback, useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map, isEmpty } from 'lodash';
import BlueButton from 'common/_Buttons/_BlueButton/BlueButton';
import { useTranslation } from 'react-i18next';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const TriggerEventSimplified = () => {
    const { t: translator } = useTranslation();

    const { simplifiedSettingsButtons, setSimplifiedSettingsButtons, errors, onClickSelectSimplifiedButton } = useContext(SideBarContext);

    const actionHasValidationErrors = !isEmpty(errors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClickSimplifiedButtonCached = useCallback(onClickSelectSimplifiedButton(setSimplifiedSettingsButtons), []);

    return (
        <div className={styles.simplifiedButtonsContainer}>
            <p className={styles.sidebarSectionName}>{translator('actionSidebars:Conditions')}</p>

            {map(simplifiedSettingsButtons, button =>
                <BlueButton
                    key={button.name}
                    value={button.name}
                    name={translator(`actionSidebars:${button.name}`)}
                    onClick={onClickSimplifiedButtonCached}
                    className={cx({ 'notSelectedSimplifiedActionSidebarButton': !button.selected, 'simplifiedButtonNotSelected': actionHasValidationErrors })}
                />
            )}

            <ErrorMessageField active={actionHasValidationErrors} message='ChooseAtLeastOneOption'/>
        </div>
    )
};
