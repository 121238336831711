import React, { useEffect, useMemo } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectActionTemplate } from 'store/actions/scenariosActions.action';
import { SectionHeaderWrapper } from 'common/Headers/SectionHeader/SectionHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { findScenariosAction } from 'store/selectors/scenariosActions.selectors';
import { TemplatesListLayout } from 'layouts/_TemplatesListLayout/TemplatesListLayout';
import { CreateTemplateButton } from 'components/Buttons/CreateTemplateButton/CreateTemplateButton';
import { NcLoader } from 'common/_NcLoader/NcLoader';
import { useLoader } from 'hooks/useLoader';
import { useTemplates } from 'hooks/TemplatesSetup/useTemplates';

const actionTypes = {
    SendEmail: 'email',
    SendSms: 'sms',
    SendViber: 'viber',
    WebsiteBuilder: 'website',
    SendMobPush: 'mobpushnotify',
    SendWebPush: 'webpushnotify'
};

export const ActionTemplatesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { goal, id } = useParams();
    const { t: translator } = useTranslation();

    const { id: actionId, type: actionType } = useSelector(store => store.scenariosActions.actionSidebar);

    const action = useSelector(store => findScenariosAction(store, actionId));

    const { templatesList, CardTemplate, type, isLoadingTemplates } = useTemplates(actionTypes[actionType]);

    const loader = useLoader(isLoadingTemplates, [isLoadingTemplates, type]);

    const closeSelectTemplatePage = () => navigate(`/communications/goal/${goal}/${id}`);

    const selectTemplate = template => {
        return () => {
            return dispatch(selectActionTemplate(actionId, template.template_unique_id)).then(() => closeSelectTemplatePage());
        }
    };

    const getActionSelectedTemplate = () => {
        return () => {
            if (action) {
                const { settings: { templateUniqueId = '' }} = action;

                return templateUniqueId;
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedTemplate = useMemo(getActionSelectedTemplate(), [action]);

    useEffect(() => {
        if (!actionId) {
            navigate(`/communications/goal/${goal}/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SectionHeaderWrapper
                sectionHeaderTitle={action?.title}
                closeButtonCallback={closeSelectTemplatePage}
            />

            <TemplatesListLayout
                creativesType={type}
                sectionTitle={translator('default:ActionTemplatePages')}
                newTemplateButton={<CreateTemplateButton typeMessage={type}/>}
                creativesListArray={
                    loader
                        ? <NcLoader/>
                        : map(templatesList, (item, key) => (
                            <React.Fragment key={key}>
                                {action &&
                                    <CardTemplate
                                        templateId={selectedTemplate}
                                        template={item}
                                        templateType={type}
                                        isShowChainButton={false}
                                        redirectPath='edit-template'
                                        onClick={selectTemplate(item)}
                                    />
                                }
                            </React.Fragment>
                ))}
            />
        </>
    );
};
