import React from 'react';
import PropTypes from 'prop-types';
import { eq } from 'lodash';
import { Pagination } from 'common/_Pagination/Pagination';
import { NumberUse } from 'lib/NumberUse';

export const PaginationWrapper = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
    const pagesCount = NumberUse.getNumberOfPages(rowCount, rowsPerPage);

    const onClickFirstPage = () => onChangePage(1);
    const onClickPreviousPage = () => onChangePage(currentPage <= 1 ? 1 : currentPage - 1);
    const onClickNextPage = () => onChangePage(currentPage >= pagesCount ? pagesCount : currentPage + 1);
    const onClickLastPage = () => onChangePage(pagesCount);
    const onClickSetCurrentPage = num => {
        return () => onChangePage(num);
    };

    return (
        <Pagination
            onClickFirstPage={onClickFirstPage}
            onClickPreviousPage={onClickPreviousPage}
            onClickNextPage={onClickNextPage}
            onClickLastPage={onClickLastPage}
            onClickSetCurrentPage={onClickSetCurrentPage}
            isDisabledLeftControlButtons={eq(currentPage, 1)}
            isDisabledRightControlButtons={eq(currentPage, pagesCount)}
            currentPage={currentPage}
            rowsCount={rowCount}
            pageSize={rowsPerPage}
        />
    )
};

PaginationWrapper.propTypes = {
    rowsPerPage: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired
};
