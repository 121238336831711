import React from 'react';
import { map } from 'lodash'
import PropTypes from 'prop-types';
import styles from 'components/SelectOptions/SelectOptions.module.css';
import classNames from 'classnames/bind';
import { Label } from 'common/_Label/Label';

const cx = classNames.bind(styles);

export const ConsentCategoriesSelectOptions = ({ onChange, required, currentValue, optionsArray, id, name, label, selectWithoutErrors }) => {
    return (
        <div>
            <Label htmlFor={name} text={label}/>
            <select
                name={name}
                id={id}
                className={cx(styles.selectContainer, styles.long, { 'selectWithoutErrors': selectWithoutErrors })}
                onChange={onChange}
                required={required}
                value={currentValue}
            >
                {map(optionsArray, option => {
                        const { consent_category_id, consent_category_name } = option;
                        return <option key={consent_category_id} value={consent_category_id}>{consent_category_name}</option>
                    }
                )}
            </select>
        </div>
    )
}
ConsentCategoriesSelectOptions.propsTypes = {
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    currentValue: PropTypes.string.isRequired,
    optionsArray: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectWithoutErrors: PropTypes.bool
}
ConsentCategoriesSelectOptions.defaultProps = {
    selectWithoutErrors: false
}

