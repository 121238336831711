import React from 'react';
import { eq, find } from 'lodash';
import styles from 'components/Cards/Cards.module.css';
import { CreativeCardWrapper } from 'components/Cards/CreativeCards/CreativeCardWrapper';
import { CreativeCardFooter } from 'components/Cards/CreativeCards/CreativeCardFooter';
import defaultCardImg from 'assets/img/DefaultTemplate.svg'
import PropTypes from 'prop-types';

export const WebsiteBuilderCard = ({
    template,
    templateId,
    templateType,
    isShowChainButton,
    isShowUsage,
    redirectPath,
    onClick,
    containerClassName
}) => {
    const { template_unique_id, jinja_fields } = template;

    const jinjaParamsValue = find(jinja_fields, { jinja_field_name: 'jinja_params' })?.value;

    const getPreviewImage = () => {
        if (jinjaParamsValue) {
            const { image } = JSON.parse(jinjaParamsValue);

            return image ? image.url : defaultCardImg;
        }

        return defaultCardImg;
    }

    return (
        <CreativeCardWrapper
            isSelected={eq(templateId, template_unique_id)}
            cardType={templateType}
            className={containerClassName}
        >
            <div className={styles.creativeCardPreview} onClick={onClick}>
                <div className={styles.websiteBuilderCardContainer}>
                    <div className={styles.websiteBuilderCard} style={{ backgroundImage: `url( ${getPreviewImage()} )` }}/>
                </div>
            </div>
            <CreativeCardFooter {...{ template, isShowChainButton, isShowUsage, redirectPath }}/>
        </CreativeCardWrapper>
    )
};

WebsiteBuilderCard.propTypes = {
    template: PropTypes.object.isRequired,
    templateId: PropTypes.string,
    templateType: PropTypes.string,
    isShowChainButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string
};
WebsiteBuilderCard.defaultProps = {
    isShowUsage: true,
    containerClassName: ''
};
