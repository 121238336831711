import React from 'react';

export const InputOpenIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15 1H1V3H15V1ZM1 0C0.447715 0 0 0.447715 0 1V3C0 3.55228 0.447715 4 1 4H15C15.5523 4 16 3.55228 16 3V1C16 0.447715 15.5523 0 15 0H1Z" fill="#1477F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 0.5V4H11V0.5H12Z" fill="#1477F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 6.5V9.5H8V6.5H9Z" fill="#1477F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11 13V16H10V13H11Z" fill="#1477F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 7H1L1 9H12V7ZM1 6C0.447715 6 0 6.44772 0 7V9C0 9.55228 0.447715 10 1 10H12C12.5523 10 13 9.55228 13 9V7C13 6.44772 12.5523 6 12 6H1Z" fill="#1477F8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 13H1L1 15H14V13ZM1 12C0.447715 12 0 12.4477 0 13V15C0 15.5523 0.447715 16 1 16H14C14.5523 16 15 15.5523 15 15V13C15 12.4477 14.5523 12 14 12H1Z" fill="#1477F8"/>
    </svg>
);
