import React from 'react';

export const SendEmailIconAlt = ({ borderColor }) => (
    <svg width="60" height="48" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M49 1.66103e-06C51.7614 1.78174e-06 54 2.23858 54 5V43C54 45.7614 51.7614 48 49 48H11C8.23858 48 6 45.7614 6 43L6 5C6 2.23858 8.23858 -1.20706e-07 11 0L49 1.66103e-06Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53 43V5C53 2.79086 51.2091 1 49 1L11 1C8.79086 1 7 2.79086 7 5L7 43C7 45.2091 8.79086 47 11 47H49C51.2091 47 53 45.2091 53 43ZM54 5C54 2.23858 51.7614 1.78174e-06 49 1.66103e-06L11 0C8.23858 -1.20706e-07 6 2.23858 6 5L6 43C6 45.7614 8.23858 48 11 48H49C51.7614 48 54 45.7614 54 43V5Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42 15.6659H18C17.4477 15.6659 17 16.1136 17 16.6659V31.3326C17 31.8849 17.4477 32.3326 18 32.3326H42C42.5523 32.3326 43 31.8849 43 31.3326V16.6659C43 16.1136 42.5523 15.6659 42 15.6659ZM18 14.6659C16.8954 14.6659 16 15.5614 16 16.6659V31.3326C16 32.4372 16.8954 33.3326 18 33.3326H42C43.1046 33.3326 44 32.4372 44 31.3326V16.6659C44 15.5614 43.1046 14.6659 42 14.6659H18Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7827 15.5124C16.9594 15.3003 17.2747 15.2716 17.4869 15.4484C19.2309 16.9017 21.9628 19.0813 24.4877 20.8952C25.751 21.8028 26.954 22.6126 27.9512 23.1934C28.4503 23.4841 28.8887 23.712 29.2514 23.8658C29.6287 24.0258 29.8721 24.0825 30.0001 24.0825C30.1375 24.0825 30.3841 24.029 30.7551 23.8851C31.1136 23.746 31.5455 23.5397 32.0368 23.276C33.0186 22.7491 34.2031 22.0116 35.4538 21.177C37.9536 19.5088 40.6844 17.4754 42.5236 16.0234C42.7403 15.8523 43.0547 15.8893 43.2259 16.106C43.397 16.3227 43.36 16.6372 43.1432 16.8083C41.288 18.2729 38.5354 20.3228 36.0089 22.0088C34.7464 22.8513 33.5319 23.6086 32.5097 24.1572C31.999 24.4313 31.5276 24.658 31.1168 24.8174C30.7183 24.972 30.3294 25.0825 30.0001 25.0825C29.6614 25.0825 29.2631 24.9569 28.8611 24.7865C28.4446 24.6099 27.966 24.3593 27.4479 24.0575C26.4107 23.4534 25.1783 22.6226 23.9042 21.7073C21.3541 19.8754 18.6027 17.6799 16.8467 16.2166C16.6345 16.0398 16.6059 15.7245 16.7827 15.5124ZM23.8924 23.606C24.1095 23.7766 24.1472 24.0909 23.9766 24.3081L17.5599 32.4747C17.3893 32.6919 17.075 32.7296 16.8579 32.559C16.6407 32.3884 16.603 32.0741 16.7736 31.8569L23.1903 23.6902C23.3609 23.4731 23.6752 23.4354 23.8924 23.606ZM36.1078 23.606C36.325 23.4354 36.6393 23.4731 36.8099 23.6902L43.2266 31.8569C43.3972 32.0741 43.3595 32.3884 43.1423 32.559C42.9252 32.7296 42.6109 32.6919 42.4403 32.4747L36.0236 24.3081C35.853 24.0909 35.8907 23.7766 36.1078 23.606Z" fill={borderColor}/>
        <path d="M60 24C60 27.3137 57.3137 30 54 30C50.6863 30 48 27.3137 48 24C48 20.6863 50.6863 18 54 18C57.3137 18 60 20.6863 60 24Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M54 29C56.7614 29 59 26.7614 59 24C59 21.2386 56.7614 19 54 19C51.2386 19 49 21.2386 49 24C49 26.7614 51.2386 29 54 29ZM54 30C57.3137 30 60 27.3137 60 24C60 20.6863 57.3137 18 54 18C50.6863 18 48 20.6863 48 24C48 27.3137 50.6863 30 54 30Z" fill="white"/>
        <path d="M12 24C12 27.3137 9.31371 30 6 30C2.68629 30 0 27.3137 0 24C0 20.6863 2.68629 18 6 18C9.31371 18 12 20.6863 12 24Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6 29C8.76142 29 11 26.7614 11 24C11 21.2386 8.76142 19 6 19C3.23858 19 1 21.2386 1 24C1 26.7614 3.23858 29 6 29ZM6 30C9.31371 30 12 27.3137 12 24C12 20.6863 9.31371 18 6 18C2.68629 18 0 20.6863 0 24C0 27.3137 2.68629 30 6 30Z" fill="white"/>
    </svg>
);
