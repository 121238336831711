import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from 'common/_Label/Label.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Label = memo(({ htmlFor, text, className }) => (
    <label htmlFor={htmlFor} className={cx(styles.label, className)}>
        {text}
    </label>
));

Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string
};
Label.defaultProps = {
    text: 'Default input label',
    className: null
};
