import React from 'react';

export const IntegrationIcon = ({ color }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="8" rx="2" fill={color}/>
            <rect x="12" width="8" height="8" rx="2" fill={color}/>
            <rect y="12" width="8" height="8" rx="2" fill={color}/>
            <rect x="12" y="12" width="8" height="8" rx="2" fill={color}/>
        </svg>
    )
};
