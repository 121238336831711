import React, { useEffect, useState } from 'react';
import { eq, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setActionsCancelChanges, setCurrentScenatioActionsChainListValidationReport } from 'store/actions/scenariosActions.action';
import { useNavigate, useParams } from 'react-router-dom';
import { changeScenarioName, silentUpdateScenario, startCurrentScenario, stopCurrentScenario, updateScenario } from 'store/actions/scenarios.action';
import { actionChainCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/actionChainCompiler';
import { confirmAlerter } from 'common/Alerters/confirmAlerter';
import { DashboardHeaderWrapper } from 'common/Headers/DashboardHeader/DashboardHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { cancelChangesScenarioConfig, updateScenarioConfig } from 'data/configSystemPopUps';
import { useToggle } from 'hooks/useToggle';
import { actionChainControl } from 'pages/Communications/ScenarioSetupPage/actionControls/actionChainControl';
//import { usePageLeaveInterrupt } from 'hooks/usePageLeaveInterrupt';
import { useToast } from 'hooks/useToast';

export const ScenarioSetupHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { t: translator } = useTranslation();

    const { id } = params;

    const { currentScenario } = useSelector(store => store.scenarios);
    const {
        scenario_unique_id,
        is_library,
        scenario_status,
        scenario_name,
        scenario_status: { color, scenario_status_name } = { color: '#828282', scenario_status_name: 'Draft' }
    } = currentScenario;

    const actionSidebar = useSelector(store => store.scenariosActions.actionSidebar);

    const {
        actionsList,
        actionConnectionsList,
    } = useSelector(store => store.scenariosActions);

    const { addToast } = useToast();

    const [isEditScenarioName, setIsEditScenarioName] = useState(false);
    const [scenarioNameField, setScenarioNameField] = useState('');
    const [errors, setErrors] = useState(false);
    const [isDisabledHeaderButtons, setDisabledHeaderButtons] = useState(false);

    const [isToggle, setToggle] = useToggle();

    const isActiveScenario = eq(scenario_status?.scenario_status_name, 'Active');
    const isOpenCustomWebHook = eq(actionSidebar.type, 'CustomWebHook');
    const isOpenSqlTrigger = eq(actionSidebar.type, 'SqlTrigger');
    //const isScenarioWithoutChanges = isEqual(actionsList, baseActionsChainList) && isEqual(actionConnectionsList, baseArrowsChainList);

    const editScenarioTitle = event => {
        errors && setErrors(false);
        setScenarioNameField(event.target.value);
    };

    const cancelChangesAnnotation = () => confirmAlerter(cancelChangesScenarioConfig);

    const closeEditScenarioNameField = () => setIsEditScenarioName(false);

    const saveScenarioName = scenarioName => {
        return () => {
            if (scenarioName.length) {
                if (scenarioName.length > 120) {
                    setErrors(true);

                    return addToast('Scenario name must be up to 120 characters', 'warning');
                }

                errors && setErrors(false);

                return dispatch(changeScenarioName(
                    scenario_unique_id,
                    { ...actionChainCompiler(currentScenario, actionsList, actionConnectionsList), scenario_name: scenarioName },
                    closeEditScenarioNameField
                ));
            }

            return setErrors(true);
        }
    };

    const successfulSaveScenario = () => confirmAlerter(updateScenarioConfig);

    //const pageLeaveInterruptModal = successCallback => confirmAlerter(closeEditScenarioWindowConfig, successCallback);

    const onClickCloseEditScenarioPage = () => navigate(`/communications/goal/all-scenarios`);

    const isDisableHeaderButtons = () => some([isOpenCustomWebHook, isOpenSqlTrigger]);

    const handleUpdateScenario = () => dispatch(updateScenario(
        scenario_unique_id,
        actionChainCompiler(currentScenario, actionsList, actionConnectionsList),
        successfulSaveScenario
    ));

    const cancelEditScenario = () => dispatch(setActionsCancelChanges()).then(() => cancelChangesAnnotation());

    const launchScenario = id => {
        return () => {
            if (isActiveScenario) {
                return dispatch(stopCurrentScenario(id));
            }

            const onSuccessSilentUpdateScenario = id => {
                return () => {
                    const turnOnToggle = () => setToggle(true);

                    dispatch(startCurrentScenario(id, turnOnToggle));
                };
            };

            const actionsControlReport = actionChainControl(actionsList);

            dispatch(setCurrentScenatioActionsChainListValidationReport(actionsControlReport))
                .then(hasErrors => {
                    if (!hasErrors) {
                        const compiledScenarioData = actionChainCompiler(currentScenario, actionsList, actionConnectionsList);

                        dispatch(silentUpdateScenario(scenario_unique_id, compiledScenarioData, onSuccessSilentUpdateScenario(id)));
                    }
                })
        }
    };

    const onClickShareButton = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => addToast('Copy scenario URL to clipboard!'))
            .catch(() => addToast('Something wrong with "Copy scenario URL to clipboard"'));
    };

    useEffect(() => {
        if (scenario_name) {
            setScenarioNameField(scenario_name);
        }
    }, [scenario_name]);

    useEffect(() => {
        setDisabledHeaderButtons(isDisableHeaderButtons())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionSidebar]);

    useEffect(() => {
        if (scenario_status) {
            setToggle(isActiveScenario);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenario_status])

    //usePageLeaveInterrupt(pageLeaveInterruptModal, !isScenarioWithoutChanges);

    const dashboardHeaderWrapperProps = {
        title: {
            isNameOnEditMode: isEditScenarioName,
            name: scenarioNameField,
            isNameHasError: errors,
            onClickSetNameToEditMode: () => setIsEditScenarioName(true),
            onChangeName: editScenarioTitle,
            onClickSaveName: saveScenarioName(scenarioNameField)
        },
        status: {
            isShowLaunchButton: !is_library,
            launchButtonSettings: {
                isChecked: isToggle,
                onChange: launchScenario(scenario_unique_id)
            },
            isShowStatus: !is_library,
            statusName: scenario_status_name && !is_library ? translator(`scenarioStatuses:${scenario_status_name}`) : translator(`scenarioStatuses:Library`),
            statusMessage: translator('dashboardHeader:ScenariosStatusTitle'),
            color: color
        },
        buttons: {
            onClickCancelChangesButton: cancelEditScenario,
            onClickSaveChangesButton: handleUpdateScenario,
            onClickCloseButton: onClickCloseEditScenarioPage,
            isDisabledHeaderButtons: isDisabledHeaderButtons,
            onClickShareButton,
        },
        navLinkSwitcher: {
            navLinkTabButtons: [
                { name: translator('dashboardHeader:DesignNavLink'), link: `/communications/goal/all-scenarios/${id}`, isShow: true },
                { name: translator('dashboardHeader:MetricsNavLink'), link: `/communications/goal/all-scenarios/${id}/metrics`, isShow: true },
                { name: translator('dashboardHeader:RequestBodyNavLink'), link: `/communications/goal/all-scenarios/${id}/custom-webhook`, isShow: isOpenCustomWebHook },
                { name: translator('dashboardHeader:SqlTriggerNavLink'), link: `/communications/goal/all-scenarios/${id}/sql-trigger`, isShow: isOpenSqlTrigger },
            ]
        }
    }

    return <DashboardHeaderWrapper {...dashboardHeaderWrapperProps} />
};
