import { eq, map, trimEnd } from 'lodash';

export const nextActionIdsCompiler = (arrows, actionType) => map(arrows, arrowObj => {

    if (eq(actionType, 'ABTestSplit') || eq(actionType, 'ConditionalSplit')) {
        return map(arrowObj, item => {
            const { to: { id } } = item;

            return trimEnd(id, '-input');
        })
    }

    const { to: { id } } = arrowObj;

    return trimEnd(id, '-input');
});
