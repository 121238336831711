import React from 'react';
import { TriggerEventIcon } from 'assets/icons/TriggerEventIcon';
import TriggerEventPreview from 'assets/img/TriggerEventPreview.svg';
import { RepeatTriggerIcon } from 'assets/icons/RepeatTriggerIcon';
import RepeatTriggerPreview from 'assets/img/RepeatTriggerPreview.svg';
import { ScheduleTriggerIcon } from 'assets/icons/ScheduleTriggerIcon';
import ScheduleTriggerPreview from 'assets/img/ScheduleTriggerPreview.svg';
import { WaitIcon } from 'assets/icons/WaitIcon';
import WaitPreview from 'assets/img/WaitPreview.svg';
import { TailoredWaitIcon } from 'assets/icons/TailoredWaitIcon';
import TailoredWaitPreview from 'assets/img/TailoredWaitPreview.svg';
import { ConditionalSplitIcon } from 'assets/icons/ConditionalSplitIcon';
import ConditionalSplitPreview from 'assets/img/ConditionalSplitPreview.svg';
import { ABTestSplitIcon } from 'assets/icons/ABTestSplitIcon';
import ABTestPreview from 'assets/img/ABTestPreview.svg';
import { FunnelIcon } from 'assets/icons/FunnelIcon';
import FunnelPreview from 'assets/img/FunnelPreview.svg';
import { SendEmailIcon } from 'assets/icons/SendEmailIcon';
import EmailPreview from 'assets/img/EmailPreview.svg';
import { SendSmsIcon } from 'assets/icons/SendSmsIcon';
import SMSPreview from 'assets/img/SMSPreview.svg';
import { SendViberIcon } from 'assets/icons/SendViberIcon';
import ViberPreview from 'assets/img/ViberPreview.svg';
import { CustomWebHookIcon } from 'assets/icons/CustomWebHookIcon';
import CustomWebHookPreview from 'assets/img/CustomWebHookPreview.svg';
import { SqlTriggerIcon } from 'assets/icons/SqlTriggerIcon';
import SqlTriggerPreview from 'assets/img/SqlTriggerPreview.svg';
import { WebsiteBuilderIcon } from 'assets/icons/WebsiteBuilderIcon';
import WebsiteBuilderPreview from 'assets/img/WebsiteBuilderPreview.svg';
import { MobilePushIcon } from 'assets/icons/MobilePushIcon';
import MobilePushPreview from 'assets/img/MobilePushPreview.svg';
import { WebPushIcon } from 'assets/icons/WebPushIcon';
import WebPushPreview from 'assets/img/WebPushPreview.svg';
import { EditSegmentIcon } from 'assets/icons/EditSegmentIcon';
import EditSegmentPreview from 'assets/img/EditSegmentPreview.svg'
// import { GoogleAdsIcon } from 'icons/GoogleAdsIcon';
// import GoogleAdsPreview from 'assets/img/GoogleAdsPreview.pictures'
// import { FacebookAdsIcon } from 'icons/FacebookAdsIcon';
// import FacebookAdsPreview from 'assets/img/FacebookAdsPreview.pictures'


export const DraggableActionsConfig = [
    [
        {
            item: <TriggerEventIcon width="32px" height="32px" color="#9088DF" bgColor="#EFEDFF"/>,
            actionType: 'TriggerEvent',
            title: 'Trigger Event',
            previewImg: TriggerEventPreview,
            hoverImg: <TriggerEventIcon width="32px" height="32px" color="#FFFFFF" bgColor="#9088DF"/>,
            hoverColorClass: 'purple'
        },
        {
            item: <RepeatTriggerIcon width="32px" height="32px" color="#9088DF" bgColor="#EFEDFF"/>,
            actionType: 'RepeatTrigger',
            title: 'Repeat Trigger',
            previewImg: RepeatTriggerPreview,
            hoverImg: <RepeatTriggerIcon width="32px" height="32px" color="#FFFFFF" bgColor="#9088DF"/>,
            hoverColorClass: 'purple'
        },
        {
            item: <ScheduleTriggerIcon width="32px" height="32px" color="#9088DF" bgColor="#EFEDFF"/>,
            actionType: 'ScheduleTrigger',
            title: 'Schedule Trigger',
            previewImg: ScheduleTriggerPreview,
            hoverImg: <ScheduleTriggerIcon width="32px" height="32px" color="#FFFFFF" bgColor="#9088DF"/>,
            hoverColorClass: 'purple'
        },
        {
            item: <SqlTriggerIcon width="32px" height="32px" color="#9088DF" bgColor="#EFEDFF"/>,
            actionType: 'SqlTrigger',
            title: 'SQL Trigger',
            previewImg: SqlTriggerPreview,
            hoverImg: <SqlTriggerIcon width="32px" height="32px" color="#FFFFFF" bgColor="#9088DF"/>,
            hoverColorClass: 'purple'
        },
    ],
    [
        {
            item: <WaitIcon width="32px" height="32px" color="#00C6A2" bgColor="#E3FFFA"/>,
            actionType: 'Wait',
            title: 'Wait',
            previewImg: WaitPreview,
            hoverImg: <WaitIcon width="32px" height="32px" color="#FFFFFF" bgColor="#00C6A2"/>,
            hoverColorClass: 'green'
        },
        {
            item: <TailoredWaitIcon width="32px" height="32px" color="#00C6A2" bgColor="#E3FFFA"/>,
            actionType: 'TailoredWait',
            title: 'Tailored Wait',
            previewImg: TailoredWaitPreview,
            hoverImg: <TailoredWaitIcon width="32px" height="32px" color="#FFFFFF" bgColor="#00C6A2"/>,
            hoverColorClass: 'green'
        },
        {
            item: <ConditionalSplitIcon width="32px" height="32px" color="#00C6A2" bgColor="#E3FFFA"/>,
            actionType: 'ConditionalSplit',
            title: 'Conditional Split',
            previewImg: ConditionalSplitPreview,
            hoverImg: <ConditionalSplitIcon width="32px" height="32px" color="#FFFFFF" bgColor="#00C6A2"/>,
            hoverColorClass: 'green'
        },
        {
            item: <ABTestSplitIcon width="32px" height="32px" color="#00C6A2" bgColor="#E3FFFA"/>,
            actionType: 'ABTestSplit',
            title: 'A/B Test Split',
            previewImg: ABTestPreview,
            hoverImg: <ABTestSplitIcon width="32px" height="32px" color="#FFFFFF" bgColor="#00C6A2"/>,
            hoverColorClass: 'green'
        },
        {
            item: <FunnelIcon width="32px" height="32px" color="#00C6A2" bgColor="#E3FFFA"/>,
            actionType: 'Funnel',
            title: 'Funnel',
            previewImg: FunnelPreview,
            hoverImg: <FunnelIcon width="32px" height="32px" color="#FFFFFF" bgColor="#00C6A2"/>,
            hoverColorClass: 'green'
        },
    ],
    [
        {
            item: <SendEmailIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'SendEmail',
            title: 'Send Email',
            previewImg: EmailPreview,
            hoverImg: <SendEmailIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <SendSmsIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'SendSms',
            title: 'Send SMS',
            previewImg: SMSPreview,
            hoverImg: <SendSmsIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <SendViberIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'SendViber',
            title: 'Send Viber',
            previewImg: ViberPreview,
            hoverImg: <SendViberIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <WebsiteBuilderIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'WebsiteBuilder',
            title: 'Website Builder',
            previewImg: WebsiteBuilderPreview,
            hoverImg: <WebsiteBuilderIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <CustomWebHookIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'CustomWebHook',
            title: 'Custom Webhook',
            previewImg: CustomWebHookPreview,
            hoverImg: <CustomWebHookIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <MobilePushIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'SendMobPush',
            title: 'Mobile Push',
            previewImg: MobilePushPreview,
            hoverImg: <MobilePushIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <WebPushIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'SendWebPush',
            title: 'Web Push',
            previewImg: WebPushPreview,
            hoverImg: <WebPushIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        {
            item: <EditSegmentIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
            actionType: 'EditSegment',
            title: 'Edit Segment',
            previewImg: EditSegmentPreview,
            hoverImg: <EditSegmentIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
            hoverColorClass: 'blue'
        },
        // {
        //     item: <GoogleAdsIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
        //     actionType: 'GoogleAds',
        //     title: 'Google Ads',
        //     previewImg: GoogleAdsPreview,
        //     hoverImg: <GoogleAdsIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
        //     hoverColorClass: 'blue'
        // },
        // {
        //     item: <FacebookAdsIcon width="32px" height="32px" color="#0CBFE7" bgColor="#E8F8FF"/>,
        //     actionType: 'FacebookAds',
        //     title: 'Facebook Ads',
        //     previewImg: FacebookAdsPreview,
        //     hoverImg: <FacebookAdsIcon width="32px" height="32px" color="#FFFFFF" bgColor="#0CBFE7"/>,
        //     hoverColorClass: 'blue'
        // },
    ],
];
