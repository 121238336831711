import {
    SET_RECOMMENDATION_UNIT_METRICS,
    UPDATE_RECOMMENDATION_UNIT_LIST,
    SET_RECOMMENDATION_PRODUCT,
    SET_SEGMENT_CLIENT_EVENT
} from 'store/types';

const initialState = {
    unitList: [],
    currentUnit: {
        metrics: {
            unit_aggregated_metrics: {},
            conversion_rate_significance: {},
            unit_ab_test_aggregated_metrics: {},
        },
        modifiedMetrics: {},
        namesGroupAB: [],
    },
    products: []
};

export default function recommendations(store = initialState, { type, payload }) {
    switch (type) {
        case UPDATE_RECOMMENDATION_UNIT_LIST: {
            return {
                ...store,
                unitList: payload
            }
        }
        case SET_RECOMMENDATION_UNIT_METRICS: {
            return {
                ...store,
                currentUnit: {
                    metrics: payload.metrics,
                    modifiedMetricsChart: payload.modifiedMetricsChart,
                    namesGroupAB: payload.namesGroupAB,
                }
            }
        }
        case SET_RECOMMENDATION_PRODUCT: {
            return {
                ...store, products: payload.data
            }
        }
        case SET_SEGMENT_CLIENT_EVENT:
            return {
                ...store, products: []
            }
        default:
            return store
    }
}
