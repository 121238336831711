import React, { useContext } from 'react';
import { map } from 'lodash';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { useTranslation } from 'react-i18next';
import { JsonHeaderMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/JsonHeader/JsonHeaderMapped';

export const JsonHeaderDropdown = () => {
    const { t: translator } = useTranslation();
    const {
        onClickCreateNewDropdown,
        jsonHeaderExample,
        jsonHeaders,
        setJsonHeaders
    } = useContext(SideBarContext);

    return (
        <>
            <AsideDropdownButton
                name={translator('actionSidebars:NewJsonHeaderButton')}
                onClick={onClickCreateNewDropdown(setJsonHeaders, jsonHeaderExample)}
            />
            <ul className={styles.filter}>
                {map(jsonHeaders, (item, index) => <JsonHeaderMapped key={item.id} item={item} index={index}/>)}
            </ul>
        </>
    );
};
