import { urlsExample } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';

export const defaultWebsiteBannerSettings = () => ({
    values: {
        communicationsMaxCount: {
            value: '',
            required: true
        },
        countTimeIntervalNumber: {
            value: '',
            required: true
        },
        countTimeIntervalType: {
            value: 'days',
            required: true
        },
        impressionsNumber: {
            value: '',
            required: false
        },
        consentCategory: {
            value: '33064260-9853-4e55-a682-f551772a6a87',
            required: true,
        },
    },
    displayDate: {
        value: null,
        required: false
    },
    urls: [
        { ...urlsExample, id: 1 }
    ]
})
