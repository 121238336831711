import React, { useRef } from 'react';
import { eq } from 'lodash';
import styles from 'common/Headers/Headers.module.css';
import animations from 'animations/FadeIn.module.css';
import PropTypes from 'prop-types';
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import classNames from 'classnames/bind';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

const cx = classNames.bind(styles);

export const DashboardHeaderTitle = ({
    isNameOnEditMode,
    name,
    isNameHasError,
    setErrorsEditedName,
    onClickSetNameToEditMode,
    onChangeName,
    onClickSaveName,
    optionalInputName
}) => {
    const ref = useRef(null);

    const onKeyDownSaveTitle = event => {
        if (eq(event.key, 'Enter') || eq(event.key,'NumpadEnter')) {
            event.preventDefault();

            if (name.length === 0) {
                return setErrorsEditedName(true)
            }

            return onClickSaveName();
        }
    };

    useOutsideAlerter(ref, onClickSaveName);

    return (
        <>
            {!isNameOnEditMode &&
                <SkeletonWrapper isShow={!!name} width={200} height={18} style={{ marginBottom: '6px' }}>
                    <div className={styles.dashboardHeaderTitle}>
                        <h1 className={styles.dashboardHeaderTitleName}
                            onClick={onClickSetNameToEditMode}
                        >
                            {name}
                        </h1>
                    </div>
                </SkeletonWrapper>}

            {isNameOnEditMode &&
                <div className={cx(styles.dashboardHeaderEditTitle, animations.fadeIn200ms)}>
                    <input
                        ref={ref}
                        className={cx({ 'error': isNameHasError })}
                        type='text'
                        value={name}
                        onChange={onChangeName}
                        onKeyDown={onKeyDownSaveTitle}
                        name={optionalInputName}
                    />
                </div>
            }
        </>
    )
}
DashboardHeaderTitle.propTypes = {
    isEditNameField: PropTypes.bool,
    name: PropTypes.string.isRequired,
    isNameHasError: PropTypes.bool,
    setIsEditTitleNameCallback: PropTypes.func,
    onChangeName: PropTypes.func,
    onClickSaveName: PropTypes.func,
    optionalInputName: PropTypes.string
}
DashboardHeaderTitle.defaultProps = {
    name: '',
    optionalInputName: 'name'
}
