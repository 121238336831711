import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/SwitchToggler/SwitchToggler.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const SwitchToggler = ({ name, onChange, isChecked, className, disabled }) => (
    <label className={styles.switch}>
        <input
            name={name}
            className={className}
            type='checkbox'
            checked={isChecked}
            onChange={onChange}
            disabled={disabled}
        />
        <span className={cx(styles.slider, styles.round)}/>
    </label>
);

SwitchToggler.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};
SwitchToggler.defaultProps = {
    name: null,
    isChecked: false,
    className: null,
    disabled: false
};
