export const defaultTailoredWaitSettings = () => ({
    waitToDate: {
        value: '',
        required: false
    },
    dateOffset: {
        value: '',
        required: false
    },
    waitToTime: {
        value: '',
        required: false
    },
    timeOffset: {
        value: '',
        required: false
    }
})
