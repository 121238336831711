import {
    LOGIN_USER,
    LOGOUT_USER
} from 'store/types';

const initialState = {
    isAuthenticated: false,
    user: {
        username: null,
        token: null,
        tokenExpireDate: null,
        sandboxMode: false
    }
}

export default function auth(store = initialState, { type, payload }) {
    switch (type) {
        case LOGIN_USER:
            return {
                ...store, user: payload.data, isAuthenticated: true
            };
        case LOGOUT_USER:
            return {
                ...store, user: payload.defaultUsernameStore, isAuthenticated: false
            };
        default:
            return store;
    }
}
