import { snakeCase, map, zipObject, isEmpty } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { StringUse } from 'lib/StringUse';

export const customWebHookCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        title,
        position,
        settings: {
            values: {
                consentCategory,
                maxFrequencyNumber,
                maxFrequencyType,
                maxNumberOfRequests,
                method,
                url
            },
            jsonHeaders,
            jsonBody
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const requestHeaderCompiled = zipObject(
        map(jsonHeaders, key => {
            const { headerKey } = key;
            return headerKey.value
        }),
        map(jsonHeaders, key => {
            const { headerValue } = key;
            return headerValue.value
        })
    );

    return {
        action_id: id,
        action_type: snakeCase(title),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            consent_category_id: StringUse.recast(consentCategory.value, { accepted: 'string', rejected: null }),
            max_frequency: {
                period_type: StringUse.recast(maxFrequencyType.value, { accepted: 'string', rejected: null }),
                period_number: StringUse.recast(maxFrequencyNumber.value, { accepted: 'number', rejected: null }),
                n_communications: StringUse.recast(maxNumberOfRequests.value, { accepted: 'number', rejected: null })
            },
            request_body: StringUse.recast(jsonBody, { accepted: 'string', rejected: null }),
            request_headers: !isEmpty(requestHeaderCompiled) ? requestHeaderCompiled : null,
            request_method: StringUse.recast(method.value, { accepted: 'string', rejected: null }),
            request_url: StringUse.recast(url.value, { accepted: 'string', rejected: null }),
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ]
    }
}
