import React, { useEffect, lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TemplateSetupPage = lazy(() => import('pages/Templates/TemplateSetupPage'));

export const ActionEditTemplatePage = () => {
    const navigate = useNavigate();
    const { goal, id } = useParams();
    const { id: actionId } = useSelector(store => store.scenariosActions.actionSidebar);

    useEffect(() => {
        if (!actionId) return navigate(`/communications/goal/${goal}/${id}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {actionId && <TemplateSetupPage />}
        </>
    );
};
