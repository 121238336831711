import {
    CLEAR_SCENARIO_DATA,
    SET_SCENARIO,
    SET_SCENARIO_COPY,
    SET_SCENARIO_CREATE,
    SET_SCENARIO_DELETE,
    SET_SCENARIO_METRIC,
    SET_SCENARIO_START,
    SET_CURRENT_SCENARIO_START,
    SET_SCENARIO_STOP,
    SET_CURRENT_SCENARIO_STOP,
    SET_SCENARIOS_FILTERED,
    SET_SCENARIOS_LIST,
    SET_SCENARIOS_METRICS,
    SET_SCENARIOS_SEARCHED,
    SET_SCENARIO_NAME_UPDATE,
    SET_SCENARIO_UPDATE,
    SET_LIBRARY_SCENARIOS_LIST
} from 'store/types';
import { LOGOUT_USER } from 'store/types';

const initialState = {
    list: [],
    listLibraryScenario: [],
    metrics: [],
    currentScenario: {},
    currentScenarioMetrics: [],
    modifiedScenarioMetrics: {
        monthMetrics: {},
        totalMetricsForPeriod: {},
    },
};

export default function scenarios(store = initialState, { type, payload }) {
    switch (type) {
        case SET_SCENARIOS_LIST:
            return {
                ...store, list: payload.data,
            };
        case SET_LIBRARY_SCENARIOS_LIST:
            return {
                ...store, listLibraryScenario: payload,
            };
        case SET_SCENARIO:
            return {
                ...store,
                currentScenario: payload.data,
            };
        case CLEAR_SCENARIO_DATA:
            return {
                ...store,
                currentScenario: payload.defaultCurrentScenario,
                currentScenarioMetrics: payload.defaultCurrentScenarioMetrics,
                modifiedScenarioMetrics: payload.defaultModifiedScenarioMetrics
            };
        case SET_SCENARIOS_SEARCHED:
            return {
                ...store, list: payload.data,
            };
        case SET_SCENARIO_CREATE:
            return {
                ...store, list: [...store.list, payload.scenario],
            };
        case SET_SCENARIO_UPDATE:
            return {
                ...store, currentScenario: {
                    ...store.currentScenario,
                    ...payload.data
                }
            };
        case SET_SCENARIO_NAME_UPDATE:
            return {
                ...store, currentScenario: {
                    ...store.currentScenario,
                    ...payload.data
                }
            };
        case SET_SCENARIO_START:
            return {
                ...store, list: payload.list,
            };
        case SET_CURRENT_SCENARIO_START:
            return {
                ...store, currentScenario: payload.scenario
            };
        case SET_SCENARIO_STOP:
            return {
                ...store, list: payload.list
            };
        case SET_CURRENT_SCENARIO_STOP:
            return {
                ...store, currentScenario: payload.scenario
            };
        case SET_SCENARIOS_FILTERED:
            return {
                ...store, list: payload.data,
            };
        case SET_SCENARIOS_METRICS:
            return {
                ...store, metrics: [...store.metrics, payload.metrics],
            };
        case SET_SCENARIO_METRIC:
            return {
                ...store,
                currentScenarioMetrics: payload.scenario_metrics,
                modifiedScenarioMetrics: payload.modifiedScenarioMetrics,
            };
        case SET_SCENARIO_COPY:
            return {
                ...store, list: payload.list,
            };
        case SET_SCENARIO_DELETE:
            return {
                ...store, list: payload.list
            };
        case LOGOUT_USER:
            return {
                ...store,
                list: payload.defaultScenariosStore.list,
                metrics: payload.defaultScenariosStore.metrics
            };
        default:
            return store;
    }
}
