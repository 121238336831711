import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { Label } from 'common/_Label/Label';
import { has, includes, isEmpty } from 'lodash';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { AttributeFilterDropdownTags } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDropdownTags';
import { ComparisonOperatorSelectOptions } from 'components/SelectOptions/ComparisonOperatorSelectOptions';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { StringUse } from 'lib/StringUse';
import { useSelector } from 'react-redux';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

const parsedErrors = (errors, conditionIndex, id) => {
    const isConditionHasErrors = !isEmpty(errors) && has(errors, 'conditionsErrors');

    if (isConditionHasErrors) {
        const { conditionsErrors } = errors;

        if (conditionsErrors[conditionIndex]) {
            const { validation: { attributeFilter } } = conditionsErrors[conditionIndex];

            return {
                eventAttributeName: {
                    hasError: includes(attributeFilter?.eventAttributeName?.id, id),
                    message: StringUse.falseyReturnEmptyString(attributeFilter?.eventAttributeName?.error)
                },
                comparisonOperator: {
                    hasError: includes(attributeFilter?.comparisonOperator?.id, id),
                    message: StringUse.falseyReturnEmptyString(attributeFilter?.comparisonOperator?.error)
                }
            }
        }
    }

    return {
        eventAttributeName: {
            hasError: false
        },
        comparisonOperator: {
            hasError: false
        }
    }
};

export const AttributeFilterDeepMapped = ({ item, setState, id, index, conditionIndex }) => {
    const { t: translator } = useTranslation();

    const dropdownFilters = useSelector(store => store.segments.dropdownFilters);
    const { allEvents } = useSelector(store => store.integrations);

    const {
        onChangeDropdownShowStatusMultiple,
        onClickRemoveDropdownItemMultiple,
        onChangeDropdownInputMultiple,
        onChangeConditionDropdownMultiple,
        errors,
        condition,
        getEventAttributeNames
    } = useContext(SideBarContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeConditionDropdownMultiple(item.id, setState, id), []);

    const { eventAttributeName, comparisonOperator } = parsedErrors(errors, conditionIndex, item.id);

    const mappedEventAttributeNames = has(dropdownFilters, 'product_attribute')
        ? getEventAttributeNames(condition[conditionIndex]._eventValues.actionName.value, allEvents.base, dropdownFilters['product_attribute'])
        : [];

    return (
        <>
            <li key={item.id}>
                <div className={styles.filterItemContainer}>
                    <AsideDropdownButtonItemButton
                        name={item?.eventAttributeName?.value || translator(`actionSidebars:${item?.filterName}`) + ' ' + (index + 1)}
                        onClick={onChangeDropdownShowStatusMultiple(item.id, setState, id)}
                        active={item.show}
                    />
                    <DeleteButton onClick={onClickRemoveDropdownItemMultiple(item.id, setState, id)}/>
                </div>

                {item.show && <div className={styles.filterContainer}>
                    <CustomSelectDropdown
                        dropdownName='eventAttributeName'
                        dropdownLabel={translator(`actionSidebars:${item.eventAttributeName.name}`)}
                        defaultDropdownValue={item.eventAttributeName.value}
                        dropdownOptions={mappedEventAttributeNames}
                        onChangeDropdown={onChangeDropdownCached}
                        isErrorExist={eventAttributeName.hasError}
                        isRequired={item.eventAttributeName.required}
                        isDisabled={!mappedEventAttributeNames.length}
                    />

                    <Label htmlFor="comparisonValue" text={translator(`actionSidebars:${item.comparisonValue.name}`)}/>
                    <AttributeFilterDropdownTags
                        id={item.id}
                        inputTagValues={item.comparisonValue.value}
                        inputTagName='comparisonValue'
                        setState={setState}
                        multipleFilter
                        multipleFilterParentId={id}
                    />

                    <ComparisonOperatorSelectOptions
                        translator={translator}
                        item={item}
                        onChangeHandler={onChangeDropdownInputMultiple(item.id, setState, id)}
                        comparisonOperatorType='attribute'
                        error={comparisonOperator.hasError}
                    />
                    <ErrorMessageField
                        active={comparisonOperator.hasError}
                        message={comparisonOperator.message}
                    />

                    <AsideDropdownButtonSeparateLine
                        onClick={onChangeDropdownShowStatusMultiple(item.id, setState, id)}
                    />
                </div>}
            </li>
        </>
    )
}
AttributeFilterDeepMapped.propTypes = {
    item: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    state: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    conditionIndex: PropTypes.number
}
