import { productAttributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ProductFilter/settings/defaultProductFilterSettings';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';

export const intervalTypeExtension = [
    {
        id: 'atScriptStart',
        name: 'AtScriptStartName',
        isChecked: false,
    },
    {
        id: 'lastTime',
        name: 'LastTimeIntervalLabel',
        isChecked: false,
    },
    {
        id: 'currentTime',
        name: 'CurrentTimeIntervalLabel',
        isChecked: true,
    },
];

export const defaultConditionalSplitSettings = () => ({
    splitType: [
        {
            id: 'event',
            name: 'EventConditionSplitTabButton',
            active: true,
        },
        {
            id: 'client',
            name: 'ClientConditionSplitTabButton',
            active: false,
        },
        {
            id: 'product',
            name: 'ProductConditionSplitTabButton',
            active: false,
        },
    ],
    values: {
        actionName: {
            value: '',
            required: true
        },
        subActionName: {
            value: '',
            required: false
        }
    },
    attributeFilter: [],
    productAttributeFilter: [
        { ...productAttributeFilterExample, id: 1 }
    ],
    condition: [
        { ...conditionExample,
            id: 1,
            _selectedTimeInterval: {
                ...conditionExample._selectedTimeInterval,
                _intervalType: [...intervalTypeExtension],
                _attributeFilter: []
            }
        },
    ],
    segment_filter: null
});
