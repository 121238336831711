import React, { useState } from 'react';
import styles from 'components/ScenarioSetup/ActionsListSidebar/ActionsListSidebar.module.css';
import { isEqual, map } from 'lodash';
import { DraggableActionsConfig } from 'components/ScenarioSetup/ActionsListSidebar/DraggableActionsConfig';
import { DraggableActionsView } from 'components/ScenarioSetup/ActionsListSidebar/DraggableActionView';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const ActionsListSidebar = () => {
    const [hoverActionType, setHoverActionType] = useState('');

    const onHoverEnter = action => {
        return () => setHoverActionType(action?.actionType);
    };

    const onHoverLeave = () => setHoverActionType('');

    return (
        <div className={cx(styles.scenariosLeftSideBar, 'customGreenScrollbar')}>
            {map(DraggableActionsConfig, (actionCategory, key) =>
                <div className={styles.scenariosItemContainer} key={key}>
                    {map(actionCategory, (action, index) =>
                        <div className={styles.scenariosItem}
                             key={index}
                             onMouseEnter={onHoverEnter(action)}
                             onMouseLeave={onHoverLeave}
                        >
                            <DraggableActionsView {...action} hoverActionType={hoverActionType}/>

                            <h5 className={cx(styles.scenariosItemTitle, { [action?.hoverColorClass]: isEqual(hoverActionType, action?.actionType) })}>
                                {action.title}
                            </h5>
                        </div>,
                    )}
                </div>,
            )}
        </div>
    )
};
