import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataWait } from 'store/selectors/scenariosActions.selectors';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

export const WaitSidebar = () => {
    const dispatch = useDispatch();
    const { title, waitInterval, actionType, errors } = useSelector(getStoreDataWait);
    const { t: translator } = useTranslation();

    const {
        onChangeNumericInput,
        onSubmitHandler
    } = useContext(SideBarContext);

    const [values, setValues] = useState({
        waitInterval
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeNumericInput(setValues), []);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData(values));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={title}/>

                <Label htmlFor="waitInterval" text={translator('actionSidebars:waitInterval')}/>
                <InputField
                    type='number'
                    id='waitInterval'
                    name='waitInterval'
                    value={values?.waitInterval?.value}
                    required={values?.waitInterval?.required}
                    error={!!errors?.waitInterval}
                    onChange={onChangeCached}
                />
                <ErrorMessageField active={!!errors?.waitInterval} message={errors?.waitInterval}/>

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
};
