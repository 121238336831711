import React, { useContext, useEffect, useState } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { find, some, map, eq, isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataConditionalSplit } from 'store/selectors/scenariosActions.selectors'
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';
import { ConditionalSplitAdvanced } from 'components/ScenarioSetup/ActionSidebars/advanced/ConditionalSplitAdvanced';
import { GearSwitchableButton } from 'common/_Buttons/_GearSwitchableButton/GearSwitchableButton';
import { ConditionalSplitSimplified } from 'components/ScenarioSetup/ActionSidebars/simplified/ConditionalSplitSimplified';
import { simplifiedConditionSettings } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/simplified/simplifiedConditionSettings';
import { StringUse } from 'lib/StringUse';
import { useSegmentSearch } from 'hooks/ScenarioSetup/useSegmentSearch';
import { useSimplifiedCondition } from 'hooks/ScenarioSetup/useSimplifiedCondition';
import { useTurnOffSegmentsButtons } from 'hooks/ScenarioSetup/useTurnOffSegmentsButtons';
import { useTurnOffSimplifiedConditionsButtons } from 'hooks/ScenarioSetup/useTurnOffSimplifiedConditionsButtons';
import { defaultConditionalSplitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultConditionalSplitSettings';
import { clearCurrentEvent, getAllIntegrationEvents } from 'store/actions/integrations.action';
import { useConditionCalendarData } from 'hooks/useConditionCalendarData';

export const ConditionalSplitSideBar = () => {
    const dispatch = useDispatch();
    const context = useContext(SideBarContext);

    const {
        onSubmitHandler,
        onClickChangeSidebarDifficultySettings,
        initSelectedSimplifiedSettings,
        initSimplifiedTimeIntervalSettings,
        initSelectedSegmentSimplifiedSettings,
        turnOffSimplifiedSettingsButtons,
        clearSidebarDifficultySettingsType,
        clearSimplifiedTimeInterval,
        initSimplifiedTimeIntervalType,
    } = context;

    const {
        title,
        actionType,
        _splitType,
        _actionParams,
        _values,
        _attributeFilter,
        _productAttributeFilter,
        _condition,
        _segmentId,
        segmentsButtonsList,
        errors
    } = useSelector(getStoreDataConditionalSplit);
    const { allEvents } = useSelector(store => store.integrations);

    const [splitType, setSplitType] = useState(_splitType);
    const [values, setValues] = useState(_values);
    const [attributeFilter, setAttributeFilter] = useState(_attributeFilter);
    const [productAttributeFilter, setProductAttributeFilter] = useState(_productAttributeFilter);
    const [condition, setCondition] = useState(_condition);
    const [actionParams, setActionParams] = useState(_actionParams);
    const [simplifiedSettingsButtons, setSimplifiedSettingsButtons] = useState(initSelectedSimplifiedSettings(_actionParams, simplifiedConditionSettings));
    const [simplifiedTimeInterval, setSimplifiedTimeInterval] = useState(initSimplifiedTimeIntervalSettings(_condition, actionParams));
    const [segmentsButtons, setSegmentsButtons] = useState(initSelectedSegmentSimplifiedSettings(_segmentId, segmentsButtonsList));
    const [simplifiedTimeIntervalType, setSimplifiedTimeIntervalType] = useState(initSimplifiedTimeIntervalType(_condition, actionParams));

    // Means that at least one simplified parameter selected
    const isActionInSimplifiedMod = some(simplifiedSettingsButtons, 'selected');
    // Means that at least one segment selected in action
    const isActionInSegmentSimplifiedMod = some(segmentsButtons, 'selected');

    const isAtScriptStartSelected = some(simplifiedTimeIntervalType, { id: 'atScriptStart', selected: true });

    const selectedSegmentId = StringUse.recast(find(segmentsButtons, 'selected')?.id, { accepted: 'string', rejected: null });

    const isEventSection = some(splitType,{ id: 'event', active: true });
    const isClientSection = some(splitType,{ id: 'client', active: true });
    const isProductSection = some(splitType,{ id: 'product', active: true });

    const actionInSimplifiedModRuler = isActionInSimplifiedMod && !isClientSection;
    const actionInSegmentSimplifiedModRuler = isActionInSegmentSimplifiedMod && !isClientSection;

    const mappedEventNames = map(allEvents.base, event => event.event_name);

    const onClickSaveActionSettings = () => {
        const stateManagementPromise = new Promise(resolve => {
            return actionParams.simplified && !isActionInSimplifiedMod
                ? resolve({
                    data: {
                        ...defaultConditionalSplitSettings(),
                        splitType,
                        condition,
                        segmentId: selectedSegmentId
                    },
                    params: { actionParams }
                })
                : resolve({
                    data: {
                        splitType,
                        values,
                        attributeFilter,
                        productAttributeFilter,
                        condition,
                        segmentId: selectedSegmentId
                    },
                    params: { actionParams }
                })
        });

        stateManagementPromise.then(actionData => {
            dispatch(saveSidebarData(actionData.data, actionData.params));
        });
    };

    useEffect(() => {
        if (isEmpty(allEvents.base)) {
            dispatch(getAllIntegrationEvents())
        }
        return () => dispatch(clearCurrentEvent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // We need clear 'event' and 'product' split type states, while user select some simplified buttons (condition or segment)
        // And if 'event' and 'product' split type states is not equal with default this state values
        const {
            values: defaultValues,
            attributeFilter: defaultAttributeFilter,
            productAttributeFilter: defaultProductAttributeFilter
        } = defaultConditionalSplitSettings();

        if (isActionInSimplifiedMod || isActionInSegmentSimplifiedMod) {
            if (!isEqual(defaultValues, values) || !isEqual(defaultAttributeFilter, attributeFilter) || !isEqual(defaultProductAttributeFilter, productAttributeFilter)) {
                setValues(defaultValues);
                setAttributeFilter(defaultAttributeFilter);
                setProductAttributeFilter(defaultProductAttributeFilter);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simplifiedSettingsButtons, segmentsButtons])

    useEffect(() => {
        // We need clear selected simplified condition buttons and selected segment buttons, while user..
        // ..do something in 'event' or 'product' split type in advanced settings
        if (actionInSimplifiedModRuler || actionInSegmentSimplifiedModRuler) {
            turnOffSimplifiedSettingsButtons(setSegmentsButtons);

            turnOffSimplifiedSettingsButtons(setSimplifiedSettingsButtons);

            clearSidebarDifficultySettingsType(setActionParams);

            clearSimplifiedTimeInterval(setSimplifiedTimeInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, attributeFilter, productAttributeFilter])

    useEffect(() => {
        // We need change selected type of conditional split to 'client' if user select some condition settings or segment in simplified mode
        const actionInSimplifiedModRuler = isActionInSimplifiedMod && !isClientSection;
        const actionInSegmentSimplifiedModRuler = isActionInSegmentSimplifiedMod && !isClientSection;

        if (actionInSimplifiedModRuler || actionInSegmentSimplifiedModRuler) {
            setSplitType(prev => map(prev, type => eq(type.id, 'client')
                ? { ...type, active: true }
                : { ...type, active: false }
            ))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simplifiedSettingsButtons, segmentsButtons, actionParams.simplified])

    const { setFocusSearchInput, setSearchTerm } = useSegmentSearch({
        segmentsButtonsList,
        setSegmentsButtons,
        _segmentId
    });

    // Update condition in advanced settings by selected simplified button settings
    useSimplifiedCondition({
        simplifiedSettingsButtons,
        isActionInSimplifiedMod,
        simplifiedTimeInterval,
        setCondition,
        setActionParams,
        setSegmentsButtons,
        simplifiedTimeIntervalType,
        setSimplifiedTimeInterval,
        isAtScriptStartSelected
    });

    // If user use action in advanced mode and have created any condition, we need turn off segmengs buttons in action
    useTurnOffSegmentsButtons({
        condition,
        isActionInSimplifiedMod,
        setSegmentsButtons
    });

    // If segment selected we need to turn off all simplified conditions buttons in action
    useTurnOffSimplifiedConditionsButtons({
        isActionInSegmentSimplifiedMod,
        segmentsButtons,
        setCondition,
        setSimplifiedSettingsButtons,
        setActionParams,
        setSimplifiedTimeInterval
    });

    useConditionCalendarData(condition, setCondition);

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <SideBarContext.Provider value={{
            ...context,
            values,
            setValues,
            attributeFilter,
            setAttributeFilter,
            productAttributeFilter,
            setProductAttributeFilter,
            condition,
            setCondition,
            errors,
            isEventSection,
            isClientSection,
            isProductSection,
            splitType,
            setSplitType,
            isActionInSimplifiedMod,
            actionParams,
            setActionParams,
            simplifiedSettingsButtons,
            setSimplifiedSettingsButtons,
            simplifiedTimeInterval,
            setSimplifiedTimeInterval,
            segmentsButtons,
            setSegmentsButtons,
            setSearchTerm,
            setFocusSearchInput,
            simplifiedTimeIntervalType,
            setSimplifiedTimeIntervalType,
            isAtScriptStartSelected,
            mappedEventNames
        }}>
            <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
                <>
                    <div className={styles.sidebarHeaderContainer}>
                        <SidebarGeneralTitle title={title}/>
                        <GearSwitchableButton active={!actionParams.simplified} onClick={onClickChangeSidebarDifficultySettings(setActionParams)}/>
                    </div>


                    {actionParams.simplified
                        ? <ConditionalSplitSimplified/>
                        : <ConditionalSplitAdvanced/>
                    }

                    <ActionSidebarButtons/>
                </>
            </ActionSidebarFormWrapper>
        </SideBarContext.Provider>
    )
}
