import React from 'react';

export const SegmentIcon = ({ color }) => (
    <svg width="22" height="15" viewBox="0 0 22 15" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99974 15C3.92426 15 1.69745 15 0.251764 13.0909C-1.19392 11.1818 3.92428 9.75 6.99976 9.75C10.0752 9.75 15.1942 11.1818 13.7481 13.0909C12.3021 14.9999 10.0752 15 6.99974 15Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.829 12.8143C14.8911 12.8143 14.9536 12.8143 15.0165 12.8143C17.6526 12.8143 19.5613 12.8142 20.8008 11C22.0403 9.18569 17.6526 7.82495 15.0165 7.82495C13.2624 7.82495 10.7332 8.42742 9.60199 9.36511C12.4965 9.75489 15.8516 11.1007 14.829 12.8143Z" fill={color}/>
        <ellipse cx="6.99997" cy="3.75" rx="3.88889" ry="3.75" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.7269 4.30576C11.7548 4.12442 11.7693 3.93884 11.7693 3.75C11.7693 3.37045 11.7108 3.00407 11.6021 2.65878C11.8872 1.14614 13.2465 0 14.8804 0C16.7213 0 18.2137 1.45507 18.2137 3.25C18.2137 5.04492 16.7213 6.5 14.8804 6.5C13.4185 6.5 12.1764 5.58243 11.7269 4.30576Z" fill={color}/>
    </svg>

);