import { useEffect } from 'react';

export const useOutsideAlerter = (ref, callback) => {

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = event => {
            return ref.current && !ref.current.contains(event.target) && callback();
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, callback]);
};
