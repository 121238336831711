import { find } from 'lodash';

const scenariosStatusExtension = [
    { id: 'd5d9fdd6-2e3d-4253-afc8-ca90eae97532', name: 'Draft', color: '#828282' },
    { id: 'b1fd8690-4baf-42d3-9248-f3c3cfe44ac3', name: 'Ready', color: '#F2C94C' },
    { id: '5d47397f-e8bf-41cf-b25b-ddfbfb172974', name: 'Stopped', color: '#EB5757' },
    { id: '9c2054a2-0cd0-4b74-beb2-d98db07ebbdf', name: 'Active', color: '#00C6A2' },
]

export const getScenariosStatusExtension = statusId => {
    const item = find(scenariosStatusExtension, { id: statusId });

    return {
        color: item?.color || '#828282'
    }
}
