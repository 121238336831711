import React from 'react';
import PropTypes from 'prop-types'
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';
import { MobilePushBody } from 'components/Cards/CreativeCards/CreativeCardPreviewBody/MobilePushBody';

export const TemplatePreviewMobilePush = ({ onClickCallback, templateName, jinja_fields }) => (
    <div onClick={onClickCallback} className={styles.templatePreview}>
        <div className={styles.previewActionMobilePush}>
            <MobilePushBody jinja_fields={jinja_fields}/>
        </div>
        <p className={styles.templatePreviewTitle}>{templateName}</p>
    </div>
);

TemplatePreviewMobilePush.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    templateName:  PropTypes.string.isRequired,
    jinja_fields: PropTypes.array
};
TemplatePreviewMobilePush.defaultProps = {
    templateName: '',
};
