import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const actionNameRules = {
    segmentId: 'required'
};

export const editSegmentControl = action => {
    const segmentIdResult = Validation.check(action.settings, actionNameRules, {
        'required': 'InputValidationRequiredMessage',
    }, 'segmentId');

    const isActionHasErrors = segmentIdResult.fails;

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                segmentIdResult.firstError,
            ], ['segmentId'])
        }
    }
};
