import React from 'react';
import PropTypes from 'prop-types';
import styles from './ShareButton.module.css';
import classNames from 'classnames/bind';
import { ShareIcon } from 'assets/icons/ShareIcon';

const cx = classNames.bind(styles);
export const ShareButton = ({ onClick, className }) => (
    <button onClick={onClick} className={cx(styles.shareButton, className)}>
        <ShareIcon/>
    </button>
);

ShareButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string
};
ShareButton.defaultProps = {
    className: ''
};