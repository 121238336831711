import React, { useEffect, useState } from 'react';
import styles from 'components/TuningSections/TuningSections.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';
import { map, toLower, filter, eq } from 'lodash';
import {
    filterTemplatesByStatus,
    filterTemplatesByEmailCreationType,
    getTemplatesList,
    searchTemplates,
} from 'store/actions/templates.action';
import { SearchInput } from 'common/SearchInput/SearchInput';
import { OptionsFilter } from 'components/_OptionsFilter/OptionsFilter';
import { UsefulOptionsLayout } from 'layouts/_UsefulOptionsLayout/UsefulOptionsLayout';
import { Console } from 'lib/Console';
import { OperatedElementsList } from 'common/_OperatedElementsList/OperatedElementsList';
import { FolderIcon } from 'assets/icons/FolderIcon';
import { getImagesFoldersList } from 'store/actions/images.action';
import { CustomModal } from 'common/_CustomModal/CustomModal';
import { useModal } from 'hooks/CustomModals/useModal';
import { FillableModal } from 'common/_CustomModal/FillableModal/FillableModal';
import { useTranslation } from 'react-i18next';
import { useEmailMasters } from 'hooks/TemplatesSetup/useEmailMasters';
import { useFolders } from 'hooks/TemplatesSetup/useFolders';
import { BranchIcon } from 'assets/icons/BranchIcon';
import { useParams } from 'react-router-dom';

const cx = classNames.bind(styles);

export const TemplatesTuningSection = ({ typeMessage }) => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();
    const { templateType } = useParams();

    const sandboxMode = useSelector(store => store.auth.user.sandboxMode);

    // Focus for Search input
    const [focusSearchInput, setFocusSearchInput] = useState(false);
    // State for searching query
    const [searchTerm, setSearchTerm] = useState('');
    // State for checkbox filter
    const [templateStatusFilter, setTemplateStatusFilter] = useState([
        { id: 'Ready', name: translator('filter:Ready'), isChecked: true },
        { id: 'Draft', name: translator('filter:Draft'), isChecked: true },
    ]);

    const [templateToolFilter, setTemplateToolFilter] = useState([
        { id: 'Master', name: translator('filter:MastersTemplateFilter'), isChecked: true },
        { id: 'Code', name: translator('filter:CodeEditorTemplateFilter'), isChecked: true },
    ]);

    const { masters, isLoadingEmailMasters, createNewEmailMaster, masterRedirectPath, emailMasterTypes, masterType, onChangeEmailMasterType } = useEmailMasters();

    const { folders, isLoadingImageFolders, createNewImageFolder, folderRedirectPath } = useFolders('/all-templates/email/folder/');

    const {
        ref,
        isToggle,
        modalType,
        onClickShowTypedModal,
        setHideModal,
        onAcceptModalPromise,
        onChangeModalInput,
        modalInputValue,
        modalOption,
        modalOptionsList,
        onChangeModalOption,
        modalErrorMessage,
        sendModalErrorMessage
    } = useModal({
        isNeedOutsideAlerter: true,
        defaultSelectedModalOption: masterType,
        modalOptionsList: emailMasterTypes,
        onChangeModalOptionCallback: onChangeEmailMasterType
    });

    // useDebounce with searching query
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const isEmailTemplatesListSelected = eq(templateType, 'email');

    const onChangeSearchInput = event => setSearchTerm(toLower(event.target.value));

    const searchTemplateByQuery = searchQuery => {
        Console.log('[Search Client]: Search query', searchQuery);

        if (typeMessage) dispatch(searchTemplates(searchQuery, typeMessage));
    };

    const resetPreviousFilter = setState => setState(prev => map(prev, filter => ({ ...filter, isChecked: true })))

    const dispatchFilterTemplatesByStatusAction = options => {
        if (isEmailTemplatesListSelected) {
            resetPreviousFilter(setTemplateToolFilter);
        }
        dispatch(filterTemplatesByStatus(filter(options, item => item.isChecked), typeMessage, sandboxMode));
    };
    const dispatchFilterTemplatesByEmailCreationTypeAction = options => {
        resetPreviousFilter(setTemplateStatusFilter);
        dispatch(filterTemplatesByEmailCreationType(filter(options, item => item.isChecked), typeMessage, sandboxMode));
    };

    const onClickAcceptModalButton = callback => {
        return () => {
            if (!modalInputValue.length) {
                return sendModalErrorMessage(translator('popUpsSystemMessages:InputRequiredField'));
            }

            return callback();
        }
    };

    const selectModalPropsByType = type => {
        switch (type) {
            case 'folder':
                return {
                    title: translator('popUpsSystemMessages:CreateNewFolder'),
                    label: translator('popUpsSystemMessages:FolderName'),
                    cancelButtonTitle: translator('default:CancelButtonTitle'),
                    acceptButtonTitle: translator('default:SaveButtonTitle'),
                    value: modalInputValue,
                    onChangeInput: onChangeModalInput,
                    onClickCancel: setHideModal,
                    onClickAccept: onClickAcceptModalButton(onAcceptModalPromise(createNewImageFolder(modalInputValue))),
                    isModalHasError: !!modalErrorMessage.length,
                    modalErrorMessage: modalErrorMessage,
                }

            case 'master':
                return {
                    title: translator('popUpsSystemMessages:CreateNewMaster'),
                    label: translator('popUpsSystemMessages:MasterName'),
                    cancelButtonTitle: translator('default:CancelButtonTitle'),
                    acceptButtonTitle: translator('default:SaveButtonTitle'),
                    value: modalInputValue,
                    onChangeInput: onChangeModalInput,
                    onClickCancel: setHideModal,
                    onClickAccept: onClickAcceptModalButton(onAcceptModalPromise(createNewEmailMaster(modalInputValue))),
                    isModalWithOptionDropdown: true,
                    defaultDropdownOption: modalOption,
                    dropdownOptionsList: modalOptionsList,
                    onChangeDropdownOption: onChangeModalOption,
                    isModalHasError: !!modalErrorMessage.length,
                    modalErrorMessage: modalErrorMessage,
                }
            default:
                return {}
        }
    };

    useEffect(() => {
        if (debouncedSearchTerm) searchTemplateByQuery(debouncedSearchTerm);

        if (!debouncedSearchTerm && focusSearchInput) {
            Console.log('[Search Client]: Search field empty, load all templates by type', typeMessage);
            dispatch(getTemplatesList(typeMessage))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, dispatch])

    useEffect(() => {
        setTemplateStatusFilter(prev => map(prev, item => ({
            ...item,
            isChecked: true
        })))
    }, [typeMessage])

    useEffect(() => {
        if (!folders.length) dispatch(getImagesFoldersList());

    }, [folders, dispatch]);

    return (
        <UsefulOptionsLayout className={styles.templatesListPage}>
            <>
                <div className={styles.searchContainer}>
                    <SearchInput
                        onChangeCallback={onChangeSearchInput}
                        setFocus={setFocusSearchInput}
                    />
                </div>
                <div className={styles.checkboxContainer}>
                    <OptionsFilter
                        options={templateStatusFilter}
                        setOptions={setTemplateStatusFilter}
                        onChangeAction={dispatchFilterTemplatesByStatusAction}
                        sectionName={translator('filter:TemplateStatusTitle')}
                        checkAllButtonName={translator('filter:AllButton')}
                        clearAllButtonName={translator('filter:ClearButton')}
                    />
                </div>
                {isEmailTemplatesListSelected &&
                    <div className={styles.checkboxContainer}>
                        <OptionsFilter
                            options={templateToolFilter}
                            setOptions={setTemplateToolFilter}
                            onChangeAction={dispatchFilterTemplatesByEmailCreationTypeAction}
                            sectionName={translator('filter:TemplateToolTitle')}
                            checkAllButtonName={translator('filter:AllButton')}
                            clearAllButtonName={translator('filter:ClearButton')}
                        />
                    </div>
                }
                {isEmailTemplatesListSelected &&
                    <div className={cx(styles.operatedElementsListContainer, { 'emptyElementsList': !masters.length || isLoadingEmailMasters })}>
                        <OperatedElementsList
                            label={translator('templates:YourMasterTemplates')}
                            actionButtonName={translator('default:AddNew')}
                            onClickActionButton={onClickShowTypedModal('master')}
                            elementsArray={masters}
                            elementsIcon={<BranchIcon/>}
                            elementsArrayNotExistsText={translator('templates:YourMasterTemplatesDefaultText')}
                            elementRedirectPath={masterRedirectPath}
                            isLoading={isLoadingEmailMasters}
                        />
                    </div>
                }
                <div className={cx(styles.operatedElementsListContainer, { 'emptyElementsList': !folders.length || isLoadingImageFolders })}>
                    <OperatedElementsList
                        label={translator('templates:YourFolders')}
                        actionButtonName={translator('default:AddNew')}
                        onClickActionButton={onClickShowTypedModal('folder')}
                        elementsArray={folders}
                        elementsIcon={<FolderIcon/>}
                        elementsArrayNotExistsText={translator('templates:YourFoldersDefaultText')}
                        elementRedirectPath={folderRedirectPath}
                        isLoading={isLoadingImageFolders}
                    />
                </div>

                {!!modalType &&
                    <CustomModal
                        componentRef={ref}
                        isOpened={isToggle}
                        children={
                            <FillableModal {...selectModalPropsByType(modalType)}/>
                        }
                    />
                }
            </>
        </UsefulOptionsLayout>
    )
};

TemplatesTuningSection.propTypes = {
    typeMessage: PropTypes.string.isRequired
};
TemplatesTuningSection.defaultProps = {
    typeMessage: ''
};
