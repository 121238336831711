import React, { useEffect, useState } from 'react';
import { eq } from 'lodash';
import styles from 'components/Cards/Cards.module.css';
import PropTypes from 'prop-types';
import { CreativeCardFooter } from 'components/Cards/CreativeCards/CreativeCardFooter';
import { CreativeCardWrapper } from 'components/Cards/CreativeCards/CreativeCardWrapper';
import { CommonMethods } from 'lib/CommonMethods';

export const EmailCard = ({
    template,
    templateId,
    templateType,
    isShowChainButton,
    isShowUsage,
    redirectPath,
    onClick,
    containerClassName,
    isMasterBasedTemplate
}) => {
    const [isImageExist, setIsImageExist] = useState(false);

    const { template_unique_id, preview_image_url } = template;

    useEffect(() => {
        CommonMethods.isImageExist(preview_image_url)
            .then(result => setIsImageExist(result));
    }, [preview_image_url])

    return (
        <CreativeCardWrapper
            isSelected={eq(templateId, template_unique_id)}
            cardType={templateType}
            className={containerClassName}
            isMasterBasedTemplate={isMasterBasedTemplate}
        >
            <div className={styles.creativeCardPreview} onClick={onClick}>
                {isImageExist
                    ? <div className={styles.emailCard} style={{ backgroundImage: `url(${preview_image_url})` }}/>
                    : <div className={styles.defaultCardPreview}/>
                }
            </div>
            <CreativeCardFooter {...{ template, isShowChainButton, isShowUsage, redirectPath }}/>
        </CreativeCardWrapper>
    );
};

EmailCard.propTypes = {
    template: PropTypes.object.isRequired,
    templateId: PropTypes.string,
    templateType: PropTypes.string,
    isShowChainButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string,
    isMasterBasedTemplate: PropTypes.bool
};
EmailCard.defaultProps = {
    isShowUsage: true,
    containerClassName: '',
    isMasterBasedTemplate: false
};
