import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { map, eq, includes } from 'lodash';
import styles from 'components/_CustomSelectDropdown/CustomSelectDropdown.module.css';
import classNames from 'classnames/bind';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { useToggle } from 'hooks/useToggle';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';

const cx = classNames.bind(styles);

export const CustomSelectDropdown = ({
    dropdownName,
    dropdownLabel,
    dropdownOptions,
    defaultDropdownValue,
    onChangeDropdown,
    isRequired,
    isErrorExist,
    isDisabled,
    clearSelectedValueCondition,
    controlledClearedSelectedValue,
    className,
    dropdownListContainerClassName
}) => {
    const ref = useRef();

    const [isToggle, setToggle] = useToggle(false);
    const [selectedOption, setSelectedOption] = useState(defaultDropdownValue);

    const onClickToggle = () => {
        if (!isDisabled) {
            // Need for prevent toggle of list if their options has only one value
            if (selectedOption && eq(dropdownOptions.length, 1)) return;

            setToggle(!isToggle);
        }
    };

    const updateDropdownState = (selectedValue, dropdownName) => {
        setSelectedOption(selectedValue);
        setToggle(false);

        return Promise.resolve({ selectedValue, dropdownName });
    };

    const onClickSelectOption = (selectedValue, dropdownName) => {
        return () => updateDropdownState(selectedValue, dropdownName).then(({ selectedValue, dropdownName }) => {
            return onChangeDropdown(selectedValue, dropdownName);
        })
    };

    const onClickOutside = () => setToggle(false);

    useEffect(() => {
        // Means that is options array has only one elem, select them automatically
        if (!selectedOption && eq(dropdownOptions.length, 1) && !isDisabled) {
            updateDropdownState(dropdownOptions[0], dropdownName).then(({ selectedValue, dropdownName }) => {
                return onChangeDropdown(selectedValue, dropdownName);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption, dropdownOptions.length, isDisabled])

    useEffect(() => {
        // Clear local state and use callback function if dropdownOptions is changed and selected value is not includes in new dropdownOptions
        if (dropdownOptions.length && selectedOption) {
            if (!includes(dropdownOptions, selectedOption)) {
                updateDropdownState('', dropdownName).then(({ selectedValue, dropdownName }) => {
                    return onChangeDropdown(selectedValue, dropdownName);
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownOptions])

    useEffect(() => {
        if (clearSelectedValueCondition && selectedOption) {
            updateDropdownState(controlledClearedSelectedValue || '', dropdownName).then(({ selectedValue, dropdownName }) => {
                return onChangeDropdown(selectedValue, dropdownName);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearSelectedValueCondition])

    useOutsideAlerter(ref, onClickOutside);

    return (
        <>
            {dropdownLabel &&
                <p className={styles.dropdownLabel}>{dropdownLabel}</p>
            }

            <div className={cx(styles.dropdownContainer, className, { 'opened': isToggle, 'hasError': isErrorExist, 'disabled': isDisabled, 'emptyOptionsList': selectedOption && eq(dropdownOptions.length, 1) })}
                 ref={ref}
                 onClick={onClickToggle}
            >
                <div className={styles.dropdownHeader}>
                    {selectedOption}
                </div>

                <div className={cx(styles.dropdownChevron, {'opened': isToggle })}/>

                {isToggle &&
                    <div className={cx(styles.dropdownListContainer, dropdownListContainerClassName)}>
                        <ul>
                            {map(dropdownOptions, option => (
                                !eq(option, selectedOption) &&
                                <li onClick={onClickSelectOption(option, dropdownName)} key={option}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </div>

            {isRequired && !isDisabled &&
                <ErrorMessageField
                    active={isErrorExist}
                    message='InputValidationRequiredMessage'
                />
            }
        </>
    )
};

CustomSelectDropdown.propTypes = {
    dropdownName: PropTypes.string.isRequired,
    dropdownOptions: PropTypes.array.isRequired,
    defaultDropdownValue: PropTypes.string,
    onChangeDropdown: PropTypes.func,
    isRequired: PropTypes.bool,
    isErrorExist: PropTypes.bool,
    isDisabled: PropTypes.bool,
    clearSelectedValueCondition: PropTypes.bool,
    controlledClearedSelectedValue: PropTypes.string,
    className: PropTypes.string,
    dropdownListContainerClassName: PropTypes.string,
};

CustomSelectDropdown.defaultProps = {
    defaultDropdownValue: '',
    onChangeDropdown: () => {},
    isRequired: false,
    isErrorExist: false,
    isDisabled: false,
    clearSelectedValueCondition: false,
    controlledClearedSelectedValue: '',
    className: null,
    dropdownListContainerClassName: null
};
