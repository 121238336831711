import React from 'react';
import styles from 'common/Headers/Headers.module.css';
import PropTypes from 'prop-types';
import { DashboardHeaderTitle } from 'common/Headers/DashboardHeader/DashboardHeaderTitle';
import { DashboardHeaderStatus } from 'common/Headers/DashboardHeader/DashboardHeaderStatus';
import { DashboardHeaderButtons } from 'common/Headers/DashboardHeader/DashboardHeaderButtons';
import { DashboardHeaderNavLinkSwitcher } from 'common/Headers/DashboardHeader/DashboardHeaderNavLinkSwitcher';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const DashboardHeaderWrapper = ({
    title,
    status,
    buttons,
    navLinkSwitcher
}) => (
    <div className={styles.dashboardHeaderWrapper}>
        <div className={styles.dashboardHeaderTopSide}>
            <div className={styles.dashboardHeaderTitleContainer}>
                <DashboardHeaderTitle {...title}/>
                <DashboardHeaderStatus {...status}/>
            </div>
            <DashboardHeaderButtons {...buttons}/>
        </div>
        <div className={cx(styles.dashboardHeaderBottomSide, {'hideHavLinkSwitcher' : !navLinkSwitcher.isShowNavLinkTabButtons})}>
            <DashboardHeaderNavLinkSwitcher {...navLinkSwitcher}/>
        </div>
    </div>
);
DashboardHeaderWrapper.propTypes = {
    title: PropTypes.object.isRequired,
    status: PropTypes.object.isRequired,
    buttons: PropTypes.object.isRequired,
    navLinkSwitcher: PropTypes.object,
};

DashboardHeaderWrapper.defaultValue = {
    navLinkSwitcher: null
};
