export const logoutConfig = {
    message: {
        title: 'LogoutTitle',
        description: ''
    },
    acceptButton: {
        active: true,
        name: 'ConfirmButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'CancelButtonTitle'
    }
}
export const preventDeleteScenarioConfig = {
    message: {
        title: 'DeleteScenarioTitle',
        text: 'DeleteScenarioPreventText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}
export const confirmDeleteScenarioConfig = {
    message: {
        title: 'DeleteScenarioTitle',
        text: 'DeleteScenarioConfirmText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}
export const cancelChangesScenarioConfig = {
    message: {
        title: 'CancelChangesScenarioTitle',
        text: 'CancelChangesScenarioText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}
export const updateScenarioConfig = {
    message: {
        title: 'UpdateScenarioTitle',
        text: 'UpdateScenarioText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}
export const closeEditScenarioWindowConfig = {
    message: {
        title: 'CloseEditScenarioTitle',
        text: 'CloseEditScenarioText'
    },
    submitButton: {
        active: true,
        name: 'YesButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'NoButtonTitle'
    }
}
export const saveTemplateConfig = {
    message: {
        title: 'SaveTemplateTitle',
        text: 'SaveTemplateText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
export const saveTemplateNameConfig = {
    message: {
        title: 'ChangeTemplateNameTitle',
        text: 'ChangeTemplateNameText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
export const cancelChangesTemplateConfig = {
    message: {
        title: 'CancelChangesTemplateTitle',
        text: 'CancelChangesTemplateText'
    },
    submitButton: {
        active: true,
        name: 'CancelButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
export const closeChangesTemplateConfig = {
    message: {
        title: 'CloseChangesTemplateTitle',
        text: 'CloseChangesTemplateText'
    },
    submitButton: {
        active: true,
        name: 'YesButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'NoButtonTitle'
    }
};
export const confirmDeleteTemplateConfig = {
    message: {
        title: 'DeleteTemplateTitle',
        text: 'DeleteTemplateText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const preventDeleteTemplateConfig = {
    message: {
        title: 'DeleteTemplateTitle',
        text: 'DeleteTemplatePreventText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}

export const confirmCopyTemplateConfig = {
    message: {
        title: 'CopyTemplateTitle',
        text: 'CopyTemplateText'
    },
    submitButton: {
        active: false,
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}
export const altTextAlerterConfig = {
    message: {
        label: 'FillableAlerterLabel',
        placeholder: 'FillableAlerterPlaceholder'
    },
    submitButton: {
        active: true,
        name: 'FillableAlerterButton'
    },
}
export const altTextErrorAlerterMessage = 'ImageUploadAlerter';

export const confirmDeleteUnitConfig = {
    message: {
        title: 'DeleteRecommendationUnitTitle',
        text: 'DeleteRecommendationUnitText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const saveUnitNameConfig = {
    message: {
        title: 'ChangeUnitNameTitle',
        text: 'ChangeUnitNameText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const updateUnitConfig = {
    message: {
        title: 'UpdateUnitTitle',
        text: 'UpdateUnitText'
    },
    submitButton: {
        active: false,
        name: ''
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const confirmDeleteAbTestGroupConfig = {
    message: {
        title: 'DeleteAbTestGroupTitle',
        text: 'DeleteAbTestGroupText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const copyWebsiteBannerScriptConfig = {
    message: {
        title: 'ConnectWebsiteBannerScript',
        text: 'ConnectWebsiteBannerScriptMessage'
    },
    submitButton: {
        active: true,
        name: 'CopyButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
}

export const confirmDeleteSegmentConfig = {
    message: {
        title: 'DeleteSegmentTitle',
        text: 'DeleteSegmentText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
export const confirmCopySegmentConfig = {
    message: {
        title: 'CopySegmentTitle',
        text: 'CopySegmentText'
    },
    submitButton: {
        active: false,
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const confirmSaveManualSegmentConfig = {
    message: {
        title: 'SaveSegmentTitle',
        text: 'SaveSegmentText'
    },
    submitButton: {
        active: false,
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};


export const deleteIntegrationEventConfig = {
    message: {
        title: 'DeleteIntegrationEventTitle',
        text: 'DeleteIntegrationEventText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
export const deleteIntegrationFieldConfig = {
    message: {
        title: 'DeleteIntegrationFieldTitle',
        text: 'DeleteIntegrationFieldText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const confirmDeletePolicyConfig = {
    message: {
        title: 'DeletePolicyTitle',
        text: 'DeletePolicyText'
    },
    submitButton: {
        active: true,
        name: 'DeleteButtonTitle'
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};

export const confirmUpdatedPoliciesConfig = {
    message: {
        title: 'UpdatedPolicyTitle',
        text: 'UpdatedPolicyText'
    },
    submitButton: {
        active: false,
    },
    cancelButton: {
        active: true,
        name: 'BackButtonTitle'
    }
};
