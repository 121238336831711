import { Validation } from 'validations/Validation';
import { conditionControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/condition/conditionControl';
import { compact, map } from 'lodash';
import { ObjectUse } from 'lib/ObjectUse';

const atTheDayRules = {
    value: 'required'
};

export const scheduleTriggerControl = action => {

    const {
        atTheDay,
        condition
    } = action.settings;

    const atTheDayResult = Validation.check(atTheDay, atTheDayRules, {
        'required.value': 'InputValidationRequiredMessage',
    }, 'value');

    const conditionsResult = conditionControl(condition);

    const isConditionsHasErrors = !!compact(map(conditionsResult, report => report.validation.fails)).length;

    const isActionHasErrors = atTheDayResult.fails || isConditionsHasErrors

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                atTheDayResult.firstError,
                isConditionsHasErrors ? conditionsResult : null
            ], ['calendarDate', 'conditionsErrors']),
        }
    }
}
