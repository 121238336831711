import React from 'react';

export const CopyIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99993 0H14.2856C15.2324 0 15.9999 0.767511 15.9999 1.71428V12C15.9999 12.9468 15.2324 13.7143 14.2856 13.7143C13.97 13.7143 13.7142 13.4585 13.7142 13.1429C13.7142 12.8273 13.97 12.5714 14.2856 12.5714C14.6012 12.5714 14.857 12.3156 14.857 12V1.71428C14.857 1.39868 14.6012 1.14285 14.2856 1.14285H3.99993C3.68432 1.14285 3.42849 1.39868 3.42849 1.71428C3.42849 2.02989 3.17268 2.28572 2.85708 2.28572C2.54148 2.28572 2.28564 2.02989 2.28564 1.71428C2.28564 0.767511 3.05315 0 3.99993 0Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.71428 2.85712H11.4286C12.3753 2.85712 13.1428 3.62463 13.1428 4.5714V14.2857C13.1428 15.2324 12.3753 16 11.4286 16H1.71428C0.767511 16 0 15.2324 0 14.2857V4.5714C0 3.62463 0.767511 2.85712 1.71428 2.85712ZM11.4286 14.8571C11.7442 14.8571 12 14.6013 12 14.2857V4.5714C12 4.2558 11.7442 3.99996 11.4286 3.99996H1.71428C1.39868 3.99996 1.14285 4.2558 1.14285 4.5714V14.2857C1.14285 14.6013 1.39868 14.8571 1.71428 14.8571H11.4286Z" fill={color}/>
        </svg>
    )
};
