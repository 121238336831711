import React from 'react';

export const FunnelIconAlt = ({borderColor}) => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.2643 3.38401C32.7427 1.9056 35.1397 1.9056 36.6181 3.38401L64.4983 31.2642C65.9767 32.7426 65.9767 35.1396 64.4983 36.618L36.6181 64.4982C35.1397 65.9767 32.7427 65.9767 31.2643 64.4982L3.38405 36.618C1.90564 35.1396 1.90563 32.7426 3.38405 31.2642L31.2643 3.38401Z" fill="white" stroke={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.2132 21.9846H21.9258V24.6061L31.9315 37.7386H36.2075L46.2132 24.6061V21.9846ZM47.1978 24.9385L36.6952 38.7233H31.4438L20.9412 24.9385V21H47.1978V24.9385Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.4437 37.5742C31.7156 37.5742 31.936 37.7946 31.936 38.0665V51.7111L36.2027 49.5778V38.0665C36.2027 37.7946 36.4231 37.5742 36.695 37.5742C36.9669 37.5742 37.1873 37.7946 37.1873 38.0665V49.882C37.1873 50.0685 37.082 50.239 36.9152 50.3224L31.6638 52.948C31.5112 53.0243 31.33 53.0162 31.1848 52.9265C31.0397 52.8368 30.9514 52.6783 30.9514 52.5077V38.0665C30.9514 37.7946 31.1718 37.5742 31.4437 37.5742Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4335 24.9385C21.4335 24.6666 21.6539 24.4462 21.9258 24.4462H46.5414C46.8133 24.4462 47.0337 24.6666 47.0337 24.9385C47.0337 25.2104 46.8133 25.4308 46.5414 25.4308H21.9258C21.6539 25.4308 21.4335 25.2104 21.4335 24.9385Z" fill={borderColor}/>
    </svg>
);
