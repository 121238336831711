export const conditionExample = {
    id: null,
    show: true,
    conditionName: 'NewConditionName',
    _options: [
        {
            id: 'event',
            name: 'SegmentationFilterEvent',
            isChecked: true,
        },
        {
            id: 'attribute',
            name: 'SegmentationFilterAttribute',
            isChecked: false,
        },
        {
            id: 'metric',
            name: 'SegmentationFilterMetric',
            isChecked: false,
        },
    ],
    _eventValues: {
        actionName: {
            value: '',
            required: true,
        },
        actionSubName: {
            value: '',
            required: false,
        },
        countDays: {
            value: '',
            required: true
        },
        countHours: {
            value: '',
            required: true
        },
        aggregationFunction: {
            name: 'AggregationFunctionNameLabel',
            value: 'COUNT',
            required: true,
        },
        aggregationColumn: {
            name: 'AggregationColumnNameLabel',
            value: '',
            required: true,
        },
        aggregationComparisonOperator: {
            name: 'ComparisonOperatorLabel',
            value: '',
            required: true,
        },
        aggregationComparisonValue: {
            name: 'ComparisonValueLabel',
            value: '',
            required: true,
        },
    },
    _selectedTimeInterval: {
        _intervalType: [
            {
                id: 'lastTime',
                name: 'LastTimeIntervalLabel',
                isChecked: false,
            },
            {
                id: 'currentTime',
                name: 'CurrentTimeIntervalLabel',
                isChecked: true,
            },
        ],
        _intervalDate: {
            required: true,
            startDate: '',
            endDate: ''
        },
    },
    _attributeFilter: [],
    _clientAttributeValues: {
        attributeName: {
            name: 'ClientAttributeNameLabel',
            value: '',
            required: true,
        },
        comparisonOperator: {
            name: 'ComparisonOperatorLabel',
            value: '',
            required: true,
        },
        comparisonValue: {
            name: 'ComparisonValueLabel',
            value: [],
            required: false,
        },
    },
    _metricAttributeValues: {
        attributeName: {
            name: 'MetricAttributeNameLabel',
            value: '',
            required: true,
        },
        comparisonOperator: {
            name: 'ComparisonOperatorLabel',
            value: '',
            required: true,
        },
        comparisonValue: {
            name: 'ComparisonValueLabel',
            value: [],
            required: false,
        },
    }
}
