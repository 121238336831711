import React, { useContext, useEffect, useState } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map, some, find, isEmpty } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { GearSwitchableButton } from 'common/_Buttons/_GearSwitchableButton/GearSwitchableButton';
import { InputTimeField } from 'common/InputTimeField/InputTimeField';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { SelectDayOfWeek } from 'components/SelectDayOfWeek/SelectDayOfWeek';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStoreDataRepeatTrigger } from 'store/selectors/scenariosActions.selectors';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';
import { RepeatTriggerAdvanced } from 'components/ScenarioSetup/ActionSidebars/advanced/RepeatTriggerAdvanced';
import { RepeatTriggerSimplified } from 'components/ScenarioSetup/ActionSidebars/simplified/RepeatTriggerSimplified';
import { defaultRepeatTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultRepeatTriggerSettings';
import { simplifiedConditionSettings } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/simplified/simplifiedConditionSettings';
import { StringUse } from 'lib/StringUse';
import { useTurnOffSimplifiedConditionsButtons } from 'hooks/ScenarioSetup/useTurnOffSimplifiedConditionsButtons';
import { useTurnOffSegmentsButtons } from 'hooks/ScenarioSetup/useTurnOffSegmentsButtons';
import { useSegmentSearch } from 'hooks/ScenarioSetup/useSegmentSearch';
import { useSimplifiedCondition } from 'hooks/ScenarioSetup/useSimplifiedCondition';
import { clearCurrentEvent, getAllIntegrationEvents } from 'store/actions/integrations.action';
import { useConditionCalendarData } from 'hooks/useConditionCalendarData';

export const RepeatTriggerSidebar = () => {
    const dispatch = useDispatch();
    const context = useContext(SideBarContext);
    const { t: translator } = useTranslation();

    const {
        onChangeTime,
        onChangeSelectedDay,
        onSubmitHandler,
        onClickChangeSidebarDifficultySettings,
        initSelectedSimplifiedSettings,
        initSimplifiedTimeIntervalSettings,
        initSelectedSegmentSimplifiedSettings,
    } = context;

    const {
        _dayOfWeek = [],
        _time,
        _values,
        _condition,
        _actionParams,
        _segmentId,
        actionType,
        errors,
        title,
        segmentsButtonsList
    } = useSelector(getStoreDataRepeatTrigger);

    const { allEvents } = useSelector(store => store.integrations);

    const [values, setValues] = useState(_values);
    const [dayOfWeek, setDayOfWeek] = useState(_dayOfWeek);
    const [time, setTime] = useState(_time);
    const [condition, setCondition] = useState(_condition);
    const [actionParams, setActionParams] = useState(_actionParams);
    const [simplifiedSettingsButtons, setSimplifiedSettingsButtons] = useState(initSelectedSimplifiedSettings(_actionParams, simplifiedConditionSettings));
    const [simplifiedTimeInterval, setSimplifiedTimeInterval] = useState(initSimplifiedTimeIntervalSettings(_condition, actionParams));
    const [segmentsButtons, setSegmentsButtons] = useState(initSelectedSegmentSimplifiedSettings(_segmentId, segmentsButtonsList));
    // Means that at least one simplified parameter selected
    const isActionInSimplifiedMod = some(simplifiedSettingsButtons, 'selected');
    // Means that at least one segment selected in action
    const isActionInSegmentSimplifiedMod = some(segmentsButtons, 'selected');

    const selectedSegmentId = StringUse.recast(find(segmentsButtons, 'selected')?.id, { accepted: 'string', rejected: null });

    const daysOfWeekArray = map(dayOfWeek, day => ({ ...day, name: { ...day.name, shortCut: translator(`default:${day.name.shortCut}`)}}));

    const mappedEventNames = map(allEvents.base, event => event.event_name);

    const onClickSaveActionSettings = () => {
        const stateManagementPromise = new Promise(resolve => {
            return actionParams.simplified && !isActionInSimplifiedMod
                ? resolve({
                    data: {
                        ...defaultRepeatTriggerSettings(),
                        dayOfWeek,
                        time,
                        segmentId: selectedSegmentId
                    },
                    params: { actionParams }
                })
                : resolve({
                    data: {
                        dayOfWeek,
                        time,
                        values,
                        condition,
                        segmentId: selectedSegmentId
                    },
                    params: { actionParams }
                })
        });

        stateManagementPromise.then(actionData => {
            dispatch(saveSidebarData(actionData.data, actionData.params));
        });
    };

    useEffect(() => {
        if (isEmpty(allEvents.base)) {
            dispatch(getAllIntegrationEvents())
        }
        return () => dispatch(clearCurrentEvent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { setFocusSearchInput, setSearchTerm } = useSegmentSearch({
        segmentsButtonsList,
        setSegmentsButtons,
        _segmentId
    });

    // Update condition in advanced settings by selected simplified button settings
    useSimplifiedCondition({
        simplifiedSettingsButtons,
        isActionInSimplifiedMod,
        simplifiedTimeInterval,
        setSimplifiedTimeInterval,
        setCondition,
        setActionParams,
        setSegmentsButtons
    });

    // If user use action in advanced mode and have created any condition, we need turn off segments buttons in action
    useTurnOffSegmentsButtons({
        condition,
        isActionInSimplifiedMod,
        setSegmentsButtons
    });
    // If segment selected we need to turn off all simplified conditions buttons in action
    useTurnOffSimplifiedConditionsButtons({
        isActionInSegmentSimplifiedMod,
        segmentsButtons,
        setCondition,
        setSimplifiedSettingsButtons,
        setActionParams,
        setSimplifiedTimeInterval
    });

    useConditionCalendarData(condition, setCondition);

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <SideBarContext.Provider
            value={{
                ...context,
                condition,
                setCondition,
                errors,
                values,
                setValues,
                isActionInSimplifiedMod,
                actionParams,
                setActionParams,
                simplifiedSettingsButtons,
                setSimplifiedSettingsButtons,
                simplifiedTimeInterval,
                setSimplifiedTimeInterval,
                segmentsButtons,
                setSegmentsButtons,
                setSearchTerm,
                setFocusSearchInput,
                mappedEventNames
            }}>
            <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
                <>
                    <div className={styles.sidebarHeaderContainer}>
                        <SidebarGeneralTitle title={title}/>
                        <GearSwitchableButton
                            active={!actionParams.simplified}
                            onClick={onClickChangeSidebarDifficultySettings(setActionParams)}
                        />
                    </div>

                    <SelectDayOfWeek
                        array={daysOfWeekArray}
                        onChange={onChangeSelectedDay(setDayOfWeek)}
                        errors={errors}
                    />

                    <InputTimeField
                        value={time}
                        onChange={onChangeTime(setTime)}
                        label={translator('actionSidebars:TimeFieldLabel')}
                    />

                    {actionParams.simplified
                        ? <RepeatTriggerSimplified/>
                        : <RepeatTriggerAdvanced/>
                    }

                    <ActionSidebarButtons/>
                </>
            </ActionSidebarFormWrapper>
        </SideBarContext.Provider>
    )
};
