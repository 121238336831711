import React from 'react';
import styles from 'common/_CatchErrorBoundary/CatchErrorBoundary.module.css';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';

export const CatchErrorBoundary = ({ error, resetErrorBoundary }) => (
    <div className={styles.container}>
        <h3>Something went wrong..</h3>

        <p>{error.message}</p>

        <LightGreenButton
            name='Reset'
            onClick={resetErrorBoundary}
            className={styles.button}
        />
    </div>
);