import { createImagesFolder } from 'store/actions/images.action';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useFolders = (folderRedirectPath) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoadingImageFolders = useSelector(store => store.api.isLoadingImageFolders);
    const folders = useSelector(store => store.images.folders);
    const currentFolder = useSelector(store => store.images.currentFolder);

    const onAfterSuccessCreateImageFolder = folder => navigate(folderRedirectPath + folder.id);

    const createNewImageFolder = (name, onAfterSuccessCallback) => {
        return () => dispatch(createImagesFolder(name, onAfterSuccessCallback
            ? onAfterSuccessCallback
            : folderRedirectPath
                ? onAfterSuccessCreateImageFolder
                : () => {}
        ));
    };

    return {
        folders,
        currentFolder,
        isLoadingImageFolders,
        createNewImageFolder,
        folderRedirectPath
    }
}