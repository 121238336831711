import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import { CustomDateRangePickerCalendar } from 'components/Calendars/DateRangePickerCalendar';
import { SelectDatesInterval } from 'components/DateSelectors/SelectDatesInterval';
import { toggleRangeCalendarMetrics } from 'store/actions/calendars.action';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useSelector } from 'react-redux';

export const RangeCalendarMetrics = ({ loading }) => {
    const { open: isOpenRangeCalendarMetrics, startDate, endDate } = useSelector(store => store.calendars.rangeCalendarMetrics);

    const closeCalendarCallback = (start, end) => toggleRangeCalendarMetrics({
        open: false,
        startDate: start ? format(start, 'dd/MM/yyyy', { locale: ru }) : null,
        endDate: end ? format(end, 'dd/MM/yyyy', { locale: ru }) : null,
    });

    return (
        <>
            {isOpenRangeCalendarMetrics && <CustomDateRangePickerCalendar
                closeCalendarCallback={closeCalendarCallback}
                start={startDate}
                end={endDate}
            />}
            <SkeletonWrapper isShow={!loading} width={160} height={35}>
                <SelectDatesInterval/>
            </SkeletonWrapper>
        </>
    );
};

RangeCalendarMetrics.propTypes = {
    loading: PropTypes.bool.isRequired
};
