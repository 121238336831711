import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const concatedValuesRules = {
    dateOffset: 'integer',
    timeOffset: 'integer'
};

export const tailoredWaitControl = action => {

    const {
        dateOffset,
        timeOffset,
    } = action.settings

    const concatedValues = {
        dateOffset: dateOffset.value,
        timeOffset: timeOffset.value
    }

    const validation = Validation.checkSome(concatedValues, concatedValuesRules);

    return {
        ...action,
        isShowWarningActionMessage: validation.fails,
        errors: {
            ...ObjectUse.createSeveralObjects([
                validation.firstErrors.dateOffset,
                validation.firstErrors.timeOffset
            ], ['dateOffset', 'timeOffset'])
        }
    }
}
