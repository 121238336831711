import { defaultTailoredWaitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultTailoredWaitSettings';
import { StringUse } from 'lib/StringUse';

const { waitToDate, dateOffset, waitToTime, timeOffset } = defaultTailoredWaitSettings();

export const tailoredWaitDecompiler = actionOptions => {
    const {
        date_offset = dateOffset?.value,
        time_offset = timeOffset?.value,
        wait_to_date = waitToDate?.value,
        wait_to_time = waitToTime?.value
    } = actionOptions;

    return {
        waitToDate: {
            ...waitToDate,
            value: StringUse.falseyReturnEmptyString(wait_to_date),
        },
        dateOffset: {
            ...dateOffset,
            value: StringUse.falseyReturnEmptyString(date_offset, 'string'),
        },
        waitToTime: {
            ...waitToTime,
            value: StringUse.falseyReturnEmptyString(wait_to_time),
        },
        timeOffset: {
            ...timeOffset,
            value: StringUse.falseyReturnEmptyString(time_offset, 'string'),
        }
    }
}
