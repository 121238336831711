import React from 'react';
import PropTypes from 'prop-types';
import { TagsInput } from 'components/TagsInput/TagsInput';
import { ArrayUse } from 'lib/ArrayUse';

export const AttributeFilterDropdownTags = ({ id, inputTagValues, inputTagName, setState, multipleFilter, multipleFilterParentId }) => {

    const updateStateFromProps = array => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: multipleFilter ? multipleFilterParentId : id });
        // For multiple parents component, attribute filter
        if (multipleFilter) {
            const attributeFilterElement = ArrayUse.findCollection(collection._attributeFilter, { id: id } );

            return ArrayUse.replace(prev, index, {
                ...collection,
                _attributeFilter: ArrayUse.replace(
                    collection._attributeFilter,
                    attributeFilterElement.index, {...attributeFilterElement.data, [inputTagName]: {...attributeFilterElement.data[inputTagName], value: array }}
                )
            })
        }

        // For single parent component, attribute filter
        return ArrayUse.replace(prev, index, {
            ...collection,
            [inputTagName]: {
                ...collection[inputTagName],
                value: array
            }})
    });

    return <TagsInput
        values={inputTagValues}
        name={inputTagName}
        updateCallback={updateStateFromProps}
        withoutErrorField
    />
}
AttributeFilterDropdownTags.protoTypes = {
    id: PropTypes.number.isRequired,
    inputTagValues: PropTypes.array.isRequired,
    inputTagName: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    multipleFilter: PropTypes.bool,
    multipleFilterParentId: PropTypes.number
}
AttributeFilterDropdownTags.defaultProps = {
    multipleFilter: false,
    multipleFilterParentId: null
}
