import { useContext, useEffect } from 'react';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';

export const useTurnOffSegmentsButtons = props => {
    const {
        condition,
        isActionInSimplifiedMod,
        setSegmentsButtons
    } = props;

    const {
        turnOffSimplifiedSettingsButtons
    } = useContext(SideBarContext);

    useEffect(() => {
        // If user use action in advanced mode and have created any condition, we need turn off segmengs buttons in action
        if (condition.length && !isActionInSimplifiedMod) {
            turnOffSimplifiedSettingsButtons(setSegmentsButtons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, isActionInSimplifiedMod])
}
