import React from 'react';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import { QuadrupleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/QuadrupleChainActionIcon';
import { ABTestSplitIconDropped } from 'assets/icons/ABTestSplitIconDropped';
import { ABTestSplitIconAlt } from 'assets/icons/ABTestSplitIconAlt';

export const AbTestSplit = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const classNameExtension = styles.actionCanted

    const actionIconConditions = {
        base: {
            icon: ABTestSplitIconDropped,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#00C6A2'
            }
        },
        opened: {
            icon: ABTestSplitIconAlt,
            iconProps: { borderColor: '#00C6A2' }
        },
        warning: {
            icon: ABTestSplitIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <QuadrupleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
        classNameExtension,
    }} />
}
