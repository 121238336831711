import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsIcon } from 'assets/icons/AnalyticsIcon';
import { CopyIcon } from 'assets/icons/CopyIcon';
import { EllipsisIcon } from 'assets/icons/EllipsisIcon';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from 'components/Buttons/EllipsisButtons/EllipsisButtons.module.css';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const EllipsisChaiInfoButton = ({ handleCopy, linkToAnalytics, handleDelete }) => {
    const [isOpenChainInfo, setIsOpenChainInfo] = useState(false);

    const { t: translator } = useTranslation();

    const ref = useRef(null);

    const handleEllipsis = () => {
        setIsOpenChainInfo(prevState => !prevState)
    };

    useEffect(() => {
        const handleClickOutsideChainInfo = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpenChainInfo(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideChainInfo);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideChainInfo);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <span onClick={handleEllipsis} className={cx(styles.ellipsisIcon , { 'activeIcon': isOpenChainInfo })}>
                <EllipsisIcon color='inherit'/>
                {isOpenChainInfo && (
                    <div className={styles.chainInfoWrapper} ref={ref}>
                        <button onClick={handleCopy}>
                            <CopyIcon color="currentColor"/>
                            <span>{translator('default:CopyButtonTitle')}</span>
                        </button>
                        {linkToAnalytics
                            ? <Link to={linkToAnalytics}>
                                <AnalyticsIcon color="currentColor"/>
                                <span>{translator('default:StatisticButtonTitle')}</span>
                            </Link>
                            : null
                        }
                        <button onClick={handleDelete}>
                            <TrashIcon color="currentColor"/>
                            <span>{translator('default:DeleteButtonTitle')}</span>
                        </button>
                    </div>
                )}
            </span>
        </>
    )
};

EllipsisChaiInfoButton.propTypes = {
    handleCopy: PropTypes.func,
    handleDelete: PropTypes.func,
    linkToAnalytics: PropTypes.string
};

EllipsisChaiInfoButton.defaultProps = {
    linkToAnalytics: null
};
