import { useCallback, useContext, useEffect, useState } from 'react';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { getSegmentsList } from 'store/actions/segments.action';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'hooks/useDebounce';

export const useSegmentSearch = props => {
    const dispatch = useDispatch();

    const {
        segmentsButtonsList,
        setSegmentsButtons,
        _segmentId
    } = props;

    const {
        initSelectedSegmentSimplifiedSettings
    } = useContext(SideBarContext);

    const [focusSearchInput, setFocusSearchInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const searchSegment = useCallback(query => dispatch(getSegmentsList(query)), [dispatch]);

    // Used for search segment by query
    useEffect(() => {
        if (focusSearchInput) {
            searchSegment(debouncedSearchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, searchSegment])

    // Used for update state with segments buttons if user search some
    useEffect(() => {
        if (focusSearchInput) {
            setSegmentsButtons(initSelectedSegmentSimplifiedSettings(_segmentId, segmentsButtonsList));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentsButtonsList])

    return {
        setFocusSearchInput,
        setSearchTerm
    }
}
