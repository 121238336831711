import React from 'react';
import PropTypes from 'prop-types';
import styles from './FillableModal.module.css';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';
import classNames from 'classnames/bind';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

const cx = classNames.bind(styles);

export const FillableModal = ({
    title,
    label,
    cancelButtonTitle,
    acceptButtonTitle,
    value,
    onChangeInput,
    onClickCancel,
    onClickAccept,
    modalErrorMessage,
    isModalHasError,
    isModalWithOptionDropdown,
    defaultDropdownOption,
    dropdownOptionsList,
    onChangeDropdownOption
}) => (
    <div className={styles.container}>
        <h3>{title}</h3>

        <Label htmlFor='fillableModalInput' text={label}/>
        <InputField
            id='fillableModalInput'
            name='folderName'
            onChange={onChangeInput}
            value={value}
            error={isModalHasError}
        />
        <p className={cx(styles.error, { 'active': isModalHasError })}>{modalErrorMessage}</p>

        {isModalWithOptionDropdown &&
            <CustomSelectDropdown
                dropdownName='fillableModalDropdown'
                dropdownLabel='Select type of uploaded images'
                defaultDropdownValue={defaultDropdownOption}
                dropdownOptions={dropdownOptionsList}
                onChangeDropdown={onChangeDropdownOption}
                className={styles.dropdown}
                dropdownListContainerClassName={styles.dropdownListContainer}
            />
        }

        <div className={styles.buttonsContainer}>
            <LightGreenButton
                name={cancelButtonTitle}
                onClick={onClickCancel}
                className={styles.button}
            />
            <GreenButton
                name={acceptButtonTitle}
                onClick={onClickAccept}
                className={styles.button}
            />
        </div>
    </div>
);

FillableModal.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cancelButtonTitle: PropTypes.string.isRequired,
    acceptButtonTitle: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeInput: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickAccept: PropTypes.func.isRequired,
    modalErrorMessage: PropTypes.string,
    isModalHasError: PropTypes.bool,
    isModalWithOptionDropdown: PropTypes.bool,
    defaultDropdownOption: PropTypes.string,
    dropdownOptionsList: PropTypes.array,
    onChangeDropdownOption: PropTypes.func
};
FillableModal.defaultProps = {
    value: '',
    modalErrorMessage: '',
    isModalHasError: false,
    isModalWithOptionDropdown: false,
    defaultDropdownOption: '',
    dropdownOptionsList: [],
    onChangeDropdownOption: () => {}
};