import React from 'react';
import { YAxis } from 'recharts';
import { BlueLinearGradient, GreenLinearGradient, VioletLinearGradient } from 'data/colorsChart';
import { map, slice } from 'lodash';

export const axisTickStyles = { fill: '#828282', fontSize: '10px', fontFamily: 'ProximaNova-Regular, sans-serif', letterSpacing: '0.18px'};

export const customizationParamsCharts = {
    linearGradients: {
        blue: <BlueLinearGradient/>,
        green: <GreenLinearGradient/>,
        violet: <VioletLinearGradient/>
    },
    typesYAxis: {
        default: <YAxis tick={axisTickStyles} axisLine={false} tickLine={false} tickSize='10'/>,
        percent: <YAxis tick={axisTickStyles} domain={[0, 100]} tickCount='6' tickFormatter={tick => `${tick}%`} axisLine={false} tickLine={false} tickSize='15'/>,
        thousandth: <YAxis tick={axisTickStyles} axisLine={false} tickLine={false} tickSize='10' tickFormatter={tick => tick > 1000? `${tick / 1000}K` : tick }/>
    },
    colorMarks: {
        blueMarks: 'linear-gradient(180deg, #0CC8E7 0%, rgba(108, 211, 234, 0.5) 154.2%)',
        greenMarks: 'linear-gradient(180deg, #00C6A2 0%, rgba(116, 206, 190, 0.5) 154.2%)',
        violetMarks: 'linear-gradient(180deg, #9088DF 22.59%, rgba(190, 185, 235, 0.5) 141.95%)'
    }
};

const { blueMarks, violetMarks, greenMarks} = customizationParamsCharts.colorMarks;
const { linearGradients, typesYAxis } = customizationParamsCharts;

export const configChartEnteredIntoScenario = {
    typeChart: 'AreaChart',
    title: 'ChartClientTitle',
    tooltipParams: {
        titles: [
            { title: 'MetricsScenarioEntered', legendColor: '#0CBFE7' },
            { title: 'MetricsCompletedPurchases', legendColor: '#00C6A2' },
        ],
        valueType: 'K',
        colorMarks: [blueMarks, greenMarks],
        columnValueBlock: true,
        prefix: false
    },
    nameXAxis: 'day',
    customYAxis: typesYAxis.thousandth,
    gradients: [
        { id: 'colorConverted', gradientColor: linearGradients['green']},
        { id: 'colorEntered', gradientColor: linearGradients['blue']}
    ],
    areas: [
        { dataKey: 'clients_entered', fillId: 'colorEntered', stroke: '#0CC8E7' },
        { dataKey: 'clients_converted', fillId: 'colorConverted', stroke: '#00C6A2' },
    ]
};

export const configChartConversionsScenario = {
    typeChart: 'AreaChart',
    title: 'ChartScenarioConversionTitle',
    tooltipParams: {
        titles: [{ title: 'MetricsConversionRate', legendColor: '#9088DF' }],
        valueType: '%',
        colorMarks: [violetMarks],
        prefix: false
    },
    gradients: [{ id: 'colorConversions', gradientColor: linearGradients['violet']}],
    nameXAxis: 'day',
    customYAxis: typesYAxis.percent,
    areas: [{ dataKey: 'conversion_rate', fillId: 'colorConversions', stroke: '#9088DF' }]
};

export const configChartRevenueScenario = {
    typeChart: 'AreaChart',
    title: 'ChartScenarioRevenueTitle',
    tooltipParams: {
        titles: [{ title: 'MetricsRevenue', legendColor: '#00C6A2' }],
        valueType: 'K',
        colorMarks: [greenMarks],
        prefix: false
    },
    gradients: [{ id: 'colorRevenue', gradientColor: linearGradients['green']}],
    nameXAxis: 'day',
    customYAxis: typesYAxis.thousandth,
    areas: [{ dataKey: 'revenue', fillId: 'colorRevenue', stroke: '#00C6A2' }]
};

export const configChartRevenueTemplate = {
    typeChart: 'AreaChart',
    title: 'ChartTemplateRevenueTitle',
    tooltipParams: {
        titles: [{ title: 'MetricsRevenue', legendColor: '#0CBFE7' }],
        valueType: 'K',
        colorMarks: [blueMarks],
        prefix: false
    },
    gradients: [{ id: 'colorRevenue', gradientColor: linearGradients['blue']}],
    nameXAxis: 'day',
    customYAxis: typesYAxis.thousandth,
    areas: [{ dataKey: 'revenue', fillId: 'colorRevenue', stroke: '#0CBFE7' }]
};

export const configChartSendMessageTemplate = {
    typeChart: 'AreaChart',
    title: 'ChartSendConversionTitle',
    tooltipParams: {
        titles: [
            { title: 'MetricsSendMessages', legendColor: '#00C6A2' },
            { title: 'MetricsSiteConversion', legendColor: '#0CBFE7' },
        ],
        valueType: 'K',
        colorMarks: [greenMarks, blueMarks],
        columnValueBlock: true,
        prefix: false
    },
    nameXAxis: 'day',
    customYAxis: typesYAxis.thousandth,
    gradients: [
        { id: 'colorSend', gradientColor: linearGradients['green']},
        { id: 'colorEntered', gradientColor: linearGradients['blue']}
    ],
    areas: [
        { dataKey: 'msg_sent', fillId: 'colorSend', stroke: '#00C6A2' },
        { dataKey: 'msg_clicked', fillId: 'colorEntered', stroke: '#0CC8E7' }
    ]
};

export const configChartPurchases = {
    typeChart: 'AreaChart',
    title: 'ChartNumberOfPurchasesTitle',
    tooltipParams: {
        titles: [{ title: 'MetricsCompletedPurchases', legendColor: '#9088DF' }],
        valueType: 'K',
        colorMarks:  [violetMarks],
        prefix: false
    },
    gradients: [{ id: 'colorPurchases', gradientColor: linearGradients['violet']}],
    nameXAxis: 'day',
    customYAxis: typesYAxis.thousandth,
    areas: [{ dataKey: 'purchases', fillId: 'colorPurchases', stroke: '#9088DF' }]
};

const colorsCircles = ['#1477F8', '#00C6A2', '#FF6E40', '#FFEA00', '#00E5FF', '#9088DF', '#89DDFF', '#71C297', '#F3AD3E', '#FFD740', '#00B8D4', '#BB6BD9'];

export const createConfigChartGroupUnit = (title, namesGroupAB) => {
    const titles = map(namesGroupAB, (name, index) => ({
        title: name,
        legendColor: colorsCircles[index]
    }));

    const colorMarks = slice(colorsCircles, 0, namesGroupAB.length);

    const areas = map(namesGroupAB, (name, index) => ({
        dataKey: name,
        stroke: colorsCircles[index]
    }));

    return {
        typeChart: 'LineChart',
        title,
        tooltipParams: {
            titles,
            valueType: 'K',
            colorMarks,
            columnValueBlock: true,
            prefix: 'MetricsABGroup'
        },
        nameXAxis: 'day',
        customYAxis: typesYAxis.thousandth,
        areas,
    };
};
