import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataEditSegment } from 'store/selectors/scenariosActions.selectors';
import { getManualSegmentsList } from 'store/actions/segments.action';
import { EditSegmentSimplified } from 'components/ScenarioSetup/ActionSidebars/simplified/EditSegmentSimplified';
import { isEmpty, map, find, eq } from 'lodash';
import { TabButtons } from 'common/Buttons/TabButtons/TabButtons';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

const getSegmentTypeId = actionTypes => {
    const { id } = find(actionTypes, { active: true });

    return id
};

export const EditSegmentSidebar = () => {
    const [selectedSegmentId, setSelectedSegmentId] = useState('');
    const [actionTypeButtons, setActionTypeButtons] = useState([
        { active: true, id: 'addToSegment', name: 'Add'},
        { active: false, id: 'removeFromSegment', name: 'Exclude'}
    ]);

    const { t: translator } = useTranslation();
    const dispatch = useDispatch();

    const { title, actionType, settings: { segmentId, segmentActionType } } = useSelector(getStoreDataEditSegment);

    const segmentsManualList = useSelector(store => store.segments.manualSegmentsList);

    const { onSubmitHandler } = useContext(SideBarContext);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData(
        { segmentId: selectedSegmentId, segmentActionType: getSegmentTypeId(actionTypeButtons) },
        { errors: {} }
    ));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    useEffect(() => {
        if (isEmpty(segmentsManualList)) {
            dispatch(getManualSegmentsList())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (segmentId) {
            setSelectedSegmentId(segmentId)
        }

        setActionTypeButtons(prev => map(prev, item => eq(item.id, segmentActionType)? { ...item, active: true } : { ...item, active: false }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentsManualList]);


    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={title}/>

                <TabButtons
                    buttons={map(actionTypeButtons, button => ({ ...button, name: translator(`conditionSegments:${button.name}`) }))}
                    setState={setActionTypeButtons}
                />

                <EditSegmentSimplified selectedSegmentId={selectedSegmentId} setSelectedSegmentId={setSelectedSegmentId}/>

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
};
