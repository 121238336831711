import React from 'react';
import styles from 'components/Cards/CreativeCards/CreativeCardPreviewBody/WebPushBody.module.css';
import PropTypes from 'prop-types';
import { find } from 'lodash';

export const WebPushBody = ({ jinja_fields }) => {
    const message = find(jinja_fields, { jinja_field_name: 'jinja_body' });
    const messageTitle = find(jinja_fields, { jinja_field_name: 'jinja_msg_title' });
    const logo = find(jinja_fields, { jinja_field_name: 'jinja_push_logo' });
    const redirectLink = find(jinja_fields, { jinja_field_name: 'jinja_redirect_link' });

    return (
        <div className={styles.webPushBodyContainer}>
            <div className={styles.webPushBodyTop}>
                <span style={{backgroundImage: `url(${logo?.value})`}}/>
                <div className={styles.webPushBodyPreview}>
                    <div className={styles.webPushBodyPreviewTitle}>
                        <p>{messageTitle?.value}</p>
                        <div className={styles.webPushBodyIcons}>
                            <span className={styles.settingsWindowsIcon}/>
                            <span className={styles.closeWindowsIcon}/>
                        </div>
                    </div>
                    <p className={styles.webPushBodyMsg}>{message?.value}</p>
                    <p className={styles.webPushBodyLink}>{redirectLink?.value}</p>
                </div>
            </div>
            <div className={styles.webPushBodyBottom}>
                <p>Button</p>
            </div>
        </div>
    )
};

WebPushBody.propTypes = {
    jinja_fields: PropTypes.array,
};
