import { snakeCase, map, some, split } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { attributeFilterCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/attributeFilterCompiler';
import { conditionsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/conditionsCompiler';
import { ArrayUse } from 'lib/ArrayUse';
import { StringUse } from 'lib/StringUse';

export const conditionalSplitCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            attributeFilter,
            condition,
            productAttributeFilter,
            splitType,
            values: {
                actionName,
                subActionName
            },
            segmentId
        }
    } = action;

    const [ top, bottom ] = nextActionIdsCompiler(filteredArrows, actionType);

    // For Conditional Split "Event" type
    const getEventFilterOptions = (splitType, actionName, subActionName) => {
        // If split type is not 'event' always return null
        if (!some(splitType, { id: 'event', active: true })) return null;

        const attributeFilterCompiled = attributeFilterCompiler(attributeFilter);

        return [{
            event_name: StringUse.recast(actionName, { accepted: 'string', rejected: null }),
            event_subname: StringUse.recast(subActionName, { accepted: 'string', rejected: null }),
            conditions: [
                ...attributeFilterCompiled
            ]
        }];
    };

    // For Conditional Split "Client" type
    const getClientFilterOptions = (splitType, condition, segmentId) => {
        // If split type is not 'client' always return null
        if (!some(splitType, { id: 'client', active: true })) return null;
        // If split type selected 'client' and user select segment id return null
        if (segmentId) return null;

        const conditionsCompiled = conditionsCompiler(condition);

        return [
            ...conditionsCompiled
        ];
    };

    // For Conditional Split "Product" type
    const getProductFilterOptions = (splitType, productAttributeFilter) => {
        // If split type is not 'Product' always return null
        if (!some(splitType, { id: 'product', active: true })) return null;

        const productAttributeFilterCompiled = map(productAttributeFilter, item => {
            const {
                columnNameOfProductFromEvent,
                columnNameFromEvent,
                columnComparisonOperator,
                columnNameFromCatalog,
            } = item;

            const {
                splitOperator: columnNameOfProductFromEventSplitOperator,
                exists: columnNameOfProductFromEventSplitOperatorExists
            } = ArrayUse.splitArrayExtension(columnNameOfProductFromEvent.value, [',', ' ', '.']);

            const {
                splitOperator: columnNameFromEventSplitOperator,
                exists: columnNameFromEventSplitOperatorExists
            } = ArrayUse.splitArrayExtension(columnNameFromEvent.value, [',', ' ', '.']);

            return {
                product_id_col_name: columnNameOfProductFromEventSplitOperatorExists ?
                        split(columnNameOfProductFromEvent.value, columnNameOfProductFromEventSplitOperator)
                        : [columnNameOfProductFromEvent.value],
                trigger_event_col_name: columnNameFromEventSplitOperatorExists ?
                        split(columnNameFromEvent.value, columnNameFromEventSplitOperator)
                        : [columnNameFromEvent.value],
                operator: StringUse.recast(columnComparisonOperator.value, { accepted: 'string', rejected: null }),
                products_table_col_name: [columnNameFromCatalog.value]
            }
        });

        return [
            ...productAttributeFilterCompiled
        ]
    }

    return {
        action_id: id,
        action_type: snakeCase(actionType),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            customer_segment: getClientFilterOptions(splitType, condition, segmentId),
            event_filter: getEventFilterOptions(splitType, actionName.value, subActionName.value),
            product_filter: getProductFilterOptions(splitType, productAttributeFilter),
            segment_filter: some(splitType, { id: 'client', active: true }) && segmentId
                ? { segment_id: segmentId }
                : null
        },
        next_actions_ids: [
            bottom,
            top
        ]
    }
}
