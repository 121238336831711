import { map, forEach, some, method, isEmpty, eq } from 'lodash';
import { defaultConnectionColor, positiveConnectionColor, negativeConnectionColor } from 'components/ScenarioSetup/ActionConnections/defaultStyles';
import { waitDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/waitDecompiler';
import { tailoredWaitDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/tailoredWaitDecompiler';
import { sendContentDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/sendContentDecompiler';
import { triggerEventDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/triggerEventDecompiler';
import { conditionalSplitDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/conditionalSplitDecompiler';
import { repeatTriggerDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/repeatTriggerDecompiler';
import { scheduleTriggerDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/scheduleTriggerDecompiler';
import { funnelDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/funnelDecompiler';
import { customWebHookDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/customWebHookDecompiler';
import { ABTestSplitDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/aBTestSplitDecompiler';
import { sqlTriggerDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/sqlTriggerDecompiler';
import { pushContentDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/pushContentDecompiler';
import { websiteBuilderDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/websiteBuilderDecompiler';
import { editSegmentDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/editSegmentDecompiler';

export const actionChainDecompiler = scenario => {
    const { triggers, actions } = scenario;

    const serverActionsTypes = {
        on_event: { type: 'TriggerEvent', title: 'Trigger Event', optionsDecompiler: triggerEventDecompiler },
        repeated: { type: 'RepeatTrigger', title: 'Repeat Trigger', optionsDecompiler: repeatTriggerDecompiler },
        on_date: { type: 'ScheduleTrigger', title: 'Schedule Trigger', optionsDecompiler: scheduleTriggerDecompiler },
        sql_trigger: { type: 'SqlTrigger', title: 'SQL Trigger', optionsDecompiler: sqlTriggerDecompiler },
        wait: { type: 'Wait', title: 'Wait', optionsDecompiler: waitDecompiler },
        individual_wait: { type: 'TailoredWait', title: 'Tailored Wait', optionsDecompiler: tailoredWaitDecompiler },
        conditional_split: { type: 'ConditionalSplit', title: 'Conditional Split', optionsDecompiler: conditionalSplitDecompiler },
        a_b_test_split: { type: 'ABTestSplit', title: 'A/B test split', optionsDecompiler: ABTestSplitDecompiler },
        funnel: { type: 'Funnel', title: 'Funnel', optionsDecompiler: funnelDecompiler },
        send_email: { type: 'SendEmail', title: 'Send Email', optionsDecompiler: sendContentDecompiler },
        send_sms: { type: 'SendSms', title: 'Send SMS', optionsDecompiler: sendContentDecompiler },
        send_viber: { type: 'SendViber', title: 'Send Viber', optionsDecompiler: sendContentDecompiler },
        custom_webhook: { type: 'CustomWebHook', title: 'Custom Webhook', optionsDecompiler: customWebHookDecompiler },
        custom_website: { type: 'WebsiteBuilder', title: 'Website Builder', optionsDecompiler: websiteBuilderDecompiler },
        send_mob_push: { type: 'SendMobPush', title: 'Mobile Push', optionsDecompiler: pushContentDecompiler },
        send_web_push: { type: 'SendWebPush', title: 'Web Push', optionsDecompiler: pushContentDecompiler },
        add_to_segment: { type: 'EditSegment', title: 'Edit Segment', optionsDecompiler: editSegmentDecompiler },
        remove_from_segment: { type: 'EditSegment', title: 'Edit Segment', optionsDecompiler: editSegmentDecompiler },
    };

    // Add all action triggers draggableTypes to array below, because next_actions_ids has difference for acttions and triggers draggableTypes
    const nextActionsIdsEqualization = (nextActionsIds, actionType) => some(['on_event', 'on_date', 'repeated', 'sql_trigger'], method('includes', actionType)) ? [ nextActionsIds ] : nextActionsIds;

    const createActionConnection = (outputId, nextActionsIds, actionType) => {
        if (nextActionsIds[0]?.length || nextActionsIds[1]?.length || nextActionsIds[2]?.length) {
            switch (actionType) {
                case 'conditional_split':
                    return map(nextActionsIds, (arr, index) => {
                        if (!isEmpty(arr) && eq(index, 0)) {
                            return map(arr, inputId => {
                                return {
                                    from: { id: `${outputId}-output-bottom`, color: negativeConnectionColor },
                                    to: { id: `${inputId}-input`}
                                }
                            })
                        }
                        if (!isEmpty(arr) && eq(index, 1)) {
                            return map(arr, inputId => {
                                return {
                                    from: { id: `${outputId}-output-top`, color: positiveConnectionColor },
                                    to: { id: `${inputId}-input`}
                                }
                            })
                        }
                        return []
                    })
                case 'a_b_test_split':
                    return map(nextActionsIds, (arr, index) => {
                        if (!isEmpty(arr) && eq(index, 0)) {
                            return map(arr, inputId => {
                                return {
                                    from: { id: `${outputId}-output-right`, color: defaultConnectionColor },
                                    to: { id: `${inputId}-input`}
                                }
                            })
                        }
                        if (!isEmpty(arr) && eq(index, 1)) {
                            return map(arr, inputId => {
                                return {
                                    from: { id: `${outputId}-output-top`, color: defaultConnectionColor },
                                    to: { id: `${inputId}-input`}
                                }
                            })
                        }
                        if (!isEmpty(arr) && eq(index, 2)) {
                            return map(arr, inputId => {
                                return {
                                    from: { id: `${outputId}-output-bottom`, color: defaultConnectionColor },
                                    to: { id: `${inputId}-input`}
                                }
                            })
                        }
                        return []
                    })
                default:
                    return map(nextActionsIdsEqualization(nextActionsIds, actionType), inputIdsArray => {
                        return map(inputIdsArray, inputId => {
                            return {
                                from: { id: `${outputId}-output`, color: defaultConnectionColor },
                                to: { id: `${inputId}-input`}
                            }
                        })
                    })
            }
        }
        return null
    };

    const transformedArrowsArray = [];

    const transformConnectionArray = array => {
        if (!array) return

        // The way for default arrows connections
        if (!isEmpty(array) && eq(array.length, 1)) {
            forEach(array, item => {
                // The way for actions
                if (item instanceof Array) { return transformedArrowsArray.push(...item) }
                // The way for triggers (remove after fix triggers)
                return transformedArrowsArray.push(item)
            })
        }

        // The way for arrows which has more than one output arrows connections
        if (!isEmpty(array) && !eq(array.length, 1)) {
            // Bottom and Negative arrows for 'conditional_split' / Control Group and Right for 'a_b_test_split'
            array[0] && forEach(array[0], item => transformedArrowsArray.push(item));
            // Top and Positive arrows for 'conditional_split' / A group and top for 'a_b_test_split'
            array[1] && forEach(array[1], item => transformedArrowsArray.push(item));
            // Bottom and B group arrows for 'a_b_test_split' only
            array[2] && forEach(array[2], item => transformedArrowsArray.push(item));
        }

    }

    const transformedTriggers = map(triggers, item => {
        const { trigger_id, trigger_type, trigger_params, next_actions_ids, trigger_options, coordinates } = item

        transformConnectionArray(createActionConnection(trigger_id, next_actions_ids, trigger_type))
        // actionParams help to add default object for trigger and actions which doesn't have it. Actual for old actions and trigger before this update
        const actionParams = trigger_params ? trigger_params : { simplified: false, simplifiedType: null };

        return {
            actionType: serverActionsTypes[trigger_type].type,
            title: serverActionsTypes[trigger_type].title,
            position: coordinates,
            id: trigger_id,
            isOpenActionInfo: false,
            isShowWarningActionMessage: false,
            isAutoSaveActive: false,
            actionParams,
            errors: {},
            settings: serverActionsTypes[trigger_type].optionsDecompiler(trigger_options, trigger_type),
        }
    })

    const transformedActions = map(actions, item => {
        const { action_id, action_type, action_params, coordinates, next_actions_ids, action_options } = item

        transformConnectionArray(createActionConnection(action_id, next_actions_ids, action_type))
        // actionParams help to add default object for trigger and actions which doesn't have it. Actual for old actions and trigger before this update
        const actionParams = action_params ? action_params : { simplified: false, simplifiedType: null };

        return {
            actionType: serverActionsTypes[action_type].type,
            title: serverActionsTypes[action_type].title,
            position: coordinates,
            id: action_id,
            isOpenActionInfo: false,
            isShowWarningActionMessage: false,
            isAutoSaveActive: false,
            actionParams,
            errors: {},
            settings: serverActionsTypes[action_type].optionsDecompiler(action_options, action_type),
        }
    })

    return {
        actions: [...transformedTriggers, ...transformedActions],
        arrows: transformedArrowsArray
    }
}
