import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CustomUIConfirmAlerter = ({ onClose, config, successCallback }) => {
    const { t: translator } = useTranslation();

    const onClickSubmit = () => {
        return new Promise(resolve => {
            onClose();

            return resolve();
        }).then(() => successCallback());
    };

    return (
        <div className="pop-up">
            <h3>{translator(`popUpsSystemMessages:${config?.message?.title}`)}</h3>
            <p>{translator(`popUpsSystemMessages:${config?.message?.text}`)}</p>

            <div className="buttons-container">
                {config?.submitButton?.active && <button
                    className='transparent-button'
                    onClick={onClickSubmit}
                >
                    {translator(`default:${config?.submitButton?.name}`)}
                </button>}

                {config?.cancelButton?.active && <button
                    onClick={onClose}
                >
                    {translator(`default:${config?.cancelButton?.name}`)}
                </button>}
            </div>
        </div>
    )
}
CustomUIConfirmAlerter.propTypes = {
    config: PropTypes.object.isRequired,
    successCallback: PropTypes.func
}
CustomUIConfirmAlerter.defaultProps = {
    config: {
        message: {
            title: 'DefaultPopUpsTitle',
            text: 'DefaultPopUpsText'
        },
        submitButton: {
            active: false,
            name: ''
        },
        cancelButton: {
            active: true,
            name: 'CancelButtonTitle'
        }
    },
    successCallback: () => null
};
