import React from 'react';
import { map, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/SelectOptions/SelectOptions.module.css';
import classNames from 'classnames/bind';
import { Label } from 'common/_Label/Label';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const FrequencyPoliciesSelectOptions = ({ onChange, value }) => {
    const { t: translator } = useTranslation();
    const { list } = useSelector(store => store.frequencyPolicies);

    return (
        <>
            <Label htmlFor='policyId' text={translator(`actionSidebars:CommunicationPolicyLabel`)}/>
            <select
                name='policyId'
                id='policyId'
                className={cx(styles.selectContainer, styles.selectWithoutErrors)}
                onChange={onChange}
                value={value}
                required
            >
                {map(sortBy(list, ({ is_default }) => !is_default), ({ policy_id, policy_name }) => (
                        <option key={policy_id} value={policy_id}>{policy_name}</option>
                    )
                )}
            </select>
        </>
    )
};
FrequencyPoliciesSelectOptions.propsTypes = {
    onChange: PropTypes.func.isRequired,
    currentValue: PropTypes.string.isRequired,
};
