import React from 'react';
import styles from 'common/Headers/Headers.module.css';
import PropTypes from 'prop-types';
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import { SwitchToggler } from 'common/SwitchToggler/SwitchToggler';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const DashboardHeaderStatus = ({
    isShowLaunchButton,
    launchButtonSettings,
    isShowStatus,
    statusName,
    statusMessage,
    color
}) => {
    return (
        <>
            <div className={styles.dashboardHeaderStatusContainer}>
                {isShowLaunchButton &&
                    <SwitchToggler
                        isChecked={launchButtonSettings.isChecked}
                        onChange={launchButtonSettings.onChange}
                    />
                }

                {isShowStatus &&
                    <SkeletonWrapper isShow={!!statusName} width={140} height={13} style={{ marginBottom: '18px' }}>
                        <p className={cx(styles.dashboardHeaderStatus, { 'dashboardHeaderStatusWithLaunchButton': isShowLaunchButton })}>
                            {statusMessage}
                            <span style={{ color: color }}>{statusName}</span>
                        </p>
                    </SkeletonWrapper>
                }

                {!isShowStatus &&
                    <p className={cx(styles.dashboardHeaderStatus, { 'dashboardHeaderStatusWithLaunchButton': isShowLaunchButton })}>
                        {statusName}
                    </p>
                }
            </div>
        </>
    )
};

DashboardHeaderStatus.propTypes = {
    isShowLaunchButton: PropTypes.bool,
    launchButtonSettings: PropTypes.object,
    isShowStatus: PropTypes.bool,
    statusName: PropTypes.string.isRequired,
    statusMessage: PropTypes.string.isRequired,
    color: PropTypes.string
};
DashboardHeaderStatus.defaultProps = {
    isShowLaunchButton: false,
    launchButtonSettings: null,
    isShowStatus: true,
    color: '#000000'
};
