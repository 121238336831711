import { find } from 'lodash';
import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const shareCountRules = {
    value: 'required|integer|min:1|max:100'
};

const countTypeConcatedRules = {
    countClients: 'required|integer|min:1',
    countTimeIntervalNumber: 'required|integer|min:1'
};

const shareValidation = shareCount => {
    return () => {
        const validation = Validation.check(shareCount, shareCountRules, {
            'required.value': 'InputValidationRequiredMessage',
            'required.integer': 'Номер',
            'min.value': 'PositiveNumberInputValidationMessage',
            'max.value': 'Max',
        }, 'value');

        return {
            isShowWarningActionMessage: validation.fails,
            errors: {
                ...ObjectUse.createObject(validation.firstError, 'shareCount'),
            }
        }
    }
};

const countValidation = countTypeConcated => {
    return () => {
        const validation = Validation.checkSome(countTypeConcated, countTypeConcatedRules, {
            'required.countClients': 'InputValidationRequiredMessage',
            'required.countTimeIntervalNumber': 'InputValidationRequiredMessage',
            'min.countClients': 'PositiveNumberInputValidationMessage',
            'min.countTimeIntervalNumber': 'PositiveNumberInputValidationMessage',
        }, ['countClients', 'countTimeIntervalNumber']);

        return {
            isShowWarningActionMessage: validation.fails,
            errors: {
                ...ObjectUse.createSeveralObjects([
                    validation.firstErrors.countClients,
                    validation.firstErrors.countTimeIntervalNumber
                ], ['countClients', 'countTimeIntervalNumber'])
            }
        }
    }
};
export const funnelControl = action => {
    const {
        selectedType,
        countClients,
        countTimeIntervalNumber,
        shareCount
    } = action.settings;

    const countTypeConcated = {
        countClients: countClients.value,
        countTimeIntervalNumber: countTimeIntervalNumber.value,
    }

    const controlTypes = {
        share: shareValidation(shareCount),
        count: countValidation(countTypeConcated)
    };

    const getSelectedType = find(selectedType, { active: true });

    return {
        ...action,
        ...controlTypes[getSelectedType.id]()
    }
};
