import React, { useState } from 'react';
import styles from 'pages/Auth/Auth.module.css';
import animations from 'animations/FadeIn.module.css';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { AuthSubmitButton } from './AuthSubmitButton';
import { AuthThankYouPage } from './AuthThankYouPage';
import { useForm } from '../../hooks/useForm';
import validateRegistration from '../../validations/RegisterFormValidationRules';
import { useTranslation } from 'react-i18next';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { Textarea } from 'common/Textarea/Textarea';

const cx = classNames.bind(styles);

export const SignUp = () => {
    const { t: translator } = useTranslation();

    const registration = () => {
        setShowThxPage(true);
        console.log('No errors, submit callback called!');
    };

    const [showThxPage, setShowThxPage] = useState(false);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(registration, validateRegistration);

    return (
        showThxPage ? <AuthThankYouPage isShow={showThxPage}/>
        :
            <div className={cx(styles.signInContainer, animations.fadeIn)}>
                <div className={styles.logo}/>
                <p className={styles.authDescription}>{translator('auth:RegistrationAnnotationMessage')}</p>
                <form onSubmit={handleSubmit} noValidate>
                    <Label htmlFor="email" text={translator('auth:EmailAddress')}/>
                    <InputField
                        type="email"
                        id='email'
                        name="email"
                        value={values.email || ''}
                        onChange={handleChange}
                        error={!!errors.email}
                        required={true}
                    />
                    <ErrorMessageField
                        active={!!errors.email}
                        message={errors.email ? errors.email : "Поле обязательно для ввода"}
                    />
                    <Label htmlFor="description" text={translator('auth:UserRegistrationMessage')}/>
                    <Textarea
                        id='description'
                        name="message"
                        value={values.message || ''}
                        onChange={handleChange}
                        error={!!errors.message}
                        required={true}
                    />
                    <ErrorMessageField
                        active={!!errors.message}
                        message={errors.message ? errors.message : "Введите краткое описание компании"}
                    />
                    <NavLink to="/">{translator('auth:LoginLink')}</NavLink>
                    <AuthSubmitButton/>
                </form>
            </div>
    )
};
