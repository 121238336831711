import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { StringUse } from 'lib/StringUse';

export const tailoredWaitCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            dateOffset,
            timeOffset,
            waitToDate,
            waitToTime
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    return {
        action_id: id,
        action_type: 'individual_wait',
        action_params: actionParams,
        coordinates: position,
        action_options: {
            date_offset: StringUse.recast(dateOffset.value, { accepted: 'number', rejected: null }),
            time_offset: StringUse.recast(timeOffset.value, { accepted: 'number', rejected: null }),
            wait_to_date: StringUse.recast(waitToDate.value, { accepted: 'string', rejected: null }),
            wait_to_time: StringUse.recast(waitToTime.value, { accepted: 'string', rejected: null })
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ]
    }
}
