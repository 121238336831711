import React from 'react';

export const SendEmailIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
        <rect x="47.5" y="0.5" width="47" height="47" rx="4.5" transform="rotate(90 47.5 0.5)" fill={bgColor} stroke={bgColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36 15.6659H12C11.4477 15.6659 11 16.1136 11 16.6659V31.3326C11 31.8849 11.4477 32.3326 12 32.3326H36C36.5523 32.3326 37 31.8849 37 31.3326V16.6659C37 16.1136 36.5523 15.6659 36 15.6659ZM12 14.6659C10.8954 14.6659 10 15.5614 10 16.6659V31.3326C10 32.4372 10.8954 33.3326 12 33.3326H36C37.1046 33.3326 38 32.4372 38 31.3326V16.6659C38 15.5614 37.1046 14.6659 36 14.6659H12Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.7827 15.5124C10.9594 15.3003 11.2747 15.2716 11.4869 15.4484C13.2309 16.9017 15.9628 19.0813 18.4877 20.8952C19.751 21.8028 20.954 22.6126 21.9512 23.1934C22.4503 23.4841 22.8887 23.712 23.2514 23.8658C23.6287 24.0258 23.8721 24.0825 24.0001 24.0825C24.1375 24.0825 24.3841 24.029 24.7551 23.8851C25.1136 23.746 25.5455 23.5397 26.0368 23.276C27.0186 22.7491 28.2031 22.0116 29.4538 21.177C31.9536 19.5089 34.6844 17.4754 36.5236 16.0234C36.7403 15.8523 37.0547 15.8893 37.2259 16.106C37.397 16.3227 37.36 16.6372 37.1432 16.8083C35.288 18.2729 32.5354 20.3228 30.0089 22.0088C28.7464 22.8513 27.5319 23.6086 26.5097 24.1572C25.999 24.4313 25.5276 24.658 25.1168 24.8174C24.7183 24.972 24.3294 25.0825 24.0001 25.0825C23.6614 25.0825 23.2631 24.9569 22.8611 24.7865C22.4446 24.6099 21.966 24.3593 21.4479 24.0575C20.4107 23.4534 19.1783 22.6226 17.9042 21.7073C15.3541 19.8754 12.6027 17.6799 10.8467 16.2166C10.6345 16.0398 10.6059 15.7246 10.7827 15.5124ZM17.8924 23.606C18.1095 23.7766 18.1472 24.0909 17.9766 24.3081L11.5599 32.4747C11.3893 32.6919 11.075 32.7296 10.8579 32.559C10.6407 32.3884 10.603 32.0741 10.7736 31.8569L17.1903 23.6902C17.3609 23.4731 17.6752 23.4354 17.8924 23.606ZM30.1078 23.606C30.325 23.4354 30.6393 23.4731 30.8099 23.6902L37.2266 31.8569C37.3972 32.0741 37.3595 32.3884 37.1423 32.559C36.9252 32.7296 36.6109 32.6919 36.4403 32.4747L30.0236 24.3081C29.853 24.0909 29.8907 23.7766 30.1078 23.606Z" fill={color}/>
    </svg>
);

