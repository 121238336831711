import React from 'react';
import styles from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions.module.css';
import PropTypes from 'prop-types';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { Select } from 'components/SelectOptions/Select';

export const JointlyInputSelectOptions = ({
    inputName,
    inputType,
    inputLabel,
    inputValue,
    inputRequired,
    inputOnChange,
    selectName,
    selectOptions,
    selectValue,
    selectRequired,
    selectOnChange,
    error
}) => (
    <>
        <div>
            <Label htmlFor={inputName} text={inputLabel}/>
            <div className={styles.jointlyInputSelectOptionsContainer}>
                <InputField
                    type={inputType}
                    id={inputName}
                    name={inputName}
                    value={inputValue}
                    required={inputRequired}
                    error={!!error}
                    onChange={inputOnChange}
                />
                <Select
                    name={selectName}
                    value={selectValue}
                    onChange={selectOnChange}
                    options={selectOptions}
                    className={styles.selectOptionsSmall}
                    required={selectRequired}
                />
            </div>
        </div>

        <ErrorMessageField active={!!error} message={error}/>
    </>
);
JointlyInputSelectOptions.propTypes = {
    inputName: PropTypes.string.isRequired,
    selectName: PropTypes.string.isRequired,
    selectOptions: PropTypes.array.isRequired,
    inputType: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    selectValue: PropTypes.string,
    inputValue: PropTypes.string,
    inputRequired: PropTypes.bool.isRequired,
    selectRequired: PropTypes.bool.isRequired,
    inputOnChange: PropTypes.func.isRequired,
    selectOnChange: PropTypes.func.isRequired,
    error: PropTypes.string
}
