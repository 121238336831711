import React, { useContext, useCallback } from 'react';
import { includes, isEmpty, omit, has } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { Label } from 'common/_Label/Label';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { AttributeFilterDropdownTags } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDropdownTags';
import { ComparisonOperatorSelectOptions } from 'components/SelectOptions/ComparisonOperatorSelectOptions';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { StringUse } from 'lib/StringUse';
import { useSelector } from 'react-redux';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

const parsedErrors = (errors, id) => {
    const isAttributeFilterHasErrors = !isEmpty(errors) && has(errors, 'attributeFilter');

    if (isAttributeFilterHasErrors) {
        const {
            attributeFilter: {
                eventAttributeName,
                comparisonOperator
            }
        } = errors;

        return {
            eventAttributeName: {
                hasError: includes(eventAttributeName.id, id),
                message: StringUse.falseyReturnEmptyString(eventAttributeName.error)
            },
            comparisonOperator: {
                hasError: includes(comparisonOperator.id, id),
                message: StringUse.falseyReturnEmptyString(comparisonOperator.error)
            }
        }
    }

    return {
        eventAttributeName: {
            hasError: false
        },
        comparisonOperator: {
            hasError: false
        }
    }
};
export const AttributeFilterMapped = ({ item, index }) => {
    const { t: translator } = useTranslation();

    const dropdownFilters = useSelector(store => store.segments.dropdownFilters);
    const { allEvents } = useSelector(store => store.integrations);

    const {
        onChangeDropdownShowStatus,
        onClickRemoveDropdownItem,
        onChangeDropdownInput,
        onChangeListedDropdown,
        errors,
        setAttributeFilter,
        values,
        getEventAttributeNames
    } = useContext(SideBarContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeListedDropdown(setAttributeFilter, item.id), []);

    const { eventAttributeName, comparisonOperator } = parsedErrors(omit(errors, ['actionName']), item.id);

    const mappedEventAttributeNames = has(dropdownFilters, 'product_attribute')
        ? getEventAttributeNames(values.actionName.value, allEvents.base, dropdownFilters['product_attribute'])
        : [];

    return (
        <>
            <li key={item.id}>
                <div className={styles.filterItemContainer}>
                    <AsideDropdownButtonItemButton
                        name={item?.eventAttributeName?.value || translator(`actionSidebars:${item?.filterName}`)  + ' ' + (index + 1)}
                        onClick={onChangeDropdownShowStatus(item.id, setAttributeFilter)}
                        active={item.show}
                    />
                    <DeleteButton onClick={onClickRemoveDropdownItem(item.id, setAttributeFilter)}/>
                </div>

                {item.show && <div className={styles.filterContainer}>
                    <CustomSelectDropdown
                        dropdownName='eventAttributeName'
                        dropdownLabel={translator(`actionSidebars:${item.eventAttributeName.name}`)}
                        defaultDropdownValue={item.eventAttributeName.value}
                        dropdownOptions={mappedEventAttributeNames}
                        onChangeDropdown={onChangeDropdownCached}
                        isErrorExist={eventAttributeName.hasError}
                        isRequired={item.eventAttributeName.required}
                        isDisabled={!mappedEventAttributeNames.length}
                    />

                    <Label htmlFor="comparisonValue" text={translator(`actionSidebars:${item.comparisonValue.name}`)}/>
                    <AttributeFilterDropdownTags
                        id={item.id}
                        inputTagValues={item.comparisonValue.value}
                        inputTagName='comparisonValue'
                        setState={setAttributeFilter}
                    />

                    <ComparisonOperatorSelectOptions
                        translator={translator}
                        item={item}
                        onChangeHandler={onChangeDropdownInput(item.id, setAttributeFilter)}
                        comparisonOperatorType='attribute'
                        error={comparisonOperator.hasError}
                    />
                    <ErrorMessageField active={comparisonOperator.hasError} message={comparisonOperator.message}/>

                    <AsideDropdownButtonSeparateLine
                        onClick={onChangeDropdownShowStatus(item.id, setAttributeFilter)}
                    />
                </div>}
            </li>
        </>
    )
};
AttributeFilterMapped.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}
