import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { eq, isEmpty } from 'lodash';
import classNames from 'classnames/bind';
import { getStoreDataEditSegment } from 'store/selectors/scenariosActions.selectors';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import VioletButton from 'common/_Buttons/_VioletButton/VioletButton';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const EditSegmentSimplified = ( { selectedSegmentId, setSelectedSegmentId }) => {
    const { errors } = useSelector(getStoreDataEditSegment);

    const [actionHasValidationErrors, setActionHasValidationErrors] = useState(!isEmpty(errors));

    const { t: translator } = useTranslation();

    const segmentsManualList = useSelector(store => store.segments.manualSegmentsList);

    const handleSelectedSegmentId = id => {
        return () => {
            setSelectedSegmentId(id);
            setActionHasValidationErrors(false)
        }
    };

    return (
        <div className={styles.simplifiedButtonsContainer}>
            <p className={styles.sidebarSectionName}>{translator('comparisonSegments:Segments')}</p>


            {map(segmentsManualList, ({ segment_id, segment_name }) =>
                <VioletButton
                    key={segment_id}
                    name={segment_name}
                    onClick={handleSelectedSegmentId(segment_id)}
                    className={cx({ 'notSelectedSimplifiedActionSidebarButton': !eq(segment_id, selectedSegmentId), 'simplifiedButtonNotSelected': actionHasValidationErrors })}
                />
            )}

            <ErrorMessageField active={actionHasValidationErrors} message='ChooseAtLeastOneOption'/>
        </div>
    );
};

EditSegmentSimplified.propTypes = {
    selectedSegmentId: PropTypes.string.isRequired,
    setSelectedSegmentId: PropTypes.func.isRequired
};
