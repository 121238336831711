import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from 'common/SidebarGeneral/SidebarGeneral.module.css'

const cx = classNames.bind(styles);

export const SidebarGeneralTitle = ({ title, className }) => <h2 className={cx(styles.sideBarTitle, className)}>{title}</h2>

SidebarGeneralTitle.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string
}
SidebarGeneralTitle.defaultProps = {
    title: 'Default title',
    className: ''
}
