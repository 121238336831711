import {
    SET_UPLOADED_IMAGES,
    SET_IMAGES_FOLDERS_LIST,
    SET_CREATE_IMAGES_FOLDER,
    CLEAR_SELECTED_IMAGE_DATA,
    CLEAR_IMAGES_LIST,
    SET_CURRENT_IMAGES_FOLDER,
    SET_CURRENT_IMAGES_FOLDER_FROM_LIST,
    CLEAR_CURRENT_IMAGES_FOLDER,
    SET_SELECTED_IMAGES_FROM_PC,
    REMOVE_SELECTED_IMAGE_FROM_PC,
    CLEAR_SELECTED_IMAGES_FROM_PC,
    SET_DELETE_IMAGE_FOLDER,
    SET_DELETE_IMAGE,
    SET_UPDATE_FOLDER_NAME
} from 'store/types';
import { LOGOUT_USER } from 'store/types';

const initialState = {
    folders: [],
    currentFolder: {},
    selectedImagesFromPc: {
        list: [],
        previewUrls: []
    },
}

export default function images(store = initialState, { type, payload }) {
    switch (type) {
        case SET_UPLOADED_IMAGES:
            return {
                ...store, currentFolder: payload.currentFolder, folders: payload.folders
            }
        case SET_IMAGES_FOLDERS_LIST:
            return {
                ...store, folders: payload.data
            }
        case SET_CURRENT_IMAGES_FOLDER_FROM_LIST:
            return {
                ...store, folders: payload.folders, currentFolder: payload.currentFolder
            }
        case SET_CURRENT_IMAGES_FOLDER:
            return {
                ...store, currentFolder: payload.data
            }
        case CLEAR_CURRENT_IMAGES_FOLDER:
            return {
                ...store, currentFolder: {}
            }
        case SET_CREATE_IMAGES_FOLDER:
            return {
                ...store, folders: [ payload.data, ...store.folders ]
            }
        case SET_DELETE_IMAGE_FOLDER:
            return {
                ...store, folders: payload.folders
            }
        case SET_DELETE_IMAGE:
            return {
                ...store, currentFolder: payload.folder
            }
        case CLEAR_SELECTED_IMAGE_DATA:
            return {
                ...store, selectedImageData: payload.data
            }
        case CLEAR_IMAGES_LIST:
            return {
                ...store, list: []
            }
        case SET_SELECTED_IMAGES_FROM_PC:
            return {
                ...store, selectedImagesFromPc: payload
            }
        case REMOVE_SELECTED_IMAGE_FROM_PC:
            return {
                ...store, selectedImagesFromPc: payload
            }
        case SET_UPDATE_FOLDER_NAME: {
            return {
                ...store, folders: payload.folders, currentFolder: payload.folder
            }
        }
        case CLEAR_SELECTED_IMAGES_FROM_PC:
            return {
                ...store, selectedImagesFromPc: {
                    list: [],
                    previewUrls: []
                }
            }
        case LOGOUT_USER:
            return {
                ...store, list: payload.defaultImagesStore.list
            };
        default:
            return store;
    }
}
