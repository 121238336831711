import { chain, eq, find, findLast, forEach, reduce, replace } from 'lodash';
import { viberSmiley } from 'assets/img/viber/viberSmiley';
import regression from 'regression';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_URL_ALT = process.env.REACT_APP_SERVER_URL_ALT;

export class CommonMethods {

    static parseJinjaFields(jinja_fields, state) {
        const fieldsInput = {};

        forEach(jinja_fields, ({ value, jinja_field_name }) => {
            if (jinja_field_name in state) {
                fieldsInput[jinja_field_name] = {...state[jinja_field_name], value}
            }
        });

        return {
            ...state,
            ...fieldsInput,
        }
    }

    static lineBreakJinja(data) {
        return data? chain(data).split(/%}\n|%}/).join('%}\n').value() : ''
    }

    static getAbTestGroupName(str) {
        return str && str.slice(0, 4);
    }

    static preventSubmitForm(event) {
        return eq(event.key, 'Enter') && event.preventDefault();
    }

    static replaceUrlEndpoint(endpoint) {
        return SERVER_URL + endpoint;
    }

    static replaceUrlEndpointAlt(endpoint) {
        return SERVER_URL_ALT + endpoint;
    }

    static tokenSecuritySettings() {
        return window.location.protocol && window.location.protocol === 'https:' ?
            { expires: 365, secure: true, domain: '.neucurrent.com' } : { expires: 365, secure: false }
    }

    static renderPreviewViberTemplate(previewBody) {
        // get all smileys
        const arraySmileys = previewBody.match(/\(([\w!@#$%^&*-=+`~{}?,]+)\)/gi);

        return arraySmileys? reduce(arraySmileys, (acc, textSmiley) => {
            // trim '(' and ')'
            const key = textSmiley.slice(1, -1);
            if (key in viberSmiley) {
                //dynamic import img and get url
                const smileyUrl = require(`assets/img/viber/smiley/${viberSmiley[key]}`).default;
                //created img tag
                const smiley = `<img src=${smileyUrl} alt={smiley ${key}/>`;
                //replace (smiley) to img
                return replace(acc, textSmiley, smiley)
            }
            return acc;
        }, previewBody) : previewBody
    }

    static lineRegressionTrend(data) {
        const regressions = regression.linear(data, { precision: 3});

        const [firstDay, firstValue] = find(regressions.points, ([day, value]) => value > 0) || [0, 0];
        const [lastDay, lastValue] = findLast(regressions.points, ([day, value]) => value > 0) || [0, 0];

        //if checked 1 day been visible 0
        const differenceDay = lastDay - firstDay;

        if (!firstValue || !lastValue|| !differenceDay ) {
            return 0
        }

        const differenceValue = lastValue / firstValue;
        const dayChange = Math.pow(differenceValue, 1 / (lastDay - firstDay));

        return Math.round((dayChange*100 - 100) * 100) / 100
    }

    static async isImageExist(url) {
        return await new Promise(resolve => {
            const img = new Image();

            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    }

    static onKeyDownEnter(callback) {
        return event => {
            if (eq(event.key, 'Enter') || eq(event.key, 'NumpadEnter')) {
                event.preventDefault();

                return callback();
            }
        };
    }
}
