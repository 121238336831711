import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { preSaveSendSidebarData, saveSidebarData } from 'store/actions/scenariosActions.action';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { SelectedDateViewer } from 'components/DateSelectors/SelectedDateViewer';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { useSelectDate } from 'hooks/useSelectDate';
import { UrlsDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Urls/UrlsDropdown';
import { ConsentCategoriesSelectOptions } from 'components/SelectOptions/ConsentCategoriesSelectOptions';
import { TemplatePreviewWrapper } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewWrapper';
import { getStoreDataWebsiteBuilder } from 'store/selectors/scenariosActions.selectors';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';
import { JointlyInputSelectOptions } from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions';
import { map } from 'lodash';

export const WebsiteBuilderSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const context = useContext(SideBarContext);
    const { t: translator } = useTranslation();

    const consentCategories = useSelector(store => store.consentCategories.list);
    const { title, actionType, _values, _displayDate, _urls, _actionId, _templateUniqueId, _sideBarType, errors } = useSelector(getStoreDataWebsiteBuilder);
    const { dateCalendar: { date } } = useSelector(store => store.calendars);

    const {
        onChangeNumericInput,
        onChangeInput,
        onSubmitHandler,
        defaultCountTimeInterval
    } = context;

    const [values, setValues] = useState(_values);
    const [urls, setUrls] = useState(_urls);

    const displayDate = useSelectDate(date, _displayDate);

    const selectBannerFromLibrary = () => {
        dispatch(preSaveSendSidebarData({
            values,
            displayDate,
            urls,
            templateUniqueId: _templateUniqueId,
        }));

        navigate('templates', { state: { shallow: true }});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericCached = useCallback(onChangeNumericInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInput(setValues), []);

    const countTimeIntervalOptions = map(defaultCountTimeInterval, timeInterval => ({ ...timeInterval, name: translator(`actionSidebars:${timeInterval.name}`) }) );


    const onClickSaveActionSettings = () => dispatch(saveSidebarData({
        values,
        urls,
        displayDate,
        templateUniqueId:
        _templateUniqueId
     }));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <SideBarContext.Provider value={{
            ...context,
            errors,
            urls,
            setUrls
        }}>
            <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
                <>
                    <SidebarGeneralTitle title={title}/>

                    <Label htmlFor='communicationsMaxCount' text={translator('actionSidebars:emailMaxCount')}/>
                    <InputField
                        type='number'
                        id='communicationsMaxCount'
                        name='communicationsMaxCount'
                        value={values?.communicationsMaxCount?.value}
                        required={values?.communicationsMaxCount?.required}
                        error={!!errors?.communicationsMaxCount}
                        onChange={onChangeNumericCached}
                    />
                    <ErrorMessageField
                        active={!!errors?.communicationsMaxCount}
                        message={errors?.communicationsMaxCount}
                    />

                    <JointlyInputSelectOptions
                        inputName='countTimeIntervalNumber'
                        inputType='number'
                        inputLabel={translator('actionSidebars:countTimeIntervalNumber')}
                        inputValue={values?.countTimeIntervalNumber?.value}
                        inputRequired={values?.countTimeIntervalNumber?.required}
                        inputOnChange={onChangeNumericCached}
                        selectName='countTimeIntervalType'
                        selectOptions={countTimeIntervalOptions}
                        selectValue={values?.countTimeIntervalType?.value}
                        selectRequired={values?.countTimeIntervalType?.required}
                        selectOnChange={onChangeCached}
                        error={errors?.countTimeIntervalNumber}
                    />

                    <Label htmlFor="waitInterval" text={translator('actionSidebars:impressionsNumber')}/>
                    <InputField
                        type='number'
                        id='impressionsNumber'
                        name='impressionsNumber'
                        value={values?.impressionsNumber?.value}
                        required={values?.impressionsNumber?.required}
                        onChange={onChangeNumericCached}
                        error={!!errors.impressionsNumber}
                    />
                    <ErrorMessageField active={!!errors?.impressionsNumber} message={errors?.impressionsNumber}/>

                    <SelectedDateViewer date={displayDate?.value} errors={errors} label={translator('actionSidebars:DisplayDateLabel')}/>

                    <ConsentCategoriesSelectOptions
                        onChange={onChangeCached}
                        required={values?.consentCategory?.required}
                        currentValue={values?.consentCategory?.value}
                        optionsArray={consentCategories}
                        id='consentCategory'
                        name='consentCategory'
                        label='Consent category'
                        selectWithoutErrors
                    />

                    <UrlsDropdown/>

                    <TemplatePreviewWrapper
                        actionId={_actionId}
                        sideBarType={_sideBarType}
                        templateUniqueId={_templateUniqueId}
                        selectTemplateCallback={selectBannerFromLibrary}
                        errors={errors}
                    />

                    <ActionSidebarButtons/>
                </>
            </ActionSidebarFormWrapper>
        </SideBarContext.Provider>
    )
}
