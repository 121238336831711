import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';

export const TemplatePreviewWebsiteBuilder = ({ onClickCallback, templateName, imageSource }) => (
    <div onClick={onClickCallback} className={styles.templatePreview}>
        <div className={styles.websiteBuilderCardContainer}>
            <div className={styles.websiteBuilderCard} style={{ backgroundImage: `url( ${imageSource} )` }}/>
        </div>
        <p className={styles.templatePreviewTitle}>{templateName}</p>
    </div>
);
TemplatePreviewWebsiteBuilder.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    templateName: PropTypes.string.isRequired,
    imageSource: PropTypes.string.isRequired
}
TemplatePreviewWebsiteBuilder.defaultProps = {
    templateName: '',
    imageSource: ''
}
