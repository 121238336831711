import { createContext } from 'react';
import { eq, some } from 'lodash';
import {
    addInputCheckpoint,
    addOutputCheckpoint,
    changeOpenActionStatus,
    clearOutputCheckpoint,
    closeActionListWarning,
    copyActionProcess,
    removeActionProcess,
    toggleSideBar,
} from 'store/actions/scenariosActions.action';

const onRightClick = (id, dispatch, isShowWarningActionMessage) => event => {
    event.preventDefault();

    if (!isShowWarningActionMessage) dispatch(changeOpenActionStatus({ id: id }));
};

const outputCheckpointHandler = (output, id, arrowConfig, dispatch) => () => {

    if (output && eq(output?.id, id)) return dispatch(clearOutputCheckpoint())

    return dispatch(addOutputCheckpoint(arrowConfig))
};

const actionIconStatusConfig = (actionIconTypes, isOpenActionInfo, id, sidebarActionId, isShowWarningActionMessage) => {
    const { base, opened, warning } = actionIconTypes;

    if (isOpenActionInfo || eq(id, sidebarActionId)) return opened;

    if (isShowWarningActionMessage) return warning;

    return base;
};

const isAlternativeEditHandler = actionType => some([
    eq(actionType, 'CustomWebHook'),
    eq(actionType, 'SqlTrigger')
]);

const onDoubleClick = (dispatch, actionType, id, alternativeEditHandler) => () => {

    if (isAlternativeEditHandler(actionType)) return alternativeEditHandler();

    return dispatch(toggleSideBar({ open: true, type: actionType, id }))
};

const acceptInputConnection = (dispatch, connectionConfig) => () => dispatch(addInputCheckpoint(connectionConfig));

const changeActionStatus = (dispatch, id) => () => dispatch(changeOpenActionStatus({ id: id }));

const onClickSetEditAction = (dispatch, alternativeEditAction, actionType, id) => () => {

    if (isAlternativeEditHandler(actionType)) return alternativeEditAction();

    return dispatch(toggleSideBar({ open: true, type: actionType, id }));
};

const onClickCopyAction = (dispatch, id, newActionId) => () => dispatch(copyActionProcess({ id, newActionId }));

const onClickDeleteAction = (dispatch, id) => () => dispatch(removeActionProcess({ id }));

const onClickCloseActionWarningTooltip = (dispatch, id) => () => dispatch(closeActionListWarning(id));

export const ActionIconsContext = createContext({
    onRightClick,
    outputCheckpointHandler,
    actionIconStatusConfig,
    isAlternativeEditHandler,
    onDoubleClick,
    acceptInputConnection,
    changeActionStatus,
    onClickSetEditAction,
    onClickCopyAction,
    onClickDeleteAction,
    onClickCloseActionWarningTooltip
});
