import React, { useEffect, useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionCodeEditor/ActionCodeEditor.module.css';
import { useSelector } from 'react-redux';
import { ActionCodeEditorContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useParams, useNavigate } from 'react-router-dom';
import Editor from '@monaco-editor/react';

export const ActionCodeEditor = () => {
    const navigate = useNavigate();
    const { goal, id } = useParams();
    const { actionCode, setActionCode } = useContext(ActionCodeEditorContext);

    const { actionSidebar: sidebar } = useSelector(store => store.scenariosActions);

    const onChangeCodeEditor = code => setActionCode(code);

    useEffect(() => {
        if (!sidebar.open) {
            navigate(`/communications/goal/${goal}/${id}`, { state: { shallow: true }});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebar]);

    const MonacoEditorLanguageTypes = {
        CustomWebHook: 'html',
        SqlTrigger: 'sql',
    };

    return (
        <div className={styles.container}>
            <Editor
                height='100%'
                defaultLanguage={MonacoEditorLanguageTypes[sidebar.type]}
                defaultValue={actionCode}
                theme='vs-dark'
                onChange={onChangeCodeEditor}
            />
        </div>
    );
};
