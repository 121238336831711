import React, { useEffect, useState } from 'react';
import { split, head, filter, eq, map, toLower, forEach, compact } from 'lodash';
import { useSelector } from 'react-redux';
import { StringUse } from 'lib/StringUse';
import { ActionChainPotentialConnection } from 'components/ScenarioSetup/ActionConnections/ActionChainPotentialConnection';

export const ActionChainPotentialConnections = () => {
    const actionOutputCheckpoint = useSelector(store => store.scenariosActions.actionOutputCheckpoint);
    const actionsList = useSelector(store => store.scenariosActions.actionsList);
    const actionConnectionsList = useSelector(store => store.scenariosActions.actionConnectionsList);

    const [potentialConnections, setPotentialConnections] = useState([]);

    const calculatePotentialConnections = () => {
        const outputChekpointId = head(split(actionOutputCheckpoint.id, '-output'));

        // On this step we removed from actions list action which is the starting point
        // And the second filter we remover all trigger type actions, because they have only output connections
        const filteredActions = filter(filter(actionsList, action => !eq(action.id, outputChekpointId)), action => {
            return !StringUse.contains(toLower(action.actionType), ['trigger'])
        });


        const potentialActionInputIds = map(filteredActions, action => action.id + '-input');

        const compareConnectionsWithPotentialIds = map(potentialActionInputIds, potentialId => {
            let match = false;

            // On this step we check that existed connections doesn't equal with potential connection
            // If true, we don't have a reason to draw potential connection for existed actions connection
            forEach(actionConnectionsList, connection => {
                if (eq(connection.from.id, actionOutputCheckpoint.id) && eq(connection.to.id, potentialId)) { match = true }
            });

            return !match && potentialId
        })

        return setPotentialConnections(compact(compareConnectionsWithPotentialIds));
    }

    useEffect(() => {
        if (actionOutputCheckpoint) {
            calculatePotentialConnections();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionOutputCheckpoint, actionConnectionsList])

    return (
        <>
            {map(potentialConnections, (connection, key) =>
                <ActionChainPotentialConnection
                    key={connection + key}
                    from={actionOutputCheckpoint?.id}
                    to={connection}/>
            )}
        </>
    );
}
