import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/RadioButton/RadioButton.module.css';
import { Label } from 'common/_Label/Label';

export const RadioButton = ({ type, id, name, checked, onChange, showName, labelName }) =>
    <p className={styles.radioboxItemContainer}>
        <input
            type={type}
            id={id}
            name={name}
            readOnly
            checked={checked}
            onChange={onChange}
            className={styles.radioboxItem}
        />
        <Label htmlFor={id} text={showName && labelName}/>
    </p>

RadioButton.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    showName: PropTypes.bool,
    labelName: PropTypes.string
}
RadioButton.defaultProps = {
    type: 'checkbox',
    checked: false,
    showName: true,
    labelName: ''
};
