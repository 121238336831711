import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';

export const TemplatePreviewSms = ({ onClickCallback, templateName, message }) => (
    <div onClick={onClickCallback} className={styles.templatePreview}>
        <div className={styles.previewActionSmsTemplate}>
            <div className={styles.previewActionSmsTemplateMsg}>
                <p>{message}</p>
            </div>
        </div>
        <p className={styles.templatePreviewTitle}>{templateName}</p>
    </div>
);

TemplatePreviewSms.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    templateName: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
}
TemplatePreviewSms.defaultProps = {
    templateName: '',
    message: ''
}
