import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createTemplate } from 'store/actions/templates.action';
import { findScenariosAction } from 'store/selectors/scenariosActions.selectors';
import { NumberUse } from 'lib/NumberUse';
import { AddNewRoundButton } from 'common/_Buttons/_AddNewRoundButton/AddNewRoundButton';
import { useTranslation } from 'react-i18next';

const utmJinjaFields = [
    { jinja_field_name: 'jinja_utm_content', value: '' },
    { jinja_field_name: 'jinja_utm_source', value: '' },
    { jinja_field_name: 'jinja_utm_medium', value: '' },
    { jinja_field_name: 'jinja_utm_term', value: '' },
    { jinja_field_name: 'jinja_utm_campaign', value: '' },
];

const configRequiredJinjaFields = {
    viber: [
        { jinja_field_name: 'jinja_body', value: '' },
        { jinja_field_name: 'jinja_button_url', value: '' },
        { jinja_field_name: 'jinja_button_text', value: '' },
        { jinja_field_name: 'jinja_msg_topic', value: '' },
        { jinja_field_name: 'jinja_sender_name', value: '' },
        { jinja_field_name: 'jinja_image_url', value: '' },
        ...utmJinjaFields
    ],
    sms: [
        { jinja_field_name: 'jinja_sender_name', value: '' },
        { jinja_field_name: 'jinja_body', value: '' },
        { jinja_field_name: 'jinja_sender_phone', value: '' },
        ...utmJinjaFields
    ],
    website: [
        { jinja_field_name: 'jinja_body', value: '' },
        { jinja_field_name: 'jinja_params', value: '' },
        ...utmJinjaFields
    ],
    mobpushnotify: [
        { jinja_field_name: 'jinja_ttl', value: '' },
        { jinja_field_name: 'jinja_action_name', value: 'ACTION' },
        { jinja_field_name: 'jinja_image', value: '' },
        { jinja_field_name: 'jinja_msg_title', value: '' },
        { jinja_field_name: 'jinja_body', value: '' },
        { jinja_field_name: 'jinja_deep_link_url', value: '' },
    ],
    webpushnotify: [
        { jinja_field_name: 'jinja_body', value: '' },
        { jinja_field_name: 'jinja_msg_title', value: '' },
        { jinja_field_name: 'jinja_ttl', value: '' },
        { jinja_field_name: 'jinja_image', value: '' },
        { jinja_field_name: 'jinja_action_name', value: 'ACTION' },
        { jinja_field_name: 'jinja_push_logo', value: '' },
        { jinja_field_name: 'jinja_redirect_link', value: '' },
        { jinja_field_name: 'jinja_button_1_text', value: '' },
        { jinja_field_name: 'jinja_button_1_url', value: '' },
        { jinja_field_name: 'jinja_button_2_text', value: '' },
        { jinja_field_name: 'jinja_button_2_url', value: '' },
        ...utmJinjaFields,
    ]
};

export const CreateTemplateButton = ({ typeMessage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t: translator } = useTranslation();
    const { pathname } = useLocation();
    const { templateType } = useParams();

    const { id: actionId } = useSelector(store => store.scenariosActions.actionSidebar);
    const action = useSelector(store => findScenariosAction(store, actionId));

    const actionsTypesMessage = {
        SendSms: 'SMS',
        SendViber: 'Viber',
        SendMobPush: 'MobPushNotify',
        SendWebPush: 'WebPushNotify',
        WebsiteBuilder: 'website'
    };

    const createDefaultTemplateName = () => {
        if (actionId) {
            return `NewTemplate_${toLower(actionsTypesMessage[action?.actionType])}_` + NumberUse.getRandomInt(1000);
        }
        return `NewTemplate_${templateType}_` + NumberUse.getRandomInt(1000);
    };

    const createDefaultTemplate = () => {
        if (actionId) {
            return {
                new_template: {
                    template_name: createDefaultTemplateName(),
                    message_type_name: typeMessage,
                    jinja_fields: configRequiredJinjaFields[toLower(actionsTypesMessage[action?.actionType])],
                }
            }
        }

        return {
            new_template: {
                template_name: createDefaultTemplateName(),
                message_type_name: typeMessage,
                jinja_fields: configRequiredJinjaFields[templateType],
            }
        }
    };

    const redirectCallback = data => {
        const {
            template: {
                template_unique_id
            }
        } = data;

        if (actionId) {
            return navigate(`${pathname}/edit-template`);
        }

        return navigate(`${pathname}/${template_unique_id}`);
    };

    const createNewTemplate = () => dispatch(createTemplate(createDefaultTemplate(), redirectCallback));

    return <AddNewRoundButton
        actionLabel={translator('default:AddButtonLabel')}
        onClick={createNewTemplate}
        isFixedDisplayPosition
    />

};
CreateTemplateButton.propTypes = {
    typeMessage: PropTypes.string.isRequired
};
CreateTemplateButton.defaultProps = {
    typeMessage: ''
};
