import { apiAllRequestAction, apiRequestAction } from 'store/actions/api.action';
import { CommonMethods } from 'lib/CommonMethods';
import { map, filter, eq, findIndex } from 'lodash';
import { DateUse } from 'lib/DateUse';
import {
    FETCH_FREQUENCY_POLICIES_LIST,
    SET_FREQUENCY_POLICIES_LIST,
    TOGGLE_SIDEBAR_POLICIES,
    ADD_NEW_FREQUENCY_POLICY,
    FETCH_UPDATING_FREQUENCY_POLICIES
} from 'store/types';

export const toggleSidebarPolicies = ({ isOpen = false, type = '', scenarios = [] } = {}) => ({
    type: TOGGLE_SIDEBAR_POLICIES,
    payload: {
        isOpen,
        scenarios,
        type
    }
});

export const getFrequencyPoliciesList = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('frequency_policies'),
        method: 'GET',
        onSuccess: setPoliciesList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_FREQUENCY_POLICIES_LIST
    })
);
const setPoliciesList = data => ({
    type: SET_FREQUENCY_POLICIES_LIST,
    payload: DateUse.sortByDataTime(data, 'updated_at')
});

export const createFrequencyPolicy = (data, closeSidebar) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('frequency_policies'),
        method: 'POST',
        data,
        onSuccess: setNewPolicy,
        onAfterSuccess: closeSidebar,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_FREQUENCY_POLICIES_LIST
    })
);
const setNewPolicy = data => ({
    type: ADD_NEW_FREQUENCY_POLICY,
    payload: data
});

export const deleteFrequencyPolicy = id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`frequency_policies/${id}`),
        method: 'DELETE',
        onSuccess: setDeletedPolicy,
        extraOptions: id,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_FREQUENCY_POLICIES_LIST
    })
);
const setDeletedPolicy = id => (dispatch, getStore) => {
    const { list } = getStore().frequencyPolicies;

    const updatedList = filter(list, item => !eq(item.policy_id, id) );

    dispatch({
        type: SET_FREQUENCY_POLICIES_LIST,
        payload: updatedList
    })
};

export const updateFrequencyPolicy = (arrayData, onAfterSuccessCallback) => (
    apiAllRequestAction({
        onSuccess: setUpdatedPolicy,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_UPDATING_FREQUENCY_POLICIES,
        requestsConfig: map(arrayData, (data) => ({
            url: CommonMethods.replaceUrlEndpoint(`frequency_policies/${data.policy_id}`),
            data,
            method: 'PUT',
        })),
        onAfterSuccess: onAfterSuccessCallback
    })
);

const setUpdatedPolicy = data => (dispatch, getStore) => {
    const { list } = getStore().frequencyPolicies;

    const updatedList = map(list, policy => {
        const includesUpdatedIndex = findIndex(data, { policy_id: policy.policy_id });

        if (includesUpdatedIndex >= 0) {
            return data[includesUpdatedIndex]
        }

        return policy
    });

    dispatch({
        type: SET_FREQUENCY_POLICIES_LIST,
        payload: updatedList
    })
};
