import React from 'react';

export const WebsiteBuilderIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
        <rect x="47.5" y="0.5" width="47" height="47" rx="4.5" transform="rotate(90 47.5 0.5)" fill={bgColor} stroke={bgColor}/>
        <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.0753 12.0058C26.3483 12.0473 26.536 12.3023 26.4944 12.5753L22.9944 35.5753C22.9529 35.8483 22.6979 36.0359 22.4249 35.9944C22.1519 35.9528 21.9643 35.6978 22.0058 35.4248L25.5058 12.4248C25.5473 12.1518 25.8023 11.9642 26.0753 12.0058Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.8536 15.6464C19.0488 15.8417 19.0488 16.1583 18.8536 16.3536L11.2071 24L18.8536 31.6464C19.0488 31.8417 19.0488 32.1583 18.8536 32.3536C18.6583 32.5488 18.3417 32.5488 18.1464 32.3536L10.1464 24.3536C9.95118 24.1583 9.95118 23.8417 10.1464 23.6464L18.1464 15.6464C18.3417 15.4512 18.6583 15.4512 18.8536 15.6464Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M29.1464 32.3536C28.9512 32.1583 28.9512 31.8417 29.1464 31.6464L36.7929 24L29.1464 16.3536C28.9512 16.1583 28.9512 15.8417 29.1464 15.6464C29.3417 15.4512 29.6583 15.4512 29.8536 15.6464L37.8536 23.6464C37.9473 23.7402 38 23.8674 38 24C38 24.1326 37.9473 24.2598 37.8536 24.3536L29.8536 32.3536C29.6583 32.5488 29.3417 32.5488 29.1464 32.3536Z" fill={color}/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="28" height="28" fill="white" transform="translate(10 10)"/>
            </clipPath>
        </defs>
    </svg>
);
