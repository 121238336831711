import React from 'react';
import { ConditionSimplified } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/simplified/ConditionSimplified';

export const ScheduleTriggerSimplified = () => {

    return (
        <>
            <ConditionSimplified/>
        </>
    )
}
