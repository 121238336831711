import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransparentButton } from 'common/Buttons/TransparentButton/TransparentButton';
import styles from './Auth.module.css';

export const AuthSubmitButton = () => {
    const { t: translator } = useTranslation();
    return <TransparentButton
                type='submit'
                name={translator('auth:AcceptButton')}
                width={360}
                height={36}
                extensionClass={styles.authSubmitButton}
            />
};
