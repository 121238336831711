import { defaultPushContentSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultPushContentSettings';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

const {
    values: { countTimeIntervalNumber, countTimeIntervalType, communicationsMaxCount, templateCache },
    minDispatchTime,
    maxDispatchTime,
    templateUniqueId,
    policyId
} = defaultPushContentSettings();

export const pushContentDecompiler = actionOptions => {
    const {
        time_to_send: { to_time, from_time } = { to_time: maxDispatchTime, from_time: minDispatchTime },
        max_frequency,
        template_unique_id = templateUniqueId,
        cache_template = templateCache,
        policy_id = policyId,
    } = actionOptions;

    const localFromTime = DateUse.convertUTCToLocal(from_time, 'HH:mm', 'HH:mm');
    const localToTime = DateUse.convertUTCToLocal(to_time, 'HH:mm', 'HH:mm');

    return {
        values: {
            communicationsMaxCount: {
                ...communicationsMaxCount,
                value: StringUse.falseyReturnEmptyString(max_frequency?.n_communications, 'string'),
            },
            countTimeIntervalNumber: {
                ...countTimeIntervalNumber,
                value: StringUse.falseyReturnEmptyString(max_frequency?.period_number, 'string'),
            },
            countTimeIntervalType: {
                ...countTimeIntervalType,
                value: max_frequency?.period_type ? max_frequency?.period_type : 'minutes',
            },
            templateCache: cache_template,
            policyId: {
                value: policy_id
            }
        },
        minDispatchTime: StringUse.falseyReturnEmptyString(localFromTime),
        maxDispatchTime: StringUse.falseyReturnEmptyString(localToTime),
        templateUniqueId: template_unique_id,
    }
};
