import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const waitIntervalRules = {
    value: 'required|integer|min:1'
}

export const waitControl = action => {
    const {
        waitInterval
    } = action.settings;

    const validation = Validation.check(waitInterval, waitIntervalRules, {
        'required.value': 'InputValidationRequiredMessage',
        'min.value': 'PositiveNumberInputValidationMessage',
    }, 'value');

    return {
        ...action,
        isShowWarningActionMessage: validation.fails,
        errors: {
            ...ObjectUse.createObject(validation.firstError, 'waitInterval'),
        }
    }
}
