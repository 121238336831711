import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { toString, toNumber, map, compact } from 'lodash';
import { conditionsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/conditionsCompiler';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

export const repeatTriggerCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            dayOfWeek,
            time,
            condition,
            segmentId,
            values: {
                maxAudienceSize
            }
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const dayOfTheWeekCompiled = compact(map(dayOfWeek, (item, index) => {
        const { isChecked } = item;
        // needed to transform all indexes to string because lodash compact remove all false values of array (includes 0 index)
        if (isChecked) return toString(index);
        return null;
    }));

    const conditionsCompiled = conditionsCompiler(condition);

    const utcTime = DateUse.convertLocalToUTC(time, 'HH:mm', 'HH:mm');

    return {
        trigger_id: id,
        trigger_type: 'repeated',
        trigger_params: actionParams,
        coordinates: position,
        trigger_options: {
            schedule: {
                // Now needed to convert all string indexes to number again
                days_of_the_week: map(dayOfTheWeekCompiled, item => toNumber(item)),
                time: utcTime
            },
            max_audience_size: StringUse.recast(maxAudienceSize.value, { accepted: 'number', rejected: null }),
            customer_segment: [
                ...conditionsCompiled
            ],
            segment_id: segmentId
        },
        next_actions_ids: [
            ...nextActionIdsCompiled
        ]
    }
}
