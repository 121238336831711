import React from 'react';
import { ProductFilterDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ProductFilter/ProductFilterDropdown';

export const ProductSection = () => {
    return (
        <ProductFilterDropdown required/>
    )
};


