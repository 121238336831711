import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { map } from 'lodash';
import { preSaveSendSidebarData, saveSidebarData } from 'store/actions/scenariosActions.action';
import { TemplatePreviewWrapper } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewWrapper';
import { useNavigate } from 'react-router-dom';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { JointlyInputSelectOptions } from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper'
import { getStoreDataPushAndSendContent } from 'store/selectors/scenariosActions.selectors'
import { SeparateFieldsContentSideBar } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/SeparateFieldsContentSideBar'
import { Checkbox } from 'common/_Checkbox/Checkbox';
import { InputTimeField } from 'common/InputTimeField/InputTimeField';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

export const SendAndPushContentSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t: translator } = useTranslation();
    const { actionType, _values, _minDispatchTime, _maxDispatchTime, _sideBarTitle, _sideBarType, _templateUniqueId, errors } = useSelector(getStoreDataPushAndSendContent);

    const {
        onSubmitHandler,
        onChangeInput,
        onChangeNumericInput,
        onChangeTime,
        onChangeCheckbox,
        defaultCountTimeInterval,
    } = useContext(SideBarContext);

    const [values, setValues] = useState(_values);
    const [minDispatchTime, setMinDispatchTime] = useState(_minDispatchTime);
    const [maxDispatchTime, setMaxDispatchTime] = useState(_maxDispatchTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeInputCached = useCallback(onChangeInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericInputCached = useCallback(onChangeNumericInput(setValues), []);

    const onChangeTimeInput = useCallback(setState => onChangeTime(setState), [onChangeTime]);

    const countTimeIntervalOptions = map(defaultCountTimeInterval, timeInterval => ({ ...timeInterval, name: translator(`actionSidebars:${timeInterval.name}`) }) );

    const saveData = {
        values,
        minDispatchTime,
        maxDispatchTime,
        templateUniqueId: _templateUniqueId,
    };

    const preSaveActionSettings = () => dispatch(preSaveSendSidebarData(saveData));

    const onClickSaveActionSettings = () => dispatch(saveSidebarData(saveData));

    const selectTemplateFromLibrary = () => {
        preSaveActionSettings();

        navigate('templates', { state: { shallow: true }});
    };

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);
    console.log('countTimeIntervalOptions', countTimeIntervalOptions);
    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={_sideBarTitle}/>

                <SeparateFieldsContentSideBar
                    values={values}
                    onChange={onChangeInputCached}
                    onChangeNumeric={onChangeNumericInputCached}
                    setValues={setValues}
                    type={_sideBarType}
                    errors={errors}
                />

                <JointlyInputSelectOptions
                    inputName='countTimeIntervalNumber'
                    inputType='number'
                    inputLabel={translator('actionSidebars:countTimeIntervalNumber')}
                    inputValue={values?.countTimeIntervalNumber?.value}
                    inputRequired={values?.countTimeIntervalNumber?.required}
                    inputOnChange={onChangeNumericInputCached}
                    selectName='countTimeIntervalType'
                    selectOptions={countTimeIntervalOptions}
                    selectValue={values?.countTimeIntervalType?.value}
                    selectRequired={values?.countTimeIntervalType?.required}
                    selectOnChange={onChangeInputCached}
                    error={errors?.countTimeIntervalNumber}
                />

                <InputTimeField
                    value={minDispatchTime}
                    onChange={onChangeTimeInput(setMinDispatchTime)}
                    label={translator('actionSidebars:TimeFieldLabel')}
                    title={translator('actionSidebars:MinDispatchTime')}
                />

                <InputTimeField
                    value={maxDispatchTime}
                    onChange={onChangeTimeInput(setMaxDispatchTime)}
                    label={translator('actionSidebars:TimeFieldLabel')}
                    title={translator('actionSidebars:MaxDispatchTime')}
                />

                <Checkbox
                    id='templateCache'
                    name='templateCache'
                    label={translator('actionSidebars:templateCache')}
                    checked={values?.templateCache}
                    onChange={onChangeCheckbox(setValues)}
                    isShowCheckboxLabelValue
                />

                <TemplatePreviewWrapper
                    sideBarType={_sideBarType}
                    templateUniqueId={_templateUniqueId}
                    selectTemplateCallback={selectTemplateFromLibrary}
                    errors={errors}
                />

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
};
