import React from 'react';
import styles from 'components/Cards/Cards.module.css';
import PropTypes from 'prop-types';
import { eq } from 'lodash';
import classNames from 'classnames/bind';
import { CodeIcon } from 'assets/icons/CodeIcon';
import { BranchIcon } from 'assets/icons/BranchIcon';

const cx = classNames.bind(styles);

export const CreativeCardWrapper = ({ children, isSelected, cardType, className, isMasterBasedTemplate }) => (
    <div className={cx(styles.creativeCard, className, { 'creativeCardChecked': isSelected, 'viberCard': eq(cardType, 'Viber' )})}>
        {eq(cardType, 'Email')
            ? <div className={styles.cardTypeLabel}>
                {isMasterBasedTemplate
                    ? <BranchIcon/>
                    : <CodeIcon/>
                }
            </div>
            : null
        }
        {children}
    </div>
);

CreativeCardWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    isSelected: PropTypes.bool.isRequired,
    cardType: PropTypes.string.isRequired,
    className: PropTypes.string,
    isMasterBasedTemplate: PropTypes.bool
};
CreativeCardWrapper.defaultProps = {
    className: '',
    isMasterBasedTemplate: false
};
