import { ADD_ARROW_CHAIN, ADD_ACTION_INPUT_CHECKPOINT } from 'store/types';
import { some, eq } from 'lodash';

export const combineCheckpoints = ({ getState, dispatch }) => next => action => {
    const store = getState();

    if (eq(action?.type, ADD_ACTION_INPUT_CHECKPOINT)) {
        const { id } = action.payload.data;
        const outputCheckpoint = store.scenariosActions.actionOutputCheckpoint;

        if (outputCheckpoint) {

            const data = {
                from: store.scenariosActions.actionOutputCheckpoint,
                to: action.payload.data,
            };

            if (!eq(id, outputCheckpoint?.disableInputButton) && !some(store.scenariosActions.actionConnectionsList, {...data})) {
                console.log('Middleware data:', data);

                return store.scenariosActions.actionOutputCheckpoint && dispatch({
                    type: ADD_ARROW_CHAIN,
                    payload: { data },
                });
            } else {
                console.log('Something wrong with arrow connection', data);
            }
        } else {
            console.log('Output checkpoint missing..');
        }
    }

    return next(action);
};
