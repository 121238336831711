import React from 'react';

export const PlusIcon = ({ color }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 0C9.55229 0 10 0.447715 10 1V17C10 17.5523 9.55229 18 9 18C8.44771 18 8 17.5523 8 17V1C8 0.447715 8.44771 0 9 0Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 9C-2.41411e-08 8.44771 0.447715 8 1 8L17 8C17.5523 8 18 8.44771 18 9C18 9.55228 17.5523 10 17 10L1 10C0.447715 10 2.41411e-08 9.55229 0 9Z" fill={color}/>
        </svg>
    )
};
