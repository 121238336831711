import { useContext, useEffect } from 'react';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';

export const useTurnOffSimplifiedConditionsButtons = props => {
    const {
        isActionInSegmentSimplifiedMod,
        segmentsButtons,
        setCondition,
        setSimplifiedSettingsButtons,
        setActionParams,
        setSimplifiedTimeInterval
    } = props;

    const {
        turnOffSimplifiedSettingsButtons,
        clearSidebarDifficultySettingsType,
        clearSimplifiedTimeInterval
    } = useContext(SideBarContext);

    useEffect(() => {
        // If segment selected we need to turn off all simplified conditios buttons in action
        if (isActionInSegmentSimplifiedMod) {
            turnOffSimplifiedSettingsButtons(setSimplifiedSettingsButtons);

            clearSidebarDifficultySettingsType(setActionParams);

            clearSimplifiedTimeInterval(setSimplifiedTimeInterval);

            setCondition([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentsButtons])
}
