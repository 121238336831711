import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ModalPortal = ({ children }) => {
    const [ container ] = useState(() => document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(container);
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.removeChild(container);
            document.body.removeAttribute('style');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return createPortal(children, container);
};

export default ModalPortal;