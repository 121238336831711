import React, { useCallback, useContext } from 'react';
import { eq, has, isEmpty, find, filter, isEqual, map, some } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { ComparisonOperatorSelectOptions } from 'components/SelectOptions/ComparisonOperatorSelectOptions';
import { useTranslation } from 'react-i18next';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { StringUse } from 'lib/StringUse';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';
import { useSelector } from 'react-redux';

// Method for disable aggregation column if needed
const isAggregationColumnDisabled = (aggregationFunctionValue, defaultAggregationColumnRequired) => {
    if (eq(aggregationFunctionValue, 'COUNT')) return false;

    return defaultAggregationColumnRequired;
};

const parsedErrors = (errors, conditionIndex) => {
    const isConditionHasErrors = !isEmpty(errors) && has(errors, 'conditionsErrors');

    if (isConditionHasErrors) {
        const { conditionsErrors } = errors;

        if (conditionsErrors[conditionIndex] && eq(conditionsErrors[conditionIndex].selectedOptionsType, 'event')) {
            const { validation: { aggregationFunction } } = conditionsErrors[conditionIndex];

            if (aggregationFunction.isSingleTypeValidation) {
                return {
                    aggregationColumn: {
                        hasError: false
                    },
                    aggregationComparisonOperator: {
                        hasError: aggregationFunction.fails,
                        message: StringUse.falseyReturnEmptyString(aggregationFunction.firstError)
                    }
                }
            }

            return {
                aggregationColumn: {
                    hasError: !!aggregationFunction.firstErrors?.aggregationColumn,
                    message: StringUse.falseyReturnEmptyString(aggregationFunction.firstErrors?.aggregationColumn)
                },
                aggregationComparisonOperator: {
                    hasError: !!aggregationFunction.firstErrors?.aggregationComparisonOperator,
                    message: StringUse.falseyReturnEmptyString(aggregationFunction.firstErrors?.aggregationComparisonOperator)
                }
            }
        }
    }

    return {
        aggregationColumn: {
            hasError: false
        },
        aggregationComparisonOperator: {
            hasError: false
        }
    }
};

const getAggregationColumns = (selectedEventValue, events) => {
    return map(filter(find(events, { event_name: selectedEventValue })?.event_schema?.fields, field => {
        const { type } = field;

        return isEqual(type, ['null', 'float'])
            || isEqual(type, 'float')
            || isEqual(type, ['null', 'int'])
            || isEqual(type, 'int');
    }), ({ name }) => name);
};
const checkEventSchemaFieldTypes = (selectedEventValue, events) => {
    if (events.length) {
        const event = find(events, { event_name: selectedEventValue });
        const fields = event?.event_schema?.fields || [];

        return some(fields, field => {
            const { type } = field;
            return (
                isEqual(type, ['null', 'float']) ||
                isEqual(type, 'float') ||
                isEqual(type, ['null', 'int']) ||
                isEqual(type, 'int')
            );
        });
    }

    return null
};

export const AggregationSection = ({ item, conditionIndex, setState }) => {
    const { t: translator } = useTranslation();

    const { allEvents } = useSelector(store => store.integrations);

    const {
        errors,
        onChangeInputMultiple,
        onChangeDropdownMultiple,
        onChangeNumericInputMultiple,
        aggregationFunctionExample,
    } = useContext(SideBarContext);

    const { aggregationColumn, aggregationComparisonOperator } = parsedErrors(errors, conditionIndex);

    const mappedAggregationColumns = getAggregationColumns(item._eventValues.actionName.value, allEvents.base);

    const isEventSchemaFieldsContainRequiredTypes = checkEventSchemaFieldTypes(item._eventValues.actionName.value, allEvents.base);

    const isNeedClearAggregationFunctionDropdown = eq(typeof isEventSchemaFieldsContainRequiredTypes, 'boolean') && !isEventSchemaFieldsContainRequiredTypes;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInputMultiple(setState, item.id), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeDropdownMultiple(setState, item.id), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericCached = useCallback(onChangeNumericInputMultiple(setState, item.id), []);

    return (
        <div className={styles.conditionAggregationContainer}>
            <CustomSelectDropdown
                dropdownName='aggregationFunction'
                dropdownLabel={translator(`actionSidebars:${item?._eventValues?.aggregationFunction?.name}`)}
                defaultDropdownValue={item?._eventValues?.aggregationFunction?.value}
                dropdownOptions={aggregationFunctionExample}
                onChangeDropdown={onChangeDropdownCached}
                isRequired={item._eventValues.aggregationFunction.required}
                isDisabled={isNeedClearAggregationFunctionDropdown}
                clearSelectedValueCondition={isNeedClearAggregationFunctionDropdown}
                controlledClearedSelectedValue='COUNT'
            />

            <CustomSelectDropdown
                dropdownName='aggregationColumn'
                dropdownLabel={translator(`actionSidebars:${item._eventValues.aggregationColumn.name}`)}
                defaultDropdownValue={item._eventValues.aggregationColumn.value}
                dropdownOptions={mappedAggregationColumns}
                onChangeDropdown={onChangeDropdownCached}
                isErrorExist={aggregationColumn.hasError}
                isRequired={isAggregationColumnDisabled(item?._eventValues?.aggregationFunction?.value, item._eventValues.aggregationColumn.required)}
                isDisabled={!isAggregationColumnDisabled(item?._eventValues?.aggregationFunction?.value, item._eventValues.aggregationColumn.required)}
                clearSelectedValueCondition={eq(item?._eventValues?.aggregationFunction?.value, 'COUNT')}
            />

            <ComparisonOperatorSelectOptions
                translator={translator}
                item={item}
                onChangeHandler={onChangeCached}
                comparisonOperatorType='aggregation'
                error={aggregationComparisonOperator.hasError}
            />
            <ErrorMessageField
                active={aggregationComparisonOperator.hasError}
                message={aggregationComparisonOperator.message}
            />

            <div>
                <Label htmlFor='aggregationComparisonValue' text={translator(`actionSidebars:${item._eventValues.aggregationComparisonValue.name}`)}/>
                <InputField
                    type='number'
                    id='aggregationComparisonValue'
                    name='aggregationComparisonValue'
                    value={item._eventValues.aggregationComparisonValue.value}
                    required={item._eventValues.aggregationComparisonValue.required}
                    onChange={onChangeNumericCached}
                    isInputWithoutErrorField
                />
            </div>
        </div>
    )
}
AggregationSection.propTypes = {
    item: PropTypes.object.isRequired,
    conditionIndex: PropTypes.number.isRequired,
    setState: PropTypes.func.isRequired
}
