
export const defaultHeaderSettings = {
    SendEmail: {
        receiverContactEmail: {
            value: '',
            required: false,
            placeholder: 'your@mail.com',
            type: 'email',
            label: 'ReceiverContactEmailLabel'
        }
    },
    SendSms: {
        receiverContactPhone: {
            value: '',
            required: false,
            placeholder: '380 99 000 00 00',
            type: 'number',
            label: 'ReceiverContactPhoneLabel'
        }
    },
    SendViber: {
        receiverContactPhone: {
            value: '',
            required: false,
            placeholder: '380 99 000 00 00',
            type: 'number',
            label: 'ReceiverContactPhoneLabel'
        }
    }
}

export const defaultSendContentSettings = actionType => ({
    values: {
        ...defaultHeaderSettings[actionType],
        communicationsMaxCount: {
            value: '1',
            required: true
        },
        countTimeIntervalNumber: {
            value: '1',
            required: true
        },
        countTimeIntervalType: {
            value: 'days',
            required: true
        },
        templateCache: false,
        policyId: {
            value: '50c8b84a-d1fc-468d-bf21-e4ddc5514131' // this is default id of communication policy, change if id changed on back-end
        }
    },
    minDispatchTime: '08:00',
    maxDispatchTime: '20:00',
    templateUniqueId: '',
});
