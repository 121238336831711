import React, { useContext } from 'react';
import { intervalTypeExtension } from 'components/ScenarioSetup/ActionSidebars/settings/defaultConditionalSplitSettings';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { useTranslation } from 'react-i18next';
import { ConditionDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/advanced/ConditionDropdown';
import { useCompareConditions } from 'hooks/ScenarioSetup/useCompareConditions';

export const ClientSection = () => {
    const { t: translator } = useTranslation();

    const context = useContext(SideBarContext);

    const {
        errors,
        condition,
        setCondition,
        onClickCreateNewCondition,
    } = context;

    useCompareConditions();

    return (
        <SideBarContext.Provider value={{
            ...context,
            condition,
            setCondition,
            errors
        }}>
            <>
                <AsideDropdownButton
                    name={translator('actionSidebars:NewConditionButton')}
                    onClick={onClickCreateNewCondition(setCondition, intervalTypeExtension)}
                    alternativeColor
                />
                <ConditionDropdown required/>
            </>
        </SideBarContext.Provider>
    )
}
