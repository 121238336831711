import { filter, find, findIndex, includes, eq, remove } from 'lodash';
import { ArrayUse } from 'lib/ArrayUse';
import {
    ADD_ACTION,
    ADD_ACTION_INPUT_CHECKPOINT,
    ADD_ACTION_OUTPUT_CHECKPOINT,
    CHANGE_ACTION_MODAL_STATUS,
    CHANGE_ACTION_POSITION,
    CLEAR_ACTION_OUTPUT_CHECKPOINT,
    CLOSE_ACTION_LIST_WARNING,
    REMOVE_ACTION_PROCESS,
    COPY_ACTION_PROCESS,
    REMOVE_ARROW_LINE,
    SAVE_SIDEBAR_DATA,
    SET_ACTIONS_CANCEL_CHANGES,
    SET_ACTIONS_CHAIN_LIST,
    SET_VALIDATED_ACTIONS_CHAIN_LIST,
    SET_ACTIONS_EDIT_BASE_COPY,
    TOGGLE_SIDEBAR,
    SELECT_ACTION_TEMPLATE,
    PRE_SAVE_SIDEBAR_DATA,
    CLEAR_SCENARIO_ACTIONS_DATA,
    SET_AUTOSAVE_SIDEBAR_DATA,
    SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
    SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
    RESET_SCENARIO_ACTION_ERRORS,
    UPDATE_SCENARIO_ACTION
} from 'store/types';

export const addActionToEditField = data => dispatch =>
    dispatch({
        type: ADD_ACTION,
        payload: { data }
    });

export const addOutputCheckpoint = data => dispatch =>
    dispatch({
        type: ADD_ACTION_OUTPUT_CHECKPOINT,
        payload: { data }
    });

export const clearOutputCheckpoint = () => dispatch =>
    dispatch({
        type: CLEAR_ACTION_OUTPUT_CHECKPOINT,
        payload: { data: null }
    });


export const addInputCheckpoint = data => dispatch =>
    dispatch({
        type: ADD_ACTION_INPUT_CHECKPOINT,
        payload: { data }
    });

export const changeActionPosition = (data, item) => (dispatch, getStore) => {
    const actionsList = [...getStore().scenariosActions.actionsList];
    const index = findIndex(actionsList,e => eq(e.id, item.id));
    const action = find(actionsList, e => eq(e.id, item.id));

    const changedActionsList = ArrayUse.replace(actionsList, index, { ...action, ...data });

    dispatch({
        type: CHANGE_ACTION_POSITION,
        payload: { data: changedActionsList }
    });
}

export const changeOpenActionStatus = data => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: data.id });

    dispatch({
        type: CHANGE_ACTION_MODAL_STATUS,
        payload: { data: ArrayUse.replace(actionsList, index, { ...collection, isOpenActionInfo: !collection.isOpenActionInfo }) }
    });
};

export const removeActionProcess = data => (dispatch, getStore) => {
    const actionsArray = [...getStore().scenariosActions.actionsList];
    const arrowsArray = [...getStore().scenariosActions.actionConnectionsList];
    const outputCheckpoint = getStore().scenariosActions.actionOutputCheckpoint;

    remove(actionsArray, i => eq(i.id, data.id));
    remove(arrowsArray, i => i.from.id.includes(data.id) || i.to.id.includes(data.id));

    if (outputCheckpoint && outputCheckpoint?.id.includes(data.id)) {
        dispatch(clearOutputCheckpoint());
    }

    dispatch({
        type: REMOVE_ACTION_PROCESS,
        payload: {
            array: actionsArray,
            arrow: arrowsArray,
        }
    });
};

export const copyActionProcess = data => (dispatch, getStore) => {
    const actionsArray = [...getStore().scenariosActions.actionsList];
    const action = find(actionsArray, { id: data.id });

    const calculateNewPosition = position => {
        const { y } = position;

        if ((y + 75) >= 1900) return { ...position, y: y - 75 }

        return { ...position, y: y + 75 }
    }

    dispatch({
        type: COPY_ACTION_PROCESS,
        payload: {
            array: [...actionsArray, {
                    ...action,
                    id: data.newActionId,
                    position: calculateNewPosition(action.position),
                    isOpenActionInfo: false
                }
            ],
        }
    });
};

export const removeArrow = data => (dispatch, getStore) => {
    const arrowsArray = [...getStore().scenariosActions.actionConnectionsList];

    remove(arrowsArray, i => includes(i.from.id, data.from) && includes(i.to.id, data.to));

    dispatch({
        type: REMOVE_ARROW_LINE,
        payload: { arrow: arrowsArray }
    });
};

export const setActionsChainList = data => dispatch =>
    dispatch({
        type: SET_ACTIONS_CHAIN_LIST,
        payload: {
            actions: data.actions,
            arrows: data.arrows,
            baseCopy: { actionsList: data.actions, actionConnectionsList: data.arrows }
        }
    })

export const setValidatedActionsChainList = data => dispatch =>
    dispatch({
        type: SET_VALIDATED_ACTIONS_CHAIN_LIST,
        payload: { actions: data.actions, arrows: data.arrows, baseCopy: { actionsList: data.actions, actionConnectionsList: data.arrows } }
    })

export const setCurrentScenatioActionsChainListValidationReport = ({ data, hasErrors }) => dispatch => {
    dispatch({
        type: SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
        payload: { actions: data }
    })

    return Promise.resolve(hasErrors);
};
export const setActionsChainListValidationReport = ({ actions, arrows }, id = null) => dispatch => {
    dispatch({
        type: SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
        payload: { actions: actions.data, arrows: arrows, scenarioId: id }
    })

    return Promise.resolve(actions.hasErrors);
};

export const setActionsCancelChanges = () => (dispatch, getStore) => {

    const { actionsList, actionConnectionsList } = getStore().scenariosActions.actionsBaseCopy;

    dispatch({
        type: SET_ACTIONS_CANCEL_CHANGES,
        payload: { actions: actionsList, arrows: actionConnectionsList }
    })

    return Promise.resolve();
};

export const toggleSideBar = (data = { open: false, type: null, id: null }) => (dispatch, getStore) => {

    if (data.id && data.type) {
        const { actionsList } = getStore().scenariosActions;
        const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: data.id });

        dispatch({
            type: CHANGE_ACTION_MODAL_STATUS,
            payload: { data: ArrayUse.replace(actionsList, index, { ...collection, isOpenActionInfo: false, isShowWarningActionMessage: false }) }
        });

        const includesCopy = find(getStore().scenariosActions.baseCopyEditActions, { id: data.id });

        !includesCopy && dispatch({
            type: SET_ACTIONS_EDIT_BASE_COPY,
            payload: { data: { ...collection, isOpenActionInfo: false, isShowWarningActionMessage: false } }
        })
    }

    dispatch({
        type: TOGGLE_SIDEBAR,
        payload: { data: data }
    });
};

export const saveSidebarData = (actionSidebarSettings = {}, actionSettings = {}) => (dispatch, getStore) => {
    const sidebar = getStore().scenariosActions.actionSidebar;

    if (sidebar.open && sidebar.id) {
        const { actionsList } = getStore().scenariosActions;
        const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: sidebar.id });

        dispatch({
            type: SAVE_SIDEBAR_DATA,
            payload: {
                data: ArrayUse.replace(actionsList, index, { ...collection, ...actionSettings, isAutoSaveActive: false, settings: actionSidebarSettings }),
                baseCopyEditActions: filter(getStore().scenariosActions.baseCopyEditActions, item => !eq(item.id, sidebar.id) ) || []
            }
        });
    } else {
        console.log("Sidebar doesn't active right now. How you want to save data?")
    }
};

export const autoSaveSidebarData = id => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: id });

    dispatch({
        type: SET_AUTOSAVE_SIDEBAR_DATA,
        payload: {
            data: ArrayUse.replace(actionsList, index, { ...collection, isAutoSaveActive: true }),
        }
    });
};

export const preSaveSendSidebarData = data => (dispatch, getStore) => {
    const sidebar = getStore().scenariosActions.actionSidebar;

    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: sidebar.id });

    dispatch({
        type: PRE_SAVE_SIDEBAR_DATA,
        payload: {
            data: ArrayUse.replace(actionsList, index, { ...collection, settings: data })
        },
    });
};

export const closeActionListWarning = id => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: id });

    dispatch({
        type: CLOSE_ACTION_LIST_WARNING,
        payload: { data: ArrayUse.replace(actionsList, index, { ...collection, isShowWarningActionMessage: false }) }
    });
};

export const selectActionTemplate = (actionId, templateUniqueId = null) => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(getStore().scenariosActions.actionsList, { id: actionId });

    dispatch({
        type: SELECT_ACTION_TEMPLATE,
        payload: {
            data: ArrayUse.replace(actionsList, index, { ...collection, settings: { ...collection.settings, templateUniqueId } })
        },
    })

    return Promise.resolve();
};

export const clearScenarioActionsData = () => dispatch =>
    dispatch({
        type: CLEAR_SCENARIO_ACTIONS_DATA,
        payload: {
            defaultCurrentScenarioId: null,
            defaultActionList: [],
            defaultActionConnectionsList: [],
            defaultActionOutputCheckpoint: null,
            defaultActionsBaseCopy: {},
            defaultActionSideBar: { open: false, type: null, id: null },
            defaultCurrentScenarioTemplates: {
                actionsList: [],
                metrics: {},
            }
        }
    })

export const resetScenarioActionErrors = actionId => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(getStore().scenariosActions.actionsList, { id: actionId });

    dispatch({
        type: RESET_SCENARIO_ACTION_ERRORS,
        payload: {
            data: ArrayUse.replace(actionsList, index, { ...collection, errors: {} })
        },
    })
};
export const updateScenarioActionData = (actionId, actionSettings) => (dispatch, getStore) => {
    const { actionsList } = getStore().scenariosActions;
    const { data: collection, index } = ArrayUse.findCollection(actionsList, { id: actionId });

    dispatch({
        type: UPDATE_SCENARIO_ACTION,
        payload: {
            data: ArrayUse.replace(actionsList, index, { ...collection, ...actionSettings, }),
        }
    });
};
