import { toString, map, eq, includes } from 'lodash';
import { defaultSqlTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultSqlTriggerSettings';
import { DateUse } from 'lib/DateUse';

const { selectedType, dayOfWeek, atTheDay, time } = defaultSqlTriggerSettings();

export const sqlTriggerDecompiler = actionOptions => {

    const {
        schedule = null,
        datetime = null,
        bq_query,
    } = actionOptions;

    const isRepeatTypeSelected = type => eq(type, 'repeat') && !!schedule;
    const isScheduleTypeSelected = type => eq(type, 'schedule') && !schedule;

    const selectedTypeDecompiler = map(selectedType, type => {

        if (isRepeatTypeSelected(type.id)) return { ...type, active: true };

        if (isScheduleTypeSelected(type.id)) return { ...type, active: true };

        return { ...type, active: false }
    });

    const dayOfWeekDecompiler = (schedule, defaultDayOfWeek) => {
        if (schedule) {
            const { days_of_the_week } = schedule;
            return map(dayOfWeek, (item, index) => includes(days_of_the_week, index)
                ? { ...item, isChecked: true }
                : { ...item, isChecked: false }
            );
        }

        return defaultDayOfWeek;
    }

    const localDate = datetime
        ? DateUse.convertUTCToLocal(datetime, 'YYYY-MM-DD HH:mm', 'DD/MM/YYYY')
        : null

    const timeDecompiler = (schedule, datetime, defaultTime) => {
        if (schedule) {
            return DateUse.convertUTCToLocal(schedule.time, 'HH:mm', 'HH:mm');
        }

        if (datetime) {
            return DateUse.convertUTCToLocal(datetime, 'YYYY-MM-DD HH:mm', 'HH:mm');
        }

        return defaultTime;
    }

    return {
        selectedType: selectedTypeDecompiler,
        dayOfWeek: dayOfWeekDecompiler(schedule, dayOfWeek),
        atTheDay: {
            ...atTheDay,
            value: localDate
        },
        time: timeDecompiler(schedule, datetime, time),
        sql: toString(bq_query),
    }
}
