import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { includes, isEmpty, omit, has} from 'lodash';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { StringUse } from 'lib/StringUse';

const parsedErrors = (errors, id) => {
    const isUrlsHasErrors = !isEmpty(errors) && has(errors, 'urls');

    if (isUrlsHasErrors) {
        const { urls: { url } } = errors;

        return {
            url: {
                hasError: includes(url.id, id),
                message: StringUse.falseyReturnEmptyString(url.error)
            }
        }
    }

    return {
        url: {
            hasError: false
        }
    }
};
export const UrlsMapped = ({ item, index }) => {

    const {
        onChangeDropdownShowStatus,
        onClickRemoveDropdownItem,
        onChangeDropdownInput,
        setUrls,
        errors
    } = useContext(SideBarContext);

    const isRequiredAtLeastOneUrl = index > 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeDropdownInput(item.id, setUrls), []);

    const { url } = parsedErrors(omit(errors, ['templateUniqueId']), item.id);

    return (
        <li key={item.id}>
            <div className={styles.filterItemContainer}>
                <AsideDropdownButtonItemButton
                    name={item?.url?.value || `Url ${(index + 1)}`}
                    onClick={onChangeDropdownShowStatus(item.id, setUrls)}
                    active={item.show}
                />
                {isRequiredAtLeastOneUrl && <DeleteButton onClick={onClickRemoveDropdownItem(item.id, setUrls)}/>}
            </div>
            {item.show && <div className={styles.filterContainer}>
                <Label htmlFor="url" text='Url address'/>
                <InputField
                    type='text'
                    id='url'
                    name='url'
                    value={item.url.value}
                    required={item.url.required}
                    error={url.hasError}
                    onChange={onChangeCached}
                />
                <ErrorMessageField active={url.hasError} message={url.message}/>

                <AsideDropdownButtonSeparateLine
                    onClick={onChangeDropdownShowStatus(item.id, setUrls)}
                />
            </div>}
        </li>
    )
};

UrlsMapped.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}
