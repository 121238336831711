import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useActionLocalDrop } from 'hooks/ScenarioSetup/useActionLocalDrop';
import { DraggableActionContext } from 'components/ScenarioSetup/context/DraggableActionContext';

export const ScenarioSetupDropField = ({ children }) => {
    const { markAsDone } = useContext(DraggableActionContext);
    const ref = useActionLocalDrop(markAsDone);

    return (
        <div ref={ref} style={{ height: '100%', position: 'relative' }}>
            {children}
        </div>
    );
};
ScenarioSetupDropField.propTypes = {
    children: PropTypes.object.isRequired
}
