import { lowerCase, some } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { StringUse } from 'lib/StringUse';

export const funnelCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            countClients,
            countTimeIntervalNumber,
            countTimeIntervalType,
            evalTimeInterval,
            selectedType,
            shareCount
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const funnelOptionsCompiler = {
        count: () => {
            return {
                max_n_clients: StringUse.recast(countClients.value, { accepted: 'number', rejected: null }),
                period_number: StringUse.recast(countTimeIntervalNumber.value, { accepted: 'number', rejected: null }),
                period_type: lowerCase(countTimeIntervalType.value),
                split_evenly: evalTimeInterval
            }
        },
        share: () => {
            return {
                probability: StringUse.recast(shareCount.value, { accepted: 'number', rejected: null }) / 100
            }
        }
    }

    return {
        action_id: id,
        action_type: lowerCase(actionType),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            count: some(selectedType, { id: 'count', active: true }) ? funnelOptionsCompiler.count() : null,
            fraction: some(selectedType, { id: 'share', active: true }) ? funnelOptionsCompiler.share() : null
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ]
    }
}
