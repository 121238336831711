import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/Headers/Headers.module.css';

export const SectionHeaderTitle = ({ title }) => <h6 className={styles.sectionHeaderTitle}>{title}</h6>

SectionHeaderTitle.propTypes = {
    title: PropTypes.string.isRequired
}
SectionHeaderTitle.defaultProps = {
    title: ''
}
