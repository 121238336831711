import { getDropdownFilters } from 'store/actions/segments.action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes, has } from 'lodash';

export const useDropdownFilters = (dropdownFiltersValues = []) => {
    const dispatch = useDispatch();

    const dropdownFilters = useSelector(store => store.segments.dropdownFilters);

    useEffect(() => {
        if (!dropdownFilters) {
            if (!has(dropdownFilters, 'ATTRIBUTE') && includes(dropdownFiltersValues, 'ATTRIBUTE')) {
                dispatch(getDropdownFilters('ATTRIBUTE'))
            }
            if (!has(dropdownFilters, 'PRODUCT_ATTRIBUTE') && includes(dropdownFiltersValues, 'PRODUCT_ATTRIBUTE')) {
                dispatch(getDropdownFilters('PRODUCT_ATTRIBUTE'))
            }
            if (!has(dropdownFilters, 'METRIC') && includes(dropdownFiltersValues, 'METRIC')) {
                dispatch(getDropdownFilters('METRIC'))
            }
        }

    }, [dispatch, dropdownFilters, dropdownFiltersValues]);
};