import React from 'react';
import { map } from 'lodash';
import styles from 'components/MainSidebar/MainSidebar.module.css';
import { NavLink } from "react-router-dom";
import { AutomationIcon } from 'assets/icons/AutomationIcon';
// import { AnalyticsPageIcon } from 'assets/icons/AnalyticsPageIcon';
import { TemplateIcon } from 'assets/icons/TemplateIcon';
// import { RecommendationIcon } from 'assets/icons/RecommendationIcon';
import { useTranslation } from 'react-i18next';
import { SegmentIcon } from 'assets/icons/SegmentIcon';
import { IntegrationIcon } from 'assets/icons/IntegrationIcon';
import { StudyIcon } from 'assets/icons/StudyIcon';
import { PeopleCircleIcon } from 'assets/icons/PeopleCircleIcon';

const buttons = [
    {
        name: 'Retention',
        icon: <PeopleCircleIcon/>,
        link: '/'
    },
    {
        name: 'Campaigns',
        icon: <AutomationIcon/>,
        link: '/communications'
    },
    {
        name: 'Audiences',
        icon: <SegmentIcon/>,
        link: '/segments'
    },
    {
        name: 'Templates',
        icon: <TemplateIcon/>,
        link: '/all-templates'
    },
    // {
    //     name: 'Analytics',
    //     icon: <AnalyticsPageIcon/>,
    //     link: '/analytics',
    // },
    // {
    //     name: 'Recommendations',
    //     icon: <RecommendationIcon/>,
    //     link: '/recommendations',
    // },
    {
        name: 'Integrations',
        icon: <IntegrationIcon/>,
        link: '/integrations'
    },
];

const activeNavLink = () => ({ isActive }) => isActive ? styles.active : null;

export const LeftSideBar = () => {
    const { t: translator } = useTranslation();

    return (
        <aside className={styles.container}>
            <div className={styles.top}>
                <NavLink to="/" className={styles.logo}/>
                {map(buttons, (item, key) =>
                    <NavLink key={key} to={item.link} className={activeNavLink()}>
                        <div className={styles.icon}>
                            {item.icon}
                        </div>
                        <p>{translator(`mainLeftSideBar:${item.name}`)}</p>
                    </NavLink>
                )}
            </div>
            <div className={styles.bottom}>
                <a href='https://docs.neucurrent.com/' target='_blank' className={styles.bottomLink} rel='noreferrer'>
                    <div className={styles.icon}>
                        <StudyIcon/>
                    </div>
                    <p>{translator('mainLeftSideBar:Instructions')}</p>
                </a>
            </div>
        </aside>
    )
};
