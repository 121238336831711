import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/SelectOptions/SelectOptions.module.css';
import classNames from 'classnames/bind';
import { map } from 'lodash';
import { Option } from 'components/SelectOptions/Option';

const cx = classNames.bind(styles);

export const Select = memo(({ name, value, onChange, required, className, options }) => {

    const mappedKey = option => option instanceof Object ? option.value : option;

    return (
        <select
            id={name}
            name={name}
            value={value}
            className={cx(styles.selectContainer, className)}
            onChange={onChange}
            required={required}
        >
            {map(options, option =>
                <Option key={mappedKey(option)} option={option}/>
            )}
        </select>
    )
});

Select.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    options: PropTypes.array.isRequired
}
Select.defaultProps = {
    value: '',
    className: null,
    required: false
}
