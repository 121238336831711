import { forEach } from 'lodash';
import { Validation } from 'validations/Validation';

const jsonHeadersRules = {
    headerKey: 'required',
    headerValue: 'required',
};

export const jsonHeadersControl = jsonHeaders => {

    let jsonHeadersErrors = {
        headerKey: {
            id: []
        },
        headerValue: {
            id: []
        }
    };

    forEach(jsonHeaders, ({ id, headerKey, headerValue }) => {
        const validation = Validation.checkSome({
            headerKey: headerKey.value,
            headerValue: headerValue.value
        }, jsonHeadersRules, {
            'required.headerKey': 'InputValidationRequiredMessage',
            'required.headerValue': 'InputValidationRequiredMessage',
        }, ['headerKey', 'headerValue'])

        if (validation.fails) {
            const headerKeyError = validation.firstErrors.headerKey;
            const headerValueError = validation.firstErrors.headerValue;

            if (headerKeyError) {
                jsonHeadersErrors.headerKey = {
                    error: headerKeyError,
                    id: [...jsonHeadersErrors.headerKey.id, id]
                }
            }

            if (headerValueError) {
                jsonHeadersErrors.headerValue = {
                    error: headerValueError,
                    id: [...jsonHeadersErrors.headerValue.id, id]
                }
            }

        }
    });

    const isJsonHeadersHasErrors = !!jsonHeadersErrors.headerKey.id.length || !!jsonHeadersErrors.headerValue.id.length

    return isJsonHeadersHasErrors
        ? jsonHeadersErrors
        : {}
};
