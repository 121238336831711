import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/Forms/Forms.module.css';
import classNames from 'classnames/bind';
import { CommonMethods } from 'lib/CommonMethods';

const cx = classNames.bind(styles);

export const ActionSidebarFormWrapper = ({ children, onSubmitCallback }) => (
    <form className={cx(styles.form, styles.actionSidebarForm, 'customGreenScrollbar')}
          noValidate
          onKeyDown={CommonMethods.preventSubmitForm}
          onSubmit={onSubmitCallback}
    >
        {children}
    </form>
);

ActionSidebarFormWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmitCallback: PropTypes.func.isRequired
}
