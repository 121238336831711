import React from 'react';
import styles from 'common/_Buttons/_VioletButton/VioletButton.module.css';
import { DefaultButton } from 'common/_Buttons/_DefaultButton/DefaultButton';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const VioletButton = Component => ({ ...props }) => {
    return <Component {...props} className={cx(styles.violetButton, props.className)}/>
};

export default VioletButton(DefaultButton);
