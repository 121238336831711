import React, { useCallback } from 'react';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/SelectDayOfWeek/SelectDayOfWeek.module.css';
import { Checkbox } from 'common/_Checkbox/Checkbox';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';

const parsedErrors = errors => {
    if (isEmpty(errors)) return {
        checkboxErrorIsActive: false,
        checkboxErrorMessage: ''
    }

    const { checkboxErrors } = errors;

    return {
        checkboxErrorIsActive: true,
        checkboxErrorMessage: checkboxErrors
    }
};

export const SelectDayOfWeek = ({ array, onChange, errors }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const onChangeCached = useCallback(onChange, []);

    const { checkboxErrorIsActive, checkboxErrorMessage } = parsedErrors(errors);

    return (
        <div className={styles.sidebarCheckbox}>
            <div className={styles.sidebarCheckboxContainer}>
                {map(array, item =>
                    <div className={styles.sidebarCheckboxItemContainer} key={item.id}>
                        <Checkbox
                            id={item.id}
                            name={item.name.key}
                            checked={item.isChecked}
                            onChange={onChangeCached}
                            className={styles.sidebarCheckbox}
                            showName={false}
                        />
                        <p>{item.name.shortCut}</p>
                    </div>
                )}
            </div>
            <ErrorMessageField active={checkboxErrorIsActive} message={checkboxErrorMessage}/>
        </div>
    )
}

SelectDayOfWeek.propTypes = {
    array: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}
