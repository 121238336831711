import { defaultWaitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultWaitSettings';
import { StringUse } from 'lib/StringUse';

const { waitInterval } = defaultWaitSettings();

export const waitDecompiler = actionOptions => {
    const {
        minutes_to_wait = waitInterval?.value
    } = actionOptions;

    return {
        waitInterval: {
            ...waitInterval,
            value: StringUse.falseyReturnEmptyString(minutes_to_wait, 'string'),
        }
    }
}
