import React from 'react';

export const ABTestSplitIconAlt = ({ borderColor }) => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="33.9412" y="0.707107" width="47" height="47" rx="4.5" transform="rotate(45 33.9412 0.707107)" fill="white" stroke={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.0297 34.0571C25.4751 34.0571 25.0255 33.6076 25.0255 33.0533L25.0255 28.0342H20.0045C19.5755 28.0342 19.1939 27.7618 19.0547 27.3562C18.9154 26.9506 19.0494 26.5014 19.388 26.2381L28.4257 19.2114C28.7883 18.9295 29.2961 18.9295 29.6587 19.2114L38.6965 26.2381C39.0351 26.5014 39.169 26.9506 39.0298 27.3562C38.8906 27.7618 38.509 28.0342 38.08 28.0342H33.059L33.059 33.0533C33.059 33.3195 32.9532 33.5748 32.7649 33.7631C32.5766 33.9513 32.3211 34.0571 32.0548 34.0571H26.0297ZM32.0548 27.0304H38.08L29.0422 20.0038L20.0045 27.0304H26.0297L26.0297 33.0533H32.0548L32.0548 27.0304Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.059 42.0875L33.059 37.5704C33.059 36.7388 32.3846 36.0647 31.5527 36.0647H26.5317C25.6998 36.0647 25.0255 36.7388 25.0255 37.5704L25.0255 42.0875H20.5931C19.1204 42.0875 18.5229 43.9825 19.7293 44.8267L28.1627 50.7278C28.7042 51.1067 29.4296 51.0885 29.9513 50.6828L37.5414 44.7817C38.6735 43.9016 38.0508 42.0875 36.6166 42.0875H33.059ZM32.0548 42.5894C32.0548 42.8666 32.2796 43.0913 32.5569 43.0913H36.6166C37.0947 43.0913 37.3022 43.696 36.9248 43.9894L29.3348 49.8905C29.1609 50.0257 28.9191 50.0318 28.7386 49.9055L20.3052 44.0044C19.903 43.723 20.1022 43.0913 20.5931 43.0913H25.5276C25.8049 43.0913 26.0297 42.8666 26.0297 42.5894L26.0296 37.5704C26.0296 37.2932 26.2544 37.0685 26.5317 37.0685H31.5527C31.83 37.0685 32.0548 37.2932 32.0548 37.5704L32.0548 42.5894Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.0967 31.0456L37.5779 31.0456C36.746 31.0456 36.0716 31.7198 36.0716 32.5514V37.5704C36.0716 38.402 36.746 39.0761 37.5779 39.0761L42.0967 39.0761V42.5679C42.0967 44.0213 43.9531 44.6312 44.8157 43.461L50.7066 35.4693C51.116 34.9139 51.0954 34.1515 50.6567 33.6189L44.7658 26.4684C43.8674 25.378 42.0967 26.013 42.0967 27.4256V31.0456ZM37.5779 32.0494L42.5988 32.0495C42.8761 32.0495 43.1009 31.8247 43.1009 31.5476V27.4256C43.1009 26.9547 43.6911 26.743 43.9906 27.1065L49.8815 34.257C50.0278 34.4346 50.0346 34.6887 49.8982 34.8738L44.0072 42.8656C43.7197 43.2556 43.1009 43.0523 43.1009 42.5679V38.5742C43.1009 38.297 42.8761 38.0723 42.5988 38.0723L37.5779 38.0723C37.3006 38.0723 37.0758 37.8476 37.0758 37.5704V32.5514C37.0758 32.2742 37.3006 32.0494 37.5779 32.0494Z" fill={borderColor}/>
        <path d="M31.1794 26.5285L29.6309 22.5113H28.5584L27.0039 26.5285H27.98L28.2331 25.8479H29.9563L30.2033 26.5285H31.1794ZM29.7213 25.095H28.468L29.0947 23.3665L29.7213 25.095Z" fill={borderColor}/>
        <path d="M45.9389 37.209C46.8246 37.209 47.3307 36.7212 47.6199 36.2213L46.8848 35.8659C46.7161 36.1972 46.3546 36.4501 45.9389 36.4501C45.2098 36.4501 44.6796 35.89 44.6796 35.1311C44.6796 34.3723 45.2098 33.8121 45.9389 33.8121C46.3546 33.8121 46.7161 34.0711 46.8848 34.3963L47.6199 34.035C47.3367 33.5351 46.8246 33.0533 45.9389 33.0533C44.7338 33.0533 43.7999 33.8965 43.7999 35.1311C43.7999 36.3658 44.7338 37.209 45.9389 37.209Z" fill={borderColor}/>
        <path d="M29.717 47.7311C30.4822 47.7311 30.8859 47.2493 30.8859 46.647C30.8859 46.1411 30.5425 45.7255 30.1147 45.6593C30.4943 45.581 30.8076 45.2437 30.8076 44.7378C30.8076 44.2017 30.416 43.7139 29.6508 43.7139H27.5359V47.7311H29.717ZM29.464 45.322H28.3915V44.4487H29.464C29.7532 44.4487 29.934 44.6354 29.934 44.8883C29.934 45.1413 29.7532 45.322 29.464 45.322ZM29.4941 46.9903H28.3915V46.0568H29.4941C29.8315 46.0568 30.0123 46.2736 30.0123 46.5265C30.0123 46.8156 29.8195 46.9903 29.4941 46.9903Z" fill={borderColor}/>
        <path d="M35.0674 35.0609C35.0674 35.6153 34.6178 36.0647 34.0632 36.0647C33.5086 36.0647 33.059 35.6153 33.059 35.0609C33.059 34.5065 33.5086 34.0571 34.0632 34.0571C34.6178 34.0571 35.0674 34.5065 35.0674 35.0609Z" fill={borderColor}/>
    </svg>

);
