import React, { useCallback, useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import PropTypes from 'prop-types';
import { isEmpty, map, eq, has } from 'lodash';
import BlueButton from 'common/_Buttons/_BlueButton/BlueButton';
import VioletButton from 'common/_Buttons/_VioletButton/VioletButton';
import { useTranslation } from 'react-i18next';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { JointlyInputSelectOptions } from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions';
import { defaultSimplifiedTimeIntervalOptions } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/simplified/simplifiedConditionSettings';
import classNames from 'classnames/bind';
import { SearchInput } from 'common/SearchInput/SearchInput';
import { RadioButton } from 'common/RadioButton/RadioButton';

const cx = classNames.bind(styles);

const onChangeUpdateCondition = (simplifiedTimeInterval, setSimplifiedTimeInterval, setCondition) => {
    return event => {
        event.persist();

        const isDaysSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'days');
        const isHoursSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'hours');

        // Update state (simplifiedTimeInterval) of component
        setSimplifiedTimeInterval(prev => ({
            ...prev,
            countTimeIntervalNumber: {
                ...prev.countTimeIntervalNumber,
                value: event.target.value
            }
        }));

        // When simplifiedTimeInterval changed we need to update advanced condition settings
        setCondition(prev => {
            const condition = prev[0];

            return [{
                ...condition,
                _eventValues: {
                    ...condition._eventValues,
                    countDays: {
                        ...condition._eventValues.countDays,
                        value: isDaysSelected ? event.target.value : '0'
                    },
                    countHours: {
                        ...condition._eventValues.countHours,
                        value: isHoursSelected ? event.target.value : '0'
                    }
                }
            }]
        });
    }
};

const getConditionSimplifiedErrors = errors => {
    if (!isEmpty(errors) && has(errors, 'conditionsErrors')) {
        if (errors?.conditionsErrors[0]?.validation?.selectedTimeInterval?.fails) return 'InputValidationRequiredMessage'
    }

    return ''
};

const onChangeRadioButton = setState => {
    return event => {
        const { name, checked } = event.target;

        return checked && setState(prev => map(prev, button =>
            eq(button.name, name)
                ? { ...button, selected: true }
                : { ...button, selected: false }
        ))
    };
};

export const ConditionSimplified = ({ extendedTimeInterval }) => {
    const { t: translator } = useTranslation();

    const {
        errors,
        setCondition,
        onClickSelectSimplifiedButton,
        onChangeInput,
        simplifiedSettingsButtons,
        setSimplifiedSettingsButtons,
        simplifiedTimeInterval,
        setSimplifiedTimeInterval,
        segmentsButtons,
        setSegmentsButtons,
        setSearchTerm,
        setFocusSearchInput,
        simplifiedTimeIntervalType,
        setSimplifiedTimeIntervalType,
        isAtScriptStartSelected
    } = useContext(SideBarContext);

    const mappedOptions = map(defaultSimplifiedTimeIntervalOptions, timeInterval => ({
        ...timeInterval,
        name: translator(`actionSidebars:${timeInterval.name}`)
    }) );

    const timeIntervalHasErrors = getConditionSimplifiedErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClickSimplifiedButtonCached = useCallback(onClickSelectSimplifiedButton(setSimplifiedSettingsButtons), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClickSegmentButtonCached = useCallback(onClickSelectSimplifiedButton(setSegmentsButtons, 'id'), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericCached = useCallback(onChangeUpdateCondition(simplifiedTimeInterval, setSimplifiedTimeInterval, setCondition), []);

    const onChangeSearchSegment = event => setSearchTerm(event.target.value);

    return (
        <>
            <div className={styles.conditionSimplifiedContainer}>
                <p className={styles.sidebarSectionName}>{translator('actionSidebars:Conditions')}</p>

                {map(simplifiedSettingsButtons, button =>
                    <React.Fragment key={button.name}>
                        <BlueButton
                            value={button.name}
                            name={translator(`actionSidebars:${button.name}`)}
                            onClick={onClickSimplifiedButtonCached}
                            className={cx({ 'notSelectedSimplifiedActionSidebarButton': !button.selected })}
                        />

                        {extendedTimeInterval && button.selected &&
                            <div className={styles.conditionSimplifiedTimeInterval}>
                                {map(simplifiedTimeIntervalType, elem => <RadioButton
                                    key={elem.id}
                                    name={elem.name}
                                    labelName={translator(`actionSidebars:${elem.name}`)}
                                    id={elem.id}
                                    checked={elem.selected}
                                    onChange={onChangeRadioButton(setSimplifiedTimeIntervalType)}
                                />)}
                            </div>
                        }

                        {button.selected && !isAtScriptStartSelected &&
                            <JointlyInputSelectOptions
                                inputName='countTimeIntervalNumber'
                                inputType='number'
                                inputLabel={translator('actionSidebars:TimeInterval')}
                                inputValue={simplifiedTimeInterval.countTimeIntervalNumber.value}
                                inputRequired={simplifiedTimeInterval.countTimeIntervalNumber.required}
                                inputOnChange={onChangeNumericCached}
                                selectName='countTimeIntervalType'
                                selectOptions={mappedOptions}
                                selectValue={simplifiedTimeInterval.countTimeIntervalType.value}
                                selectRequired={simplifiedTimeInterval.countTimeIntervalType.required}
                                selectOnChange={onChangeInput(setSimplifiedTimeInterval)}
                                error={timeIntervalHasErrors}
                            />
                        }
                    </React.Fragment>
                )}
            </div>
            <div className={styles.selectorsSimplifiedContainer}>
                <p className={styles.sidebarSectionName}>{translator('actionSidebars:Segments')}</p>

                <div className={styles.sidebarSearchContainer}>
                    <SearchInput
                        onChangeCallback={onChangeSearchSegment}
                        placeholder='SearchSegmentPlaceholder'
                        setFocus={setFocusSearchInput}
                    />
                </div>

                {map(segmentsButtons, button =>
                    <VioletButton
                        key={button.id}
                        value={button.id}
                        name={button.name}
                        onClick={onClickSegmentButtonCached}
                        className={cx(styles.segmentButton, { 'notSelectedSimplifiedActionSidebarButton': !button.selected })}
                    />
                )}
            </div>
        </>
    )
};

ConditionSimplified.propTypes = {
    extendedTimeInterval: PropTypes.bool
};
ConditionSimplified.defaultProps = {
    extendedTimeInterval: false
};
