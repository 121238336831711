import React from 'react';

export const FunnelIconDropped = ({ color, bgColor, width, height }) => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.9107 3.03046C32.5844 1.35678 35.2979 1.35678 36.9716 3.03046L64.8518 30.9107C66.5255 32.5843 66.5255 35.2979 64.8518 36.9716L36.9716 64.8518C35.2979 66.5255 32.5844 66.5255 30.9107 64.8518L3.0305 36.9716C1.35682 35.2979 1.35682 32.5843 3.03049 30.9107L30.9107 3.03046Z" fill="#00C6A2"/>
        <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="17" y="21" width="33" height="32">
            <rect x="17.9412" y="21" width="32" height="32" fill="#C4C4C4"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.2132 21.9846H21.9258V24.6061L31.9315 37.7386H36.2075L46.2132 24.6061V21.9846ZM47.1978 24.9385L36.6952 38.7233H31.4438L20.9412 24.9385V21H47.1978V24.9385Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.4437 37.5742C31.7156 37.5742 31.936 37.7946 31.936 38.0665V51.7111L36.2027 49.5778V38.0665C36.2027 37.7946 36.4231 37.5742 36.695 37.5742C36.9669 37.5742 37.1873 37.7946 37.1873 38.0665V49.882C37.1873 50.0685 37.082 50.239 36.9152 50.3224L31.6638 52.948C31.5112 53.0243 31.33 53.0162 31.1848 52.9265C31.0397 52.8368 30.9514 52.6783 30.9514 52.5077V38.0665C30.9514 37.7946 31.1718 37.5742 31.4437 37.5742Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4335 24.9385C21.4335 24.6666 21.6539 24.4462 21.9258 24.4462H46.5414C46.8133 24.4462 47.0337 24.6666 47.0337 24.9385C47.0337 25.2104 46.8133 25.4308 46.5414 25.4308H21.9258C21.6539 25.4308 21.4335 25.2104 21.4335 24.9385Z" fill="white"/>
    </svg>
);
