import { map } from 'lodash';
import { defaultCustomWebHookSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultCustomWebHookSettings';
import { jsonHeaderExample } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { StringUse } from 'lib/StringUse';

const {
    values: { maxNumberOfRequests, maxFrequencyNumber, maxFrequencyType, consentCategory, method, url },
    jsonHeaders,
    jsonBody
} = defaultCustomWebHookSettings();

export const customWebHookDecompiler = actionOptions => {

    const {
        consent_category_id = consentCategory,
        max_frequency,
        request_method = method,
        request_url = url,
        request_headers = jsonHeaders,
        request_body = jsonBody
    } = actionOptions;

    const { show, headerName, headerKey, headerValue } = jsonHeaderExample;

    const headers = map(request_headers, (value, key) => {

       return {
           id: key,
           show,
           headerName,
           headerKey: {
               ...headerKey,
               value: key
           },
           headerValue: {
               ...headerValue,
               value: value
           }
       }
    })

    return {
        values: {
            maxNumberOfRequests: {
                ...maxNumberOfRequests,
                value: StringUse.falseyReturnEmptyString(max_frequency?.n_communications, 'string'),
            },
            maxFrequencyNumber: {
                ...maxFrequencyNumber,
                value: StringUse.falseyReturnEmptyString(max_frequency?.period_number, 'string'),
            },
            maxFrequencyType: {
                ...maxFrequencyType,
                value: max_frequency?.period_type ? max_frequency?.period_type : 'days',
            },
            consentCategory: {
                ...consentCategory,
                value: StringUse.falseyReturnEmptyString(consent_category_id),
            },
            method: {
                ...method,
                value: request_method,
            },
            url: {
                ...url,
                value: StringUse.falseyReturnEmptyString(request_url),
            }
        },
        jsonBody: request_body,
        jsonHeaders: headers
    }
}
