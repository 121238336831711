import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map } from 'lodash';
import classNames from 'classnames/bind';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { ConditionMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/advanced/ConditionMapped';

const cx = classNames.bind(styles);

export const ConditionDropdown = ({ required }) => {

    const {
        condition,
    } = useContext(SideBarContext);

    return (
        <ul className={cx(styles.filter, styles.filterGreen)}>
            {map(condition, (item, index) =>
                <ConditionMapped
                    required={required}
                    key={item.id}
                    item={item}
                    index={index}
                />
            )}
        </ul>
    )
};
ConditionDropdown.propTypes = {
    required: PropTypes.bool
};
ConditionDropdown.defaultProps = {
    required: false
};
