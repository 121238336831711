import {
    SET_SEGMENT,
    SET_SEGMENT_CLIENT,
    SET_SEGMENT_CLIENT_EVENT,
    SET_SEGMENT_CLIENT_EVENTS_LIST,
    SET_SEGMENT_CLIENTS_LIST,
    SET_SEGMENTS_LIST,
    SET_SEGMENTS_TEMPLATE,
    TOGGLE_CONDITION_SIDEBAR,
    SET_EDIT_CONDITION,
    CANCEL_EDIT_CONDITION,
    SET_UPDATED_CONDITION,
    SET_NEW_CONDITION,
    SET_CLIENT_BY_ATTRIBUTES,
    CLEAR_SEGMENT_CLIENT,
    CLEAR_SEGMENT_CLIENTS_LIST,
    CLEAR_CLIENT_BY_ATTRIBUTES,
    SET_MANUAL_SEGMENTS_LIST,
    SET_AUTOSAVE_CONDITION_SIDEBAR_DATA,
    SET_DROPDOWN_FILTERS,
    SET_UPDATE_CLIENT_CONSENTS,
    SET_SILENT_UPDATE_SEGMENTS_LIST,
} from '../types';

const initialState = {
    list: [],
    manualSegmentsList: [],
    templatesSegment: [],
    segment: {},
    segmentClients: {
        result: []
    },
    clientsByAttributes: [],
    client: {},
    clientEvents: [],
    event: {},
    conditionSidebar: {
        open: false,
        isAutoSave: false,
        indexConditionGroup: null,
        indexCondition: null,
        typeCondition: ''
    },
    condition: {},
    dropdownFilters: null
};

export default function segments(store = initialState, { type, payload }) {
    switch (type) {
        case SET_AUTOSAVE_CONDITION_SIDEBAR_DATA:
            return  { ...store, conditionSidebar: { ...store.conditionSidebar, isAutoSave: true }};
        case TOGGLE_CONDITION_SIDEBAR:
            return { ...store, conditionSidebar: { ...store.conditionSidebar, payload }};
        case SET_SEGMENTS_LIST:
        case SET_SILENT_UPDATE_SEGMENTS_LIST:
            return { ...store, list: payload };
        case SET_SEGMENTS_TEMPLATE:
            return { ...store, templatesSegment: payload };
        case SET_SEGMENT:
            return { ...store, segment: payload };
        case SET_SEGMENT_CLIENTS_LIST:
            return { ...store, segmentClients: payload };
        case SET_SEGMENT_CLIENT:
            return { ...store, client: payload };
        case SET_SEGMENT_CLIENT_EVENTS_LIST:
            return { ...store, clientEvents: payload };
        case SET_SEGMENT_CLIENT_EVENT:
            return { ...store, event: payload };
        case SET_EDIT_CONDITION:
            return { ...store, condition: payload.condition, conditionSidebar: { ...payload.sidebarParams, open: true } };
        case CANCEL_EDIT_CONDITION:
            return { ...store, condition: {}, conditionSidebar: { open: false } };
        case SET_UPDATED_CONDITION:
            return { ...store, segment: payload, conditionSidebar: { open: false } };
        case SET_NEW_CONDITION:
            return {
                ...store,
                condition: payload.condition,
                conditionSidebar: { open: true, ...payload.sidebarParams }
            };
        case SET_CLIENT_BY_ATTRIBUTES:
            return { ...store, clientsByAttributes: payload };
        case CLEAR_SEGMENT_CLIENT:
            return  { ...store, client: {} };
        case CLEAR_SEGMENT_CLIENTS_LIST:
            return  { ...store, segmentClients: { result: [] } };
        case CLEAR_CLIENT_BY_ATTRIBUTES:
            return  { ...store, clientsByAttributes: [] };
        case SET_MANUAL_SEGMENTS_LIST:
            return { ...store, manualSegmentsList: payload };
        case SET_DROPDOWN_FILTERS:
            return {
                ...store,
                dropdownFilters: {
                    ...store.dropdownFilters,
                    ...payload.data
                }
            }
        case SET_UPDATE_CLIENT_CONSENTS:
            return {
                ...store,
                client: {
                    ...store.client,
                    ...payload.data
                }
            }
        default:
            return store;
    }
}
