import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { ActionChainConnection } from 'components/ScenarioSetup/ActionConnections/ActionChainConnection';

export const ActionChainConnections = () => {
    const { actionConnectionsList } = useSelector(store => store.scenariosActions);

    return (
        <>
            {map(actionConnectionsList, (connection, key) => <ActionChainConnection key={connection.from + key} from={connection.from} to={connection.to}/>)}
        </>
    )
}
