import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/Headers/Headers.module.css';
import animations from 'animations/FadeIn.module.css';
import classNames from 'classnames/bind';
import { CloseButton } from 'common/_Buttons/_CloseButton/CloseButton';
import { SectionHeaderTitle } from 'common/Headers/SectionHeader/SectionHeaderTitle';

const cx = classNames.bind(styles);

export const SectionHeaderWrapper = ({ sectionHeaderTitle, closeButtonCallback }) => (
    <div className={styles.sectionHeaderContainer}>
        <SectionHeaderTitle title={sectionHeaderTitle}/>
        <div className={cx(styles.sectionHeaderButtonsContainer, animations.fadeIn200ms)}>
            <CloseButton onClick={closeButtonCallback} color='#00c6a2'/>
        </div>
    </div>
);

SectionHeaderWrapper.propTypes = {
    sectionHeaderTitle: PropTypes.string,
    closeButtonCallback: PropTypes.func.isRequired
};
