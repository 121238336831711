import React from 'react';

export const GreenLinearGradient = () => (
    <>
        <stop offset="5%" stopColor="#00C6A2" stopOpacity={0.9}/>
        <stop offset="95%" stopColor="#74CEBE" stopOpacity={0.5}/>
    </>
);

export const BlueLinearGradient = () => (
    <>
        <stop offset="5%" stopColor="#0CC8E7" stopOpacity={0.8}/>
        <stop offset="95%" stopColor="#6CD3EA" stopOpacity={0.4}/>
    </>
);

export const VioletLinearGradient = () => (
    <>
        <stop offset="5%" stopColor="#9088DF" stopOpacity={0.9}/>
        <stop offset="95%" stopColor="#BEB9EB" stopOpacity={0.6}/>
    </>
);