import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { includes, isEmpty, omit, has } from 'lodash';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { StringUse } from 'lib/StringUse';

const parsedErrors = (errors, id) => {
    const isJsonHeadersHasErrors = !isEmpty(errors) && has(errors, 'jsonHeaders');

    if (isJsonHeadersHasErrors) {
        const {
            jsonHeaders: {
                headerKey,
                headerValue
            }
        } = errors;

        return {
            headerKey: {
                hasError: includes(headerKey.id, id),
                message: StringUse.falseyReturnEmptyString(headerKey.error)
            },
            headerValue: {
                hasError: includes(headerValue.id, id),
                message: StringUse.falseyReturnEmptyString(headerValue.error)
            }
        }

    }

    return {
        headerKey: {
            hasError: false
        },
        headerValue: {
            hasError: false
        }
    }
};

export const JsonHeaderMapped = ({ item, index }) => {
    const {
        onChangeDropdownShowStatus,
        onClickRemoveDropdownItem,
        onChangeDropdownInput,
        setJsonHeaders,
        errors
    } = useContext(SideBarContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeDropdownInput(item.id, setJsonHeaders), []);

    const { headerKey, headerValue } = parsedErrors(omit(errors, ['values']), item.id);

    return (
        <li key={item.id}>
            <div className={styles.filterItemContainer}>
                <AsideDropdownButtonItemButton
                    name={item?.headerKey?.value || item?.headerName + ' ' + (index + 1)}
                    onClick={onChangeDropdownShowStatus(item.id, setJsonHeaders)}
                    active={item.show}
                />
                <DeleteButton onClick={onClickRemoveDropdownItem(item.id, setJsonHeaders)}/>
            </div>
            {item.show && <div className={styles.filterContainer}>
                <Label htmlFor="headerKey" text='Header key *'/>
                <InputField
                    type='text'
                    id='headerKey'
                    name='headerKey'
                    value={item.headerKey.value}
                    required={item.headerKey.required}
                    error={headerKey.hasError}
                    onChange={onChangeCached}
                />
                <ErrorMessageField active={headerKey.hasError} message={headerKey.message}/>

                <Label htmlFor="headerKey" text='Header value *'/>
                <InputField
                    type='text'
                    id='headerValue'
                    name='headerValue'
                    value={item.headerValue.value}
                    required={item.headerValue.required}
                    error={headerValue.hasError}
                    onChange={onChangeCached}
                />
                <ErrorMessageField active={headerValue.hasError} message={headerValue.message}/>

                <AsideDropdownButtonSeparateLine
                    onClick={onChangeDropdownShowStatus(item.id, setJsonHeaders)}
                />
            </div>}
        </li>
    )
};

JsonHeaderMapped.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}
