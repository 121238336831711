import { toString } from 'lodash';

export class Console {
    static log(logTitle, logValue) {
        if (logValue) {
            return console.log(`${logTitle}:`, logValue);
        }
        return console.log(logTitle)
    }

    static group(value, groupName) {
        console.group(toString(groupName))
        console.log(value)
        console.groupEnd()
    }
    static groupCollapsed(value, groupName) {
        console.groupCollapsed(toString(groupName))
        console.log(value)
        console.groupEnd()
    }
    static table (value) {
        // array or object
        return console.table(value)
    }
}
