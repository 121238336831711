import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import { MonthDateSwitcher } from 'common/MonthDateSwitcher/MonthDateSwitcher';
import { chain, eq, forEach, indexOf, keys, map, split } from 'lodash';
import styles from 'components/Metrics/ChartMetrics.module.css';
import { CustomAreaChart } from 'components/Metrics/CustomAreaChart';
import { useTranslation } from 'react-i18next';
import { CustomLineChart } from 'components/Metrics/CustomLineChart';

const monthDesignation = ['MonthJanuary', 'MonthFebruary', 'MonthMarch', 'MonthApril', 'MonthMay', 'MonthJune', 'MonthJuly', 'MonthAugust', 'MonthSeptember', 'MonthOctober', 'MonthNovember', 'MonthDecember'];

const getNumberOfDate = date => chain(date).split('-').join('').toNumber().value();

export const ChartMetrics = ({ config, loading, monthMetrics }) => {
    const { title, tooltipParams, typeChart } = config;
    const { t: translator, i18n: { language } } = useTranslation();

    const [selectedMonth, setSelectedMonth] = useState('');
    const [monthsArray, setMonthsArray] = useState([]);
    const [monthsMap, setMonthsMap] = useState({});

    const handlerMonthTitle = monthsMap[selectedMonth];
    const chartData = monthMetrics[selectedMonth];

    const typesChart = {
        'AreaChart': CustomAreaChart,
        'LineChart': CustomLineChart
    };
    const Chart = typesChart[typeChart];

    useEffect(()=> {
        const monthsArray = keys(monthMetrics).sort((a,b) => getNumberOfDate(a) - getNumberOfDate(b));
        //set default selected month
        setSelectedMonth(monthsArray[monthsArray.length - 1]);
        setMonthsArray(monthsArray);
        //created map for month
        const monthsMap = {};
        forEach(monthsArray, item => {
            const [year, monthNumber] = split(item, '-');
            // monthNumber-1 because it is index for month
            monthsMap[item] = translator(`default:${monthDesignation[monthNumber-1]}`) + ` ${year}`
        });

        setMonthsMap(monthsMap)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthMetrics, language]);

    const handlerMonth = (arrow) => {
        const index = indexOf(monthsArray, selectedMonth);

        if (index > 0 && eq(arrow, 'left')) {
            setSelectedMonth(monthsArray[index - 1])
        }
        if (index !== monthsArray.length - 1 && eq(arrow, 'right')) {
            setSelectedMonth(monthsArray[index + 1])
        }
    };

    return (
        <div className={styles.metricsChart}>
            <div className={styles.metricsChartHeader}>
                <SkeletonWrapper isShow={!loading} width={310} height={16}>
                    <div>
                        <h2>{translator(`chartsTitle:${title}`)}</h2>
                        <MonthDateSwitcher months={handlerMonthTitle} handler={handlerMonth}/>
                    </div>
                </SkeletonWrapper>
                <SkeletonWrapper isShow={!loading} width={352} height={32}>
                    <div className={styles.metricsChartHeaderLegendWrapper}>
                        {map(tooltipParams.titles, ({ title , legendColor }, index) => (
                            <div key={`${title}-${index}`}>
                                <span className={styles.metricsChartHeaderLegendCircle} style={{background: legendColor}}/>
                                {tooltipParams.prefix? (
                                    <p>{translator(`metrics:${tooltipParams.prefix}`)} {title}</p>
                                ) : (
                                    <p>{translator(`metrics:${title}`)}</p>
                                )}
                            </div>
                        ))}
                    </div>
                </SkeletonWrapper>
            </div>
            <SkeletonWrapper isShow={!loading} height={400} width="100%">
                <div style={{ height: '400px' }}>
                    <Chart config={config} chartData={chartData}/>
                </div>
            </SkeletonWrapper>
        </div>
    );
};

ChartMetrics.propTypes = {
    config: PropTypes.shape({
        title: PropTypes.string,
        tooltipParams: PropTypes.shape({
            titles: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
            valueType: PropTypes.string,
            colorMarks: PropTypes.arrayOf(PropTypes.string),
            columnValueBlock: PropTypes.bool,
            prefix: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool
            ])
        }),
        nameXAxis: PropTypes.string,
        customYAxis: PropTypes.node,
        gradients: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            gradientColor: PropTypes.node
        })),
        areas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    }),
    loading: PropTypes.bool,
    monthMetrics: PropTypes.object
};
