import React, { useContext, useRef } from 'react';
import { eq } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import animation from 'animations/Pulse.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { defaultConnectionColor } from 'components/ScenarioSetup/ActionConnections/defaultStyles';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { ActionTooltipWindow } from 'components/ScenarioSetup/ActionIcons/sections/ActionTooltipWindow';
import { ActionTooltipWarnings } from 'components/ScenarioSetup/ActionIcons/sections/ActionTooltipWarnings';
import classNames from 'classnames/bind';
import { ActionIconsContext } from 'components/ScenarioSetup/ActionIcons/context/ActionIconsContext';

const cx = classNames.bind({ ...animation, ...styles });

export const DoubleChainActionIcon = ({
    id,
    actionType,
    title,
    isShowWarningActionMessage,
    isOpenActionInfo,
    actionIconConditions,
    classNameExtension,
    alternativeEditHandler
}) => {
    const dispatch = useDispatch();
    const idInput = id + '-input';
    const idOutput = id + '-output';
    const output = useSelector(store => store.scenariosActions.actionOutputCheckpoint);
    const sidebarActionId = useSelector(store => store.scenariosActions.actionSidebar.id);

    const context = useContext(ActionIconsContext);

    const {
        onRightClick,
        outputCheckpointHandler,
        actionIconStatusConfig,
        onDoubleClick,
        changeActionStatus,
        acceptInputConnection
    } = context;

    const connectionConfig_left = {
        id: idInput,
    };
    const connectionConfig_right = {
        id: idOutput,
        disableInputButton: idInput,
        color: defaultConnectionColor,
    };

    const wrappedRef = useRef(null);

    useOutsideAlerter(wrappedRef, changeActionStatus(dispatch, id));

    const { icon, iconProps } = actionIconStatusConfig(actionIconConditions, isOpenActionInfo, id, sidebarActionId, isShowWarningActionMessage);
    const ActionIcon = icon;

    const isActiveInputDot = output && !eq(output.disableInputButton, idInput);
    const isActiveOutputDot = output && eq(output.id, idOutput);

    return (
        <ActionIconsContext.Provider value={{
            ...context,
            isOpenActionInfo,
            isShowWarningActionMessage,
            wrappedRef,
            id,
            title,
            actionType,
            alternativeEditHandler,
        }}>
            <div className={cx(styles.action, styles.actionDoubleChain, { [classNameExtension]: classNameExtension } )}>
                <div id={idInput}
                     onClick={acceptInputConnection(dispatch, connectionConfig_left)}
                     className={cx(styles.actionInputDot, { 'pulse': isActiveInputDot, 'active': isActiveInputDot })}
                />
                <div onContextMenu={onRightClick(id, dispatch, isShowWarningActionMessage)} onDoubleClick={onDoubleClick(dispatch, actionType, id, alternativeEditHandler)}>
                    <ActionIcon children={icon} { ...iconProps }/>
                </div>
                <div id={idOutput}
                     onClick={outputCheckpointHandler(output, idOutput, connectionConfig_right, dispatch)}
                     className={cx(styles.actionOutputDot, { 'active': isActiveOutputDot })}
                />

                <ActionTooltipWindow/>

                <ActionTooltipWarnings/>
            </div>
        </ActionIconsContext.Provider>
    );
};

DoubleChainActionIcon.propTypes = {
    id: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isShowWarningActionMessage: PropTypes.bool.isRequired,
    isOpenActionInfo: PropTypes.bool.isRequired,
    actionIconConditions: PropTypes.object.isRequired,
    alternativeEditHandler: PropTypes.func,
    classNameExtension: PropTypes.string
}
