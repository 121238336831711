import React from 'react';

export const MobilePushIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V43C0.5 45.4853 2.51472 47.5 5 47.5H43C45.4853 47.5 47.5 45.4853 47.5 43V5C47.5 2.51472 45.4853 0.5 43 0.5Z" fill={bgColor}/>
        <path d="M32.1799 38.5H15.8209C14.2979 38.5 13.2749 37.667 13.2749 36.427V11.573C13.2749 10.333 14.2979 9.5 15.8209 9.5H32.1799C33.7029 9.5 34.7249 10.333 34.7249 11.573V36.584C34.7249 37.641 33.5829 38.5 32.1799 38.5ZM15.8209 10.5C15.0739 10.5 14.2749 10.782 14.2749 11.573V36.427C14.2749 37.218 15.0749 37.5 15.8209 37.5H32.1799C33.0639 37.5 33.7249 37.017 33.7249 36.584V11.573C33.7249 10.782 32.9249 10.5 32.1799 10.5H15.8209Z" fill={color}/>
        <path d="M24 29.691C18.084 29.691 17.912 28.827 17.847 28.502C17.8175 28.1819 17.8686 27.8594 17.9955 27.5641C18.1225 27.2687 18.3214 27.0099 18.574 26.811C18.7349 26.6572 18.8751 26.4831 18.991 26.293C19.1577 25.9508 19.2945 25.5948 19.4 25.229C19.311 24.599 18.987 21.603 20.548 19.752C20.9768 19.2661 21.5103 18.8838 22.1082 18.6338C22.7061 18.3839 23.3529 18.2728 24 18.309C24.6562 18.2769 25.3114 18.3898 25.9191 18.6395C26.5268 18.8893 27.072 19.2698 27.516 19.754C29.309 21.779 28.916 25.176 28.901 25.319C28.8971 25.742 28.9766 26.1617 29.135 26.554C29.2238 26.67 29.3244 26.7766 29.435 26.872C29.6942 27.0503 29.8983 27.2975 30.0243 27.5857C30.1503 27.874 30.1931 28.1917 30.148 28.503C30.018 28.862 29.687 29.691 24 29.691ZM18.821 28.208C20.5185 28.5965 22.2599 28.7589 24 28.691C25.7421 28.7633 27.4858 28.595 29.182 28.191C29.173 28.01 29.041 27.869 28.762 27.61C28.5891 27.4628 28.4349 27.2949 28.303 27.11C28.0231 26.5347 27.8859 25.9005 27.903 25.261C27.91 25.17 28.258 22.101 26.763 20.417C26.4115 20.0415 25.9819 19.7477 25.5045 19.5563C25.0271 19.3648 24.5135 19.2804 24 19.309C23.4992 19.2768 22.9976 19.3583 22.5326 19.5472C22.0677 19.7361 21.6514 20.0276 21.315 20.4C19.899 22.074 20.398 25.15 20.404 25.18L20.421 25.28L20.396 25.38C20.2693 25.8482 20.0982 26.3033 19.885 26.739C19.7289 27.0183 19.5325 27.273 19.302 27.495C19 27.82 18.833 28.017 18.821 28.208V28.208Z" fill={color}/>
        <path d="M23.9999 32.522C23.5789 32.538 23.1591 32.4669 22.7668 32.3131C22.3746 32.1593 22.0183 31.9262 21.7203 31.6283C21.4224 31.3304 21.1891 30.9742 21.0352 30.582C20.8813 30.1898 20.81 29.77 20.8259 29.349H21.3259H21.8259C21.8145 29.6375 21.8629 29.9252 21.9682 30.1941C22.0734 30.463 22.2331 30.7072 22.4373 30.9113C22.6415 31.1154 22.8858 31.275 23.1547 31.3801C23.4236 31.4852 23.7114 31.5335 23.9999 31.522C24.2993 31.5478 24.6008 31.5105 24.8848 31.4124C25.1689 31.3143 25.4292 31.1577 25.6489 30.9527C25.8686 30.7477 26.0429 30.4988 26.1603 30.2222C26.2778 29.9456 26.3359 29.6475 26.3309 29.347H27.3309C27.3386 29.7795 27.2565 30.2088 27.0898 30.6079C26.923 31.007 26.6753 31.3671 26.3622 31.6655C26.0491 31.9639 25.6775 32.1941 25.2709 32.3416C24.8643 32.489 24.4315 32.5504 23.9999 32.522V32.522Z" fill={color}/>
        <path d="M26.223 18.892L25.237 18.728C25.366 18.0625 25.2532 17.3728 24.919 16.783C24.8233 16.6784 24.7051 16.597 24.5732 16.5451C24.4413 16.4931 24.2993 16.4718 24.158 16.483C23.9902 16.4727 23.8222 16.4984 23.6651 16.5582C23.508 16.618 23.3655 16.7107 23.247 16.83C22.901 17.3037 22.7319 17.8836 22.769 18.469L21.769 18.53C21.7197 17.6717 21.9849 16.8248 22.515 16.148C22.7258 15.9272 22.9811 15.7537 23.264 15.639C23.5469 15.5244 23.851 15.4712 24.156 15.483C24.4435 15.4722 24.7298 15.5259 24.9938 15.6401C25.2579 15.7543 25.493 15.9261 25.682 16.143C26.207 16.9565 26.4006 17.9402 26.223 18.892V18.892Z" fill={color}/>
    </svg>
);

