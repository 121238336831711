import { useRef, useState } from 'react';
import { useToggle } from 'hooks/useToggle';
import { isFunction, isString, includes } from 'lodash';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

const defaultProps = {
    isNeedOutsideAlerter: true,
    isOutsideAlerterNeedCallback: false,
    outsideAlerterCallback: null,
    outsideAlerterExceptionModalsTypes: [],
    defaultSelectedModalOption: '',
    modalOptionsList: [],
    onChangeModalOptionCallback: () => {},
};

export const useModal = ({
    isNeedOutsideAlerter,
    isOutsideAlerterNeedCallback,
    outsideAlerterCallback,
    outsideAlerterExceptionModalsTypes,
    defaultSelectedModalOption,
    modalOptionsList,
    onChangeModalOptionCallback,
} = defaultProps) => {

    const ref = useRef(null);
    const [ isToggle, setToggle ] = useToggle(false);
    const [ modalInputValue, setModalInputValue ] = useState('');
    const [ modalErrorMessage, setModalErrorMessage ] = useState('');
    const [ modalType, setModalType ] = useState(null);
    const [ modalOption, setModalOption ] = useState(defaultSelectedModalOption);

    const setShowModal = () => setToggle(true);

    const setHideModal = () => {
        if (modalErrorMessage.length) setModalErrorMessage('');
        if (modalInputValue.length) setModalInputValue('');

        setToggle(false);
    };

    const onClickShowTypedModal = type => {
        return () => new Promise(resolve => {
            if (isString(type)) setModalType(type)

            return resolve(type)
        })
            .then(type => isString(type)
                ? setToggle(true)
                : null
            )

    };

    const onCloseModalPromise = callback => {
        return () => new Promise(resolve => {
            setToggle(false);
            setModalInputValue('');
            setModalErrorMessage('');
            return resolve(callback)
        })
            .then(callback => isFunction(callback)
                ? callback()
                : null
            )
    };

    const onAcceptModalPromise = callback => {
        return () => new Promise(resolve => {
            setToggle(false);
            setModalInputValue('');
            return resolve(callback)
        })
            .then(callback => isFunction(callback)
                ? callback()
                : null
            )
    };

    const sendModalErrorMessage = message => setModalErrorMessage(message);

    const onChangeModalInput = event => {
        if (modalErrorMessage.length) {
            sendModalErrorMessage('');
        }

        setModalInputValue(event.target.value);
    };

    const onChangeModalOption = option => {
        new Promise(resolve => {
            setModalOption(option);

            return resolve(option)
        })
            .then(option => isFunction(onChangeModalOptionCallback)
                ? onChangeModalOptionCallback(option)
                : null
            )
    };

    useOutsideAlerter(ref, isNeedOutsideAlerter || includes(outsideAlerterExceptionModalsTypes, modalType)
        ? isOutsideAlerterNeedCallback
            ? onCloseModalPromise(outsideAlerterCallback)
            : setHideModal
        : () => {}
    );

    return {
        ref,
        isToggle,
        modalType,
        setShowModal,
        setHideModal,
        onClickShowTypedModal,
        onCloseModalPromise,
        onAcceptModalPromise,
        modalInputValue,
        onChangeModalInput,
        modalErrorMessage,
        sendModalErrorMessage,
        modalOption,
        modalOptionsList,
        onChangeModalOption
    }
};