import React from 'react';

export const TrashIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2396 2.76028C15.1772 2.69793 15.0973 2.66659 15.0001 2.66659H11.7812L11.0521 0.9271C10.9479 0.670095 10.7604 0.451327 10.4897 0.27076C10.2188 0.0902289 9.94432 0 9.66663 0H6.33319C6.0555 0 5.78113 0.0902289 5.5103 0.27076C5.23943 0.451327 5.05193 0.670058 4.94773 0.9271L4.21856 2.66659H0.9998C0.902493 2.66659 0.822735 2.69793 0.760199 2.76028C0.697845 2.82278 0.666504 2.90269 0.666504 2.99988V3.66662C0.666504 3.76382 0.697699 3.84372 0.760199 3.90611C0.822735 3.96858 0.902639 3.99981 0.9998 3.99981H1.99972V13.9166C1.99972 14.4932 2.16292 14.9844 2.48929 15.3905C2.81573 15.797 3.20809 16 3.66646 16H12.3333C12.7918 16 13.1841 15.79 13.5105 15.3699C13.8369 14.9496 14 14.4514 14 13.8751V3.99981H15.0001C15.0972 3.99981 15.1772 3.96858 15.2395 3.90611C15.3019 3.84376 15.3333 3.76382 15.3333 3.66662V2.99988C15.3334 2.90269 15.3019 2.82278 15.2396 2.76028ZM6.177 1.44768C6.22556 1.38521 6.28463 1.3469 6.35406 1.33311H9.65616C9.72559 1.34701 9.78477 1.38529 9.83326 1.44768L10.3334 2.66644H5.66657L6.177 1.44768ZM12.6668 13.8752C12.6668 14.0279 12.6425 14.1686 12.5938 14.297C12.5452 14.4253 12.4947 14.5191 12.4427 14.5781C12.3905 14.6373 12.3543 14.6667 12.3334 14.6667H3.6665C3.64566 14.6667 3.6094 14.6373 3.55719 14.5781C3.50505 14.5191 3.45466 14.4253 3.40603 14.297C3.35746 14.1686 3.33316 14.0277 3.33316 13.8752V3.99981H12.6667L12.6668 13.8752Z" fill={color}/>
            <path d="M5.00004 12.6667H5.66667C5.76398 12.6667 5.84392 12.6355 5.90627 12.5731C5.96863 12.5104 5.99997 12.4307 5.99997 12.3333V6.33314C5.99997 6.23598 5.96863 6.15608 5.90627 6.09354C5.84374 6.03119 5.76402 5.99995 5.66667 5.99995H5.00004C4.90274 5.99995 4.82294 6.03115 4.76044 6.09354C4.69802 6.15608 4.66675 6.23598 4.66675 6.33314V12.3333C4.66675 12.4307 4.69791 12.5104 4.76044 12.5731C4.82294 12.6355 4.90274 12.6667 5.00004 12.6667Z" fill={color}/>
            <path d="M7.66655 12.6667H8.33318C8.43045 12.6667 8.51035 12.6355 8.57267 12.5731C8.63535 12.5104 8.6664 12.4307 8.6664 12.3333V6.33314C8.6664 6.23598 8.63532 6.15608 8.57267 6.09354C8.51035 6.03119 8.43045 5.99995 8.33318 5.99995H7.66655C7.56935 5.99995 7.48945 6.03115 7.42695 6.09354C7.36441 6.15608 7.33325 6.23598 7.33325 6.33314V12.3333C7.33325 12.4307 7.36441 12.5104 7.42695 12.5731C7.48941 12.6355 7.56935 12.6667 7.66655 12.6667Z" fill={color}/>
            <path d="M10.3334 12.6667H10.9999C11.0973 12.6667 11.1772 12.6355 11.2396 12.5731C11.302 12.5104 11.3331 12.4307 11.3331 12.3333V6.33314C11.3331 6.23598 11.302 6.15608 11.2396 6.09354C11.1772 6.03119 11.0973 5.99995 10.9999 5.99995H10.3334C10.236 5.99995 10.1563 6.03115 10.0936 6.09354C10.0312 6.15608 10 6.23598 10 6.33314V12.3333C10 12.4307 10.0312 12.5104 10.0936 12.5731C10.1563 12.6355 10.236 12.6667 10.3334 12.6667Z" fill={color}/>
        </svg>
    )
};
