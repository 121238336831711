import React, { useEffect } from 'react';
import styles from 'components/ScenarioSetup/ScenarioSetup.module.css';
import { isEmpty, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams, useLocation, useNavigate } from 'react-router-dom';
import { ScenarioMetricsPage } from 'pages/Communications/ScenarioSetupPage/ScenarioMetricsPage';
import { ScenarioSetupPage } from 'pages/Communications/ScenarioSetupPage/ScenarioSetupPage';
import { ScenarioSetupHeader } from 'components/Headers/ScenarioSetupHeader';
import { clearCurrentScenarioData, getScenario } from 'store/actions/scenarios.action';
import { clearScenarioActionsData, setActionsChainList, setValidatedActionsChainList, toggleSideBar } from 'store/actions/scenariosActions.action';
import { actionChainDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/actionChainDecompiler';
import { ActionTemplatesPage } from 'pages/Communications/ActionTemplatePages/ActionTemplatesPage';
import { ActionEditTemplatePage } from 'pages/Communications/ActionTemplatePages/ActionEditTemplatePage';
import { useComponentUnmount } from 'hooks/useComponentUnmount';
import { getSegmentsList } from 'store/actions/segments.action';
import { getFrequencyPoliciesList } from 'store/actions/frequencyPolicies.action';

export const ScenarioSetupLayout = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { currentScenario: scenario } = useSelector(store => store.scenarios);
    const { currentScenarioId, actionsList } = useSelector(store => store.scenariosActions);

    const redirectCallback = () => navigate(`/communications/goal/${params.goal}`);

    const toggleActionSidebar = () => dispatch(toggleSideBar());

    const renderActionsList = (scenario, id, actionsList) => {
        if (!isEmpty(scenario)) {
            const data = actionChainDecompiler(scenario);

            if (id && actionsList.length) {
                return dispatch(setValidatedActionsChainList({ actions: actionsList, arrows: data.arrows }));
            }

            return dispatch(setActionsChainList(data));
        }
    };

    useEffect(() => {
        dispatch(getScenario(params?.id, redirectCallback));

        return () => {
            dispatch(clearCurrentScenarioData())
            dispatch(clearScenarioActionsData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    useEffect(() => {
        dispatch(getSegmentsList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenario]);

    useEffect(() => {
        dispatch(getFrequencyPoliciesList())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        renderActionsList(scenario, currentScenarioId, actionsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenario]);

    useComponentUnmount(toggleActionSidebar);

    const isTemplates = includes(pathname,'/templates');

    return (
        <div className={styles.scenarioSetupLayout}>
            {!isTemplates && <ScenarioSetupHeader />}

            <Routes>
                <Route path='/*' element={ <ScenarioSetupPage/> }/>
                <Route path='metrics' element={ <ScenarioMetricsPage/> }/>
                <Route path='templates' element={ <ActionTemplatesPage/> }/>
                <Route path='templates/edit-template/*' element={ <ActionEditTemplatePage/> }/>
            </Routes>
        </div>
    )
};
