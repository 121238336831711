import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/TagsInput/TagsInput.module.css';
import { eq, map } from 'lodash';
import { CrossButtonIcon } from 'assets/icons/CrossButtonIcon';
import { InputCloseIcon } from 'assets/icons/InputCloseIcon';
import { InputOpenIcon } from 'assets/icons/InputOpenIcon';
import classNames from 'classnames/bind';
import { ArrayUse } from 'lib/ArrayUse';

const cx = classNames.bind(styles);

export const TagsInput = ({ values, name, updateCallback, error, withoutErrorField }) => {
    const [tags, setTags] = useState(values);
    const [inputValue, setInputValue] = useState('');
    const [isOpenInput, setIsOpenInput] = useState(true);
    const [isUserEditExistValue, setIsUserEditExistValue] = useState({
        isEdit: false,
        editedValue: null,
        index: null
    });

    const inputOpenHandler = setState => () => {
        if (tags.length) return setState(prev => !prev)
    };

    const handleInputChange = setTags => event => setTags(event.target.value);

    const addValue = (tags, inputValue, setInputValue) => event => {
        const { isEdit, index } = isUserEditExistValue;

        if (isEdit && eq(event.key, 'Enter') && inputValue) {
            updateCallback(ArrayUse.replace(tags, index, inputValue));
            setIsUserEditExistValue({ isEdit: false, editedValue: null, index: null });

            return setInputValue('');
        }

        if (eq(event.key, 'Enter') && inputValue) {
            updateCallback([...tags, inputValue]);

            return setInputValue('');
        }
    };

    const editValue = (setIsUserEditExistValue, tag, isOpenInput, tagIndex) => () => {
        if (isOpenInput) return setIsUserEditExistValue({ isEdit: true, editedValue: tag, index: tagIndex });
    };

    const removeValue = (setTags, removedValue, tags) => () => {
        const array = ArrayUse.remove(tags, removedValue);
        setTags(array);

        return updateCallback(array);
    };

    useEffect(() => {
        if (isUserEditExistValue.isEdit) setInputValue(isUserEditExistValue.editedValue);
    }, [isUserEditExistValue]);

    useEffect(() => setTags(values), [values])

    return (
        <div id={name}
             className={cx(styles.tagsInputContainer, { 'open': isOpenInput, error: error, 'withoutErrorField': withoutErrorField })}
        >
            {map(tags, (currentTag, i) =>
                <div className={styles.tagContainer} key={currentTag + i}>
                    <p onClick={editValue(setIsUserEditExistValue, currentTag, isOpenInput, i)}>
                        {currentTag}
                    </p>
                    <div onClick={removeValue(setTags, currentTag, tags)}
                         className={styles.removeTagButton}>
                        <CrossButtonIcon color={'#FFFFFF'}/>
                    </div>
                </div>
            )}

            <input type='text'
                   name={name}
                   className={cx(styles.tagInput, { 'tagInputEmpty': !tags.length })}
                   placeholder='Add..'
                   value={inputValue}
                   onChange={handleInputChange(setInputValue)}
                   onKeyDown={addValue(tags, inputValue, setInputValue)}
            />

            <div
                onClick={inputOpenHandler(setIsOpenInput, tags)}
                className={styles.tagInputOpenButton}
            >
                {isOpenInput ? <InputCloseIcon/> : <InputOpenIcon/>}
            </div>
        </div>
    )
}
TagsInput.propTypes = {
    values: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    updateCallback: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    withoutErrorField: PropTypes.bool
}
TagsInput.defaultProps = {
    values: [],
    name: 'TagInput',
    error: false,
    withoutErrorField: false
}
