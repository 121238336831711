import React, { useEffect } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';
import PropTypes from 'prop-types';
import { eq, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviewTemplate } from 'store/actions/templates.action';
import { TemplatePreviewViber } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewViber';
import { TemplatePreviewDefault } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewDefault';
import { TemplatePreview } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview';
import { TemplatePreviewSms } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewSms';
import { TemplatePreviewMobilePush } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewMobilePush';
import { TemplatePreviewWebPush } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewWebPush';
import { TemplatePreviewWebsiteBuilder } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreviewWebsiteBuilder';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { CommonMethods } from 'lib/CommonMethods';

export const TemplatePreviewWrapper = ({ selectTemplateCallback, sideBarType, templateUniqueId, errors }) => {
    const dispatch = useDispatch();

    const templatePreviewObject = useSelector(store => store.templates.previewTemplate);

    useEffect(() => {
        if (templateUniqueId) {
            dispatch(getPreviewTemplate(templateUniqueId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateUniqueId]);


    if (templateUniqueId && eq(sideBarType, 'WebsiteBuilder') && templatePreviewObject?.jinja_fields) {
        const jinjaParamsValue = find(templatePreviewObject?.jinja_fields, { jinja_field_name: 'jinja_params' })?.value;

        const getPreviewImage = () => {
            if (jinjaParamsValue) {
                const { image } = JSON.parse(jinjaParamsValue);

                return image?.url
            }
        }

        return <TemplatePreviewWebsiteBuilder
            onClickCallback={selectTemplateCallback}
            templateName={templatePreviewObject?.template_name}
            imageSource={getPreviewImage()}
        />
    }

    if (templateUniqueId && eq(sideBarType, 'SendViber') && templatePreviewObject?.jinja_fields && templatePreviewObject?.preview_body) {
        const jinjaImageObject = find(templatePreviewObject?.jinja_fields, { jinja_field_name: 'jinja_image_url' });
        const jinjaButtonObject = find(templatePreviewObject?.jinja_fields, { jinja_field_name: 'jinja_button_text' });
        const jinjaBodyObject = find(templatePreviewObject?.jinja_fields, { jinja_field_name: 'jinja_body' });
        const preview = jinjaBodyObject && CommonMethods.renderPreviewViberTemplate(jinjaBodyObject.value);

        return <TemplatePreviewViber
            onClickCallback={selectTemplateCallback}
            preview={preview}
            imageSource={jinjaImageObject?.value}
            buttonText={jinjaButtonObject?.value}
            templateName={templatePreviewObject?.template_name}
        />;
    }

    if (templateUniqueId && eq(sideBarType, 'SendSms')) {
        const jinjaBodyObject = find(templatePreviewObject?.jinja_fields, { jinja_field_name: 'jinja_body' });
        const preview = jinjaBodyObject && CommonMethods.renderPreviewViberTemplate(jinjaBodyObject.value);

        return <TemplatePreviewSms
            onClickCallback={selectTemplateCallback}
            templateName={templatePreviewObject?.template_name}
            message={preview}
        />
    }

    if (templateUniqueId && eq(sideBarType, 'SendMobPush')) {
        return <TemplatePreviewMobilePush
            onClickCallback={selectTemplateCallback}
            templateName={templatePreviewObject?.template_name}
            jinja_fields={templatePreviewObject?.jinja_fields}
        />
    }

    if (templateUniqueId && eq(sideBarType, 'SendWebPush')) {
        return <TemplatePreviewWebPush
            onClickCallback={selectTemplateCallback}
            templateName={templatePreviewObject?.template_name}
            jinja_fields={templatePreviewObject?.jinja_fields}
        />
    }

    if (templateUniqueId) {
        return <TemplatePreview
            onClickCallback={selectTemplateCallback}
            previewImageUrl={templatePreviewObject?.preview_image_url}
            templateName={templatePreviewObject?.template_name}
        />
    }

    return (
        <>
            <TemplatePreviewDefault onClickCallback={selectTemplateCallback}/>
            <ErrorMessageField
                active={!!errors.templateUniqueId}
                message={errors.templateUniqueId}
                className={styles.templatePreviewDefaultErrorMessageField}
            />
        </>
    )
};
TemplatePreviewWrapper.propTypes = {
    selectTemplateCallback: PropTypes.func.isRequired,
    sideBarType: PropTypes.string.isRequired,
    templateUniqueId: PropTypes.string,
    errors: PropTypes.object
};
