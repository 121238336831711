export const defaultPushContentSettings = () => ({
    values: {
        communicationsMaxCount: {
            value: '1',
            required: true
        },
        countTimeIntervalNumber: {
            value: '1',
            required: true
        },
        countTimeIntervalType: {
            value: 'days',
            required: true
        },
        templateCache: false,
        policyId: {
            value: '50c8b84a-d1fc-468d-bf21-e4ddc5514131' // this is default id of communication policy, change if id changed on back-end
        }
    },
    minDispatchTime: '08:00',
    maxDispatchTime: '20:00',
    templateUniqueId: '',
});
