import React from 'react';

export const TailoredWaitIconAlt = ({ borderColor }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="47.5" y="0.5" width="47" height="47" rx="4.5" transform="rotate(90 47.5 0.5)" fill="white" stroke={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 15.3333C24.2762 15.3333 24.5001 15.5572 24.5001 15.8333V24C24.5001 24.2761 24.2762 24.5 24.0001 24.5H18.7501C18.4739 24.5 18.2501 24.2761 18.2501 24C18.2501 23.7239 18.4739 23.5 18.7501 23.5H23.5001V15.8333C23.5001 15.5572 23.7239 15.3333 24.0001 15.3333Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.4167 27C30.6929 27 30.9167 27.2239 30.9167 27.5V28.75H32.7501C33.0262 28.75 33.2501 28.9739 33.2501 29.25C33.2501 29.5261 33.0262 29.75 32.7501 29.75H30.4167C30.1406 29.75 29.9167 29.5261 29.9167 29.25V27.5C29.9167 27.2239 30.1406 27 30.4167 27Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9172 15.2956C16.1184 12.6389 19.4636 10.6667 24.0001 10.6667C30.0428 10.6667 33.8376 14.5594 35.7501 17.4729V14.0833C35.7501 13.8072 35.9739 13.5833 36.2501 13.5833C36.5262 13.5833 36.7501 13.8072 36.7501 14.0833V19.3333C36.7501 19.6095 36.5262 19.8333 36.2501 19.8333H30.4167C30.1406 19.8333 29.9167 19.6095 29.9167 19.3333C29.9167 19.0572 30.1406 18.8333 30.4167 18.8333H35.414C33.8149 16.0467 30.1446 11.6667 24.0001 11.6667C19.7865 11.6667 16.7151 13.4861 14.6872 15.9336C12.6477 18.395 11.6667 21.4902 11.6667 24C11.6667 26.5097 12.6477 29.605 14.6872 32.0664C16.7151 34.5139 19.7865 36.3333 24.0001 36.3333C24.2762 36.3333 24.5001 36.5572 24.5001 36.8333C24.5001 37.1095 24.2762 37.3333 24.0001 37.3333C19.4636 37.3333 16.1184 35.3611 13.9172 32.7044C11.7275 30.0617 10.6667 26.7402 10.6667 24C10.6667 21.2597 11.7275 17.9383 13.9172 15.2956Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.4167 33.5C32.764 33.5 34.6667 31.5972 34.6667 29.25C34.6667 26.9028 32.764 25 30.4167 25C28.0695 25 26.1667 26.9028 26.1667 29.25C26.1667 31.5972 28.0695 33.5 30.4167 33.5ZM30.4167 34.5C33.3162 34.5 35.6667 32.1495 35.6667 29.25C35.6667 26.3505 33.3162 24 30.4167 24C27.5173 24 25.1667 26.3505 25.1667 29.25C25.1667 32.1495 27.5173 34.5 30.4167 34.5Z" fill={borderColor}/>
    </svg>
);
