import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-nice-dates/build/style.css';
import 'components/Calendars/Calendars.css';
import { format } from 'date-fns';
import { ru, enGB } from 'date-fns/locale';
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates';
import { TransparentButton } from 'common/Buttons/TransparentButton/TransparentButton';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { DateUse } from 'lib/DateUse';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

export const CustomDateRangePickerCalendar = ({ closeCalendarCallback, start, end }) => {
    const dispatch = useDispatch();
    const { t: translator, i18n } = useTranslation();

    const RangeOfDateRef = useRef(null);

    const [startDate, setStartDate] = useState(start && new Date(DateUse.checkValidFormatYearMonthDay(start)));
    const [endDate, setEndDate] = useState(end && new Date(DateUse.checkValidFormatYearMonthDay(end)));
    const [focus, setFocus] = useState(START_DATE);

    const selectLocale = {
        ru: ru,
        en: enGB
    };

    const onFocusChange = newFocus => setFocus(newFocus || START_DATE);

    useOutsideAlerter(RangeOfDateRef, () => dispatch(closeCalendarCallback(startDate, endDate)));

    return (
        <div className="custom-date-picker-calendar-container" ref={RangeOfDateRef}>
            <DateRangePickerCalendar
                startDate={startDate}
                endDate={endDate}
                focus={focus}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                onFocusChange={onFocusChange}
                locale={selectLocale[i18n.language]}
                month={null}
                weekdayFormat="EEEEEE"
            />
            <div className="custom-date-picker-calendar-footer">
                <div className="custom-date-picker-interval-container">
                    <p>
                        <span>{startDate && format(startDate, 'dd/MM/yyyy', { locale: selectLocale[i18n.language] })}</span>
                        <span>{endDate && ' - ' + format(endDate, 'dd/MM/yyyy', { locale: selectLocale[i18n.language] })}</span>
                    </p>
                    <CalendarIcon/>
                </div>

                <TransparentButton
                    callback={() => dispatch(closeCalendarCallback(startDate, endDate))}
                    name={translator('default:SaveButtonTitle')}
                    height={35}
                    width={100}
                    extensionClass="button-green-text"
                />
            </div>
        </div>
    );
};
CustomDateRangePickerCalendar.propTypes = {
    closeCalendarCallback: PropTypes.func.isRequired,
    start: PropTypes.string,
    end: PropTypes.string
};
