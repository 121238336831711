import React from 'react';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import { TripleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/TripleChainActionIcon';
import { ConditionalSplitIconDropped } from 'assets/icons/ConditionalSplitIconDropped';
import { ConditionalSplitIconAlt } from 'assets/icons/ConditionalSplitIconAlt';

export const ConditionalSplit = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const classNameExtension = styles.actionCanted
    const alternativeDotsColor = {
        top: styles.green,
        right: null,
        bottom: styles.red
    }

    const actionIconConditions = {
        base: {
            icon: ConditionalSplitIconDropped,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#00C6A2'
            }
        },
        opened: {
            icon: ConditionalSplitIconAlt,
            iconProps: { borderColor: '#00C6A2' }
        },
        warning: {
            icon: ConditionalSplitIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <TripleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
        classNameExtension,
        alternativeDotsColor
    }} />
}
