import React from 'react';
import styles from 'common/Headers/Headers.module.css';
import animations from 'animations/FadeIn.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import { CloseButton } from 'common/_Buttons/_CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';
import { ShareButton } from 'common/_Buttons/_ShareButton/ShareButton';

const cx = classNames.bind(styles);

export const DashboardHeaderButtons = ({
    onClickCancelChangesButton,
    onClickSaveChangesButton,
    onClickCloseButton,
    onClickShareButton,
    isDisabledHeaderButtons,
}) => {
    const { t: translator } = useTranslation();

    return (
        <div className={cx(styles.dashboardHeaderButtonsContainer, animations.fadeIn200ms)}>

            <ShareButton
                onClick={onClickShareButton}
            />

            {onClickCancelChangesButton &&
                <GreenButton
                    name={translator('dashboardHeader:CancelChangesButton')}
                    onClick={onClickCancelChangesButton}
                    disabled={isDisabledHeaderButtons}
                    className={cx(styles.dashboardHeaderButton, styles.dashboardHeaderButtonLarge)}
                />
            }

            {onClickSaveChangesButton &&
                <GreenButton
                    name={translator('dashboardHeader:SaveChangesButton')}
                    onClick={onClickSaveChangesButton}
                    disabled={isDisabledHeaderButtons}
                    className={styles.dashboardHeaderButton}
                />
            }

            <CloseButton
                color='#00c6a2'
                onClick={onClickCloseButton}
            />

        </div>
    )
}
DashboardHeaderButtons.propTypes = {
    onClickCancelChangesButton: PropTypes.func,
    onClickSaveChangesButton: PropTypes.func,
    onClickCloseButton: PropTypes.func.isRequired,
    onClickShareButton: PropTypes.func.isRequired,
    isDisabledHeaderButtons: PropTypes.bool,
};
DashboardHeaderButtons.defaultProps = {
    isDisabledHeaderButtons: false,
    onClickCancelChangesButton: null,
    onClickSaveChangesButton: null,
};
