import React, { useEffect } from 'react';
import 'assets/global/modal.css';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import Cookies from 'js-cookie';
import { getRoutes } from 'routes';
import { MainHeader } from 'components/MainHeader/MainHeader';
import { LeftSideBar } from 'components/MainSidebar/MainSidebar';
import { makeAuth } from 'store/actions/auth.action';
import { DateUse } from 'lib/DateUse';
import { ErrorBoundary } from 'react-error-boundary'
import { CatchErrorBoundary } from 'common/_CatchErrorBoundary/CatchErrorBoundary';
import { ToastProvider } from 'components/_Toast/ToastProvider';
import { Hotjar } from 'components/_Hotjar/Hotjar';

const getAuthDataFromCookies = cookiesJson => {
    const defaultCookiesObject = { cookies: null }

    if (cookiesJson) {
        const cookies = JSON.parse(cookiesJson);

        if (DateUse.isDateExpired(cookies?.tokenExpireDate)) return defaultCookiesObject

        return { cookies }
    }

    return defaultCookiesObject
};

const App = () => {
    const dispatch = useDispatch();
    const { cookies } = getAuthDataFromCookies(Cookies.get('auth'));
    const { isAuthenticated, user } = useSelector(store => store.auth);
    // const { i18n } = useTranslation();
    const routes = getRoutes(isAuthenticated);

    const isEnableHotjar = !includes(user.username, '_apiuser');

    useEffect(() => {
        if (cookies) {
            return dispatch(makeAuth(cookies));
        }
        console.log('[Client Auth]: Auth token not found, need authorization');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAuthenticated && isEnableHotjar && window.hj) {
            window.hj('identify', user.username, {
                username: user.username,
                retailer_name: user.retailerName,
                user_given_name: user.userGivenName,
                retailer_Currency: user.retailerCurrency,
                domain: window.location.hostname
            });
        }
    }, [isAuthenticated, isEnableHotjar, user]);

    return (
        isAuthenticated
            ? <ErrorBoundary
                FallbackComponent={props =>
                    <CatchErrorBoundary
                        error={props.error}
                        resetErrorBoundary={props.resetErrorBoundary}
                    />
                }
                onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                }}
            >
                <ToastProvider>
                    <React.Fragment>
                        {isEnableHotjar &&
                            <Hotjar/>
                        }
                        <div className={'app app-authorized'}>
                            <LeftSideBar/>
                            <section className='main-container'>
                                <MainHeader/>
                                {routes}
                            </section>
                        </div>
                    </React.Fragment>
                </ToastProvider>
            </ErrorBoundary>
            : <ErrorBoundary
                FallbackComponent={props =>
                    <CatchErrorBoundary
                        error={props.error}
                        resetErrorBoundary={props.resetErrorBoundary}
                    />
                }
                onReset={() => {
                    // reset the state of your app so the error doesn't happen again
                }}
            >
                <div className={'app'}>
                    <MainHeader/>
                    {routes}
                </div>
            </ErrorBoundary>
    )
};

export default App;
