import React from 'react';
import styles from './OperatedElementsList.module.css';
import PropTypes from 'prop-types';
import { map, eq } from 'lodash';
import { NavLink } from 'react-router-dom';
import { CircleLoaderSmall } from 'common/_CircleLoaderSmall/CircleLoaderSmall';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const OperatedElementsList = ({
    label,
    actionButtonName,
    onClickActionButton,
    elementsArray,
    elementsIcon,
    elementsArrayNotExistsText,
    elementSelectedId,
    elementRedirectPath,
    isLoading,
}) => (
    <div className={styles.container}>
        <div className={styles.header}>
            <h6>{label}</h6>
            <p onClick={onClickActionButton}>
                {actionButtonName}
            </p>
        </div>

        {isLoading
            ? <div className={styles.loaderContainer}>
                <CircleLoaderSmall/>
            </div>
            : !!elementsArray.length
                ? <div className={styles.elementListContainer}>
                    {map(elementsArray, element =>
                        <NavLink
                            to={elementRedirectPath + element.id}
                            key={element.id}
                            className={cx(styles.elementRowContainer, { 'selected': eq(elementSelectedId, element.id) })}
                        >
                            <div className={styles.elementIconContainer}>
                                {elementsIcon}
                            </div>
                            <p>{element.name}</p>
                        </NavLink>
                    )}
                </div>
                : <p className={styles.elementDefaultMessage}>{elementsArrayNotExistsText}</p>
        }
    </div>
);

OperatedElementsList.propTypes = {
    label: PropTypes.string.isRequired,
    actionButtonName: PropTypes.string.isRequired,
    onClickActionButton: PropTypes.func.isRequired,
    elementsArray: PropTypes.array.isRequired,
    elementsIcon: PropTypes.element.isRequired,
    elementsArrayNotExistsText: PropTypes.string,
    elementSelectedId: PropTypes.string,
    elementRedirectPath: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};
OperatedElementsList.defaultProps = {
    elementsArrayNotExistsText: '',
    elementSelectedId: '',
    isLoading: false
};