import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/_Toast/Toast.module.css';
import classNames from 'classnames/bind';
import { useSpring, animated } from 'react-spring';
import { CrossButtonIcon } from 'assets/icons/CrossButtonIcon';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { InfoSquaredInfo } from 'assets/icons/InfoSquearedInfo';
import { DoneIcon } from 'assets/icons/DoneIcon';

const cx = classNames.bind(styles);

const stylesTypes = {
    info: {
        icon: <InfoSquaredInfo/>,
        iconStyles: styles.infoIcon,
        messageStyles: styles.infoMessage,
        timelineStyles: styles.infoMessageTimeline
    },
    warning: {
        icon: <WarningIcon/>,
        iconStyles: styles.warningIcon,
        messageStyles: styles.warningMessage,
        timelineStyles: styles.warningMessageTimeline
    },
    success: {
        icon: <DoneIcon/>,
        iconStyles: styles.successIcon,
        messageStyles: styles.successMessage,
        timelineStyles: styles.successMessageTimeline
    }
}
export const Toast = ({ content, type, id, deleteToastCallback }) => {
    const container = useRef(null)

    const [style, set] = useSpring(() => ({
        opacity: 0
    }));
    const [isRemoved, setIsRemoved] = useState(false);

    const deleteToast = useCallback(() => setIsRemoved(true), []);

    useEffect(() => {
        set({ opacity: 1 });

        // Добавим авто-закрытие после 3 секунд
        const timer = setTimeout(deleteToast, 3000);
        return () => clearTimeout(timer);
    }, [deleteToast, id, set]);

    useEffect(() => {
        if (isRemoved) {
            new Promise(resolve => {
                container.current.classList.add(styles.deleteToastAnimation)

                resolve()
            }).then(() => setTimeout(() => deleteToastCallback(id), 600))
        }
    }, [isRemoved, set, id, deleteToastCallback]);

    return (
        <animated.div style={style} className={styles.toast} ref={container}>
            <div className={cx(styles.icon, stylesTypes[type].iconStyles)}>
                {stylesTypes[type].icon}
            </div>
            <div className={cx(styles.message, stylesTypes[type].messageStyles)}>
                <p>{content}</p>
                <div className={cx(styles.timeline, stylesTypes[type].timelineStyles)}/>
            </div>
            <button onClick={deleteToast}>
                <CrossButtonIcon color='#828282'/>
            </button>
        </animated.div>
    )
};

Toast.propTypes = {
    content: PropTypes.string.isRequired,
    type: PropTypes.string,
    id: PropTypes.number.isRequired,
    deleteToastCallback: PropTypes.func.isRequired
};
Toast.defaultProps = {
    type: 'info',
};