import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { snakeCase } from 'lodash';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

export const pushContentCompiler = (action, filteredArrows) => {
    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            minDispatchTime,
            maxDispatchTime,
            templateUniqueId,
            values: {
                countTimeIntervalNumber,
                countTimeIntervalType,
                communicationsMaxCount,
                templateCache,
                policyId: { value: policyId }
            }
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const utcMinDispatchTime = DateUse.convertLocalToUTC(minDispatchTime, 'HH:mm', 'HH:mm');
    const utcMaxDispatchTime = DateUse.convertLocalToUTC(maxDispatchTime, 'HH:mm', 'HH:mm');

    return {
        action_id: id,
        action_type: snakeCase(actionType),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            max_frequency: {
                period_type: StringUse.recast(countTimeIntervalType.value, { accepted: 'string', rejected: null }),
                period_number: StringUse.recast(countTimeIntervalNumber.value, { accepted: 'number', rejected: null }),
                n_communications: StringUse.recast(communicationsMaxCount.value, { accepted: 'number', rejected: null })
            },
            template_unique_id: StringUse.recast(templateUniqueId, { accepted: 'string', rejected: null }),
            time_to_send: {
                to_time: StringUse.recast(utcMaxDispatchTime, { accepted: 'string', rejected: null }),
                from_time: StringUse.recast(utcMinDispatchTime, { accepted: 'string', rejected: null })
            },
            cache_template: templateCache,
            policy_id: policyId
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ],
    }
}
