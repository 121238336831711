import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'common/InputRangeWrapper/InputRangeWrapper.css';
import InputRange from 'react-input-range';

export const InputRangeWrapper = memo(({
    maxValue,
    minValue,
    value,
    onChange,
}) => (
    <InputRange
        maxValue={maxValue}
        minValue={minValue}
        value={value}
        onChange={onChange}
    />
));

InputRangeWrapper.propTypes = {
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}
