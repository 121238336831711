export const silmplifiedTriggerEventSettins = [
    {
        name: 'MadePurchaseSilmplifiedSettings',
        selected: false,
        values: {
            actionName: {
                value: 'order_event',
            },
            actionSubName: {
                value: '',
            },
        }
    },
    {
        name: 'AddToBasketSilmplifiedSettings',
        selected: false,
        values: {
            actionName: {
                value: 'website_event',
            },
            actionSubName: {
                value: 'add',
            },
        }
    },
    {
        name: 'ViewedProductSilmplifiedSettings',
        selected: false,
        values: {
            actionName: {
                value: 'website_event',
            },
            actionSubName: {
                value: 'detail',
            },
        }
    }
];

