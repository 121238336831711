import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { snakeCase } from 'lodash';

export const editSegmentCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        position,
        actionParams,
        settings: {
            segmentId,
            segmentActionType
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    return {
        action_id: id,
        action_type: snakeCase(segmentActionType),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            segment_id: segmentId
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ],
    }
};
