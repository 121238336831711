import Validator from 'validatorjs';
import { reduce, map, compact, isArray } from 'lodash';

export class Validation {
    static validation(validate, rules, messages = {}) {
        return new Validator(validate, rules, messages);
    }

    static check(validate, rules, messages, attribute = '') {
        const validation = this.validation(validate, rules, messages);

        return {
            passes: validation.passes(),
            fails: validation.fails(),
            isSingleTypeValidation: true,
            firstError: validation.errors.first(attribute)
        }
    }

    static checkSome(validate, rules, messages, attributes = []) {
        const validation = this.validation(validate, rules, messages);

        return {
            passes: validation.passes(),
            fails: validation.fails(),
            isSingleTypeValidation: false,
            firstErrors: reduce(attributes, (acc, curr) => {
                acc[curr] = validation.errors.first(curr)

                return acc
            }, {})
        }
    }

    static leastOneFromList(validate = [], rules, messages, attributes) {
        const arr = map(validate, item => {
            return this.validation(item, rules).passes();
        });

        const isPassesValidation = !!compact(arr).length;

        const errorMessages = (rules, messages, attributes) => {
            if (isArray(attributes)) {
                return map(attributes, item => {
                    console.log(item);
                    return messages[rules[item] + '.' + item]
                })
            }
            return messages[rules[attributes] + '.' + attributes]
        };

        return {
            passes: isPassesValidation,
            fails: !isPassesValidation,
            errorMessages: !isPassesValidation
                ? errorMessages(rules, messages, attributes)
                : null
        }
    }
}
