import { includes, some, toString, toNumber, upperCase, lowerCase,  } from 'lodash';

export class StringUse {
    static recastTypes = {
        string: toString,
        number: toNumber,
    };

    // This method find something on string
    // For example you need to find any values of array like ['.', /.', '<', ','] on current string 'p.r.o.d.u.c.t._.r.e.d.'
    // return true if exists
    static contains(string = '', valuesArray = []) {
        return some(valuesArray, el => includes(string, el))
    };

    // This method convert all falsey values to empty sting
    static falseyReturnEmptyString(string, recastType = null) {
        if (recastType) {
            return string ? this.recastTypes[recastType](string) : ''
        }

        return string ? string : ''
    };

    // Recast method change string according to settings object
    // settings: { accepted: 'number', rejected: null }
    // Means that string is not empty, string converts to number
    // But if string is empty returns null
    static recast(string = '', settings = {}) {
        return string ? this.recastTypes[settings.accepted](string) : settings.rejected;
    };

    static isJinja(string = '') {
        const firstCheck = includes(string, '{%');
        const secondCheck = includes(string, '%}');

        return firstCheck && secondCheck;
    };

    static isUrl(string) {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return pattern.test(string);
    }

    static toPascalCase(string) {
        return string
            .match(/[a-z]+/gi) // Ищем все слова, игнорируя пробелы
            .map(word => upperCase(word.charAt(0)) + lowerCase(word.substr(1))) // Каждое слово с заглавной буквы
            .join(''); // Склеиваем слова без пробелов
    }
}
