import {
    SET_CONSENT_CATEGORIES_LIST,
    SET_DELETE_CONSENT_CATEGORY,
    SET_CREATE_CONSENT_CATEGORY,
    SET_UPDATE_CONSENT_CATEGORY,
    SET_SELECTED_CONSENT_CATEGORY
} from 'store/types';
import { LOGOUT_USER } from 'store/types';
import { filter, eq } from 'lodash';

const initialState = {
    list: [],
    selectedConsentCategory: {}
};

export default function consentCategories(store = initialState, { type, payload }) {
    switch (type) {
        case SET_CONSENT_CATEGORIES_LIST:
            return {
                ...store, list: payload.consent_categories
            };
        case SET_DELETE_CONSENT_CATEGORY:
            return {
                ...store, list: filter(store.list, consent => !eq(consent.consent_category_id, payload.id))
            }
        case SET_CREATE_CONSENT_CATEGORY:
            return {
                ...store, list: [ ...store.list, payload.data]
            }
        case SET_UPDATE_CONSENT_CATEGORY:
            return {
                ...store, list: [...filter(store.list, consent => !eq(consent.consent_category_id, payload.data.consent_category_id)), payload.data]
            }
        case SET_SELECTED_CONSENT_CATEGORY:
            return {
                ...store, selectedConsentCategory: payload.data
            }
        case LOGOUT_USER:
            return {
                ...store,
                list: payload.defaultConsentCategoriesStore.list
            };
        default:
            return store;
    }
}
