import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { EditIcon } from 'assets/icons/EditIcon';
import { copyTemplate, deleteTemplate, getTemplate } from 'store/actions/templates.action';
import styles from 'components/Cards/Cards.module.css';
import { confirmAlerter } from 'common/Alerters/confirmAlerter';
import { confirmCopyTemplateConfig, confirmDeleteTemplateConfig, preventDeleteTemplateConfig } from 'data/configSystemPopUps';
import { EllipsisChaiInfoButton } from 'components/Buttons/EllipsisButtons/EllipsisChaiInfoButton';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const CreativeCardFooter = ({ template, isShowChainButton, isShowUsage, redirectPath }) => {
    const dispatch = useDispatch();

    const { templateType } = useParams();
    const navigate = useNavigate();

    const templateInScenariosUsageCount = 'active_all_scenarios' in template.template_usage && 'active_experiments' in template.template_usage
        ? template.template_usage.active_all_scenarios.length
        : null

    const onClickEditTemplate = () => dispatch(getTemplate(template.template_unique_id), navigate(redirectPath));

    const onClickDeleteTemplate = () => {
        if (template.is_chosen) {
            confirmAlerter(preventDeleteTemplateConfig)
        } else {
            const confirmDeleteTemplate = () => dispatch(deleteTemplate(template.template_unique_id, template.message_type?.message_type_name));

            confirmAlerter(confirmDeleteTemplateConfig, confirmDeleteTemplate)
        }
    };

    const onClickCopyTemplate = () => {
        dispatch(copyTemplate(template.template_unique_id, () => confirmAlerter(confirmCopyTemplateConfig)))
    };

    const linkToAnalytics = `/all-templates/${templateType}/${template.template_unique_id}/metrics`;

    return (
        <div className={cx(styles.creativeCardFooter, { 'withoutUsage': !isShowUsage })}>
            <div className={styles.creativeCardFooterContainer}>
                <p className={styles.name} data-title={template.template_name}>{template.template_name}</p>

                <div className={styles.creativeCardFooterIcons}>
                <span onClick={onClickEditTemplate}>
                    <EditIcon color='inherit'/>
                </span>
                    {isShowChainButton
                        ? <EllipsisChaiInfoButton
                            handleCopy={onClickCopyTemplate}
                            linkToAnalytics={linkToAnalytics}
                            handleDelete={onClickDeleteTemplate}
                        />
                        : null
                    }
                </div>
            </div>
            {isShowUsage && template.is_chosen && templateInScenariosUsageCount
                ? <p className={styles.creativeCardFooterUsage}>
                    {`Used in ${templateInScenariosUsageCount} scenarios`}
                </p>
                : <p className={styles.creativeCardFooterUsage}>
                    Not used in scenarios
                </p>
            }
        </div>
    )
};

CreativeCardFooter.propTypes = {
    template: PropTypes.object.isRequired,
    isChainInfoButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string
};

CreativeCardFooter.defaultProps = {
    isShowUsage: true,
    metrics: true
};
