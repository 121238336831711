import { apiRequestAction } from 'store/actions/api.action';
import { CommonMethods } from 'lib/CommonMethods';
import { find, map, eq, toLower } from 'lodash';
import { ArrayUse } from 'lib/ArrayUse';
import {
    CANCEL_EDIT_CONDITION,
    CLEAR_CLIENT_BY_ATTRIBUTES,
    CLEAR_SEGMENT_CLIENT,
    CLEAR_SEGMENT_CLIENTS_LIST,
    FETCH_CREATE_SEGMENT,
    FETCH_SEGMENT,
    FETCH_SEGMENT_CLIENT,
    FETCH_SEGMENT_CLIENT_EVENT,
    FETCH_SEGMENT_CLIENTS_LIST,
    FETCH_SEGMENT_EVENTS_CLIENT_LIST,
    FETCH_SEGMENTS_LIST,
    FETCH_UPDATE_SEGMENT,
    SET_CLIENT_BY_ATTRIBUTES,
    SET_EDIT_CONDITION,
    SET_NEW_CONDITION,
    SET_SEGMENT,
    SET_SEGMENT_CLIENT,
    SET_SEGMENT_CLIENT_EVENT,
    SET_SEGMENT_CLIENT_EVENTS_LIST,
    SET_SEGMENT_CLIENTS_LIST,
    SET_SEGMENTS_LIST,
    SET_SEGMENTS_TEMPLATE,
    SET_UPDATED_CONDITION,
    TOGGLE_CONDITION_SIDEBAR,
    SET_AUTOSAVE_CONDITION_SIDEBAR_DATA,
    SET_MANUAL_SEGMENTS_LIST,
    FETCH_SEGMENTS_TEMPLATE_LIST,
    FETCH_DROPDOWN_FILTERS,
    SET_DROPDOWN_FILTERS,
    FETCH_UPDATE_CLIENT_CONSENTS,
    SET_UPDATE_CLIENT_CONSENTS,
    SET_SILENT_UPDATE_SEGMENTS_LIST,
    FETCH_SILENT_UPDATE_SEGMENTS_LIST
} from 'store/types';

import { defaultDecompilerConditionSettings } from 'pages/Segments/EditSegment/Standard/Conditions/settings/defaultDecompilerConditionSettings';
import { decompilerSegmentCondition } from 'pages/Segments/EditSegment/Standard/Conditions/settings/decompilerSegmentCondition';
import { compilerSegmentCondition } from 'pages/Segments/EditSegment/Standard/Conditions/settings/compilerSegmentCondition';

export const autoSaveConditionSidebarData = () => ({
    type: SET_AUTOSAVE_CONDITION_SIDEBAR_DATA
});

export const changeTypeConditionSidebar = typeCondition => (dispatch, getStore) => {
    const { conditionSidebar } = getStore().segments;

    dispatch({
        payload: {
            ...conditionSidebar,
            typeCondition
        },
        type: TOGGLE_CONDITION_SIDEBAR
    })
};

export const getManualSegmentsList = () => {
    return  apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments?is_template=&segment_type=MANUAL`),
        method: 'GET',
        onSuccess: setManualSegmentsList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENTS_LIST,
    })
};

const setManualSegmentsList = data => ({
    payload: data,
    type: SET_MANUAL_SEGMENTS_LIST,
});


const setSegmentsList = (data, silentUpdate) => ({
    payload: data,
    type: silentUpdate
        ? SET_SILENT_UPDATE_SEGMENTS_LIST
        : SET_SEGMENTS_LIST
});

export const getSegmentsList = (nameQuery = '', isEmptyCallback, silentUpdate = false) => {
    return apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments?is_template=&name_query=${nameQuery}`),
        method: 'GET',
        onSuccess: setSegmentsList,
        onAfterSuccess: isEmptyCallback,
        extraOptions: { nameQuery, silentUpdate },
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: silentUpdate
            ? FETCH_SILENT_UPDATE_SEGMENTS_LIST
            : FETCH_SEGMENTS_LIST,
    })
};

export const getSegmentsTemplateList = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('segments?is_template=true&segment_type=STANDARD'),
        method: 'GET',
        onSuccess: setSegmentsTemplateList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENTS_TEMPLATE_LIST,
    })
);

const setSegmentsTemplateList = data => ({
    payload: data,
    type: SET_SEGMENTS_TEMPLATE
});

export const createManualSegment = redirectCallback => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('segments'),
        method: 'POST',
        data: {
            is_template: false,
            segment_type: 'MANUAL'
        },
        extraOptions: redirectCallback,
        onSuccess: setCreateSegmentWithRedirect,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CREATE_SEGMENT,
    })
);

const setCreateSegmentWithRedirect = (data, redirectCallback) => {
    const { segment_id } = data;

    redirectCallback(segment_id);

    return {
        payload: data,
        type: SET_SEGMENT,
    };
};

export const createEmptyStandardSegment = (redirectCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('segments'),
        method: 'POST',
        data: {
            is_template: false,
            segment_type: 'STANDARD',
        },
        onSuccess: setCreateSegmentWithRedirect,
        extraOptions: redirectCallback,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CREATE_SEGMENT,
    })
);

export const createStandardTemplateSegment = templateSegmentId => dispatch => {
    // First: We need get full template segment
    // Second: Create new standard segment with empty conditions settings
    // Third: Set template setting to new created segment and save
    return new Promise(resolve => {
        const actionGetTemplateSegment = () => (
            apiRequestAction({
                url: CommonMethods.replaceUrlEndpoint(`segments/${templateSegmentId}`),
                method: 'GET',
                onSuccess: actionCreateNewSegment,
                onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
                label: FETCH_SEGMENT,
            })
        );

        const actionCreateNewSegment = template => (
            apiRequestAction({
                url: CommonMethods.replaceUrlEndpoint('segments'),
                method: 'POST',
                data: {
                    is_template: false,
                    segment_type: 'STANDARD'
                },
                extraOptions: template,
                onSuccess: actionUpdateNewSegment,
                onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
                label: FETCH_CREATE_SEGMENT,
            }));

        const actionUpdateNewSegment = (newSegment, templateSegment) => {
            const { segment_conditions, segment_description, segment_name } = templateSegment;
            const { segment_id } = newSegment;

            return apiRequestAction({
                url: CommonMethods.replaceUrlEndpoint(`segments/${segment_id}`),
                method: 'PUT',
                data: {
                    segment_conditions,
                    segment_description,
                    segment_name
                },
                onSuccess: setSegment,
                onAfterSuccess: () => resolve(segment_id),
                onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
                label: FETCH_UPDATE_SEGMENT,
            })
        };

        dispatch(actionGetTemplateSegment());
    })
};

export const updateSegment = (data, segmentId, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}`),
        method: 'PUT',
        data,
        onSuccess: setSegment,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        onAfterSuccess: onAfterSuccess,
        label: FETCH_UPDATE_SEGMENT,
    })
);

export const getSegment = (segmentId, onFinallyCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}`),
        method: 'GET',
        onSuccess: setSegment,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT,
        onFinally: onFinallyCallback
    })
);

const setSegment = data => ({
    type: SET_SEGMENT,
    payload: data
});

export const copySegment = (segmentId, successCopySegmentAlert) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}/copy`),
        method: 'POST',
        extraOptions: successCopySegmentAlert,
        onSuccess: setCopySegment,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT,
    })
);

const setCopySegment = (data, successCopySegmentAlert) => (dispatch, getStore) => {
    successCopySegmentAlert();

    const segmentsList = getStore().segments.list;

    dispatch({
        type: SET_SEGMENTS_LIST,
        payload: {...segmentsList, data}
    })
};

export const deleteSegment = segmentId => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}`),
        method: 'DELETE',
        onSuccess: setDeleteSegment,
        extraOptions: segmentId,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT,
    })
);

const setDeleteSegment = segmentId => (dispatch, getStore) => {
    const segmentsList = getStore().segments.list;
    const deleteSegment = find(segmentsList, { segment_id: segmentId });

    dispatch({
        type: SET_SEGMENTS_LIST,
        payload: ArrayUse.remove(segmentsList, deleteSegment)
    })
};

export const getSegmentClientsList = (segmentId, params = { rowsPerPage: null, pageParams: null, attributeValue: '' }, onFinallyCallback) => {
    let paramsQuery = `clients?segment_id=${segmentId}&page_switch=first&limit=30`;

    if (params.rowsPerPage) {
        paramsQuery = `clients?segment_id=${segmentId}&page_switch=first&limit=${params.rowsPerPage}`
    }
    if (params.pageParams) {
        paramsQuery = `clients?segment_id=${segmentId}&${params.pageParams}`
    }
    //search term
    if (params.attributeValue) {
        paramsQuery += `&client_attribute_value=${encodeURIComponent(params.attributeValue)}`
    }

    return (
        apiRequestAction({
            url: CommonMethods.replaceUrlEndpoint(paramsQuery),
            method: 'GET',
            onSuccess: setSegmentClientsList,
            onFinally: onFinallyCallback,
            onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
            label: FETCH_SEGMENT_CLIENTS_LIST,
        })
    )
};

const setSegmentClientsList = data => ({
        type: SET_SEGMENT_CLIENTS_LIST,
        payload: data
});

export const clearSegmentClientsList = () => ({
   type: CLEAR_SEGMENT_CLIENTS_LIST
});

export const getSegmentClientByAttributes = attributeValue => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`clients/by_attributes?&client_attribute_value=${encodeURIComponent(attributeValue)}`),
        method: 'GET',
        onSuccess: setSegmentClientByAttributes,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_CLIENTS_LIST,
    })
);

const setSegmentClientByAttributes = data => {
    return ({
        type: SET_CLIENT_BY_ATTRIBUTES,
        payload: data
    })
};

export const clearSegmentClientByAttributes = () => ({
    type: CLEAR_CLIENT_BY_ATTRIBUTES,
});

const setSegmentClient = data => ({
    type: SET_SEGMENT_CLIENT,
    payload: data
});

export const getSegmentClient = uniqueUserId => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`clients/${uniqueUserId}`),
        method: 'GET',
        onSuccess: setSegmentClient,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_CLIENT,
    })
);

export const clearSegmentClient = () => ({
    type: CLEAR_SEGMENT_CLIENT
});

export const getSegmentClientEventsList = (uniqueUserId, rowsPerPage = null, pageParams = null, orderDesc = 1, dateFrom = '', dateTo = '', eventNames = []) => {
    //let paramsQuery = `events?unique_user_id=${uniqueUserId}&page_switch=first&limit=30&order_desc=1&event_names=[]&date_from=&date_to=`
    let paramsQuery = `events?unique_user_id=${uniqueUserId}&page_switch=first&limit=30&order_desc=${orderDesc}&date_from=${dateFrom}&date_to=${dateTo}&event_names=${eventNames}`;

    if (rowsPerPage) {
        paramsQuery = `events?unique_user_id=${uniqueUserId}&page_switch=first&limit=${rowsPerPage}&order_desc=${orderDesc}&date_from=${dateFrom}&date_to=${dateTo}&event_names=${eventNames}`
    }
    if (pageParams) {
        paramsQuery = `events?unique_user_id=${uniqueUserId}&${pageParams}&order_desc=${orderDesc}&date_from=${dateFrom}&date_to=${dateTo}&event_names=${eventNames}`
    }

    return apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(paramsQuery),
        method: 'GET',
        onSuccess: setSegmentClientEventsList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_EVENTS_CLIENT_LIST,
    });
};

const setSegmentClientEventsList = data => ({
    type: SET_SEGMENT_CLIENT_EVENTS_LIST,
    payload: data
});

const setSegmentClientEvent = data => ({
    type: SET_SEGMENT_CLIENT_EVENT,
    payload: data
});

export const getSegmentClientEvent = (searchId, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`events/${searchId}`),
        method: 'GET',
        onSuccess: setSegmentClientEvent,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_CLIENT_EVENT,
    })
);

export const setEditCondition = (settings, sidebarParams) => (dispatch, getStore) =>  {
    const { condition_object, condition_is_simple, condition_name } = settings;

    const { conditionSidebar } = getStore().segments;

    dispatch({
        type: SET_EDIT_CONDITION,
        payload: {
            condition: {
                ...decompilerSegmentCondition(condition_object),
                conditionIsSimple: condition_is_simple,
                conditionName: condition_name
            },
            sidebarParams : {
                ...conditionSidebar,
                ...sidebarParams,
            }
        }
    });
};

export const createNewCondition = typeCondition => (dispatch, getStore) => {
    const segment = getStore().segments.segment;

    dispatch({
        type: SET_NEW_CONDITION,
        payload: {
            condition: defaultDecompilerConditionSettings,
            sidebarParams: {
                typeCondition,
                indexConditionGroup: segment.segment_conditions[typeCondition].length,
                indexCondition: 0
            }
        }
    })
};

export const cancelEditCondition = () => ({
    type: CANCEL_EDIT_CONDITION,
});

export const saveEditCondition = (settingsCondition, isSimpleSettings) => (dispatch, getStore) => {
    const { segment, conditionSidebar: { typeCondition, indexConditionGroup, indexCondition } } = getStore().segments;

    const updatedCondition = {
        condition_object: compilerSegmentCondition(settingsCondition),
        condition_name: settingsCondition.conditionName,
        condition_is_simple: isSimpleSettings
    };

    const updatedConditionGroup = ArrayUse.replace(segment.segment_conditions[typeCondition][indexConditionGroup], indexCondition, updatedCondition);

    dispatch({
        type: SET_UPDATED_CONDITION,
        payload: {
            ...segment,
            segment_conditions: {
                ...segment.segment_conditions,
                [typeCondition]: ArrayUse.replace(segment.segment_conditions[typeCondition], indexConditionGroup, updatedConditionGroup)
            }
        }
    })
};

export const setSegmentConditions = (conditions, type) => (dispatch, getStore) => {
    const segment = getStore().segments.segment;

    dispatch({
        type: SET_SEGMENT,
        payload: {
            ...segment,
            segment_conditions: {
                ...segment.segment_conditions,
                [type]: conditions
            }
        }
    })
};

export const addClientToManualSegment = (segmentId, uniqueUserId) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}/add_clients`),
        method: 'POST',
        data: {
            uuids: uniqueUserId
        },
        onSuccess: setAddClientAttribute,
        extraOptions: { uniqueUserId, segmentId },
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_CLIENT_EVENT,
    })
);

export const setAddClientAttribute = ({ uniqueUserId, segmentId }) => (dispatch, getStore) => {
    const clients = getStore().segments.clientsByAttributes;

    const findClient = find(clients, { unique_user_id: uniqueUserId});

    findClient.segment_ids.push(segmentId);

    dispatch({
        payload: [...clients],
        type: SET_CLIENT_BY_ATTRIBUTES
    })
};

export const removeClientFromManualSegment = (segmentId, uniqueUserId, successCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`segments/${segmentId}/remove_clients`),
        method: 'POST',
        data: {
            uuids: uniqueUserId
        },
        onSuccess: successCallback,
        extraOptions: { uniqueUserId, segmentId },
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SEGMENT_CLIENT_EVENT,
    })
);

export const setRemoveClient = ({ uniqueUserId }) => (dispatch, getStore) => {
    const clients = getStore().segments.segmentClients;
    const removeClient = find(clients.result, { unique_user_id: uniqueUserId});

    dispatch({
        payload: {
            ...clients,
            result: ArrayUse.remove(clients.result, removeClient)
        },
        type: SET_SEGMENT_CLIENTS_LIST
    })
};

export const setRemoveClientAttribute = ({ uniqueUserId, segmentId }) => (dispatch, getStore) => {
    const clients = getStore().segments.clientsByAttributes;

    const deletedSegment = map(clients, client => {
       if (eq(client.unique_user_id, uniqueUserId)) {
           return {
               ...client,
               segment_ids: ArrayUse.remove(client.segment_ids, segmentId)
           }
       }
       return client
    });

    dispatch({
        payload: deletedSegment,
        type: SET_CLIENT_BY_ATTRIBUTES
    })
};
const setDropdownFilters = (data, type) => ({
    type: SET_DROPDOWN_FILTERS,
    payload: {
        data: {
            [toLower(type)]: data.result
        }
    }
});

export const getDropdownFilters = type => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`dropdown_filters?type=${type}`),
        method: 'GET',
        extraOptions: type,
        onSuccess: setDropdownFilters,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_DROPDOWN_FILTERS,
    })
);
const setUpdateClientConsents = data => {
    console.log('setUpdateClientConsents', data);
    return {
        type: SET_UPDATE_CLIENT_CONSENTS,
        payload: { data }
    }
}
export const updateClientConsents = (userId, retailerName, consents, onAfterSuccess) => (
    apiRequestAction({
        headersOverride: {
            'Retailer-Name': retailerName,
        },
        url: CommonMethods.replaceUrlEndpointAlt(`consents/${userId}`),
        method: 'POST',
        data: {
            consents
        },
        onAfterSuccess,
        onSuccess: setUpdateClientConsents,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_UPDATE_CLIENT_CONSENTS,
    })
);
