import {
    ADD_ACTION,
    ADD_ARROW_CHAIN,
    ADD_ACTION_OUTPUT_CHECKPOINT,
    CHANGE_ACTION_MODAL_STATUS,
    CHANGE_ACTION_POSITION,
    CLEAR_ACTION_OUTPUT_CHECKPOINT,
    CLOSE_ACTION_LIST_WARNING,
    REMOVE_ACTION_PROCESS,
    COPY_ACTION_PROCESS,
    REMOVE_ARROW_LINE,
    SAVE_SIDEBAR_DATA,
    SET_ACTIONS_CANCEL_CHANGES,
    SET_ACTIONS_CHAIN_LIST,
    SET_VALIDATED_ACTIONS_CHAIN_LIST,
    SET_ACTIONS_EDIT_BASE_COPY,
    TOGGLE_SIDEBAR,
    SELECT_ACTION_TEMPLATE,
    PRE_SAVE_SIDEBAR_DATA,
    CLEAR_SCENARIO_ACTIONS_DATA,
    SET_AUTOSAVE_SIDEBAR_DATA,
    SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
    SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT,
    RESET_SCENARIO_ACTION_ERRORS,
    UPDATE_SCENARIO_ACTION
} from 'store/types';

const initialState = {
    currentScenarioId: null,
    actionsList: [],
    actionOutputCheckpoint: null,
    actionConnectionsList: [],
    actionsBaseCopy: {},
    actionSidebar: {
        open: false,
        type: null,
        id: null
    },
    baseCopyEditActions: []
};

export default function scenariosActions(store = initialState, { type, payload }) {
    switch (type) {
        case ADD_ACTION:
            return {
                ...store, actionsList: [...store.actionsList, payload.data],
            };
        case ADD_ACTION_OUTPUT_CHECKPOINT:
            return {
                ...store, actionOutputCheckpoint: payload.data
            };
        case CLEAR_ACTION_OUTPUT_CHECKPOINT:
            return {
                ...store, actionOutputCheckpoint: payload.data,
            };
        case ADD_ARROW_CHAIN:
            return {
                ...store,
                actionConnectionsList: [ ...store.actionConnectionsList, payload.data ],
                actionOutputCheckpoint: null,
            };
        case CHANGE_ACTION_POSITION:
            return {
                ...store,
                actionsList: payload.data
            };
        case CHANGE_ACTION_MODAL_STATUS:
            return {
                ...store, actionsList: payload.data,
            };
        case REMOVE_ACTION_PROCESS:
            return {
                ...store, actionsList: payload.array, actionConnectionsList: payload.arrow,
            };
        case COPY_ACTION_PROCESS:
            return {
                ...store, actionsList: payload.array
            };
        case REMOVE_ARROW_LINE:
            return {
                ...store, actionConnectionsList: payload.arrow,
            };
        case SET_ACTIONS_CHAIN_LIST:
            return {
                ...store,
                actionsList: payload.actions,
                actionConnectionsList: payload.arrows,
                actionsBaseCopy: payload.baseCopy
            };
        case SET_VALIDATED_ACTIONS_CHAIN_LIST:
            return {
                ...store,
                actionsList: payload.actions,
                actionConnectionsList: payload.arrows,
                actionsBaseCopy: payload.baseCopy
            };
        case SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT:
            return {
                ...store,
                actionsList: payload.actions,
                actionConnectionsList: payload.arrows,
                currentScenarioId: payload.scenarioId
            };
        case SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT:
            return {
                ...store, actionsList: payload.actions,
            };
        case SET_ACTIONS_CANCEL_CHANGES:
            return {
                ...store, actionsList: payload.actions, actionConnectionsList: payload.arrows, baseCopyEditActions: []
            };
        case TOGGLE_SIDEBAR:
            return {
                ...store, actionSidebar: payload.data,
            };
        case SAVE_SIDEBAR_DATA:
            return {
                ...store,
                actionsList: payload.data,
                baseCopyEditActions: payload.baseCopyEditActions || [],
                actionSidebar: { open: false, type: null, id: null },
            };
        case SET_AUTOSAVE_SIDEBAR_DATA:
            return {
                ...store, actionsList: payload.data
            };
        case PRE_SAVE_SIDEBAR_DATA:
            return {
                ...store, actionsList: payload.data,
            };
        case SELECT_ACTION_TEMPLATE:
            return {
                ...store, actionsList: payload.data,
            };
        case CLEAR_SCENARIO_ACTIONS_DATA:
            return {
                ...store,
                currentScenarioId: payload.defaultCurrentScenarioId,
                actionsList: payload.defaultActionList,
                actionConnectionsList: payload.defaultActionConnectionsList,
                actionOutputCheckpoint: payload.defaultActionOutputCheckpoint,
                actionsBaseCopy: payload.defaultActionsBaseCopy,
                actionSidebar: payload.defaultActionSideBar
            };
        case CLOSE_ACTION_LIST_WARNING:
            return {
                ...store, actionsList: payload.data,
            };
        case SET_ACTIONS_EDIT_BASE_COPY:
            return {
                ...store, baseCopyEditActions: [...store.baseCopyEditActions, payload.data]
            };
        case RESET_SCENARIO_ACTION_ERRORS:
            return {
                ...store, actionsList: payload.data
            }
        case UPDATE_SCENARIO_ACTION: {
            return {
                ...store,
                actionsList: payload.data,
                baseCopyEditActions: payload.baseCopyEditActions || [],
            }
        }
        default:
            return store;
    }
}
