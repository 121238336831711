import React from 'react';

export const FunnelIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
        <rect width="32px" height="32px" rx="3.5" fill={bgColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.8333 6.83333H8.83333V8.21871L15.0794 16.4167H17.5873L23.8333 8.21871V6.83333ZM24.6667 8.5L18 17.25H14.6667L8 8.5V6H24.6667V8.5Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 16.4167C14.8968 16.4167 15.0833 16.6032 15.0833 16.8333V24.9092L17.5833 23.6592V16.8333C17.5833 16.6032 17.7699 16.4167 18 16.4167C18.2301 16.4167 18.4167 16.6032 18.4167 16.8333V23.9167C18.4167 24.0745 18.3275 24.2188 18.1863 24.2893L14.853 25.956C14.7238 26.0206 14.5705 26.0137 14.4476 25.9378C14.3248 25.8619 14.25 25.7277 14.25 25.5833V16.8333C14.25 16.6032 14.4365 16.4167 14.6667 16.4167Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.41667 8.5C8.41667 8.26988 8.60321 8.08333 8.83333 8.08333H23.8333C24.0635 8.08333 24.25 8.26988 24.25 8.5C24.25 8.73012 24.0635 8.91667 23.8333 8.91667H8.83333C8.60321 8.91667 8.41667 8.73012 8.41667 8.5Z" fill={color}/>
    </svg>
);
