import React from 'react';
import { TriggerEventIcon } from 'assets/icons/TriggerEventIcon';
import { TriggerEventIconAlt } from 'assets/icons/TriggerEventIconAlt';
import { SingleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/SingleChainActionIcon';

export const TriggerEvent = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: TriggerEventIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#9088DF'
            }
        },
        opened: {
            icon: TriggerEventIconAlt,
            iconProps: { borderColor: '#9088DF' }
        },
        warning: {
            icon: TriggerEventIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <SingleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions
    }} />
}
