import React from 'react';
import styles from 'common/_Buttons/_BlueButton/BlueButton.module.css';
import { DefaultButton } from 'common/_Buttons/_DefaultButton/DefaultButton';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const BlueButton = Component => ({ ...props }) => {
    return <Component {...props} className={cx(styles.blueButton, props.className)}/>
};

export default BlueButton(DefaultButton);
