import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/ErrorMessageField/ErrorMessageField.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const ErrorMessageField = ({ active, message, className }) => {
    const { t: translator } = useTranslation();

    return  <p className={cx(styles.errorMessage, { 'activeMessage': active }, className)}>
        {message && translator(`validationErrorMessages:${message}`)}
    </p>
}

ErrorMessageField.propTypes = {
    active: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]).isRequired,
    className: PropTypes.string
}
ErrorMessageField.defaultProps = {
    active: false,
    message: '',
    classNames: null
}
