import React from 'react';

export const GoogleAdsIconAlt = ({ borderColor }) => (
    <svg width="60" height="48" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="6.5" y="0.5" width="47" height="47" rx="4.5" fill="white" stroke={borderColor}/>
        <path d="M12 24C12 27.3137 9.31371 30 6 30C2.68629 30 0 27.3137 0 24C0 20.6863 2.68629 18 6 18C9.31371 18 12 20.6863 12 24Z" fill="#9088DF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6 29C8.76142 29 11 26.7614 11 24C11 21.2386 8.76142 19 6 19C3.23858 19 1 21.2386 1 24C1 26.7614 3.23858 29 6 29ZM6 30C9.31371 30 12 27.3137 12 24C12 20.6863 9.31371 18 6 18C2.68629 18 0 20.6863 0 24C0 27.3137 2.68629 30 6 30Z" fill="white"/>
        <path d="M60 24C60 27.3137 57.3137 30 54 30C50.6863 30 48 27.3137 48 24C48 20.6863 50.6863 18 54 18C57.3137 18 60 20.6863 60 24Z" fill="#9088DF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M54 29C56.7614 29 59 26.7614 59 24C59 21.2386 56.7614 19 54 19C51.2386 19 49 21.2386 49 24C49 26.7614 51.2386 29 54 29ZM54 30C57.3137 30 60 27.3137 60 24C60 20.6863 57.3137 18 54 18C50.6863 18 48 20.6863 48 24C48 27.3137 50.6863 30 54 30Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.4538 29.6287L33.5375 14.0479C32.4282 12.1096 29.956 11.4365 28.0155 12.5445L27.4711 12.8554C25.5306 13.9634 24.8568 16.433 25.9661 18.3713L34.8824 33.9521C35.9916 35.8904 38.4639 36.5635 40.4044 35.4555L40.9488 35.1446C42.8892 34.0366 43.5631 31.567 42.4538 29.6287ZM34.4159 13.5463C33.0293 11.1234 29.939 10.282 27.5134 11.6671L26.969 11.9779C24.5434 13.363 23.7011 16.4499 25.0877 18.8729L34.004 34.4537C35.3906 36.8766 38.4809 37.718 40.9065 36.3329L41.4509 36.0221C43.8765 34.637 44.7188 31.5501 43.3322 29.1271L34.4159 13.5463Z" fill={borderColor}/>
        <path d="M25.1297 13.7642L16.6275 29.2002C15.2807 31.6454 16.1733 34.7182 18.6211 36.0635L19.1706 36.3655C21.6184 37.7109 24.6946 36.8193 26.0414 34.3741L30.3147 26.6157L29.8265 25.4056L25.155 33.8869C24.0775 35.843 21.6166 36.5563 19.6583 35.48L19.1089 35.1781C17.1506 34.1018 16.4365 31.6435 17.514 29.6874L25.618 14.9743L25.1297 13.7642Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.5385 35.5007C23.7736 35.5007 25.5855 33.6908 25.5855 31.4581C25.5855 29.2254 23.7736 27.4154 21.5385 27.4154C19.3034 27.4154 17.4914 29.2254 17.4914 31.4581C17.4914 33.6908 19.3034 35.5007 21.5385 35.5007ZM21.5385 36.5114C24.3324 36.5114 26.5973 34.2489 26.5973 31.4581C26.5973 28.6672 24.3324 26.4048 21.5385 26.4048C18.7446 26.4048 16.4797 28.6672 16.4797 31.4581C16.4797 34.2489 18.7446 36.5114 21.5385 36.5114Z" fill={borderColor}/>
    </svg>
);
