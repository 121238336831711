export const viberSmiley = {
    "smiley": "smiley.png",
    "sad": "sad.png",
    "wink": "wink.png",
    "angry": "angry.png",
    "inlove": "inlove.png",
    "yummi": "yummi.png",
    "laugh": "laugh.png",
    "surprised": "surprised.png",
    "moa": "moa.png",
    "happy": "happy.png",
    "cry": "cry.png",
    "sick": "sick.png",
    "shy": "shy.png",
    "teeth": "teeth.png",
    "tongue": "tongue.png",
    "money": "money.png",
    "mad": "mad.png",
    "flirt": "flirt.png",
    "crazy": "crazy.png",
    "confused": "confused.png",
    "depressed": "depressed.png",
    "scream": "scream.png",
    "nerd": "nerd.png",
    "not_sure": "not_sure.png",
    "cool": "cool.png",
    "huh": "huh.png",
    "happycry": "happycry.png",
    "mwah": "mwah.png",
    "exhausted": "exhausted.png",
    "eek": "eek.png",
    "dizzy": "dizzy.png",
    "dead": "dead.png",
    "straight": "straight.png",
    "yo": "yo.png",
    "wtf": "wtf.png",
    "ohno": "ohno.png",
    "oh": "oh.png",
    "wink2": "wink2.png",
    "what": "what.png",
    "weak": "weak.png",
    "upset": "upset.png",
    "ugh": "ugh.png",
    "teary": "teary.png",
    "singing": "singing.png",
    "silly": "silly.png",
    "meh": "meh.png",
    "mischievous": "mischievous.png",
    "ninja": "ninja.png",
    "spiderman": "spiderman.png",
    "batman": "batman.png",
    "devil": "devil.png",
    "angel": "angel.png",
    "heart": "heart.png",
    "heart_break": "heart_break.png",
    "purple_heart": "purple_heart.png",
    "unlike": "unlike.png",
    "like": "like.png",
    "V": "V.png",
    "fu": "fu.png",
    "clap": "clap.png",
    "rockon": "rockon.png",
    "angrymark": "angrymark.png",
    "thinking": "thinking.png",
    "zzz": "zzz.png",
    "!": "exclamation.png",
    "Q": "Q.png",
    "diamond": "diamond.png",
    "trophy": "trophy.png",
    "crown": "crown.png",
    "ring": "ring.png",
    "$": "dollars.png",
    "hammer": "hammer.png",
    "wrench": "wrench.png",
    "key": "key.png",
    "lock": "lock.png",
    "video": "video.png",
    "TV": "TV.png",
    "tape": "tape.png",
    "trumpet": "trumpet.png",
    "guitar": "guitar.png",
    "speaker": "speaker.png",
    "music": "music.png",
    "microphone": "microphone.png",
    "bell": "bell.png",
    "koala": "koala.png",
    "sheep": "sheep.png",
    "ladybug": "ladybug.png",
    "kangaroo": "kangaroo.png",
    "chick": "chick.png",
    "monkey": "monkey.png",
    "panda": "panda.png",
    "turtle": "turtle.png",
    "bunny": "bunny.png",
    "dragonfly": "dragonfly.png",
    "fly": "fly.png",
    "bee": "bee.png",
    "bat": "bat.png",
    "cat": "cat.png",
    "dog": "dog.png",
    "squirrel": "squirrel.png",
    "snake": "snake.png",
    "snail": "snail.png",
    "goldfish": "goldfish.png",
    "shark": "shark.png",
    "pig": "pig.png",
    "owl": "owl.png",
    "penguin": "penguin.png",
    "paw": "paw.png",
    "poo": "poo.png",
    "cap": "cap.png",
    "fidora": "fidora.png",
    "partyhat": "partyhat.png",
    "cactus": "cactus.png",
    "clover": "clover.png",
    "sprout": "sprout.png",
    "palmtree": "palmtree.png",
    "christmas_tree": "christmas_tree.png",
    "mapleleaf": "mapleleaf.png",
    "flower": "flower.png",
    "sunflower": "sunflower.png",
    "sun": "sun.png",
    "moon": "moon.png",
    "rain": "rain.png",
    "cloud": "cloud.png",
    "umbrella": "umbrella.png",
    "snowman": "snowman.png",
    "snowflake": "snowflake.png",
    "relax": "relax.png",
    "flipflop": "flipflop.png",
    "telephone": "telephone.png",
    "phone": "phone.png",
    "nobattery": "nobattery.png",
    "battery": "battery.png",
    "time": "time.png",
    "knife": "knife.png",
    "syringe": "syringe.png",
    "termometer": "termometer.png",
    "meds": "meds.png",
    "ruler": "ruler.png",
    "scissor": "scissor.png",
    "paperclip": "paperclip.png",
    "pencil": "pencil.png",
    "magnify": "magnify.png",
    "glasses": "glasses.png",
    "book": "book.png",
    "letter": "letter.png",
    "weight": "weight.png",
    "muscle": "muscle.png",
    "boxing": "boxing.png",
    "light_bulb": "light_bulb.png",
    "lantern": "lantern.png",
    "fire": "fire.png",
    "torch": "torch.png",
    "bomb": "bomb.png",
    "cigarette": "cigarette.png",
    "kiss": "kiss.png",
    "gift": "gift.png",
    "skull": "skull.png",
    "ghost": "ghost.png",
    "golf": "golf.png",
    "golfball": "golfball.png",
    "football": "football.png",
    "tennis": "tennis.png",
    "soccer": "soccer.png",
    "basketball": "basketball.png",
    "baseball": "baseball.png",
    "8ball": "8ball.png",
    "beachball": "beachball.png",
    "balloon1": "balloon1.png",
    "balloon2": "balloon2.png",
    "cards": "cards.png",
    "dice": "dice.png",
    "console": "console.png",
    "chicken": "chicken.png",
    "burger": "burger.png",
    "pizza": "pizza.png",
    "noodles": "noodles.png",
    "sushi1": "sushi1.png",
    "sushi2": "sushi2.png",
    "donut": "donut.png",
    "egg": "egg.png",
    "hotdog": "hotdog.png",
    "ice_cream": "ice_cream.png",
    "popsicle": "popsicle.png",
    "cupcake": "cupcake.png",
    "croissant": "croissant.png",
    "chocolate": "chocolate.png",
    "lollipop": "lollipop.png",
    "popcorn": "popcorn.png",
    "cake": "cake.png",
    "cherry": "cherry.png",
    "banana": "banana.png",
    "watermelon": "watermelon.png",
    "strawberry": "strawberry.png",
    "grapes": "grapes.png",
    "pineapple": "pineapple.png",
    "pea": "pea.png",
    "corn": "corn.png",
    "mushroom": "mushroom.png",
    "beer": "beer.png",
    "wine": "wine.png",
    "martini": "martini.png",
    "coffee": "coffee.png",
    "soda": "soda.png",
    "car": "car.png",
    "taxi": "taxi.png",
    "ambulance": "ambulance.png",
    "policecar": "policecar.png",
    "bicycle": "bicycle.png",
    "airplane": "airplane.png",
    "ufo": "ufo.png",
    "rocket": "rocket.png",
    "run": "run.png"
};
