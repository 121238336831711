import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SkeletonWrapper = ({ children, isShow, width, height, style }) => (
    <>
        { isShow ? children :
            <SkeletonTheme baseColor="#F2F2F2" highlightColor="#E7E7E7">
                <Skeleton width={width} height={height} style={style}/>
            </SkeletonTheme>
        }
    </>
)
SkeletonWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    isShow: PropTypes.bool.isRequired,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    style: PropTypes.object
};
SkeletonWrapper.defaultProps = {
    isShow: false,
    width: 150,
    height: 20,
    style: null
};
