import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { GoogleAdsIcon } from 'assets/icons/GoogleAdsIcon';
import { GoogleAdsIconAlt } from 'assets/icons/GoogleAdsIconAlt';

export const GoogleAds = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: GoogleAdsIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#0CBFE7'
            }
        },
        opened: {
            icon: GoogleAdsIconAlt,
            iconProps: { borderColor: '#0CBFE7' }
        },
        warning: {
            icon: GoogleAdsIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    };

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
    }} />
};
