import React from 'react';
import styles from 'components/Cards/Cards.module.css';
import { eq, find } from 'lodash';
import PropTypes from 'prop-types';
import { CreativeCardFooter } from 'components/Cards/CreativeCards/CreativeCardFooter';
import { CreativeCardWrapper } from 'components/Cards/CreativeCards/CreativeCardWrapper';

export const SmsCard = ({
    template,
    templateId,
    templateType,
    isShowChainButton,
    isShowUsage,
    redirectPath,
    onClick,
    containerClassName
}) => {
    const { template_unique_id, jinja_fields } = template;
    const jinjaBodyObject = find(jinja_fields, { jinja_field_name: 'jinja_body' });
    return (
        <CreativeCardWrapper
            isSelected={eq(templateId, template_unique_id)}
            cardType={templateType}
            className={containerClassName}
        >
            <div className={styles.creativeCardPreview} onClick={onClick}>
                {!jinjaBodyObject.value && <div className={styles.defaultCardPreview}/>}
                {jinjaBodyObject.value && <div className={styles.smsCard}>
                    <p>{jinjaBodyObject.value}</p>
                </div>}
            </div>
            <CreativeCardFooter {...{ template, isShowChainButton, isShowUsage, redirectPath }}/>
        </CreativeCardWrapper>
    );
};

SmsCard.propTypes = {
    template: PropTypes.object.isRequired,
    templateId: PropTypes.string,
    templateType: PropTypes.string,
    isShowChainButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string
};
SmsCard.defaultProps = {
    isShowUsage: true,
    containerClassName: ''
};
