import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { WebsiteBuilderIcon } from 'assets/icons/WebsiteBuilderIcon';
import { WebsiteBuilderIconAlt } from 'assets/icons/WebsiteBuilderIconAlt';

export const WebsiteBuilder = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: WebsiteBuilderIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#0CBFE7'
            }
        },
        opened: {
            icon: WebsiteBuilderIconAlt,
            iconProps: { borderColor: '#0CBFE7' }
        },
        warning: {
            icon: WebsiteBuilderIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions
    }} />
}
