import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { pick, eq, find, isEmpty, map, some, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { RadioButton } from 'common/RadioButton/RadioButton';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { SelectedDatesIntervalViewer } from 'components/DateSelectors/SelectedDatesIntervalViewer';
import { AttributeFilterDropdownDeep } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDropdownDeep';
import { AggregationSection } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AggregationSection';
import { ConditionSegmentationFilter } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/advanced/ConditionSegmentationFilter';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { ArrayUse } from 'lib/ArrayUse';
import { StringUse } from 'lib/StringUse';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

const parsedErrors = (errors, index) => {
    if (!isEmpty(errors) && has(errors, 'conditionsErrors')) {
        const { conditionsErrors } = errors;

        if (conditionsErrors[index]) {
            const { validation: { actionName, selectedTimeInterval } } = conditionsErrors[index];

            return {
                actionName: {
                    hasError: actionName?.fails,
                    message: StringUse.falseyReturnEmptyString(actionName?.error)
                },
                selectedTimeInterval: {
                    countDays: {
                        hasError: !!selectedTimeInterval?.lastTime?.countDays,
                        message: StringUse.falseyReturnEmptyString(selectedTimeInterval?.lastTime?.countDays)
                    },
                    countHours: {
                        hasError: !!selectedTimeInterval?.lastTime?.countHours,
                        message: StringUse.falseyReturnEmptyString(selectedTimeInterval?.lastTime?.countHours)
                    },
                    startDate: {
                        hasError: !!selectedTimeInterval?.currentTime?.startDate,
                        message: StringUse.falseyReturnEmptyString(selectedTimeInterval?.currentTime?.startDate)
                    },
                    endDate: {
                        hasError: !!selectedTimeInterval?.currentTime?.endDate,
                        message: StringUse.falseyReturnEmptyString(selectedTimeInterval?.currentTime?.endDate)
                    }
                }
            }
        }
    }

    return {
        actionName: {
            hasError: false
        },
        selectedTimeInterval: {
            countDays: {
                hasError: false
            },
            countHours: {
                hasError: false
            },
            startDate: {
                hasError: false
            },
            endDate: {
                hasError: false
            }
        }
    }
}

export const ConditionMapped = ({ item, index, required }) => {
    const { t: translator } = useTranslation();

    const {
        errors,
        condition,
        setCondition,
        onChangeDropdownShowStatus,
        onClickRemoveDropdownItem,
        onChangeInputMultiple,
        onChangeDropdownMultiple,
        mappedEventNames
    } = useContext(SideBarContext);

    const { actionName, selectedTimeInterval } = parsedErrors(errors, index);

    const onChangeSegmentationFilter = (setState, id) => event => {
        const { name, checked } = event.target;

        return checked && setState(prev => {
            const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })

            return ArrayUse.replace(prev, index, {
                ...collection,
                _options: map(collection._options, item =>
                    eq(item.name, name)
                        ? { ...item, isChecked: true }
                        : { ...item, isChecked: false }
                )
            })
        });
    };

    const onChangeConditionTimeInterval = (setState, id) => event => {
        const { name, checked } = event.target;

        return checked && setState(prev => {
            const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })

            return ArrayUse.replace(prev, index, {
                ...collection,
                _selectedTimeInterval: {
                    ...collection._selectedTimeInterval,
                    _intervalType: map(collection._selectedTimeInterval._intervalType, item =>
                        eq(item.name, name)
                            ? { ...item, isChecked: true }
                            : { ...item, isChecked: false }
                    )
                }
            })
        });
    };

    const selectedConditionTimeInterval = (id, type) => some(find(condition, { id: id })._selectedTimeInterval._intervalType, { id: type, isChecked: true });

    const selectedSegmentationFilter = (show, id, type) => show && some(find(condition, { id: id })._options, { id: type, isChecked: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInputMultiple(setCondition, item.id), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeDropdownMultiple(setCondition, item.id), []);

    const isRequiredAtLeastOneCondition = !(required && (eq(index, 0)));

    return (
        <>
            <li key={item.id}>
                <div className={styles.filterItemContainer}>
                    <AsideDropdownButtonItemButton
                        name={translator(`actionSidebars:${item.conditionName}`) + ' ' + (index + 1)}
                        onClick={onChangeDropdownShowStatus(item.id, setCondition)}
                        active={item.show}
                        alternativeColor
                    />
                    {isRequiredAtLeastOneCondition &&
                        <DeleteButton onClick={onClickRemoveDropdownItem(item.id, setCondition)}/>
                    }
                </div>
                {item.show &&
                    <div>
                        <p className={styles.sidebarSectionName}>{translator('actionSidebars:SegmentationFilterLabel')}</p>
                        <div className={styles.radioboxContainer}>
                            {item._options.map((elem, index) => <RadioButton
                                key={index}
                                name={elem.name}
                                labelName={translator(`actionSidebars:${elem.name}`)}
                                id={elem.id + item.id}
                                checked={elem.isChecked}
                                onChange={onChangeSegmentationFilter(setCondition, item.id)}
                            />)}
                        </div>
                    </div>
                }

                {selectedSegmentationFilter(item.show, item.id, 'event') &&
                    <div className={styles.conditionContainer}>
                        <CustomSelectDropdown
                            dropdownName='actionName'
                            dropdownLabel={translator('actionSidebars:actionName')}
                            defaultDropdownValue={item._eventValues.actionName.value}
                            dropdownOptions={mappedEventNames}
                            onChangeDropdown={onChangeDropdownCached}
                            isErrorExist={actionName.hasError}
                            isRequired={item._eventValues.actionName.required}
                        />

                        <Label htmlFor="actionSubName" text={translator('actionSidebars:actionSubName')}/>
                        <InputField
                            type='text'
                            id='actionSubName'
                            name='actionSubName'
                            value={item._eventValues.actionSubName.value}
                            required={item._eventValues.actionSubName.required}
                            isInputWithoutErrorField
                            onChange={onChangeCached}
                        />

                        <div className={styles.timeIntervalRadioboxContainer}>
                            {map(item._selectedTimeInterval._intervalType, (elem, index) => <RadioButton
                                key={elem.id}
                                name={elem.name}
                                labelName={translator(`actionSidebars:${elem.name}`)}
                                id={elem.id + item.id}
                                checked={elem.isChecked}
                                onChange={onChangeConditionTimeInterval(setCondition, item.id)}
                            />)}
                        </div>

                        {selectedConditionTimeInterval(item.id, 'lastTime') &&
                            <>
                                <Label htmlFor="countDays" text={translator('actionSidebars:countDays')}/>
                                <InputField
                                    type='number'
                                    id='countDays'
                                    name='countDays'
                                    value={item._eventValues.countDays.value}
                                    required={item._eventValues.countDays.required}
                                    error={selectedTimeInterval.countDays.hasError}
                                    onChange={onChangeCached}
                                />
                                <ErrorMessageField
                                    active={selectedTimeInterval.countDays.hasError}
                                    message={selectedTimeInterval.countDays.message}
                                />

                                <Label htmlFor="countHours" text={translator('actionSidebars:countHours')}/>
                                <InputField
                                    type='number'
                                    id='countHours'
                                    name='countHours'
                                    value={item._eventValues.countHours.value}
                                    required={item._eventValues.countHours.required}
                                    error={selectedTimeInterval.countHours.hasError}
                                    onChange={onChangeCached}
                                />
                                <ErrorMessageField
                                    active={selectedTimeInterval.countHours.hasError}
                                    message={selectedTimeInterval.countHours.message}
                                />
                            </>
                        }

                        {selectedConditionTimeInterval(item.id, 'currentTime') &&
                            <div className={styles.calendarContainer}>
                                <SelectedDatesIntervalViewer
                                    item={item}
                                    errors={pick(selectedTimeInterval, ['startDate', 'endDate'])}
                                    label={translator('actionSidebars:TimeInterval')}
                                />
                            </div>
                        }
                    </div>
                }

                {selectedSegmentationFilter(item.show, item.id, 'event') &&
                    <AttributeFilterDropdownDeep
                        id={item.id}
                        conditionIndex={index}
                        setState={setCondition}
                        state={condition}
                        array={item._attributeFilter}
                    />}

                {selectedSegmentationFilter(item.show, item.id, 'event') &&
                    <AggregationSection
                        item={item}
                        conditionIndex={index}
                        setState={setCondition}
                    />}

                {selectedSegmentationFilter(item.show, item.id, 'attribute') &&
                    <ConditionSegmentationFilter
                        data={item._clientAttributeValues}
                        setState={setCondition}
                        conditionIndex={index}
                        conditionId={item.id}
                        segmentationFilterType='attribute'
                        segmentationFilterKey='_clientAttributeValues'
                    />}

                {selectedSegmentationFilter(item.show, item.id, 'metric') &&
                    <ConditionSegmentationFilter
                        data={item._metricAttributeValues}
                        setState={setCondition}
                        conditionIndex={index}
                        conditionId={item.id}
                        segmentationFilterType='metric'
                        segmentationFilterKey='_metricAttributeValues'
                    />}

                <AsideDropdownButtonSeparateLine
                    onClick={onChangeDropdownShowStatus(item.id, setCondition)}
                    alternativeColor={true}
                />
            </li>
        </>
    )
};

ConditionMapped.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
};
