import { createSelector } from 'reselect';
import { find, map } from 'lodash';

export const findScenariosAction = createSelector(
    store => store.scenariosActions.actionsList,
    (_, actionId) => actionId,
    (actionsList, actionId) => find(actionsList , { id: actionId })
);

export const getStoreDataWait = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id : scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            waitInterval: {
                ...settings.waitInterval,
                value: settings.waitInterval?.value
            }
        }
    }
);

export const getStoreDataConditionalSplit = createSelector(
    store => store.scenariosActions,
    store => store.segments.list,
    (scenariosActions, segmentsList) => {
        const { settings, title, actionType, actionParams, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        const segmentsButtonsList = map(segmentsList, segment => ({
            id: segment.segment_id,
            name: segment.segment_name,
            selected: false
        }));

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            _actionParams: actionParams,
            _splitType: settings.splitType,
            _values: settings.values,
            _attributeFilter: settings.attributeFilter,
            _productAttributeFilter: settings.productAttributeFilter,
            _condition: settings.condition,
            _segmentId: settings.segmentId,
            segmentsButtonsList
        }
    },
);

export const getStoreDataCustomWebHook = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            _values: settings.values,
            _jsonHeaders: settings.jsonHeaders,
            _jsonBody: settings.jsonBody,
        };
    },
);

export const getStoreDataFunnel = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            selectedType: settings.selectedType,
            shareCount: settings.shareCount,
            countClients: settings.countClients,
            countTimeIntervalNumber: settings.countTimeIntervalNumber,
            countTimeIntervalType: settings.countTimeIntervalType,
            evalTimeInterval: settings.evalTimeInterval
        }
    },
);

export const getStoreDataSqlTrigger = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            selectedType: settings.selectedType,
            _dayOfWeek: settings.dayOfWeek,
            _atTheDay: settings.atTheDay,
            _time: settings.time,
            _sql: settings.sql
        }
    },
);

export const getStoreDataPushAndSendContent = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, id, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id : scenariosActions.actionSidebar.id });

        const chooseReceiverContactType = {
            SendEmail: {
                receiverContactEmail: settings.values.receiverContactEmail,
                communicationsMaxCount: settings.values.communicationsMaxCount,
            },
            SendSms: {
                receiverContactPhone: settings.values.receiverContactPhone,
                communicationsMaxCount: settings.values.communicationsMaxCount,
            },
            SendViber: {
                receiverContactPhone: settings.values.receiverContactPhone,
                communicationsMaxCount: settings.values.communicationsMaxCount,
            },
            SendMobPush: {
                communicationsMaxCount: settings.values.communicationsMaxCount,
            },
            SendWebPush: {
                communicationsMaxCount: settings.values.communicationsMaxCount,
            }
        };

        return {
            actionType,
            isAutoSaveActive,
            errors,
            _values: {
                ...chooseReceiverContactType[actionType],
                countTimeIntervalNumber: settings.values.countTimeIntervalNumber,
                countTimeIntervalType: settings.values.countTimeIntervalType,
                templateCache: settings.values.templateCache,
                policyId: settings.values.policyId
            },
            _minDispatchTime: settings.minDispatchTime,
            _maxDispatchTime: settings.maxDispatchTime,
            _sideBarType: actionType,
            _sideBarTitle: title,
            _templateUniqueId: settings.templateUniqueId,
            _actionId: id,
        }
    }
);

export const getStoreDataWebsiteBuilder = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, id, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id : scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            _values: settings?.values,
            _displayDate: settings?.displayDate,
            _urls: settings?.urls,
            _actionId: id,
            _templateUniqueId: settings.templateUniqueId,
            _sideBarType: actionType
        }
    }
);
export const getStoreDataScheduleTrigger = createSelector(
    store => store.scenariosActions,
    store => store.segments.list,
    (scenariosActions, segmentsList) => {
        const { settings, actionType, actionParams, title, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id : scenariosActions.actionSidebar.id });

        const segmentsButtonsList = map(segmentsList, segment => ({
            id: segment.segment_id,
            name: segment.segment_name,
            selected: false
        }));

        return {
            _atTheDay: settings.atTheDay,
            _time: settings.time,
            _values: settings.values,
            _condition: settings.condition,
            _actionParams: actionParams,
            _segmentId: settings.segmentId,
            errors,
            actionType,
            isAutoSaveActive,
            title,
            segmentsButtonsList
        }
    }
);
export const getStoreDataRepeatTrigger = createSelector(
    store => store.scenariosActions,
    store => store.segments.list,
    (scenariosActions, segmentsList) => {
        const { settings, actionType, actionParams, title, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id : scenariosActions.actionSidebar.id });

        const segmentsButtonsList = map(segmentsList, segment => ({
            id: segment.segment_id,
            name: segment.segment_name,
            selected: false
        }));

        return {
            _dayOfWeek: settings.dayOfWeek,
            _time: settings.time,
            _values: settings.values,
            _condition: settings.condition,
            _actionParams: actionParams,
            _segmentId: settings.segmentId,
            errors,
            actionType,
            isAutoSaveActive,
            title,
            segmentsButtonsList
        }
    }
);

export const getStoreDataTailoredWait = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            waitToDate: settings.waitToDate,
            dateOffset: settings.dateOffset,
            waitToTime: settings.waitToTime,
            timeOffset: settings.timeOffset
        }
    },
);

export const getStoreDataTriggerEvent = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { id, settings, title, actionType, isAutoSaveActive, actionParams, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            id,
            title,
            actionType,
            isAutoSaveActive,
            errors,
            _actionParams: actionParams,
            _values: settings.values,
            _attributeFilter: settings.attributeFilter
        }
    }
);

export const getStoreDataABTest = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { settings, title, actionType, isAutoSaveActive, errors } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            controlGroup: settings.controlGroup,
            testGroup: settings.testGroup
        }
    }
);

export const getStoreDataEditSegment = createSelector(
    store => store.scenariosActions,
    scenariosActions => {
        const { title, actionType, isAutoSaveActive, errors, settings } = find(scenariosActions.actionsList, { id: scenariosActions.actionSidebar.id });

        return {
            title,
            actionType,
            isAutoSaveActive,
            errors,
            settings,
        }
    }
);
