import React from 'react';

export const TriggerEventIconAlt = ({ borderColor }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="47.5" y="0.5" width="47" height="47" rx="4.5" transform="rotate(90 47.5 0.5)" fill="white" stroke={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.9543 32.9558C14.8596 32.9171 14.6145 32.812 14.4049 32.5824C14.0474 32.1907 13.9313 31.688 14.0382 31.2096C14.1203 30.8424 14.3209 30.5542 14.4371 30.3971C14.5564 30.236 14.7038 30.064 14.8303 29.9164C14.8417 29.9031 14.8529 29.89 14.8639 29.8772C15.0093 29.7073 15.1547 29.536 15.302 29.3442C15.8631 28.6131 16.4777 27.5529 16.4777 25.8143C16.4777 25.7748 16.4777 25.7351 16.4776 25.6951C16.4773 23.6979 16.4769 21.123 17.3144 19.0442C17.7574 17.9446 18.4543 16.9244 19.5465 16.1894C20.6378 15.4551 22.0174 15.0769 23.7018 15.0769C25.3886 15.0769 26.7925 15.4558 27.9288 16.1673C29.0646 16.8785 29.8503 17.8701 30.3856 18.9638C31.4313 21.1008 31.5689 23.731 31.5242 25.8402C31.4892 27.493 32.0912 28.5431 32.669 29.2975C32.8196 29.4942 32.9689 29.671 33.1182 29.8466C33.1286 29.8588 33.1391 29.8711 33.1497 29.8836C33.2808 30.0375 33.4317 30.2148 33.5542 30.3808C33.6728 30.5417 33.8714 30.8284 33.9569 31.1891C34.0676 31.6561 33.971 32.173 33.5972 32.5824C33.3877 32.812 33.1425 32.9171 33.0479 32.9558C32.9204 33.0079 32.7922 33.0442 32.6842 33.0705C32.4655 33.1239 32.2072 33.1653 31.9377 33.1996C31.3915 33.269 30.6734 33.3239 29.8584 33.3669C28.2215 33.4534 26.1061 33.496 24.0011 33.496C21.896 33.496 19.7807 33.4534 18.1437 33.3669C17.3287 33.3239 16.6106 33.269 16.0645 33.1996C15.7949 33.1653 15.5366 33.1239 15.318 33.0705C15.2099 33.0442 15.0818 33.0079 14.9543 32.9558ZM15.3235 31.756C15.9433 32.435 32.0588 32.435 32.6786 31.756C32.9071 31.5057 32.6304 31.1805 32.1942 30.6678C31.4467 29.7893 30.2309 28.3602 30.2848 25.8143C30.3729 21.6561 29.6864 16.3077 23.7018 16.3077C17.7173 16.3077 17.7173 21.6552 17.7173 25.8143C17.7173 28.4454 16.5113 29.8537 15.782 30.7052C15.3585 31.1997 15.0958 31.5065 15.3235 31.756Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4046 14.0256C22.0929 14.506 21.9922 15.1193 22.0709 15.5918L20.8479 15.7928C20.7215 15.0345 20.8756 14.1094 21.3624 13.3591C21.8667 12.5818 22.7269 12 23.9387 12C25.1645 12 25.9688 12.5959 26.4339 13.38C26.8759 14.1253 27.0034 15.0169 27.0034 15.6923L25.7637 15.6923C25.7638 15.137 25.6537 14.4901 25.3657 14.0046C25.1008 13.558 24.6827 13.2308 23.9387 13.2308C23.1808 13.2308 22.6988 13.572 22.4046 14.0256Z" fill={borderColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.2042 35.0984C20.5366 34.5751 19.9767 33.8005 19.9767 32.9231H21.2164C21.2164 33.2764 21.4623 33.7326 21.9724 34.1324C22.4702 34.5226 23.113 34.7692 23.6957 34.7692C24.1371 34.7692 24.797 34.66 25.3233 34.3591C25.8188 34.0757 26.175 33.6435 26.175 32.9231H27.4147C27.4147 34.1719 26.7379 34.9704 25.942 35.4256C25.177 35.863 24.2874 36 23.6957 36C22.7908 36 21.8841 35.6313 21.2042 35.0984Z" fill={borderColor}/>
    </svg>

);
