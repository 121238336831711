import { Validation } from 'validations/Validation';
import { compact, eq, find, isEmpty, map } from 'lodash';
import { ObjectUse } from 'lib/ObjectUse';
import { attributeFilterControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/attributeFilterControl';

const actionNameRules = {
    value: 'required'
};
const lastTimeValuesRules = {
    countDays: 'required',
    countHours: 'required'
};
const currentTimeValuesRules = {
    startDate: 'required',
    endDate: 'required'
};

const createAttributeFilterValidationReportObject = errors => {
    if (isEmpty(errors)) return { passes: true, fails: false, ...errors };

    return { passes: false, fails: true, ...errors };
};

const createActionNameValidationReportObject = errors => {
    if (errors.error) return { passes: false, fails: true, ...errors };

    return { passes: true, fails: false, ...errors };
};

const getAggregationSettings = (isAggregationFunctionCountSelected, aggregationColumn, aggregationComparisonOperator) => {
    if (isAggregationFunctionCountSelected) {
        return {
            validate: aggregationComparisonOperator,
            rules: { value: 'required' },
            messages: { 'required.value': 'InputValidationRequiredMessage' },
            attributes: 'value'
        }
    }

    return {
        validate: {
            aggregationColumn: aggregationColumn.value,
            aggregationComparisonOperator: aggregationComparisonOperator.value
        },
        rules: {
            aggregationColumn: 'required',
            aggregationComparisonOperator: 'required'
        },
        messages: {
            'required.aggregationColumn': 'InputValidationRequiredMessage',
            'required.aggregationComparisonOperator': 'InputValidationRequiredMessage',
        },
        attributes: ['aggregationColumn', 'aggregationComparisonOperator']
    }
};

const aggregationFunctionValidate = (isAggregationFunctionCountSelected, aggregation) => {
    const { validate, rules, messages, attributes } = aggregation;

    if (isAggregationFunctionCountSelected) {
        return Validation.check(validate, rules, messages, attributes);
    }

    return Validation.checkSome(validate, rules, messages, attributes);
};

export const eventSegmentationTypeValidation = condition => {
    return () => {
        const {
            _eventValues: {
                actionName,
                countDays,
                countHours,
                aggregationFunction,
                aggregationColumn,
                aggregationComparisonOperator
            },
            _selectedTimeInterval: {
                _intervalType,
                _intervalDate
            },
            _attributeFilter
        } = condition;

        const actionNameResult = Validation.check(actionName, actionNameRules, {
            'required.value': 'InputValidationRequiredMessage',
        }, 'value');

        const selectedIntervalType = find(_intervalType, 'isChecked');

        const intervalValidationTypes = {
            lastTime: () => {
                const lastTimeValues = {
                    countDays: countDays.value,
                    countHours: countHours.value
                };
                const validation = Validation.checkSome(lastTimeValues, lastTimeValuesRules, {
                    'required.countDays': 'InputValidationRequiredMessage',
                    'required.countHours': 'InputValidationRequiredMessage',
                }, ['countDays', 'countHours']);

                return {
                    lastTime: {
                        ...ObjectUse.createSeveralObjects([
                            validation.firstErrors.countDays,
                            validation.firstErrors.countHours,
                        ], ['countDays', 'countHours'])
                    },
                    passes: validation.passes,
                    fails: validation.fails
                }
            },
            currentTime: () => {
                const validation = Validation.checkSome(_intervalDate, currentTimeValuesRules, {
                    'required.startDate': 'InputValidationRequiredMessage',
                    'required.endDate': 'InputValidationRequiredMessage',
                }, ['startDate', 'endDate']);

                return {
                    currentTime: {
                        ...ObjectUse.createSeveralObjects([
                            validation.firstErrors.startDate,
                            validation.firstErrors.endDate
                        ], ['startDate', 'endDate'])
                    },
                    passes: validation.passes,
                    fails: validation.fails
                }
            },
            atScriptStart: () => {
                return {
                    atScriptStart: {},
                    passes: true,
                    fails: false
                }
            }
        };

        const intervalValidationResult = intervalValidationTypes[selectedIntervalType.id]();

        const attributeFilterResult = attributeFilterControl(_attributeFilter);

        const isAggregationFunctionCountSelected = eq(aggregationFunction.value, 'COUNT');

        const aggregation = getAggregationSettings(isAggregationFunctionCountSelected, aggregationColumn, aggregationComparisonOperator);

        const aggregationFunctionResult = aggregationFunctionValidate(isAggregationFunctionCountSelected, aggregation);

        const isEventSegmentationHasErrors = !!compact(map([
            createActionNameValidationReportObject({ error: actionNameResult.firstError }).fails,
            intervalValidationResult.fails,
            createAttributeFilterValidationReportObject(attributeFilterResult).fails,
            aggregationFunctionResult.fails
        ], report => report)).length;

        return {
            actionName: createActionNameValidationReportObject({ error: actionNameResult.firstError }),
            selectedTimeInterval: intervalValidationResult,
            attributeFilter: createAttributeFilterValidationReportObject(attributeFilterResult),
            aggregationFunction: aggregationFunctionResult,
            passes: !isEventSegmentationHasErrors,
            fails: isEventSegmentationHasErrors
        }
    }
};
