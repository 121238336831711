import React from 'react';
import styles from 'components/ScenarioSetup/ActionConnections/ActionConnections.module.css';
import PropTypes from 'prop-types';
import { DisconnectArrowIcon } from 'assets/icons/DisconnectArrowIcon';

export const DisconnectLabel = ({ onClick }) =>
    <div className={styles.disconnectLabel} onClick={onClick}>
        <DisconnectArrowIcon/>
    </div>

DisconnectLabel.propTypes = {
    onClick: PropTypes.func.isRequired
}
