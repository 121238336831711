import React from 'react';

export const ConditionalSplitIconDropped = ({ color, bgColor, width, height }) => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="33.9412" width="48" height="48" rx="5" transform="rotate(45 33.9412 0)" fill="#00C6A2"/>
        <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="20" y="20" width="28" height="28">
            <rect x="20" y="20" width="28" height="28" fill="#C4C4C4"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.226 23.774C44.3969 23.9448 44.3969 24.2218 44.226 24.3927L40.726 27.8927C40.5552 28.0635 40.2782 28.0635 40.1073 27.8927C39.9365 27.7218 39.9365 27.4448 40.1073 27.274L43.6073 23.774C43.7782 23.6031 44.0552 23.6031 44.226 23.774Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.826 23.7193C28.0271 23.8533 28.0814 24.125 27.9474 24.326L25.614 27.826C25.5497 27.9226 25.4496 27.9896 25.3358 28.0123C25.222 28.0351 25.1039 28.0117 25.0073 27.9474L23.2573 26.7807C23.0563 26.6467 23.0019 26.375 23.136 26.174C23.27 25.9729 23.5416 25.9186 23.7427 26.0526L25.1287 26.9766L27.2193 23.8407C27.3533 23.6396 27.625 23.5853 27.826 23.7193Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.1074 23.774C40.2783 23.6031 40.5553 23.6031 40.7261 23.774L44.226 27.274C44.3969 27.4448 44.3969 27.7218 44.226 27.8927C44.0552 28.0635 43.7782 28.0635 43.6073 27.8927L40.1074 24.3927C39.9366 24.2218 39.9366 23.9448 40.1074 23.774Z" fill="white"/>
        <path d="M33.894 45.6667C34.2454 45.6667 34.5303 45.3818 34.5303 45.0303C34.5303 44.6789 34.2454 44.3939 33.894 44.3939C33.5425 44.3939 33.2576 44.6789 33.2576 45.0303C33.2576 45.3818 33.5425 45.6667 33.894 45.6667Z" fill="white"/>
        <path d="M33.8944 38.6667C32.7716 38.6667 31.8581 39.5802 31.8581 40.703C31.8581 40.9842 32.086 41.2121 32.3672 41.2121C32.6483 41.2121 32.8763 40.9842 32.8763 40.703C32.8763 40.1416 33.333 39.6848 33.8944 39.6848C34.4559 39.6848 34.9126 40.1416 34.9126 40.703C34.9126 41.2645 34.4559 41.7212 33.8944 41.7212C33.6133 41.7212 33.3853 41.9491 33.3853 42.2303V43.503C33.3853 43.7842 33.6133 44.0121 33.8944 44.0121C34.1756 44.0121 34.4035 43.7842 34.4035 43.503V42.675C35.2808 42.4483 35.9308 41.6502 35.9308 40.703C35.9308 39.5802 35.0173 38.6667 33.8944 38.6667Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.0833 37.2083H29.9167C29.4334 37.2083 29.0417 37.6001 29.0417 38.0833V46.25C29.0417 46.7332 29.4334 47.125 29.9167 47.125H38.0833C38.5666 47.125 38.9583 46.7332 38.9583 46.25V38.0833C38.9583 37.6001 38.5666 37.2083 38.0833 37.2083ZM29.9167 36.3333C28.9502 36.3333 28.1667 37.1168 28.1667 38.0833V46.25C28.1667 47.2165 28.9502 48 29.9167 48H38.0833C39.0498 48 39.8333 47.2165 39.8333 46.25V38.0833C39.8333 37.1168 39.0498 36.3333 38.0833 36.3333H29.9167Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.8333 30.7917C28.5717 30.7917 30.7917 28.5717 30.7917 25.8333C30.7917 23.0949 28.5717 20.875 25.8333 20.875C23.0949 20.875 20.875 23.0949 20.875 25.8333C20.875 28.5717 23.0949 30.7917 25.8333 30.7917ZM25.8333 31.6667C29.055 31.6667 31.6667 29.055 31.6667 25.8333C31.6667 22.6117 29.055 20 25.8333 20C22.6117 20 20 22.6117 20 25.8333C20 29.055 22.6117 31.6667 25.8333 31.6667Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.1667 30.7917C44.9051 30.7917 47.125 28.5717 47.125 25.8333C47.125 23.0949 44.9051 20.875 42.1667 20.875C39.4283 20.875 37.2083 23.0949 37.2083 25.8333C37.2083 28.5717 39.4283 30.7917 42.1667 30.7917ZM42.1667 31.6667C45.3883 31.6667 48 29.055 48 25.8333C48 22.6117 45.3883 20 42.1667 20C38.945 20 36.3333 22.6117 36.3333 25.8333C36.3333 29.055 38.945 31.6667 42.1667 31.6667Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.8333 30.8608C26.075 30.8608 26.2708 30.8417 26.2708 31.0833V32.9792H41.7292V31.0833C41.7292 30.8417 42.0709 30.9375 42.3125 30.9375C42.5541 30.9375 42.6042 30.8417 42.6042 31.0833V33.4167C42.6042 33.6583 42.4083 33.8542 42.1667 33.8542H34.4375V36.3333C34.4375 36.575 34.2416 36.7708 34 36.7708C33.7584 36.7708 33.5625 36.575 33.5625 36.3333V33.8542H25.8333C25.5917 33.8542 25.3958 33.6583 25.3958 33.4167V31.0833C25.3958 30.8417 25.5917 30.8608 25.8333 30.8608Z" fill="white"/>
    </svg>
);
