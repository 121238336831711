import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/_Buttons/_GearSwitchableButton/GearSwitchableButton.module.css';
import { GearIcon } from 'assets/icons/GearIcon';
import { GearIconAlt } from 'assets/icons/GearIconAlt';

export const GearSwitchableButton = ({ active, onClick }) => (
    <div className={styles.gearSwitchableButton} onClick={onClick}>
        {active
            ? <GearIcon/>
            : <GearIconAlt/>
        }
    </div>
);

GearSwitchableButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};
GearSwitchableButton.defaultProps = {
    active: false
}
