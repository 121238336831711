import { apiRequestAction } from 'store/actions/api.action';
import { CommonMethods } from 'lib/CommonMethods';
import { map, filter, includes, eq, forEach, some, findIndex, find, toNumber } from 'lodash';
import { ArrayUse } from 'lib/ArrayUse';
import {
    FETCH_ALL_INTEGRATION_EVENTS,
    SET_ALL_INTEGRATION_EVENTS,
    FETCH_CREATE_INTEGRATION_EVENT,
    SET_CREATE_INTEGRATION_EVENT,
    SET_FILTERED_ALL_INTEGRATION_EVENTS,
    FETCH_DELETE_INTEGRATION_EVENT,
    SET_DELETE_INTEGRATION_EVENT,
    PUT_CSV_TABLE_DATA,
    FETCH_UPDATE_EVENT_SCHEMA_FIELDS,
    SET_UPDATE_EVENT_SCHEMA_FIELDS,
    SET_CSV_TABLE_COLUMN_COMPOUND,
    DELETE_CSV_TABLE_COLUMN_COMPOUND,
    RESET_DRAGGABLE_COMPOUNDS_KEYS,
    SET_CURRENT_EVENT,
    CLEAR_CURRENT_EVENT,
    FETCH_START_UPLOAD_CSV_TABLE,
    SET_START_UPLOAD_CSV_TABLE,
    SET_FAILURE_START_UPLOAD_CSV_TABLE,
    FETCH_GET_UPLOAD_CSV_TABLE_HISTORY,
    SET_GET_UPLOAD_CSV_TABLE_HISTORY,
    FETCH_GET_CONFIG,
    SET_GET_CONFIG
} from 'store/types';

export const getAllIntegrationEvents = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`event_schemas`),
        method: 'GET',
        onSuccess: setAllIntegrationEvents,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_ALL_INTEGRATION_EVENTS,
    })
);

const setAllIntegrationEvents = data => {

    return {
        type: SET_ALL_INTEGRATION_EVENTS,
        payload: map(data, item => ({ ...item, event_schema: JSON.parse(item.event_schema) }))
    }
};

export const setCurrentEvent = eventId => (dispatch, getStore) => {
    const events = getStore().integrations.allEvents.base;

    const currentEvent = find(events, { event_id: toNumber(eventId) });
    console.log('currentEvent', currentEvent);
    if (currentEvent) {
        dispatch({
            type: SET_CURRENT_EVENT,
            payload: currentEvent
        });

        return Promise.resolve();
    }
};
export const clearCurrentEvent = () => dispatch =>
    dispatch({
        type: CLEAR_CURRENT_EVENT
    })

const setCreatedEvent = data => (dispatch, getStore) =>  {
    const { base: allEvents } = getStore().integrations.allEvents;

    dispatch({
        type: SET_CREATE_INTEGRATION_EVENT,
        payload: [
            ...allEvents,
            {
                ...data,
                event_schema: JSON.parse(data.event_schema)
            }
        ]
    })
};

export const createIntegrationEvent = (data, onAfterSuccessCallback) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`event_schemas`),
        method: 'POST',
        onSuccess: setCreatedEvent,
        data,
        onAfterSuccess: onAfterSuccessCallback,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CREATE_INTEGRATION_EVENT,
    })
);

export const filteredAllIntegrationEvents = (searchQuery, topicOptions, internalOptions) => (dispatch, getStore) => {
    let allEvents = getStore().integrations.allEvents.base;

    if(searchQuery) {
        allEvents = filter(allEvents, ({ event_name }) => includes(event_name, searchQuery));
    }

    if (some(topicOptions,  { isChecked: false } )) {
        const topicTypes = {
            'ClientEvents': 'CLIENT_EVENTS',
            'ProductEvents': 'PRODUCT_EVENTS'
        };

        forEach(topicOptions, ({ name, isChecked }) => {
            if (!isChecked) {
                allEvents = filter(allEvents, ({ topic_type }) => !eq(topic_type, topicTypes[name]));
            }
        });
    }

    if (some(internalOptions,  { isChecked: false } )) {
        const internalNames = {
            'Yes': true,
            'No': false
        };

        forEach(internalOptions, ({ name, isChecked }) => {
            if (!isChecked) {
                allEvents = filter(allEvents, { is_internal: !internalNames[name]});
            }
        });
    }

    dispatch({
        type: SET_FILTERED_ALL_INTEGRATION_EVENTS,
        payload: allEvents
    })
};

const setDeleteEvent = eventId => (dispatch, getStore) =>  {
    const { base: allEvents } = getStore().integrations.allEvents;

    dispatch({
        type: SET_DELETE_INTEGRATION_EVENT,
        payload: filter(allEvents, ({ event_id }) => !eq(event_id, eventId))
    })
};

export const deleteIntegrationEvent = eventId => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`event_schemas/${eventId}`),
        method: 'DELETE',
        onSuccess: setDeleteEvent,
        extraOptions: eventId,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_DELETE_INTEGRATION_EVENT,
    })
);

const setUpdateEventSchemaFields = data => (dispatch, getStore) => {
    const { base: allEvents } = getStore().integrations.allEvents;

    const index = findIndex(allEvents, { event_id: data.event_id });

    const updatedEvent = {
        ...data,
        event_schema: JSON.parse(data.event_schema)
    };

    dispatch({
        type: SET_UPDATE_EVENT_SCHEMA_FIELDS,
        payload: {
            base: ArrayUse.replace(allEvents, index, updatedEvent),
            currentEvent: updatedEvent
        }
    })
};

export const updateEventSchemaFields = (eventId, data, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`event_schemas/${eventId}`),
        method: 'PUT',
        data: {
            event_schema: data
        },
        onAfterSuccess,
        onSuccess: setUpdateEventSchemaFields,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_UPDATE_EVENT_SCHEMA_FIELDS,
    })
);

export const putCsvTableData = data => dispatch => {
    dispatch({
        type: PUT_CSV_TABLE_DATA,
        payload: data
    })

    return Promise.resolve();
};

export const setCsvTableColumnCompound = data => (dispatch, getStore) => {
    const draggableCompoundsKeys = getStore().integrations.draggableCompoundsKeys;

    dispatch({
        type: SET_CSV_TABLE_COLUMN_COMPOUND,
        payload: {
            compound: data,
            filteredDraggableCompoundsKeys: ArrayUse.remove(draggableCompoundsKeys, find(draggableCompoundsKeys, { value: data.compoundKey }))
        }
    });
};

export const deleteCsvTableColumnCompound = (value, type) => (dispatch, getStore) => {
    const draggableCompoundsKeys = getStore().integrations.draggableCompoundsKeys;
    const compounds = getStore().integrations.csvTableColumnCompounds;

    dispatch({
        type: DELETE_CSV_TABLE_COLUMN_COMPOUND,
        payload: {
            cleanedCompounds: ArrayUse.remove(compounds, find(compounds, { compoundKey: value })),
            increasedDraggableCompoundsKeys: [...draggableCompoundsKeys, { value: value, type: type }]
        }
    })

    return Promise.resolve();
};

export const resetDraggableCompoundsKeys = () => dispatch =>
    dispatch({
        type: RESET_DRAGGABLE_COMPOUNDS_KEYS
    })

const setStartUploadCsvTable = data => ({
    type: SET_START_UPLOAD_CSV_TABLE,
    payload: data
});

const setFailureStartUploadCsvTable = (data, dispatch) => {
    console.log('%cRequest failure:', 'color:indianred', data);

    dispatch({
        type: SET_FAILURE_START_UPLOAD_CSV_TABLE,
        payload: {
            isFailure: true
        }
    })
};

export const startUploadCsvTable = data => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('event_schemas/upload_events_csv'),
        method: 'POST',
        data: data,
        onSuccess: setStartUploadCsvTable,
        onFailure: setFailureStartUploadCsvTable,
        label: FETCH_START_UPLOAD_CSV_TABLE,
    })
);

const setUploadCsvTableHistory = data => (dispatch, getStore) => {
    const csvTableUploadResult = getStore().integrations.csvTableUploadResult;

    dispatch({
        type: SET_GET_UPLOAD_CSV_TABLE_HISTORY,
        payload: find(data, { id: csvTableUploadResult.id })
    })
};

export const getUploadCsvTableHistory = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('event_schemas/upload_history'),
        method: 'GET',
        onSuccess: setUploadCsvTableHistory,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_GET_UPLOAD_CSV_TABLE_HISTORY,
    })
);

const setGetConfig = data => ({
    type: SET_GET_CONFIG,
    payload: {
        data: data
    }
});

export const getConfig = data => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('config'),
        method: 'GET',
        data: data,
        onSuccess: setGetConfig,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_GET_CONFIG,
    })
);
