import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { DraggableTypes } from 'lib/DraggableTypes';

export const useActionLocalDrop = onDrop => {
    const ref = useRef();

    const [, dropTarget] = useDrop({
        accept: DraggableTypes.ACTION,
        drop(item, monitor) {
            const offset = monitor.getSourceClientOffset();

            if (offset && ref.current) {
                const dropTargetXy = ref.current.getBoundingClientRect();

                onDrop(DraggableTypes.ACTION, item, {
                    x: offset.x - dropTargetXy.left,
                    y: offset.y - dropTargetXy.top,
                });
            }
        },
    });

    return elem => {
        ref.current = elem;
        dropTarget(ref);
    };
};
