import React from 'react';

export const ConditionalSplitIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
        <rect width="32px" height="32px" rx="3.5" fill={bgColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.378 8.62204C23.5407 8.78476 23.5407 9.04858 23.378 9.21129L20.878 11.7113C20.7152 11.874 20.4514 11.874 20.2887 11.7113C20.126 11.5486 20.126 11.2848 20.2887 11.122L22.7887 8.62204C22.9514 8.45932 23.2152 8.45932 23.378 8.62204Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6478 8.56998C11.8393 8.69763 11.891 8.95632 11.7634 9.14779L10.0967 11.6478C9.96904 11.8393 9.71035 11.891 9.51887 11.7634L8.26887 10.93C8.0774 10.8024 8.02567 10.5437 8.15331 10.3522C8.28096 10.1607 8.53965 10.109 8.73112 10.2366L9.63444 10.8389L11.07 8.68554C11.1976 8.49407 11.4563 8.44233 11.6478 8.56998Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.2888 8.62204C20.4515 8.45932 20.7153 8.45932 20.878 8.62205L23.378 11.122C23.5407 11.2848 23.5407 11.5486 23.378 11.7113C23.2152 11.874 22.9514 11.874 22.7887 11.7113L20.2888 9.21129C20.1261 9.04857 20.1261 8.78475 20.2888 8.62204Z" fill={color}/>
        <path d="M15.9244 24.3333C16.1754 24.3333 16.3789 24.1298 16.3789 23.8788C16.3789 23.6278 16.1754 23.4242 15.9244 23.4242C15.6733 23.4242 15.4698 23.6278 15.4698 23.8788C15.4698 24.1298 15.6733 24.3333 15.9244 24.3333Z" fill={color}/>
        <path d="M15.9246 19.3333C15.1226 19.3333 14.4701 19.9858 14.4701 20.7879C14.4701 20.9887 14.6329 21.1515 14.8337 21.1515C15.0345 21.1515 15.1973 20.9887 15.1973 20.7879C15.1973 20.3869 15.5236 20.0606 15.9246 20.0606C16.3256 20.0606 16.6519 20.3869 16.6519 20.7879C16.6519 21.1889 16.3256 21.5152 15.9246 21.5152C15.7238 21.5152 15.561 21.678 15.561 21.8788V22.7879C15.561 22.9887 15.7238 23.1515 15.9246 23.1515C16.1254 23.1515 16.2882 22.9887 16.2882 22.7879V22.1964C16.9149 22.0345 17.3791 21.4644 17.3791 20.7879C17.3791 19.9858 16.7266 19.3333 15.9246 19.3333Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5 18.5H13.5C13.0398 18.5 12.6667 18.8731 12.6667 19.3333V24.3333C12.6667 24.7936 13.0398 25.1667 13.5 25.1667H18.5C18.9602 25.1667 19.3333 24.7936 19.3333 24.3333V19.3333C19.3333 18.8731 18.9602 18.5 18.5 18.5ZM13.5 17.6667C12.5795 17.6667 11.8333 18.4129 11.8333 19.3333V24.3333C11.8333 25.2538 12.5795 26 13.5 26H18.5C19.4205 26 20.1667 25.2538 20.1667 24.3333V19.3333C20.1667 18.4129 19.4205 17.6667 18.5 17.6667H13.5Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1667 13.5C12.0076 13.5 13.5 12.0076 13.5 10.1667C13.5 8.32572 12.0076 6.83333 10.1667 6.83333C8.32572 6.83333 6.83333 8.32572 6.83333 10.1667C6.83333 12.0076 8.32572 13.5 10.1667 13.5ZM10.1667 14.3333C12.4679 14.3333 14.3333 12.4679 14.3333 10.1667C14.3333 7.86548 12.4679 6 10.1667 6C7.86548 6 6 7.86548 6 10.1667C6 12.4679 7.86548 14.3333 10.1667 14.3333Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.8333 13.5C23.6743 13.5 25.1667 12.0076 25.1667 10.1667C25.1667 8.32572 23.6743 6.83333 21.8333 6.83333C19.9924 6.83333 18.5 8.32572 18.5 10.1667C18.5 12.0076 19.9924 13.5 21.8333 13.5ZM21.8333 14.3333C24.1345 14.3333 26 12.4679 26 10.1667C26 7.86548 24.1345 6 21.8333 6C19.5321 6 17.6667 7.86548 17.6667 10.1667C17.6667 12.4679 19.5321 14.3333 21.8333 14.3333Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1667 13.5C10.3968 13.5 10.5833 13.6865 10.5833 13.9167V15.1667H21.4167V13.9167C21.4167 13.6865 21.6032 13.5 21.8333 13.5C22.0635 13.5 22.25 13.6865 22.25 13.9167V15.5833C22.25 15.8135 22.0635 16 21.8333 16H16.4167V17.6667C16.4167 17.8968 16.2301 18.0833 16 18.0833C15.7699 18.0833 15.5833 17.8968 15.5833 17.6667V16H10.1667C9.93655 16 9.75 15.8135 9.75 15.5833V13.9167C9.75 13.6865 9.93655 13.5 10.1667 13.5Z" fill={color}/>
    </svg>
);
