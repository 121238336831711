export const defaultCustomWebHookSettings = () => ({
    values: {
        maxNumberOfRequests: {
            value: '',
            required: true
        },
        maxFrequencyNumber: {
            value: '',
            required: true
        },
        maxFrequencyType: {
            value: 'minutes',
            required: true
        },
        consentCategory: {
            value: '33064260-9853-4e55-a682-f551772a6a87',
            required: true,
        },
        method: {
            value: 'POST',
            required: true
        },
        url: {
            value: '',
            required: true,
        }
    },
    jsonBody: null,
    jsonHeaders: []
})
