import { find } from 'lodash';

const templatesStatusExtension = [
    { id: '4fb6d5ff-3512-4dce-86ca-a19e411115b5', name: 'Draft', color: '#828282' },
    { id: '5af18214-c5d9-4825-bdfe-5e74514e922b', name: 'Ready',  color: '#F2C94C' },
];

export const getTemplatesStatusExtension = statusId => {
    const item = find(templatesStatusExtension, { id: statusId });

    return {
        color: item?.color || '#828282'
    }
};
