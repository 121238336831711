import {
    SET_PROBLEMS,
    SET_CURRENT_PROBLEM,
    CLEAR_CURRENT_PROBLEM,
    LOGOUT_USER,
    SET_METRICS_VIEW_DATA,
    SET_METRICS_MAIN_PAGE_DATA,
    CLEAR_METRICS_VIEW_DATA
} from 'store/types';

const initialState = {
    generationStatus: '',
    lastGeneratedDatetime: '',
    problems: [],
    currentProblem: {},
    metricsMainPageData: {},
    metricsViewData: {},
    generationIssues: [],
};

export default function analytics(store = initialState, { type, payload }) {
    switch (type) {
        case SET_PROBLEMS:
            return {
                ...store, ...payload,
            }
        case SET_METRICS_VIEW_DATA:
            return {
                ...store, metricsViewData: { ...payload.data }
            }
        case CLEAR_METRICS_VIEW_DATA:
            return {
                ...store, metricsViewData: {}
            }
        case SET_METRICS_MAIN_PAGE_DATA:
            return {
                ...store, metricsMainPageData: { ...payload.data }
            }
        case SET_CURRENT_PROBLEM:
            return {
                ...store, currentProblem: { ...payload }
            }
        case CLEAR_CURRENT_PROBLEM:
            return {
                ...store, currentProblem: {}
            }
        case LOGOUT_USER:
            return {
                ...store,
                problems: [],
                generationStatus: '',
            };
        default:
            return store;
    }
}
