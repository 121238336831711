import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';

export const TemplatePreview = ({ onClickCallback, templateName, previewImageUrl }) => (
    <div onClick={onClickCallback} className={styles.templatePreview}>
        <div className={styles.templatePreviewImg} style={{ backgroundImage: `url(${previewImageUrl})` }}/>
        <p className={styles.templatePreviewTitle}>{templateName}</p>
    </div>
);

TemplatePreview.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    templateName: PropTypes.string.isRequired,
    previewImageUrl: PropTypes.string.isRequired,
}
TemplatePreview.defaultProps = {
    onClickCallback: '',
    templateName: '',
    previewImageUrl: '',
}
