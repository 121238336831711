import React from 'react';

export const TemplateIcon = ({ color }) => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M15 7H17.75C18.4404 7 19 7.55964 19 8.25V17.125C19 18.1605 18.1605 19 17.125 19H16.875C15.8395 19 15 18.1605 15 17.125V7Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 2.5C0 1.11929 1.11929 0 2.5 0H11.5C12.8807 0 14 1.11929 14 2.5V8V17.6809V18.125C14 19.1211 14.7768 19.9358 15.7577 19.9964C15.7481 19.9987 15.7378 20 15.7271 20H2.5C1.11929 20 0 18.8807 0 17.5V2.5ZM3 6.25C2.58579 6.25 2.25 6.58579 2.25 7C2.25 7.41421 2.58579 7.75 3 7.75H11C11.4142 7.75 11.75 7.41421 11.75 7C11.75 6.58579 11.4142 6.25 11 6.25H3ZM2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H11C11.4142 9.25 11.75 9.58579 11.75 10C11.75 10.4142 11.4142 10.75 11 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10ZM3 12.25C2.58579 12.25 2.25 12.5858 2.25 13C2.25 13.4142 2.58579 13.75 3 13.75H11C11.4142 13.75 11.75 13.4142 11.75 13C11.75 12.5858 11.4142 12.25 11 12.25H3Z" fill={color}/>
        </svg>
    )
};
