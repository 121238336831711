import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { englishTranslation } from 'translations/english';
import { russianTranslation } from 'translations/russian';
import { ukrainianTranslation } from 'translations/ukrainian';
import { polishTranslation } from 'translations/polish';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // init with resources
        resources: {
            en: englishTranslation,
            ua: ukrainianTranslation,
            ru: russianTranslation,
            pl: polishTranslation
        },
        debug: true,

        // have a common namespace used around the full app
        ns: [
            'translations',
            'auth',
            'mainHeaderBreadcrumbs',
            'mainLeftSideBar',
            'filter',
            'searchInput',
            'dashboardHeader',
            'scenarioStatuses',
            'templateStatuses',
            'actionSidebars',
            'scenarioMetrics',
            'templates',
            'templateMetrics',
            'consentHeader',
            'popUpsSystemMessages',
            'validationErrorMessages',
            'actionArrows',
            'unitStatuses',
            'recommendationUnit',
            'subjectRecommendationType',
            'recommendationMetrics',
            'tableRecommendationGroup',
            'actionArrows',
            'conditionalSegments',
            'segmentsTable',
            'calendar',
            'mainPageDashboard',
            'mainPage',
            'frequencyPolicies',
            'integrations',
            'clientDashboardPage',
            'iconicDropdown',
            'templatesSetupAside',
            'policies',
            'metricViewPage'
        ],

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
