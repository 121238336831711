import React, { useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { map } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { AttributeFilterDeepMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDeepMapped';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';

const cx = classNames.bind(styles);

export const AttributeFilterDropdownDeep = ({ array, state, setState, id, conditionIndex}) => {
    const {
        onClickCreateNewDropdownMultiple,
    } = useContext(SideBarContext);

    const { t: translator } = useTranslation();

    return (
        <>
            <AsideDropdownButton
                name={translator('actionSidebars:NewAttributeFilterButton')}
                className={cx({ 'dropDownButtonEmpty': !array.length })}
                onClick={onClickCreateNewDropdownMultiple(setState, id, attributeFilterExample)}
            />
            <ul className={styles.filter}>
                {map(array, (item, index) =>
                    <AttributeFilterDeepMapped
                        key={item.id}
                        item={item}
                        id={id}
                        index={index}
                        setState={setState}
                        state={state}
                        conditionIndex={conditionIndex}
                    />
                )}
            </ul>
        </>
    )
}
AttributeFilterDropdownDeep.propTypes = {
    array: PropTypes.array,
    setState: PropTypes.func.isRequired,
    state: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    conditionIndex: PropTypes.number
}
