import React from 'react';
import styles from './AddNewRoundButton.module.css';
import PropTypes from 'prop-types';
import { PlusIcon } from 'assets/icons/PlusIcon';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const AddNewRoundButton = ({
    onClick,
    actionLabel,
    isFixedDisplayPosition,
    className,
}) => (
    <div className={cx(styles.container, className, { 'fixed': isFixedDisplayPosition })}>
        {actionLabel &&
            <p>{actionLabel}</p>
        }

        <div onClick={onClick} className={styles.roundButton}>
            <PlusIcon color='#FFFFFF'/>
        </div>

        {actionLabel &&
            <div className={styles.crookedArrow}/>
        }
    </div>
);

AddNewRoundButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    actionLabel: PropTypes.string,
    isFixedDisplayPosition: PropTypes.bool,
    className: PropTypes.string,
    options: PropTypes.array,
};
AddNewRoundButton.defaultProps = {
    isFixedDisplayPosition: false,
    className: '',
    options: [],
    actionLabel: ''
};