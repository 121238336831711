import React from 'react';

export const EllipsisIcon = ({ color }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg" data-testid='ellipsisButton'>
        <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill={color}/>
        </mask>
        <g mask="url(#mask0)">
            <path d="M3 8.49994C3 9.32837 2.32861 9.99994 1.5 9.99994C0.671387 9.99994 0 9.32837 0 8.49994C0 7.67151 0.671387 6.99994 1.5 6.99994C2.32861 6.99994 3 7.67151 3 8.49994Z" fill={color}/>
            <path d="M16 8.49994C16 9.32837 15.3286 9.99994 14.5 9.99994C13.6714 9.99994 13 9.32837 13 8.49994C13 7.67151 13.6714 6.99994 14.5 6.99994C15.3286 6.99994 16 7.67151 16 8.49994Z" fill={color}/>
            <path d="M8 10C8.82861 10 9.5 9.32843 9.5 8.5C9.5 7.67157 8.82861 7 8 7C7.17139 7 6.5 7.67157 6.5 8.5C6.5 9.32843 7.17139 10 8 10Z" fill={color}/>
        </g>
    </svg>
);
