import React, { useCallback, useContext } from 'react';
import { InputField } from 'common/InputField/InputField';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { AttributeFilterDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDropdown';
import { Label } from 'common/_Label/Label';
import { useTranslation } from 'react-i18next';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

export const EventSection = () => {
    const {
        values,
        errors,
        setValues,
        onChangeInput,
        onChangeDropdown,
        mappedEventNames
    } = useContext(SideBarContext);

    const { t: translator } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeDropdown(setValues), []);

    return (
        <>
            <CustomSelectDropdown
                dropdownName='actionName'
                dropdownLabel={translator('actionSidebars:actionName')}
                defaultDropdownValue={values?.actionName?.value}
                dropdownOptions={mappedEventNames}
                onChangeDropdown={onChangeDropdownCached}
                isErrorExist={!!errors?.actionName}
                isRequired={values?.actionName?.required}
            />

            <Label htmlFor="actionSubName" text={translator('actionSidebars:actionSubName')}/>
            <InputField
                type='text'
                id='actionSubName'
                name='subActionName'
                value={values?.subActionName?.value}
                required={values?.subActionName?.required}
                isInputWithoutErrorField={true}
                onChange={onChangeCached}
            />

            <AttributeFilterDropdown/>
        </>
    )
}
