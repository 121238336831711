import React from 'react';
import styles from 'components/MainHeader/MainHeader.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { logout } from 'store/actions/auth.action';
import { SelectLanguageDropdown } from 'components/MainHeader/SelectLanguageDropdown';
import { logoutConfig } from 'data/configSystemPopUps';
import { Breadcrumbs } from 'components/MainHeader/Breadcrumbs';
import { SandboxModeLabel } from 'components/MainHeader/SandboxModeLabel';
import { BookCallRedirectButton } from 'components/MainHeader/BookCallRedirectButton';
import { CustomModal } from 'common/_CustomModal/CustomModal';
import { useModal } from 'hooks/CustomModals/useModal';
import { ConfirmModal } from 'common/_CustomModal/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
//import { LaunchScenariosMessage } from 'components/MainHeader/LaunchScenariosMessage';

const cx = classNames.bind(styles);

export const MainHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t: translator } = useTranslation();
    const { isAuthenticated, user: { username, sandboxMode } } = useSelector(store => store.auth);

    const { message, acceptButton, cancelButton } = logoutConfig;
    const { ref, isToggle, setShowModal, setHideModal, onAcceptModalPromise }  = useModal();

    const logoutRedirect = () => navigate('/');

    const logoutUser = () => dispatch(logout()).then(() => logoutRedirect());

    return (
        <header className={styles.header}>
            <Breadcrumbs/>

            <div className={styles.headerIconsContainer}>
                {/*{isAuthenticated &&*/}
                {/*    <LaunchScenariosMessage*/}
                {/*        message={translator('mainHeader:LaunchScenarioMessage')}*/}
                {/*        buttonName={translator('mainHeader:LaunchScenarioButtonName')}*/}
                {/*    />*/}
                {/*}*/}

                {sandboxMode &&
                    <SandboxModeLabel/>
                }

                {isAuthenticated &&
                    <div className={styles.logOutContainer}>
                        <h6 className={styles.username}>{username}</h6>
                        <button className={cx(styles.icon, styles.logOutIcon)} onClick={setShowModal}/>
                    </div>
                }

                <SelectLanguageDropdown/>

                {sandboxMode &&
                    <BookCallRedirectButton/>
                }
            </div>

            <CustomModal
                componentRef={ref}
                isOpened={isToggle}
                children={
                    <ConfirmModal
                        title={translator(`popUpsSystemMessages:${message.title}`)}
                        description={translator(`popUpsSystemMessages:${message.description}`)}
                        cancelButtonTitle={translator(`default:${cancelButton.name}`)}
                        acceptButtonTitle={translator(`default:${acceptButton.name}`)}
                        onClickCancel={setHideModal}
                        onClickAccept={onAcceptModalPromise(logoutUser)}
                    />
                }
            />
        </header>
    )
};
