import React from 'react';
import { map, eq } from 'lodash';
import PropTypes from 'prop-types';
import styles from 'common/Buttons/TabButtons/TabButtons.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles)

const onClickButton = (id, setState) => {
    return () => setState(prev => map(prev, item =>
        eq(item.id, id)
            ? { ...item, active: true }
            : { ...item, active: false }
    ))
};

export const TabButtons = ({ buttons, setState }) => {

    return (
        <div className={styles.tabButtonsContainer}>
            {map(buttons, item =>
                <button
                    type='button'
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    className={cx({'active': item.active})}
                    onClick={onClickButton(item.id, setState)}
                >
                    {item.name}
                </button>
            )}
        </div>
    )
}

TabButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    setState: PropTypes.func.isRequired
}
