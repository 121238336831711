import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from 'common/_Buttons/_CloseButton/CloseButton.module.css';
import { CrossButtonIcon } from 'assets/icons/CrossButtonIcon';

const cx = classNames.bind(styles);

export const CloseButton = ({ onClick, className, color }) => (
    <button className={cx(styles.closeButton, className )} onClick={onClick}>
        <CrossButtonIcon color={color}/>
    </button>
);

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    color: PropTypes.string
};
CloseButton.defaultProps = {
    onClick: () => {},
    className: null,
    color: '#BDBDBD'
};
