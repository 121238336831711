import React from 'react';
import PropTypes from 'prop-types';
import styles from 'layouts/_UsefulOptionsLayout/UsefulOptionsLayout.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const UsefulOptionsLayout = ({ children, className }) => (
    <aside className={cx(styles.usefulOptionsLayout, className)}>
        {children}
    </aside>
);

UsefulOptionsLayout.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string
};
UsefulOptionsLayout.defaultProps = {
    className: null
};
