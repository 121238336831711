import { useEffect } from 'react';
import { ArrayUse } from 'lib/ArrayUse';
import { useDispatch, useSelector } from 'react-redux';
import { clearRangePickerCalendar } from 'store/actions/calendars.action';

export const useConditionCalendarData = (condition, setCondition) => {
    const dispatch = useDispatch();
    const {
        rangePickerCalendar: { data: rangePickerCalendarData },
    } = useSelector(store => store.calendars);

    const isNeedUpdateIntervalDate = rangePickerCalendarData?.calendarRangeStartDate
        && rangePickerCalendarData?.calendarRangeEndDate
        && rangePickerCalendarData?.conditionId

    useEffect(() => {
        if (rangePickerCalendarData && isNeedUpdateIntervalDate) {
            setCondition(prev => {
                const { conditionId, calendarRangeStartDate, calendarRangeEndDate } = rangePickerCalendarData;

                const { data: updateCandidateCondition, index } = ArrayUse.findCollection(prev, { id: conditionId })

                return ArrayUse.replace(prev, index, {
                    ...updateCandidateCondition,
                    _selectedTimeInterval: {
                        ...updateCandidateCondition._selectedTimeInterval,
                        _intervalDate: {
                            ...updateCandidateCondition._selectedTimeInterval._intervalDate,
                            startDate: calendarRangeStartDate,
                            endDate: calendarRangeEndDate
                        }
                    }
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangePickerCalendarData, isNeedUpdateIntervalDate]);

    useEffect(() => {
        return () => dispatch(clearRangePickerCalendar());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
};