import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { ProductFilterMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ProductFilter/ProductFilterMapped';
import { productAttributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ProductFilter/settings/defaultProductFilterSettings';

export const ProductFilterDropdown = ({ required }) => {
    const { t: translator } = useTranslation();

    const {
        productAttributeFilter,
        setProductAttributeFilter,
        onClickCreateNewDropdown,
    } = useContext(SideBarContext);

    return (
        <>
            <AsideDropdownButton
                name={translator('actionSidebars:NewProductFilterButton')}
                onClick={onClickCreateNewDropdown(setProductAttributeFilter, productAttributeFilterExample)}
            />
            <ul className={styles.filter}>
                {map(productAttributeFilter, (item, index) =>
                    <ProductFilterMapped
                        required={required}
                        key={item.id}
                        item={item}
                        index={index}
                        dropdownFilters
                    />
                )}
            </ul>
        </>
    )
};
ProductFilterDropdown.propTypes = {
    required: PropTypes.bool
};
ProductFilterDropdown.defaultProps = {
    required: false
};
