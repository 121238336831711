import React from 'react';

export const Option = ({ option }) => {

    if (option instanceof Object) {
        return <option value={option.value}>
            {option.name}
        </option>
    }

    return <option value={option}>
        {option}
    </option>
}
