import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { round, toString, toNumber, eq } from 'lodash';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import classNames from 'classnames/bind';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { InputRangeWrapper } from 'common/InputRangeWrapper/InputRangeWrapper';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { InputField } from 'common/InputField/InputField';
import { Label } from 'common/_Label/Label';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataABTest } from 'store/selectors/scenariosActions.selectors';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { NumberUse } from 'lib/NumberUse';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

const cx = classNames.bind(styles);

export const ABTestSidebar = () => {
    const dispatch = useDispatch();
    const { title, controlGroup, testGroup, actionType, errors } = useSelector(getStoreDataABTest);
    const { t: translator } = useTranslation();
    const { onSubmitHandler } = useContext(SideBarContext);

    const [values, setValues] = useState({ controlGroup, testGroup });

    const onChangeRangeControlGroup = (name, setState) => value => setState(prev => {
        const testGroup = 100 - value;
        const aGroup = toString(round(testGroup / 100 * prev['testGroup'].rangeSliderValue));
        const bGroup = toString(round(testGroup - aGroup));

        return {
            ...prev,
            [name]: {
                ...prev[name],
                value: toString(value),
                rangeSliderValue: value
            },
            testGroup: {
                ...prev['testGroup'],
                aGroupValue: aGroup,
                bGroupValue: bGroup,
            }
        }
    })

    const onChangeRangeTestGroup = (name, setState) => value => setState(prev => {
        const testGroup = 100 - prev['controlGroup'].rangeSliderValue;
        const aGroup = toString(round(testGroup / 100 * value));
        const bGroup = toString(round(testGroup - aGroup));

        return {
            ...prev,
            [name]: {
                ...prev[name],
                rangeSliderValue: round(value),
                aGroupValue: aGroup,
                bGroupValue: bGroup,
            }
        }
    });

    const onChangeInputControlGroup = setState => event => {
        event.persist();
        // Fix Negative Number value
        const value = NumberUse.transformToPositiveNumber(event.target.value);

        // If value true
        if (value) {
            // For value which more than 100
            if (value > 100) {
                return setState(prev => ({
                    ...prev,
                    [event.target.name]: {
                        ...prev[event.target.name],
                        value: toString(100),
                        rangeSliderValue: toNumber(100)
                    },
                    testGroup: {
                        ...prev['testGroup'],
                        aGroupValue: toString(round(0)),
                        bGroupValue: toString(round(0)),
                    }
                }))
            }
            // For normal value
            return setState(prev => {
                const testGroup = 100 - value;
                const aGroup = toString(round(testGroup / 100 * prev['testGroup'].rangeSliderValue));
                const bGroup = toString(round(testGroup - aGroup));

                return {
                    ...prev,
                    [event.target.name]: {
                        ...prev[event.target.name],
                        value: toString(value),
                        rangeSliderValue: toNumber(value)
                    },
                    testGroup: {
                        ...prev['testGroup'],
                        aGroupValue: aGroup,
                        bGroupValue: bGroup,
                    }
                }
            })
        }
        // For value which equal to 0
        return setState(prev => {
            const aGroup = toString(round(prev['testGroup'].rangeSliderValue));
            const bGroup = toString(round(100 - prev['testGroup'].rangeSliderValue));
            return {
                ...prev,
                [event.target.name]: {
                    ...prev[event.target.name],
                    value: toString(0),
                    rangeSliderValue: toNumber(0)
                },
                testGroup: {
                    ...prev['testGroup'],
                    aGroupValue: aGroup,
                    bGroupValue: bGroup,
                }
            }
        })

    }

    const onChangeInputTestGroupA = setState => event => {
        event.persist();

        const value = NumberUse.transformToPositiveNumber(event.target.value);

        if (value && eq(event.target.name, 'aGroupValue')) {
            return setState(prev => {
                const testGroup = 100 - prev['controlGroup'].value;
                const bGroup = toString(round(testGroup - value));
                const percent = toNumber(round(100 / testGroup * value));

                // For value which more than percent of test group
                if (value > testGroup) {
                    // Use if control group is 100% and test group is 0
                    if (!testGroup) {
                        return {
                            ...prev,
                            testGroup: {
                                ...prev['testGroup'],
                                [event.target.name]: toString(0),
                                bGroupValue: toString(0),
                                rangeSliderValue: toNumber(round(50))
                            }
                        }
                    }
                    return {
                        ...prev,
                        testGroup: {
                            ...prev['testGroup'],
                            [event.target.name]: toString(testGroup),
                            bGroupValue: toString(0),
                            rangeSliderValue: toNumber(round(100))
                        }
                    }
                }

                // default for A Group
                return {
                    ...prev,
                    testGroup: {
                        ...prev['testGroup'],
                        [event.target.name]: toString(value),
                        bGroupValue: bGroup,
                        rangeSliderValue: percent
                    }
                }
            })
        }
        // if value is empty or 0
        return setState(prev => {
            const testGroup = toString(100 - prev['controlGroup'].value);
            return {
                ...prev,
                testGroup: {
                    ...prev['testGroup'],
                    [event.target.name]: toString(0),
                    bGroupValue: testGroup,
                    rangeSliderValue: toNumber(round(0))
                }
            }
        })
    };

    const onChangeInputTestGroupB = setState => event => {
        event.persist();

        const value = NumberUse.transformToPositiveNumber(event.target.value);

        if (value && eq(event.target.name, 'bGroupValue')) {
            return setState(prev => {
                const testGroup = 100 - prev['controlGroup'].value;
                const aGroup = toString(round(testGroup - value));
                const percent = toNumber(round(100 - (100 / testGroup * value)));
                // For value which more than percent of test group
                if (value > testGroup) {
                    // Use if control group is 100% and test group is 0
                    if (!testGroup) {
                        return {
                            ...prev,
                            testGroup: {
                                ...prev['testGroup'],
                                [event.target.name]: toString(0),
                                bGroupValue: toString(0),
                                rangeSliderValue: toNumber(round(50))
                            }
                        }
                    }
                    return {
                        ...prev,
                        testGroup: {
                            ...prev['testGroup'],
                            [event.target.name]: toString(testGroup),
                            aGroupValue: toString(0),
                            rangeSliderValue: toNumber(round(0))
                        }
                    }
                }
                // default for A Group
                return {
                    ...prev,
                    testGroup: {
                        ...prev['testGroup'],
                        [event.target.name]: toString(value),
                        aGroupValue: aGroup,
                        rangeSliderValue: percent
                    }
                }
            })
        }
        // if value is empty or 0
        return setState(prev => {
            const testGroup = toString(100 - prev['controlGroup'].value);

            return {
                ...prev,
                testGroup: {
                    ...prev['testGroup'],
                    [event.target.name]: toString(0),
                    aGroupValue: testGroup,
                    rangeSliderValue: toNumber(round(100))
                }
            }
        })
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeRangeControlGroupCached = useCallback(onChangeRangeControlGroup('controlGroup', setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeRangeTestGroupCached = useCallback(onChangeRangeTestGroup('testGroup', setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeInputControlGroupCached = useCallback(onChangeInputControlGroup(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeInputTestGroupACached = useCallback(onChangeInputTestGroupA(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeInputTestGroupBCached = useCallback(onChangeInputTestGroupB(setValues), []);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData(values));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={title}/>

                <Label htmlFor="controlGroup" text={translator('actionSidebars:controlGroup')}/>
                <div className={cx(styles.percentInputContainer, { 'error': errors?.groupRange })}>
                    <InputField
                        type='number'
                        id='controlGroup'
                        name='controlGroup'
                        value={values.controlGroup?.value}
                        required={values.controlGroup?.required}
                        onChange={onChangeInputControlGroupCached}
                    />
                    <p>%</p>
                </div>
                <ErrorMessageField active={!!errors?.groupRange} message={errors?.groupRange}/>

                <InputRangeWrapper
                    maxValue={100}
                    minValue={0}
                    value={values.controlGroup?.rangeSliderValue}
                    onChange={onChangeRangeControlGroupCached}
                />

                <div className={styles.intervalInputContainer}>
                    <div className={styles.intervalInputItem}>
                        <Label htmlFor="aGroupValue" text={translator('actionSidebars:aGroupValue')}/>
                        <div className={cx(styles.percentInputContainer, { 'error': errors?.abRange })}>
                            <InputField
                                type='number'
                                id='aGroupValue'
                                name='aGroupValue'
                                value={values.testGroup?.aGroupValue}
                                required={values.testGroup.required}
                                onChange={onChangeInputTestGroupACached}
                            />
                            <p>%</p>
                        </div>
                    </div>
                    <span className={styles.intervalInputLine}/>
                    <div className={styles.intervalInputItem}>
                        <Label htmlFor="bGroupValue" text={translator('actionSidebars:bGroupValue')}/>
                        <div className={cx(styles.percentInputContainer, { 'error': errors?.abRange })}>
                            <InputField
                                type='number'
                                id='bGroupValue'
                                name='bGroupValue'
                                value={values.testGroup?.bGroupValue}
                                required={values.testGroup.required}
                                onChange={onChangeInputTestGroupBCached}
                            />
                            <p>%</p>
                        </div>
                    </div>
                </div>
                <ErrorMessageField active={!!errors?.abRange} message={errors?.abRange}/>

                <InputRangeWrapper
                    maxValue={100}
                    minValue={0}
                    value={values.testGroup?.rangeSliderValue}
                    onChange={onChangeRangeTestGroupCached}
                />

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
};
