import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/Buttons/TransparentButton/TransparentButton.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles)

export const TransparentButton = ({ name, width, height, extensionClass, callback, isDisabled }) =>
    <button
        onClick={callback}
        style={{ width: width, height: height }}
        className={cx(styles.button, styles.transparentButton, { [extensionClass]: extensionClass })}
        disabled={isDisabled}
    >
        {name}
    </button>;

TransparentButton.propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.number.isRequired,
    extensionClass: PropTypes.string,
    callback: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};

TransparentButton.defaultProps = {
    name: 'default',
    callback: () => {},
    extensionClass: null,
    isDisabled: false
};
