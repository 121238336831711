import React from 'react';
import styles from 'components/MainHeader/MainHeader.module.css';
import { PhoneIcon } from 'assets/icons/PhoneIcon';

export const BookCallRedirectButton = () => (
    <a href='https://calendly.com/nuecurrent/demo' target='_blank' rel='noreferrer' className={styles.bookCallRedirectButton}>
        <PhoneIcon/>
        <p>Book a call</p>
    </a>
);
