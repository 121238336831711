export const defaultRepeatTriggerSettings = () => ({
    dayOfWeek: [
        {
            id: 'cb1',
            name: {
                shortCut: 'MondayShortcut',
                key: 'monday',
            },
            isChecked: true,
        },
        {
            id: 'cb2',
            name: {
                shortCut: 'TuesdayShortcut',
                key: 'tuesday',
            },
            isChecked: false,
        },
        {
            id: 'cb3',
            name: {
                shortCut: 'WednesdayShortcut',
                key: 'wednesday',
            },
            isChecked: false,
        },
        {
            id: 'cb4',
            name: {
                shortCut: 'ThursdayShortcut',
                key: 'thursday',
            },
            isChecked: false,
        },
        {
            id: 'cb5',
            name: {
                shortCut: 'FridayShortcut',
                key: 'friday',
            },
            isChecked: false,
        },
        {
            id: 'cb6',
            name: {
                shortCut: 'SaturdayShortcut',
                key: 'saturday',
            },
            isChecked: false,
        },
        {
            id: 'cb7',
            name: {
                shortCut: 'SundayShortcut',
                key: 'sunday',
            },
            isChecked: false,
        },
    ],
    time: '11:00',
    values: {
        maxAudienceSize: {
            value: '',
            required: false
        },
    },
    condition: [],
    segmentId: null
})
