import React from 'react';

export const LeftFirstPageIcon = () => (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <g mask="url(#mask0_21477_70957)">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.88284 7.86983C8.03905 7.69626 8.03905 7.41485 7.88284 7.24129L4.96569 4L7.88284 0.758714C8.03905 0.585148 8.03905 0.303742 7.88284 0.130175C7.72663 -0.0433915 7.47337 -0.0433915 7.31716 0.130175L4.11716 3.68573C3.96095 3.8593 3.96095 4.1407 4.11716 4.31427L7.31716 7.86983C7.47337 8.04339 7.72663 8.04339 7.88284 7.86983Z" fill="inherit"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8C0.776142 8 1 7.81149 1 7.57895L1 0.421053C1 0.188512 0.776143 6.78525e-08 0.500001 4.37114e-08C0.223858 1.95703e-08 6.82902e-07 0.188512 6.62573e-07 0.421052L3.68096e-08 7.57895C1.64802e-08 7.81149 0.223858 8 0.5 8Z" fill="inherit"/>
        </g>
    </svg>

);
