import React from 'react';
import PropTypes from 'prop-types';
import { eq, map } from 'lodash';
import { OptionsFilterHeader } from 'components/_OptionsFilter/OptionsFilterHeader';
import { Checkbox } from 'common/_Checkbox/Checkbox';

const updateOptionsState = (event, setState, options) => {
    const { checked, name } = event.target;

    const updatedOptions = map(options, item => eq(item.name, name) ? { ...item, isChecked: checked } : item);

    setState(updatedOptions);

    return Promise.resolve(updatedOptions)
};

const changeFilterSelectedOptions = (setOptions, onChangeAction, options) => {
    return event => updateOptionsState(event, setOptions, options)
        .then(options => onChangeAction(options));
};

export const OptionsFilter = ({ options, setOptions, onChangeAction, sectionName, checkAllButtonName, clearAllButtonName }) => (
    <>
        <OptionsFilterHeader {...{options, setOptions, onChangeAction, sectionName, checkAllButtonName, clearAllButtonName }} />

        {map(options, (item, key) =>
            <Checkbox
                key={key}
                id={item.id}
                name={item.name}
                checked={item.isChecked}
                onChange={changeFilterSelectedOptions(setOptions, onChangeAction, options)}
                label={item.name}
            />,
        )}
    </>
);

OptionsFilter.propTypes = {
    options: PropTypes.array.isRequired,
    setOptions: PropTypes.func.isRequired,
    onChangeAction: PropTypes.func,
    sectionName: PropTypes.string.isRequired,
    checkAllButtonName: PropTypes.string.isRequired,
    clearAllButtonName: PropTypes.string.isRequired
};
OptionsFilter.defaultProps = {
    options: [],
    setOptions: () => {},
    onChangeAction: () => {}
};
