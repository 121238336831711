import React from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import { TransparentButton } from 'common/Buttons/TransparentButton/TransparentButton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleSideBar } from 'store/actions/scenariosActions.action';

export const ActionSidebarButtons = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();

    const closeActionSidebarHandler = () => dispatch(toggleSideBar());

    return (
        <div className={styles.actionSidebarButtons}>
            <GreenButton
                type='submit'
                name={translator('default:SaveButtonTitle')}
            />
            <TransparentButton
                callback={closeActionSidebarHandler}
                name={translator('default:CancelButtonTitle')}
                width={130}
                height={36}
            />
        </div>
    )
};
