import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';

export const CommunicationsMaxCount = ({ values, errors, onChangeNumeric, label }) => {
    return (
        <>
            <div>
                <Label htmlFor='communicationsMaxCount' text={label}/>
                <InputField
                    type='number'
                    id='communicationsMaxCount'
                    name='communicationsMaxCount'
                    value={values?.communicationsMaxCount?.value}
                    required={values?.communicationsMaxCount?.required}
                    error={!!errors?.communicationsMaxCount}
                    onChange={onChangeNumeric}
                />
            </div>
            <ErrorMessageField
                active={!!errors?.communicationsMaxCount}
                message={errors?.communicationsMaxCount}
            />
        </>
    )
};

CommunicationsMaxCount.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    onChangeNumeric: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};
