import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { axisTickStyles } from 'data/configChart';
import { CustomTooltip } from 'components/Metrics/CustomTooltip';

export const CustomLineChart = ({ config, chartData }) => {
    const { tooltipParams, areas, nameXAxis, customYAxis } = config;

    chartData && chartData.sort((a, b) => a[nameXAxis] - b[nameXAxis]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={chartData}
                margin={{
                    top: 40,
                    right: 5,
                    left: 10,
                    bottom: 20,
                }}
            >
                <CartesianGrid vertical={false} opacity='1' stroke='#E0E0E0'/>
                <XAxis
                    tick={axisTickStyles}
                    dataKey={nameXAxis} axisLine={false} tickLine={false} tickSize='10'
                />
                {customYAxis}
                <Tooltip content={<CustomTooltip tooltipParams={tooltipParams}/>}/>
                {map(areas, ({ dataKey, fillId, stroke }, index) => (
                    <Line key={`line-${index}`} type="monotone" dot={false} dataKey={dataKey} stroke={stroke} stopOpacity={1} fillOpacity={1} fill={`url(#${fillId})`}/>
                ))}
            </LineChart>
        </ResponsiveContainer>
    )
};

CustomLineChart.propTypes = {
    config: PropTypes.object,
    chartData: PropTypes.array
};
