import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/_Checkbox/Checkbox.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Checkbox = ({ id, name, checked, onChange, isShowCheckboxLabelValue, label, className }) =>
    <div className={cx(styles.checkbox, className)}>
        <input
            type='checkbox'
            id={id}
            name={name}
            checked={checked}
            onChange={onChange}
            readOnly
        />
        <label htmlFor={id}>
            {isShowCheckboxLabelValue && label}
        </label>
    </div>

Checkbox.propsTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isShowCheckboxLabelValue: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string
}
Checkbox.defaultProps = {
    checked: false,
    isShowCheckboxLabelValue: true,
    label: '',
    className: ''
}
