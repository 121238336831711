export const productAttributeFilterExample = {
    id: null,
    show: true,
    filterName: 'NewProductFilterName',
    columnNameOfProductFromEvent: {
        name: 'ColumnNameOfProductFromEventLabel',
        value: '',
        required: true,
    },
    columnNameFromEvent: {
        name: 'ColumnNameFromEventLabel',
        value: '',
        required: true,
    },
    columnComparisonOperator: {
        name: 'ComparisonOperatorLabel',
        value: '',
        required: true,
    },
    columnNameFromCatalog: {
        name: 'ColumnNameFromCatalog',
        value: '',
        required: true,
    },
};
