import React from 'react';
import PropTypes from 'prop-types'
import styles from 'components/Cards/CreativeCards/CreativeCardPreviewBody/MobilePushBody.module.css';
import { find } from 'lodash';

export const MobilePushBody = ({ jinja_fields }) => {
    const message = find(jinja_fields, { jinja_field_name: 'jinja_body' });
    const messageTitle = find(jinja_fields, { jinja_field_name: 'jinja_msg_title' });

    return (
        <div className={styles.mobilePushBodyContainer}>
            <div className={styles.mobilePushHeader}>
                <span />
                <p className={styles.mobilePushAppName}>App name</p>
                <p className={styles.mobilePushTime}>now</p>
            </div>
            <p className={styles.mobilePushTitle}>{messageTitle?.value}</p>
            <p className={styles.mobilePushMsg}>{message?.value}</p>
        </div>
    )
};

MobilePushBody.propTypes = {
    jinja_fields: PropTypes.array,
};
