import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from 'components/DateSelectors/DateSelectors.module.css';
import { toggleDateCalendar } from 'store/actions/calendars.action';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import classNames from 'classnames/bind';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';

const cx = classNames.bind(styles);

export const SelectedDateViewer = ({ date, errors, label }) => {
    const dispatch = useDispatch();

    const onClickToggle = date => () => dispatch(toggleDateCalendar({ date: date }));

    return (
        <>
            {label &&
                <p className={styles.dateInputViewerLabel}>
                    {label}
                </p>
            }
            <div onClick={onClickToggle(date)}
                 className={cx(styles.sidebarDateViewer, styles.singleDate, { 'error': errors?.calendarDate })}
            >
                <p>
                    <span>{date}</span>
                </p>

                <CalendarIcon/>
            </div>
            <ErrorMessageField active={!!errors?.calendarDate} message={errors?.calendarDate} />
        </>
    )
};

SelectedDateViewer.propTypes = {
    date: PropTypes.string,
    errors: PropTypes.object,
    label: PropTypes.string
};
SelectedDateViewer.defaultProps = {
    date: '',
    label: ''
};
