import React, { useContext, useRef } from 'react';
import { eq } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import animation from 'animations/Pulse.module.css';
import { defaultConnectionColor, negativeConnectionColor, positiveConnectionColor } from 'components/ScenarioSetup/ActionConnections/defaultStyles';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import { ActionTooltipWindow } from 'components/ScenarioSetup/ActionIcons/sections/ActionTooltipWindow';
import { ActionTooltipWarnings } from 'components/ScenarioSetup/ActionIcons/sections/ActionTooltipWarnings';
import classNames from 'classnames/bind';
import { ActionIconsContext } from 'components/ScenarioSetup/ActionIcons/context/ActionIconsContext';

const cx = classNames.bind({ ...styles, ...animation });

export const TripleChainActionIcon = ({
    id,
    actionType,
    title,
    isShowWarningActionMessage,
    isOpenActionInfo,
    actionIconConditions,
    classNameExtension,
    alternativeDotsColor
}) => {
    const dispatch = useDispatch();
    const output = useSelector(store => store.scenariosActions.actionOutputCheckpoint);
    const sidebarActionId = useSelector(store => store.scenariosActions.actionSidebar.id);

    const context = useContext(ActionIconsContext);

    const {
        onRightClick,
        outputCheckpointHandler,
        actionIconStatusConfig,
        onDoubleClick,
        changeActionStatus,
        acceptInputConnection
    } = context;

    const idOutputTop = id + '-output-top';
    const idInput = id + '-input';
    const idOutputBottom = id + '-output-bottom';

    const connectionConfig_top = {
        id: idOutputTop,
        disableInputButton: idInput,
        color: positiveConnectionColor,
    };
    const connectionConfig_left = {
        id: idInput,
        color: defaultConnectionColor,
    };
    const connectionConfig_bottom = {
        id: idOutputBottom,
        disableInputButton: idInput,
        color: negativeConnectionColor,
    };

    const wrappedRef = useRef(null);

    useOutsideAlerter(wrappedRef, changeActionStatus(dispatch, id));

    const { icon, iconProps } = actionIconStatusConfig(actionIconConditions, isOpenActionInfo, id, sidebarActionId, isShowWarningActionMessage);
    const ActionIcon = icon;

    const isActiveInputDot = output && !eq(output.disableInputButton, idInput);
    const isActiveOutputTopDot = output && eq(output.id, idOutputTop);
    const isActiveOutputBottomDot = output && eq(output.id, idOutputBottom);

    return (
        <ActionIconsContext.Provider value={{
            ...context,
            isOpenActionInfo,
            isShowWarningActionMessage,
            wrappedRef,
            id,
            title,
            actionType
        }}>
            <div className={cx(styles.action, { [classNameExtension]: classNameExtension })}>
                <div id={idOutputTop}
                     onClick={outputCheckpointHandler(output, idOutputTop, connectionConfig_top, dispatch)}
                     className={cx(styles.actionOutputDot, styles.actionCantedOutputTopDot, { 'active': isActiveOutputTopDot, [alternativeDotsColor.top]: alternativeDotsColor.top })}
                />
                <div id={idInput}
                     onClick={acceptInputConnection(dispatch, connectionConfig_left)}
                     className={cx(styles.actionInputDot, styles.actionCantedInputDot, { 'pulse': isActiveInputDot, 'active': isActiveInputDot })}
                />
                <div onContextMenu={onRightClick(id, dispatch, isShowWarningActionMessage)} onDoubleClick={onDoubleClick(dispatch, actionType, id)}>
                    <ActionIcon children={icon} { ...iconProps }/>
                </div>
                <div id={idOutputBottom}
                     onClick={outputCheckpointHandler(output, idOutputBottom, connectionConfig_bottom, dispatch)}
                     className={cx(styles.actionOutputDot, styles.actionCantedOutputBottomDot, { 'active': isActiveOutputBottomDot, [alternativeDotsColor.bottom]: alternativeDotsColor.bottom })}
                />

                <ActionTooltipWindow/>

                <ActionTooltipWarnings/>
            </div>
        </ActionIconsContext.Provider>
    );
};

TripleChainActionIcon.propTypes = {
    id: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isShowWarningActionMessage: PropTypes.bool.isRequired,
    isOpenActionInfo: PropTypes.bool.isRequired,
    actionIconConditions: PropTypes.object.isRequired,
    classNameExtension: PropTypes.string,
    alternativeDotsColor: PropTypes.object
}
