import { urlsControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/urlsControl';
import { isEmpty } from 'lodash';
import { ObjectUse } from 'lib/ObjectUse';
import { Validation } from 'validations/Validation';

const concatedValuesRules = {
    communicationsMaxCount: 'required|integer|min:1',
    countTimeIntervalNumber: 'required|integer|min:1'
};

const isActionTemplateHasError = templateUniqueId => {
    return !templateUniqueId ? 'TemplateValidationMessage' : null
};

const isUrlsResultHasErros = errors => {
    if (isEmpty(errors)) return false;

    return errors;
};

export const websiteBuilderControl = action => {

    const {
        values: {
            communicationsMaxCount,
            countTimeIntervalNumber
        },
        urls,
        templateUniqueId
    } = action.settings;

    const concatedValues = {
        communicationsMaxCount: communicationsMaxCount.value,
        countTimeIntervalNumber: countTimeIntervalNumber.value
    };

    const validation = Validation.checkSome(concatedValues, concatedValuesRules, {
        'required.communicationsMaxCount': 'InputValidationRequiredMessage',
        'integer.communicationsMaxCount': 'InputTagsValuesOnlyNumericMessage',
        'min.communicationsMaxCount': 'PositiveNumberInputValidationMessage',
        'required.countTimeIntervalNumber': 'InputValidationRequiredMessage',
        'integer.countTimeIntervalNumber': 'InputTagsValuesOnlyNumericMessage',
        'min.countTimeIntervalNumber': 'PositiveNumberInputValidationMessage',
    }, ['communicationsMaxCount', 'countTimeIntervalNumber']);

    const isSelectedActionTemplate = isActionTemplateHasError(templateUniqueId);

    const urlsResult = urlsControl(urls);

    const isActionHasErrors = !isEmpty(urlsResult) || !!isSelectedActionTemplate || validation.fails;

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                validation.firstErrors.communicationsMaxCount,
                validation.firstErrors.countTimeIntervalNumber,
                isUrlsResultHasErros(urlsResult),
                isSelectedActionTemplate
            ], ['communicationsMaxCount', 'countTimeIntervalNumber', 'urls', 'templateUniqueId']),
        }
    }
}
