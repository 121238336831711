import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { AreaChart, Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { axisTickStyles } from 'data/configChart';
import { CustomTooltip } from 'components/Metrics/CustomTooltip';

export const CustomAreaChart = ({ config, chartData }) => {
    const { tooltipParams, gradients = [], areas, nameXAxis, customYAxis } = config || {};

    chartData && chartData.sort((a, b) => a[nameXAxis] - b[nameXAxis]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={chartData}
                margin={{
                    top: 40,
                    right: 5,
                    left: 10,
                    bottom: 20,
                }}
            >
                {!!gradients.length && (
                    <defs>
                        {map(gradients, ({ id, gradientColor }) => (
                            <linearGradient id={id} x1="0" y1="0" x2="0" y2="1" key={id}>
                                {gradientColor}
                            </linearGradient>
                        ))}
                    </defs>
                )}
                <CartesianGrid vertical={false} opacity='1' stroke='#E0E0E0'/>
                <XAxis
                    tick={axisTickStyles}
                    dataKey={nameXAxis} axisLine={false} tickLine={false} tickSize='10'
                />
                {customYAxis}
                <Tooltip content={<CustomTooltip tooltipParams={tooltipParams}/>}/>
                {map(areas, ({ dataKey, fillId, stroke }) => (
                    <Area type="monotone" dataKey={dataKey} stroke={stroke} stopOpacity={1} fillOpacity={1} fill={`url(#${fillId})`} key={fillId}/>
                ))}
            </AreaChart>
        </ResponsiveContainer>
    )
};

CustomAreaChart.propTypes = {
    config: PropTypes.object,
    chartData: PropTypes.array
};
