import { find } from 'lodash';
import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const atTheDayRules = {
    value: 'required'
};

const dayOfWeekRules = {
    isChecked: 'accepted'
};

const repeatTriggerValidation = dayOfWeek => {
    return () => {
        const validation = Validation.leastOneFromList(dayOfWeek, dayOfWeekRules, {
            'accepted.isChecked': 'CheckboxValidationRequired'
        }, 'isChecked')

        return {
            isShowWarningActionMessage: validation.fails,
            errors: {
                ...ObjectUse.createObject(validation.errorMessages, 'checkboxErrors'),
            }
        }
    }
};

const scheduleTriggerValidation = atTheDay => {
    return () => {
        const validation = Validation.check(atTheDay, atTheDayRules, {
            'required.value': 'InputValidationRequiredMessage',
        }, 'value')

        return {
            isShowWarningActionMessage: validation.fails,
            errors: {
                ...ObjectUse.createObject(validation.firstError, 'calendarDate'),
            }
        }
    }
};

export const sqlTriggerControl = action => {

    const {
        selectedType,
        atTheDay,
        dayOfWeek
    } = action.settings;

    const controlTypes = {
        repeat: repeatTriggerValidation(dayOfWeek),
        schedule: scheduleTriggerValidation(atTheDay)
    };

    const getSelectedType = find(selectedType, { active: true });

    return {
        ...action,
        ...controlTypes[getSelectedType.id]()
    }
}
