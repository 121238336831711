import { createSelector } from 'reselect';
import { map } from 'lodash';

export const consentCategoriesMapped = createSelector(
    store => store.consentCategories,
    consentCategories => map(consentCategories.list, category => {
        const { consent_category_id, consent_category_name } = category;

        return { value: consent_category_id, name: consent_category_name }
    })
);
