import { forEach, compact } from 'lodash';
import { Validation } from 'validations/Validation';

const attributeFilterRules = {
    eventAttributeName: 'required',
    comparisonOperator: 'required',
};

export const attributeFilterControl = attributeFilter => {

    let attributeFilterErrors = {
        eventAttributeName: {
            id: []
        },
        comparisonOperator: {
            id: []
        }
    }

    forEach(attributeFilter, ({ id, eventAttributeName, comparisonOperator }) => {
        const validation = Validation.checkSome({
            eventAttributeName: eventAttributeName.value,
            comparisonOperator: comparisonOperator.value
        }, attributeFilterRules, {
            'required.eventAttributeName': 'InputValidationRequiredMessage',
            'required.comparisonOperator': 'InputValidationRequiredMessage',
        }, ['eventAttributeName', 'comparisonOperator'])

        if (validation.fails) {
            const eventAttributeNameError = validation.firstErrors.eventAttributeName;
            const comparisonOperatorError = validation.firstErrors.comparisonOperator;

            if (eventAttributeNameError) {
                attributeFilterErrors.eventAttributeName = {
                    error: eventAttributeNameError,
                    id: [...attributeFilterErrors.eventAttributeName.id, id]
                }
            }

            if (comparisonOperatorError) {
                attributeFilterErrors.comparisonOperator = {
                    error: comparisonOperatorError,
                    id: [...attributeFilterErrors.comparisonOperator.id, id]
                }
            }

        }
    });

    const isAttributeFilterHasErrors = !!compact([
        attributeFilterErrors.eventAttributeName.id.length,
        attributeFilterErrors.comparisonOperator.id.length
    ]).length

    return isAttributeFilterHasErrors
        ? attributeFilterErrors
        : {}
}
