export const attributeFilterExample = {
    id: null,
    show: true,
    filterName: 'NewAttributeFilterName',
    eventAttributeName: {
        name: 'EventAttributeNameLabel',
        value: '',
        required: true,
    },
    comparisonOperator: {
        name: 'ComparisonOperatorLabel',
        value: '',
        required: true,
    },
    comparisonValue: {
        name: 'ComparisonValueLabel',
        value: [],
        required: false,
    },
};
