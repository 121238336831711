import React from 'react';

export const MobilePushIconAlt = ({ borderColor }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V43C0.5 45.4853 2.51472 47.5 5 47.5H43C45.4853 47.5 47.5 45.4853 47.5 43V5C47.5 2.51472 45.4853 0.5 43 0.5Z" fill="white"/>
        <path d="M43 48H5C3.6744 47.9984 2.40356 47.4711 1.46622 46.5338C0.528882 45.5964 0.00158786 44.3256 0 43L0 5C0.00158786 3.6744 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.6744 0.00158786 5 0L43 0C44.3256 0.00158786 45.5964 0.528882 46.5338 1.46622C47.4711 2.40356 47.9984 3.6744 48 5V43C47.9984 44.3256 47.4711 45.5964 46.5338 46.5338C45.5964 47.4711 44.3256 47.9984 43 48V48ZM5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V43C1 44.0609 1.42143 45.0783 2.17157 45.8284C2.92172 46.5786 3.93913 47 5 47H43C44.0609 47 45.0783 46.5786 45.8284 45.8284C46.5786 45.0783 47 44.0609 47 43V5C47 3.93913 46.5786 2.92172 45.8284 2.17157C45.0783 1.42143 44.0609 1 43 1H5Z" fill={borderColor}/>
        <path d="M32.1799 38.5H15.8209C14.2979 38.5 13.2759 37.667 13.2759 36.427V11.573C13.2759 10.333 14.2979 9.5 15.8209 9.5H32.1799C33.7019 9.5 34.7249 10.333 34.7249 11.573V36.584C34.7249 37.641 33.5829 38.5 32.1799 38.5ZM15.8209 10.5C15.0739 10.5 14.2759 10.782 14.2759 11.573V36.427C14.2759 37.218 15.0759 37.5 15.8209 37.5H32.1799C33.0639 37.5 33.7249 37.017 33.7249 36.584V11.573C33.7249 10.782 32.9249 10.5 32.1799 10.5H15.8209Z" fill={borderColor}/>
        <path d="M23.9999 29.691C18.0839 29.691 17.9109 28.827 17.8469 28.502C17.8171 28.1818 17.8681 27.8593 17.9951 27.5639C18.1221 27.2685 18.3211 27.0097 18.5739 26.811C18.7345 26.6569 18.8746 26.4828 18.9909 26.293C19.158 25.9509 19.2949 25.5949 19.3999 25.229C19.3109 24.6 18.9869 21.603 20.5479 19.752C20.9767 19.2661 21.5101 18.8838 22.1081 18.6338C22.706 18.3839 23.3528 18.2728 23.9999 18.309C24.6561 18.2769 25.3113 18.3898 25.919 18.6395C26.5267 18.8892 27.0719 19.2697 27.5159 19.754C29.3089 21.779 28.9159 25.176 28.9009 25.319C28.897 25.742 28.9765 26.1617 29.1349 26.554C29.2237 26.67 29.3243 26.7765 29.4349 26.872C29.6941 27.0503 29.8982 27.2975 30.0242 27.5857C30.1502 27.874 30.193 28.1917 30.1479 28.503C30.0179 28.862 29.6869 29.691 23.9999 29.691ZM18.8209 28.208C20.5184 28.5965 22.2598 28.7589 23.9999 28.691C25.742 28.7633 27.4857 28.595 29.1819 28.191C29.1729 28.01 29.0409 27.869 28.7619 27.61C28.589 27.4628 28.4348 27.2949 28.3029 27.11C28.023 26.5347 27.8858 25.9005 27.9029 25.261C27.9089 25.17 28.2579 22.101 26.7629 20.417C26.4114 20.0415 25.9818 19.7477 25.5044 19.5562C25.027 19.3648 24.5134 19.2804 23.9999 19.309C23.4991 19.2767 22.9974 19.358 22.5324 19.547C22.0675 19.7359 21.6512 20.0275 21.3149 20.4C19.8989 22.074 20.3979 25.15 20.4039 25.18L20.4209 25.28L20.3959 25.38C20.2704 25.8493 20.0993 26.3051 19.8849 26.741C19.7285 27.0198 19.5321 27.2742 19.3019 27.496C18.9999 27.82 18.8329 28.017 18.8209 28.208V28.208Z" fill={borderColor}/>
        <path d="M23.9999 32.522C23.5789 32.538 23.1591 32.4669 22.7668 32.3131C22.3746 32.1593 22.0183 31.9262 21.7203 31.6283C21.4224 31.3304 21.1891 30.9742 21.0352 30.582C20.8813 30.1898 20.81 29.77 20.8259 29.349H21.3259H21.8259C21.8143 29.6376 21.8627 29.9254 21.9678 30.1943C22.073 30.4632 22.2328 30.7075 22.437 30.9116C22.6412 31.1158 22.8856 31.2754 23.1545 31.3805C23.4235 31.4855 23.7114 31.5337 23.9999 31.522C24.2993 31.5478 24.6008 31.5105 24.8848 31.4124C25.1689 31.3143 25.4292 31.1577 25.6489 30.9527C25.8686 30.7477 26.0429 30.4989 26.1603 30.2223C26.2778 29.9457 26.3359 29.6475 26.3309 29.347H27.3309C27.3386 29.7795 27.2565 30.2088 27.0898 30.6079C26.923 31.007 26.6753 31.3671 26.3622 31.6655C26.0491 31.964 25.6775 32.1942 25.2709 32.3416C24.8643 32.489 24.4315 32.5504 23.9999 32.522V32.522Z" fill={borderColor}/>
        <path d="M26.2238 18.891L25.2378 18.728C25.3669 18.0624 25.2537 17.3725 24.9188 16.783C24.823 16.6785 24.7047 16.5972 24.5729 16.5453C24.441 16.4933 24.2991 16.472 24.1578 16.483C23.99 16.4727 23.8219 16.4984 23.6648 16.5582C23.5077 16.618 23.3652 16.7107 23.2468 16.83C22.9007 17.3037 22.7316 17.8836 22.7688 18.469L21.7688 18.53C21.7194 17.6717 21.9846 16.8248 22.5148 16.148C22.7255 15.9272 22.9808 15.7537 23.2637 15.639C23.5467 15.5244 23.8507 15.4712 24.1558 15.483C24.4433 15.4722 24.7295 15.5259 24.9936 15.6401C25.2576 15.7543 25.4928 15.9261 25.6818 16.143C26.2069 16.9561 26.4008 17.9394 26.2238 18.891V18.891Z" fill={borderColor}/>
    </svg>
);