import React from 'react';
import { map } from 'lodash';
import styles from 'common/Headers/Headers.module.css';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const DashboardHeaderNavLinkSwitcher = ({ navLinkTabButtons, isShowNavLinkTabButtons, className }) => (
    <>
        {isShowNavLinkTabButtons && map(navLinkTabButtons, (navLink, index) => {
            const { link, name, isShow } = navLink;
            return isShow && <NavLink
                to={link}
                className={navLink => cx(styles.navLinkSwitcher, className, { 'navLinkSwitcherActiveTab': navLink.isActive })}
                key={name + index}
                end
            >
                {name}
            </NavLink>
        })}
    </>
);
DashboardHeaderNavLinkSwitcher.propTypes = {
    navLinkTabButtons: PropTypes.array.isRequired,
    isShowNavLinkTabButtons: PropTypes.bool,
    className: PropTypes.string
};
DashboardHeaderNavLinkSwitcher.defaultProps = {
    navLinkTabButtons: [],
    isShowNavLinkTabButtons: true,
};
