import { isEmpty } from 'lodash';
import { Validation } from 'validations/Validation';
import { attributeFilterControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/attributeFilterControl';
import { ObjectUse } from 'lib/ObjectUse';

const actionNameRules = {
    value: 'required'
};

export const triggerEventControl = action => {

    const {
        values: {
            actionName
        },
        attributeFilter
    } = action.settings;

    const actionNameResult = Validation.check(actionName, actionNameRules, {
        'required.value': 'InputValidationRequiredMessage',
    }, 'value');

    const attributeFilterResult = attributeFilterControl(attributeFilter);

    const isActionHasErrors = actionNameResult.fails || !isEmpty(attributeFilterResult);

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                actionNameResult.firstError,
                !isEmpty(attributeFilterResult) ? attributeFilterResult : false
            ], ['actionName', 'attributeFilter'])
        }
    }
};
