import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { DraggableTypes } from 'lib/DraggableTypes';

export const DraggableActionsView = ({ item, title, previewImg, hoverImg, actionType, hoverActionType }) => {
    const [{ isDragging }, drag, preview] = useDrag({
        item: { item, title, actionType, type: DraggableTypes.ACTION },
        type: DraggableTypes.ACTION,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    });

    const style = {
        opacity: 1,
        maxHeight: '32px',
    };
    const draggingStyle = {
        opacity: 0.4,
        maxHeight: '32px',
    };

    return (
        <div ref={drag} style={isDragging ? draggingStyle : style}>
            { isEqual(hoverActionType, actionType) ? hoverImg : item }

            <DragPreviewImage
                src={previewImg}
                connect={preview}
            />
        </div>
    );
};

DraggableActionsView.propTypes = {
    item: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    previewImg: PropTypes.string.isRequired,
    hoverImg: PropTypes.object,
    actionType: PropTypes.string.isRequired,
    hoverActionType: PropTypes.string.isRequired
}
