import { map, filter, eq, has } from 'lodash';
import { waitCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/waitCompiler';
import { funnelCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/funnelCompiler';
import { abTestSplitCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/abTestSplitCompiler';
import { sendContentCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/sendContentCompiler';
import { sqlTriggerCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/sqlTriggerCompiler';
import { triggerEventCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/triggerEventCompiler';
import { tailoredWaitCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/tailoredWaitCompiler';
import { customWebHookCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/customWebHookCompiler';
import { repeatTriggerCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/repeatTriggerCompiler';
import { scheduleTriggerCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/scheduleTriggerCompiler';
import { conditionalSplitCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/conditionalSplitCompiler';
import { pushContentCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/pushContentCompiler';
import { websiteBuilderCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/websiteBuilderCompiler';
import { editSegmentCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/editSegmentCompiler';

export const actionChainCompiler = (currentScenario, actionsList, actionConnectionsList) => {
    console.log('%c[Client Compiler Props]: actionsList:', 'color:dodgerblue', actionsList);
    console.log('%c[Client Compiler Props]: actionConnectionsList:', 'color:dodgerblue', actionConnectionsList);

    // Action compilers list
    const actionTypeCompilers = {
        TriggerEvent: triggerEventCompiler,
        RepeatTrigger: repeatTriggerCompiler,
        ScheduleTrigger: scheduleTriggerCompiler,
        SqlTrigger: sqlTriggerCompiler,
        Wait: waitCompiler,
        TailoredWait: tailoredWaitCompiler,
        ConditionalSplit: conditionalSplitCompiler,
        ABTestSplit: abTestSplitCompiler,
        Funnel: funnelCompiler,
        SendEmail: sendContentCompiler,
        SendSms: sendContentCompiler,
        SendViber: sendContentCompiler,
        CustomWebHook: customWebHookCompiler,
        WebsiteBuilder: websiteBuilderCompiler,
        SendMobPush: pushContentCompiler,
        SendWebPush: pushContentCompiler,
        EditSegment: editSegmentCompiler
    };

    const compiledActionsArray = map(actionsList, action => {
        const { id, actionType } = action;

        if (eq(actionType, 'ConditionalSplit')) {
            // Filtered array with arrows for ConditionalSplit, with two output checkpoint (top arrows is Yes, bottom for No)
            const filteredArrows = {
                top: filter(actionConnectionsList, { from: { id: `${id}-output-top` }}),
                bottom: filter(actionConnectionsList, { from: { id: `${id}-output-bottom` }})
            }
            // Choose compiler function according to action type
            return actionTypeCompilers[actionType](action, filteredArrows);
        }

        if (eq(actionType, 'ABTestSplit')) {
            // Filtered array with arrows for ABTestSplit, with three output checkpoint
            const filteredArrows = {
                top: filter(actionConnectionsList, { from: { id: `${id}-output-top` }}),
                right: filter(actionConnectionsList, { from: { id: `${id}-output-right` }}),
                bottom: filter(actionConnectionsList, { from: { id: `${id}-output-bottom` }})
            }
            // Choose compiler function according to action type
            return actionTypeCompilers[actionType](action, filteredArrows);
        }

        // Default filtered array with arrows which have only one output checkpoint
        const filteredArrows = filter(actionConnectionsList, { from: { id: `${id}-output` }});
        // Choose compiler function according to action type
        return actionTypeCompilers[actionType](action, filteredArrows);
    })

    console.log('%c[Client Compiler Result]: compiledActionsArray:', 'color:dodgerblue', compiledActionsArray);

    // return compiled scenario for server without changing id's
    return {
        ...currentScenario,
        actions: filter(compiledActionsArray, action => has(action, 'action_id')),
        triggers: filter(compiledActionsArray, trigger => has(trigger, 'trigger_id'))
    }
}
