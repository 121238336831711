import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from 'common/InputField/InputField.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const disableOnWheelScroll = event => event.target.blur();

export const InputField = memo(({
    type,
    id,
    name,
    value,
    required,
    error,
    isInputWithoutErrorField,
    onChange,
    placeholder,
    className,
    disabled,
}) => (
    <input
        type={type}
        id={id}
        name={name}
        value={value}
        required={required}
        className={cx(styles.input, className, { 'error': error, 'inputWithoutErrorField': isInputWithoutErrorField } )}
        onChange={onChange}
        onWheel={disableOnWheelScroll}
        placeholder={placeholder}
        disabled={disabled}
    />
));

InputField.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    required: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    isInputWithoutErrorField: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}
InputField.defaultProps = {
    type: 'text',
    name: '',
    value: '',
    required: false,
    error: false,
    isInputWithoutErrorField: false,
    placeholder: '',
    className: null,
    disabled: false,
}
