import { useContext, useEffect } from 'react';
import { find, isEqual, omit, map } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';

export const useCompareConditions = () => {
    const {
        isActionInSimplifiedMod,
        condition,
        simplifiedSettingsButtons,
        setSimplifiedSettingsButtons,
        simplifiedTimeInterval,
        setSimplifiedTimeInterval,
        setActionParams,
        turnOffSimplifiedSettingsButtons,
        clearSidebarDifficultySettingsType,
        clearSimplifiedTimeInterval,
        updateSimplifiedSettingsCondition,
        simplifiedTimeIntervalType = [],
        isAtScriptStartSelected = false
    } = useContext(SideBarContext);

    useEffect(() => {
        if (isActionInSimplifiedMod) {
            // Get seleected action simplified settings
            const simplifiedSettings = find(simplifiedSettingsButtons, 'selected');

            // Used for remove all condition keys and values which doesn't need for compare
            const conditionExceptionsArray = ['show'];
            // We need to update selected simplified settings (condition) by simplifiedTimeInterval values, before compare
            // Remove all exceptions from simplidied condition, and for each _attributeFilter in condition too
            const updatedSimplifiedSettingsCondition = omit(
                updateSimplifiedSettingsCondition({
                        ...simplifiedSettings,
                        condition: {
                            ...simplifiedSettings.condition,
                            _attributeFilter: map(simplifiedSettings.condition._attributeFilter, filter => omit(filter, conditionExceptionsArray))
                        }
                    },
                    simplifiedTimeInterval,
                    simplifiedTimeIntervalType,
                    isAtScriptStartSelected
                ),
                conditionExceptionsArray
            );
            // Remove all exceptions from advanced condition, and for each _attributeFilter in condition too
            const advancedCondition = omit({
                    ...condition[0],
                    _attributeFilter: map(condition[0]._attributeFilter, filter => omit(filter, conditionExceptionsArray))
                },
                conditionExceptionsArray
            );

            // Compare advanced condition and updated simplified settings condition. For detect user changes in advanced settings of condition
            const isNotEqualValues = !isEqual(advancedCondition, updatedSimplifiedSettingsCondition);

            if (isNotEqualValues || condition.length > 1) {
                turnOffSimplifiedSettingsButtons(setSimplifiedSettingsButtons);

                clearSidebarDifficultySettingsType(setActionParams);

                clearSimplifiedTimeInterval(setSimplifiedTimeInterval);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])
}
