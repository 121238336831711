import { apiRequestFailedStatuses } from 'store/apiRequestFailedStatuses';

export const api = (label = null) => `API_REQUEST => ${label}`;
export const apiStartType = (label = null) => `API_START => ${label}`;
export const apiEndType = (label = null) => `API_END => ${label}`;

export const apiStart = label => ({
    type: apiStartType(label),
    payload: label
});

export const apiEnd = label => ({
    type: apiEndType(label),
    payload: label
});

export const apiRequestAction = ({
    url = '',
    method = 'GET',
    extraOptions = null,
    data = {},
    accessToken = null,
    onSuccess = () => {},
    onAfterSuccess = () => {},
    onFailure = () => {},
    onFailureWithStatus = apiRequestFailedStatuses,
    onFinally = () => {},
    label = '',
    lang = '*',
    headersOverride = null,
}) => ({
    type: api(label),
    payload: {
        url,
        method,
        extraOptions,
        data,
        accessToken,
        onSuccess,
        onAfterSuccess,
        onFailure,
        onFailureWithStatus,
        onFinally,
        label,
        lang,
        headersOverride
    },
});

export const apiAllRequestAction = ({
    extraOptions = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    onFailureWithStatus = apiRequestFailedStatuses,
    onFinally = () => {},
    label = '',
    requestsConfig = [],
    onAfterSuccess = () => {},
}) => ({
    type: api(label),
    payload: {
        requestsConfig,
        extraOptions,
        accessToken,
        onSuccess,
        onFailure,
        onFailureWithStatus,
        onFinally,
        label,
        onAfterSuccess
    },
    meta: 'axiosAll',
});
