import React, { useContext, useEffect, useState } from 'react';
import { find, map, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ActionCodeEditorContext,
    SideBarContext,
} from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { TabButtons } from 'common/Buttons/TabButtons/TabButtons';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SelectDayOfWeek } from 'components/SelectDayOfWeek/SelectDayOfWeek';
import { SelectedDateViewer } from 'components/DateSelectors/SelectedDateViewer';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { useComponentUnmount } from 'hooks/useComponentUnmount';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import{ getStoreDataSqlTrigger } from 'store/selectors/scenariosActions.selectors'
import { InputTimeField } from 'common/InputTimeField/InputTimeField';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

export const SqlTriggerSidebar = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();
    const { actionCode, setActionCode } = useContext(ActionCodeEditorContext);
    const {
        onChangeTime,
        onSubmitHandler,
        onChangeSelectedDay
    } = useContext(SideBarContext);

    const {
        title,
        actionType,
        selectedType,
        _dayOfWeek = [],
        _atTheDay = { value: null, required: true },
        _time,
        _sql,
        errors
    } = useSelector(getStoreDataSqlTrigger);

    const { dateCalendar: { date } } = useSelector(store => store.calendars);

    const [sqlTriggerButtons, setSqlTriggerButtons] = useState(selectedType);
    const [dayOfWeek, setDayOfWeek] = useState(_dayOfWeek);
    const [atTheDay, setAtTheDay] = useState(_atTheDay);
    const [time, setTime] = useState(_time);

    const isRepeatTypeSelected = some(sqlTriggerButtons, { id: 'repeat', active: true });
    const isScheduleTypeSelected = some(sqlTriggerButtons, { id: 'schedule', active: true });
    const selectedActionType = find(sqlTriggerButtons, { active: true });

    const onClickSaveActionSettings = selectedActionType => {
        return () => {
            const getSelectedTypeInterval = {
                repeat: {
                    dayOfWeek,
                    atTheDay: { ...atTheDay, value: null }

                },
                schedule: {
                    dayOfWeek,
                    atTheDay,
                }
            }

            const selectedTypeInterval = getSelectedTypeInterval[selectedActionType.id];

            return dispatch(saveSidebarData({
                ...selectedTypeInterval,
                time,
                selectedType: sqlTriggerButtons,
                sql: actionCode
            }));
        }
    }

    useEffect(() => {
        date && setAtTheDay(prev => ({
            ...prev,
            value: date
        }))
    }, [date])

    useEffect(() => {
        if (_sql) setActionCode(_sql)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_sql])

    useComponentUnmount(() => setActionCode(''));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings(selectedActionType))}>
            <>
                <SidebarGeneralTitle title={title}/>

                <TabButtons
                    buttons={map(sqlTriggerButtons, button => ({ ...button, name: translator(`actionSidebars:${button.name}`) }) )}
                    setState={setSqlTriggerButtons}
                />

                {isRepeatTypeSelected && <SelectDayOfWeek
                    array={map(dayOfWeek, day => ({ ...day, name: { ...day.name, shortCut: translator(`default:${day.name.shortCut}`) }  }) )}
                    onChange={onChangeSelectedDay(setDayOfWeek)}
                    errors={errors}
                />}

                {isScheduleTypeSelected && <SelectedDateViewer date={atTheDay?.value} errors={errors}/>}

                <InputTimeField
                    value={time}
                    onChange={onChangeTime(setTime)}
                    label={translator('actionSidebars:TimeFieldLabel')}
                />

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    )
}
