import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { SendSmsIcon } from 'assets/icons/SendSmsIcon';
import { SendSmsIconAlt } from 'assets/icons/SendSmsIconAlt';

export const SendSms = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: SendSmsIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#0CBFE7'
            }
        },
        opened: {
            icon: SendSmsIconAlt,
            iconProps: { borderColor: '#0CBFE7' }
        },
        warning: {
            icon: SendSmsIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions
    }} />
}
