import React, { createContext, useState, useCallback, useEffect } from 'react';
import ToastPortal from 'components/_Portals/ToastPortal/ToastPortal';
import { map } from 'lodash';
import { Toast } from 'components/_Toast/Toast';
import { useSelector } from 'react-redux';

export const ToastContext = createContext(null);

let id = 1;

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const catchedApiFailWithStatusCode = useSelector(store => store.api.catchedApiFailWithStatusCode);

    const addToast = useCallback((content, type = 'info') => {
        setToasts(prevToasts => [
            ...prevToasts,
            { id: id++, content, type }
        ]);
    }, []);

    const deleteToast = useCallback(toastId => {
        setToasts(prevToasts => prevToasts.filter(t => t.id !== toastId));
    }, []);

    useEffect(() => {
        if (catchedApiFailWithStatusCode) {
            addToast(catchedApiFailWithStatusCode.message, 'warning');
        }
    }, [addToast, catchedApiFailWithStatusCode]);

    return (
        <ToastContext.Provider value={{ addToast, deleteToast }}>
            {children}
            <ToastPortal>
                {map(toasts, toast => (
                    <Toast
                        key={toast.id}
                        deleteToastCallback={deleteToast}
                        {...toast}
                    />
                ))}
            </ToastPortal>
        </ToastContext.Provider>
    );
};
