import { map, split, trim } from 'lodash';
import { ArrayUse } from 'lib/ArrayUse';

export const attributeFilterCompiler = array => map(array, item => {
    const {
        eventAttributeName,
        comparisonValue,
        comparisonOperator
    } = item;
    const { splitOperator: eventNameSplitOperator, exists: eventNameSplitOperatorExists  } = ArrayUse.splitArrayExtension(eventAttributeName.value, [',', ' ', '.']);

    const splitedEventAttributeNameArray = map(split(eventAttributeName.value, eventNameSplitOperator), trim);

    return {
        column_name: eventNameSplitOperatorExists ? splitedEventAttributeNameArray : [eventAttributeName.value],
        values_to_include: comparisonValue.value,
        operator: comparisonOperator.value
    }
});
