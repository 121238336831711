import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './ToastPortal.module.css';

const ToastPortal = ({ children }) => {
    const [ container ] = useState(() => document.createElement('div'));

    useEffect(() => {
        container.classList.add(styles.container)
        document.body.appendChild(container);

        return () => {
            document.body.removeChild(container);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return createPortal(children, container);
};

export default ToastPortal;