import React from 'react';
import Xarrow from 'react-xarrows';
import PropTypes from 'prop-types';

export const ActionChainPotentialConnection = ({ from, to }) => {

    if (from && to) {
        return (
            <Xarrow
                start={from}
                end={to}
                headSize={0}
                color='#9088df80'
                strokeWidth={3}
                startAnchor="auto"
                endAnchor="auto"
                curveness={0.5}
                advanced={{ extendSVGcanvas: 0 }}
                dashness={{ animation: 0.7 }}
            />
        )
    }

    return null;
}

ActionChainPotentialConnection.propTypes = {
    from: PropTypes.string,
    to: PropTypes.string,
}
