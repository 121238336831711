import React, { useContext, useState, useEffect } from 'react';
import { some, find, mapValues, isEmpty, map } from 'lodash';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { resetScenarioActionErrors, saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataTriggerEvent } from 'store/selectors/scenariosActions.selectors';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';
import { TriggerEventAdvanced } from 'components/ScenarioSetup/ActionSidebars/advanced/TriggerEventAdvanced';
import { TriggerEventSimplified } from 'components/ScenarioSetup/ActionSidebars/simplified/TriggerEventSimplified';
import { GearSwitchableButton } from 'common/_Buttons/_GearSwitchableButton/GearSwitchableButton';
import { silmplifiedTriggerEventSettins } from 'components/ScenarioSetup/ActionSidebars/settings/simplified/simplifiedTriggerEventSettings';
import { defaultTriggerEventSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultTriggerEventSettings';
import { clearCurrentEvent, getAllIntegrationEvents } from 'store/actions/integrations.action';
import { useDropdownFilters } from 'hooks/useDropdownFilters';

export const TriggerEventSidebar = () => {
    const dispatch = useDispatch();
    const context = useContext(SideBarContext);
    const { allEvents } = useSelector(store => store.integrations);
    const { id, title, actionType, _actionParams, _values, _attributeFilter, errors } = useSelector(getStoreDataTriggerEvent);

    const {
        onSubmitHandler,
        onClickChangeSidebarDifficultySettings,
        initSelectedSimplifiedSettings
    } = context;

    const [values, setValues] = useState(_values);
    const [attributeFilter, setAttributeFilter] = useState(_attributeFilter);
    const [actionParams, setActionParams] = useState(_actionParams);
    const [simplifiedSettingsButtons, setSimplifiedSettingsButtons] = useState(initSelectedSimplifiedSettings(_actionParams, silmplifiedTriggerEventSettins));

    // Means that at least one simplified parameter selected
    const isActionInSimplifiedMod = some(simplifiedSettingsButtons, 'selected');

    const mappedEventNames = map(allEvents.base, event => event.event_name);

    const onClickSaveActionSettings = () => {
        const stateManagementPromise = new Promise(resolve => {
            return actionParams.simplified && !isActionInSimplifiedMod
                ? resolve({
                    data: defaultTriggerEventSettings(),
                    params: { actionParams }
                })
                : resolve({
                    data: { values, attributeFilter },
                    params: { actionParams }
                })
        });

        stateManagementPromise.then(actionData => {
            dispatch(saveSidebarData(actionData.data, actionData.params));
        });
    };

    useDropdownFilters(['ATTRIBUTE', 'PRODUCT_ATTRIBUTE' ]);

    useEffect(() => {
        if (isActionInSimplifiedMod) {
            if (!isEmpty(errors)) dispatch(resetScenarioActionErrors(id));

            const simplifiedSettings = find(simplifiedSettingsButtons, 'selected');

            setValues(prev => {
                return mapValues(prev, (value, key) => {
                    return { ...value, ...simplifiedSettings.values[key] }
                })
            })

            setAttributeFilter([]);

            setActionParams(prev => ({ ...prev, simplifiedType: simplifiedSettings.name }));
        }
    }, [dispatch, errors, id, isActionInSimplifiedMod, simplifiedSettingsButtons])

    useEffect(() => {
        if (isEmpty(allEvents.base)) {
            dispatch(getAllIntegrationEvents())
        }
        return () => dispatch(clearCurrentEvent());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <SideBarContext.Provider
            value={{
                ...context,
                values,
                setValues,
                errors,
                simplifiedSettingsButtons,
                setSimplifiedSettingsButtons,
                isActionInSimplifiedMod,
                attributeFilter,
                setAttributeFilter,
                actionParams,
                setActionParams,
                mappedEventNames
            }}>
            <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
                <>
                    <div className={styles.sidebarHeaderContainer}>
                        <SidebarGeneralTitle title={title}/>
                        <GearSwitchableButton
                            active={!actionParams.simplified}
                            onClick={onClickChangeSidebarDifficultySettings(setActionParams)}
                        />
                    </div>

                    {actionParams.simplified
                        ? <TriggerEventSimplified/>
                        : <TriggerEventAdvanced/>
                    }

                    <ActionSidebarButtons/>
                </>
            </ActionSidebarFormWrapper>
        </SideBarContext.Provider>
    );
};
