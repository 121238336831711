import { map, eq, toString } from 'lodash';
import { defaultFunnelSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultFunnelSettings';
import { StringUse } from 'lib/StringUse';

const {
    selectedType,
    shareCount,
    countClients,
    countTimeIntervalNumber,
    countTimeIntervalType,
    evalTimeInterval
} = defaultFunnelSettings();

export const funnelDecompiler = actionOptions => {

    const {
        count,
        fraction
    } = actionOptions;

    const funnelSelectedTypeConverter = map(selectedType, item => {

        if (eq(item.id, 'count') && count) return { ...item, active: true };

        if (eq(item.id, 'share') && fraction) return { ...item, active: true };

        return { ...item, active: false }
    })

    if (count) {
        const {
            max_n_clients = countClients.value,
            period_number = countTimeIntervalNumber.value,
            period_type = countTimeIntervalType.value,
            split_evenly = evalTimeInterval
        } = count;

        return {
            selectedType: funnelSelectedTypeConverter,
            shareCount,
            countClients: {
                ...countClients,
                value: StringUse.falseyReturnEmptyString(max_n_clients, 'string'),
            },
            countTimeIntervalNumber: {
                ...countTimeIntervalNumber,
                value: StringUse.falseyReturnEmptyString(period_number, 'string'),
            },
            countTimeIntervalType: {
                ...countTimeIntervalType,
                value: period_type,
            },
            evalTimeInterval: split_evenly
        }
    }

    if (fraction) {
        const {
            probability
        } = fraction

        return {
            selectedType: funnelSelectedTypeConverter,
            shareCount: {
                ...shareCount,
                value: toString(probability * 100),
            },
            countClients,
            countTimeIntervalNumber,
            countTimeIntervalType,
            evalTimeInterval
        }
    }
}
