import React from 'react'
import styles from 'common/_Buttons/_DeleteButton/DeleteButton.module.css';
import PropTypes from 'prop-types';
import { TrashIcon } from 'assets/icons/TrashIcon';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const DeleteButton = ({ name, onClick, className, color, isShowName }) => (
    <div onClick={onClick} className={cx(styles.deleteButton, className, { 'withName': isShowName })}>
        <TrashIcon color={color}/>
        {isShowName &&
            <p>{name}</p>
        }
    </div>
);

DeleteButton.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    isShowName: PropTypes.bool
};
DeleteButton.defaultProps = {
    name: '',
    className: null,
    color: 'inherit',
    isShowName: false
};
