import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import { EditIcon } from 'assets/icons/EditIcon';
import { TrashIcon } from 'assets/icons/TrashIcon';
import { CopyIcon } from 'assets/icons/CopyIcon';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ActionIconsContext } from 'components/ScenarioSetup/ActionIcons/context/ActionIconsContext';
import { includes } from 'lodash'

const cx = classNames.bind(styles);

export const ActionTooltipWindow = () => {
    const dispatch = useDispatch();
    const newActionId = uuid();
    const { t: translator } = useTranslation();
    const { pathname } = useLocation();

    const {
        onClickSetEditAction,
        onClickCopyAction,
        onClickDeleteAction,
        isOpenActionInfo,
        wrappedRef,
        id,
        title,
        actionType,
        alternativeEditHandler
    } = useContext(ActionIconsContext);

    const pageTestScenario = includes(pathname, 'create-test-scenario');

    const handleCopyAction = () => {
        if (!pageTestScenario) {
            return onClickCopyAction(dispatch, id, newActionId)
        }
    };

    const handleDeleteAction = () => {
        if (!pageTestScenario) {
            return onClickDeleteAction(dispatch, id)
        }
    };

    return (
        isOpenActionInfo &&
        <div className={cx(styles.actionInfoTooltip, styles.actionInfoTooltipAddition)} ref={wrappedRef}>
            <p className={styles.actionId}>id: <span>{id}</span></p>
            <p className={styles.actionType}>Name: <span>{title}</span></p>
            <button onClick={onClickSetEditAction(dispatch, alternativeEditHandler, actionType, id)}>
                <EditIcon color="currentColor"/>
                <span>{translator('default:EditButtonTitle')}</span>
            </button>
            <button
                onClick={handleCopyAction()}
            >
                <CopyIcon color="currentColor"/>
                <span>{translator('default:CopyButtonTitle')}</span>
            </button>
            <button onClick={handleDeleteAction()}>
                <TrashIcon color="currentColor"/>
                <span>{translator('default:DeleteButtonTitle')}</span>
            </button>
        </div>
    )
}
