import { useEffect, useState } from 'react';

export const useLoader = (condition, deps = [], timeout = 800) => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);

        let interval = setInterval(() => {
            if (!condition) setLoader(false);
        }, timeout);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    return loader;
}