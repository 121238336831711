import React from 'react';
import PropTypes from 'prop-types';
import styles from 'pages/Auth/Auth.module.css';
import animations from 'animations/FadeIn.module.css';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const AuthThankYouPage = ({ isShow }) => {
    const { t: translator } = useTranslation();

    return (
        isShow ?
            <div className={cx(styles.signInContainer, styles.thankYouContainer, animations.fadeIn)}>
                <div className={styles.logo}/>
                <p className={styles.thankYouPhrase}>{translator('auth:ThankYouPopUpMessage')}</p>
                <NavLink to='/' className={styles.thankYouButton}>{translator('auth:ReturnButton')}</NavLink>
            </div>
            : null
    )
};

AuthThankYouPage.propTypes = {
    isShow: PropTypes.bool.isRequired
}
AuthThankYouPage.defaultProps = {
    isShow: false
}
