import React from 'react';
import PropTypes from 'prop-types';
import styles from './CustomModal.module.css';
import animation from 'animations/FadeIn.module.css';
import ModalPortal from 'components/_Portals/ModalPortal';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const CustomModal = ({ children, componentRef, isOpened, modalClassName }) => {

    if (!isOpened) {
        return null
    }

    return (
        <ModalPortal>
            <div className={styles.overlay}>
                <div className={styles.wrapper}>
                    <div className={cx(styles.modal, animation.fadeIn300ms, modalClassName)} ref={componentRef}>
                        {children}
                    </div>
                </div>
            </div>
        </ModalPortal>
    )
};

CustomModal.propTypes = {
    children: PropTypes.element.isRequired,
    componentRef: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    modalClassName: PropTypes.string,
};
CustomModal.defaultProps = {
    modalClassName: ''
};