import React, { useEffect } from 'react';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { toggleRangeCalendarMetrics } from 'store/actions/calendars.action';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'components/DateSelectors/DateSelectors.module.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DateUse } from 'lib/DateUse';

export const SelectDatesInterval = () => {
    const dispatch = useDispatch();
    const { startDate, endDate} = useSelector(store => store.calendars.rangeCalendarMetrics);

    const { start: defaultStart, end: defaultEnd } = DateUse.defaultDateRequest();

    useEffect(() => {
        if (!startDate || !endDate) {
            dispatch(toggleRangeCalendarMetrics({
                open: false,
                startDate: defaultStart ? format(defaultStart, 'dd/MM/yyyy', { locale: ru }) : null,
                endDate: defaultEnd ? format(defaultEnd, 'dd/MM/yyyy', { locale: ru }) : null,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            onClick={() => dispatch(toggleRangeCalendarMetrics({ open: true, startDate, endDate }))}
            className={styles.sidebarDateViewer}
            data-testid='rangeOfDateOpenCalendar'
        >
            <p>
                <span>{startDate}</span>
                <span>{' - '}</span>
                <span>{endDate}</span>
            </p>

            <CalendarIcon/>
        </div>
    );
};
