import React, { useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { map } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { useTranslation } from 'react-i18next';
import { AttributeFilterMapped } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterMapped';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';

export const AttributeFilterDropdown = () => {
    const { t: translator } = useTranslation();
    const {
        onClickCreateNewDropdown,
        attributeFilter,
        setAttributeFilter,
    } = useContext(SideBarContext);

    return (
        <>
            <AsideDropdownButton
                name={translator('actionSidebars:NewAttributeFilterButton')}
                onClick={onClickCreateNewDropdown(setAttributeFilter, attributeFilterExample)}
            />
            <ul className={styles.filter}>
                {map(attributeFilter, (item, index) =>
                    <AttributeFilterMapped
                        item={item}
                        key={item.id}
                        index={index}/>
                )}
            </ul>
        </>
    );
}
