import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/_OptionsFilter/OptionsFilter.module.css';
import { map } from 'lodash';

const updateOptionsState = (setState, isChecked, options) => {

    const updatedOptions = map(options, item => ({ ...item, isChecked }));

    setState(updatedOptions);

    return Promise.resolve(updatedOptions);
};

const changeFilterSelectedOptions = (setStateProps, isChecked, onChangeAction, options) => {
    return () => updateOptionsState(setStateProps, isChecked, options)
        .then(options => onChangeAction(options));
};

export const OptionsFilterHeader = ({ options, setOptions, onChangeAction, sectionName, checkAllButtonName, clearAllButtonName }) => (
    <div className={styles.selectCheckboxOptionsHeader}>
        <h6>{sectionName}</h6>

        <p onClick={changeFilterSelectedOptions(setOptions, true, onChangeAction, options)}>
            {checkAllButtonName}
        </p>

        <p onClick={changeFilterSelectedOptions(setOptions, false, onChangeAction, options)}>
            {clearAllButtonName}
        </p>
    </div>
);

OptionsFilterHeader.propTypes = {
    options: PropTypes.array.isRequired,
    setOptions: PropTypes.func.isRequired,
    onChangeAction: PropTypes.func,
    sectionName: PropTypes.string.isRequired,
    checkAllButtonName: PropTypes.string.isRequired,
    clearAllButtonName: PropTypes.string.isRequired
}
OptionsFilterHeader.defaultProps = {
    dispatchAction: () => {}
}
