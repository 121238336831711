import {
    TOGGLE_RANGE_CALENDAR,
    TOGGLE_DATE_CALENDAR,
    CLEAR_DATE_CALENDAR_DATA,
    TOGGLE_RANGE_CALENDAR_METRICS,
    CLOSE_RANGE_CALENDAR_METRICS,
    OPEN_RANGE_PICKER_CALENDAR,
    CLOSE_RANGE_PICKER_CALENDAR,
    UPDATE_RANGE_PICKER_CALENDAR_DATA,
    CLEAR_RANGE_PICKER_CALENDAR
} from 'store/types';

const initialState = {
    rangeCalendar: {
        id: null,
        open: false,
        startDate: null,
        endDate: null
    },
    dateCalendar: {
        open: false,
        date: null,
    },
    rangeCalendarMetrics: {
        open: false,
        startDate: null,
        endDate: null
    },
    rangePickerCalendar: {
        open: false,
        data: null
    }
};

export default function calendars(store = initialState, { type, payload }) {
    switch (type) {
        case TOGGLE_RANGE_CALENDAR:
            return {
                ...store, rangeCalendar: payload.data,
            };
        case TOGGLE_RANGE_CALENDAR_METRICS:
            return {
                ...store, rangeCalendarMetrics: payload.data,
            };
        case CLOSE_RANGE_CALENDAR_METRICS:
            return {
                ...store, rangeCalendarMetrics: {...store.rangeCalendarMetrics, open: false}
            };
        case TOGGLE_DATE_CALENDAR:
            return {
                ...store, dateCalendar: payload.data,
            };
        case CLEAR_DATE_CALENDAR_DATA:
            return {
                ...store, dateCalendar: payload.data,
            };
        case OPEN_RANGE_PICKER_CALENDAR:
            return {
                ...store,
                rangePickerCalendar: {
                    ...store.rangePickerCalendar,
                    open: true,
                    data: payload.data
                },
            };
        case CLOSE_RANGE_PICKER_CALENDAR:
            return {
                ...store,
                rangePickerCalendar: {
                    ...store.rangePickerCalendar,
                    open: false,
                },
            };
        case UPDATE_RANGE_PICKER_CALENDAR_DATA:
            return {
                ...store,
                rangePickerCalendar: {
                    ...store.rangePickerCalendar,
                    data: {
                        ...store.rangePickerCalendar.data,
                        ...payload.data
                    }
                }
            }
        case CLEAR_RANGE_PICKER_CALENDAR:
            return {
                ...store,
                rangePickerCalendar: {
                    open: false,
                    data: null
                },
            };

        default:
            return store;
    }
}
