import { useEffect, useState } from 'react';

export const useSelectDate = (storeCalendarDate, defaultDate) => {
    const [displayDate, setDisplayDate] = useState(defaultDate);

    useEffect(() => {
        storeCalendarDate && setDisplayDate(prev => ({
            ...prev,
            value: storeCalendarDate
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeCalendarDate]);

    return displayDate;
};
