import { find, findIndex, slice, forEach, filter, eq, map, some, includes } from 'lodash';

export class ArrayUse {

    static findCollection(array, params) {
        return {
            data: find(array, params),
            index: findIndex(array, params)
        }
    };

    static replace(array, index, replaceableData) {
        return [
            ...slice(array,0, index),
            replaceableData,
            ...slice(array,index + 1),
        ]
    };

    static replaceSome(array, index, ...newItems) {
        return [
            ...slice(array,0, index),
            ...newItems,
            ...slice(array,index + 1),
        ]
    };

    // Example of replaceableArray: [ { data: 'test', index: 0 }, { data: 'another text', index: 3 } ]
    static replaceGroup(array, replaceableArray) {
        let arr = array;

        forEach(replaceableArray, item => {
            arr = this.replace(arr, item.index, item.data)
        })

        return arr;
    };

    static insert(array, index, newItem) {
        return [
            ...slice(array,0, index),
            newItem,
            ...slice(array, index),
        ]
    };

    static insertSome(arr, index, ...newItems) {
        return [
            ...slice(arr, 0, index),
            ...newItems,
            ...slice(arr, index),
        ]
    };

    static removeByIndex(array, index) {
        return [
            ...slice(array,0, index),
            ...slice(array,index + 1),
        ]
    };

    static remove(array, data) {
        return filter(array,e => !eq(e, data));
    }

    static createJinjaFieldsArray(values, jinja_fields) {
        let editedJinjaFields = jinja_fields;

        forEach(values, ({ value }, key) => {
            const index = findIndex(jinja_fields, { jinja_field_name : key });

            if (index >= 0) {
                editedJinjaFields = this.replace(editedJinjaFields, index, { value,  jinja_field_name: key })
            }
        });

        return editedJinjaFields;
    };

    static splitArrayExtension(value, splitOperatorsArray) {
        const arr = map(splitOperatorsArray, item => {
            return some(value, el => includes(item, el)) ? { splitOperator: item } : null
        });
        const collection = find(arr, 'splitOperator');

        return collection ? {
            splitOperator: collection?.splitOperator,
            exists: true
        } : {
            splitOperator: null,
            exists: false
        }
    }

    static methodsCallOrder(methods = []) {
        forEach(methods, method => method());
    }
    // Recast method change array according to settings object
    // settings: { accepted: arr => arr, rejected: null }
    // Means that array lenght is false, recast return null or any needed value
    // But if array lenght is true, recast use accepted menthod. By default, return array
    static recast(array = [], settings = {}) {
        return array.length ? settings.accepted(array) : settings.rejected
    }
}
