import axios from 'axios';
import { eq, find, map } from 'lodash';
import { apiEnd } from 'store/actions/api.action';
import { DateUse } from 'lib/DateUse';
import { logout } from 'store/actions/auth.action';

export const allApiMiddleware = ({ getState, dispatch }) => next => action => {
    next(action);

    if (!eq(action.meta, 'axiosAll')) {
        return;
    }

    const store = getState();

    if (store.auth.isAuthenticated && DateUse.isDateExpired(store.auth.user?.tokenExpireDate)) {
        console.log('Need logout or re-login by refresh token');
        dispatch(logout());
        return
    }

    const {
        requestsConfig,
        extraOptions,
        onSuccess,
        onFailure,
        onFailureWithStatus,
        onFinally,
        label,
        onAfterSuccess
    } = action.payload;

    axios.all(map(requestsConfig, (requestConfig) => axios.request(requestConfig)))
        .then(response => {
            const resultData = response.map(item => item.data);

            onSuccess(resultData, extraOptions);

            return response;
        })
        .then(response => {
            onAfterSuccess();

            return response;
        })
        .catch(error => {
            onFailure(error.response);

            if (error.response) {
                const detectedFailureWithStatus = find(onFailureWithStatus, { statusCode: error.response.status }) ?? null;

                if (detectedFailureWithStatus) {
                    detectedFailureWithStatus.callback(dispatch);
                }
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label));
                onFinally();
            }
        })
};
