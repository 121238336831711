import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { CustomWebHookIcon } from 'assets/icons/CustomWebHookIcon';
import { CustomWebHookIconAlt } from 'assets/icons/CustomWebHookIconAlt';
import { toggleSideBar } from 'store/actions/scenariosActions.action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CustomWebhook = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const actionIconConditions = {
        base: {
            icon: CustomWebHookIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#0CBFE7'
            }
        },
        opened: {
            icon: CustomWebHookIconAlt,
            iconProps: { borderColor: '#0CBFE7' }
        },
        warning: {
            icon: CustomWebHookIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    const alternativeEditHandler = () => {
        dispatch(toggleSideBar({ open: true, type: actionType, id: id }));
        navigate('custom-webhook', { state: { shallow: true }});
    };

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
        alternativeEditHandler
    }} />
}
