import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { WaitIcon } from 'assets/icons/WaitIcon';
import { WaitIconAlt } from 'assets/icons/WaitIconAlt';

export const Wait = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: WaitIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#00C6A2'
            }
        },
        opened: {
            icon: WaitIconAlt,
            iconProps: { borderColor: '#00C6A2' }
        },
        warning: {
            icon: WaitIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions
    }} />
}
