import React from 'react';

export const EditSegmentIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="5" fill={bgColor}/>
        <g clipPath="url(#clip0_17429_42993)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 11.0833C8.64298 11.0833 8.08333 11.643 8.08333 12.3333C8.08333 13.0237 8.64298 13.5833 9.33333 13.5833C10.0237 13.5833 10.5833 13.0237 10.5833 12.3333C10.5833 11.643 10.0237 11.0833 9.33333 11.0833ZM7.25 12.3333C7.25 11.1827 8.18274 10.25 9.33333 10.25C10.4839 10.25 11.4167 11.1827 11.4167 12.3333C11.4167 13.4839 10.4839 14.4167 9.33333 14.4167C8.18274 14.4167 7.25 13.4839 7.25 12.3333Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.35697 16.8565C7.29979 16.9137 7.24984 17.0107 7.24984 17.166V23.416H11.4165V17.166C11.4165 17.0107 11.3666 16.9137 11.3094 16.8565C11.2522 16.7993 11.1552 16.7493 10.9998 16.7493H7.6665C7.51119 16.7493 7.41414 16.7993 7.35697 16.8565ZM6.76771 16.2672C7.0022 16.0327 7.32182 15.916 7.6665 15.916H10.9998C11.3445 15.916 11.6641 16.0327 11.8986 16.2672C12.1331 16.5017 12.2498 16.8213 12.2498 17.166V24.2493H6.4165V17.166C6.4165 16.8213 6.53322 16.5017 6.76771 16.2672Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2127 8.73958C15.5224 8.73958 14.9627 9.29923 14.9627 9.98958C14.9627 10.6799 15.5224 11.2396 16.2127 11.2396C16.9031 11.2396 17.4627 10.6799 17.4627 9.98958C17.4627 9.29923 16.9031 8.73958 16.2127 8.73958ZM14.1294 9.98958C14.1294 8.83899 15.0621 7.90625 16.2127 7.90625C17.3633 7.90625 18.2961 8.83899 18.2961 9.98958C18.2961 11.1402 17.3633 12.0729 16.2127 12.0729C15.0621 12.0729 14.1294 11.1402 14.1294 9.98958Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2642 14.3565C14.207 14.4137 14.1571 14.5107 14.1571 14.666V23.416H18.3237V14.666C18.3237 14.5107 18.2738 14.4137 18.2166 14.3565C18.1594 14.2993 18.0624 14.2493 17.9071 14.2493H14.5737C14.4184 14.2493 14.3214 14.2993 14.2642 14.3565ZM13.6749 13.7672C13.9094 13.5327 14.229 13.416 14.5737 13.416H17.9071C18.2518 13.416 18.5714 13.5327 18.8059 13.7672C19.0404 14.0017 19.1571 14.3213 19.1571 14.666V24.2493H13.3237V14.666C13.3237 14.3213 13.4404 14.0017 13.6749 13.7672Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0618 9.86849C22.3715 9.86849 21.8118 10.4281 21.8118 11.1185C21.8118 11.8088 22.3715 12.3685 23.0618 12.3685C23.7522 12.3685 24.3119 11.8088 24.3119 11.1185C24.3119 10.4281 23.7522 9.86849 23.0618 9.86849ZM20.9785 11.1185C20.9785 9.9679 21.9113 9.03516 23.0618 9.03516C24.2124 9.03516 25.1452 9.9679 25.1452 11.1185C25.1452 12.2691 24.2124 13.2018 23.0618 13.2018C21.9113 13.2018 20.9785 12.2691 20.9785 11.1185Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.107 15.5244C21.0498 15.5816 20.9998 15.6787 20.9998 15.834V23.4173H25.1665V15.834C25.1665 15.6787 25.1166 15.5816 25.0594 15.5244C25.0022 15.4673 24.9052 15.4173 24.7498 15.4173H21.4165C21.2612 15.4173 21.1641 15.4673 21.107 15.5244ZM20.5177 14.9352C20.7522 14.7007 21.0718 14.584 21.4165 14.584H24.7498C25.0945 14.584 25.4141 14.7007 25.6486 14.9352C25.8831 15.1697 25.9998 15.4893 25.9998 15.834V24.2507H20.1665V15.834C20.1665 15.4893 20.2832 15.1697 20.5177 14.9352Z" fill={color}/>
        </g>
    </svg>
);
