import React from 'react';

export const GoogleAdsIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="5" fill={bgColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5658 20.2174L18.4634 9.4817C17.6967 8.1329 15.9903 7.6757 14.6519 8.46052C13.3136 9.24534 12.8502 10.975 13.6169 12.3238L19.7193 23.0595C20.486 24.4083 22.1925 24.8655 23.5308 24.0807C24.8691 23.2959 25.3325 21.5662 24.5658 20.2174ZM19.3867 8.94028C18.3279 7.07759 15.9713 6.4462 14.123 7.53004C12.2748 8.61387 11.6348 11.0025 12.6936 12.8652L18.7961 23.6009C19.8549 25.4636 22.2115 26.095 24.0597 25.0112C25.9079 23.9274 26.5479 21.5387 25.4891 19.676L19.3867 8.94028Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.56043 24.3473C10.9161 24.3473 12.0434 23.2264 12.0434 21.8085C12.0434 20.3905 10.9161 19.2697 9.56043 19.2697C8.20477 19.2697 7.07742 20.3905 7.07742 21.8085C7.07742 23.2264 8.20477 24.3473 9.56043 24.3473ZM9.56043 25.4176C11.5229 25.4176 13.1137 23.8017 13.1137 21.8085C13.1137 19.8152 11.5229 18.1994 9.56043 18.1994C7.59799 18.1994 6.00712 19.8152 6.00712 21.8085C6.00712 23.8017 7.59799 25.4176 9.56043 25.4176Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.6123 17.296L12.27 23.074C11.4931 24.4171 9.78308 24.8614 8.4506 24.0662C7.11813 23.2711 6.66779 21.5376 7.44474 20.1944L13.1027 10.4131L13.4625 8.02461C13.1712 8.28726 12.9152 8.59987 12.7074 8.95902L6.52565 19.646C5.45272 21.5008 6.07462 23.8946 7.91469 24.9927C9.75476 26.0908 12.1162 25.4773 13.1891 23.6224L16.2417 18.3453L15.6123 17.296ZM19.1268 8.62754C18.9161 8.34125 18.6642 8.08072 18.3738 7.85586L19.1268 8.62754Z" fill={color}/>
    </svg>
);
