import React, { useCallback, useContext, useState } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { some, map } from 'lodash';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { TabButtons } from 'common/Buttons/TabButtons/TabButtons';
import { Checkbox } from 'common/_Checkbox/Checkbox';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { JointlyInputSelectOptions } from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { useTranslation } from 'react-i18next';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataFunnel } from 'store/selectors/scenariosActions.selectors'
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

const cx = classNames.bind(styles);

export const FunnelSidebar = () => {
    const dispatch = useDispatch();
    const { title, actionType, selectedType, shareCount, countClients, countTimeIntervalNumber, countTimeIntervalType, evalTimeInterval, errors } = useSelector(getStoreDataFunnel);
    const { t: translator } = useTranslation();

    const {
        defaultCountTimeInterval,
        onSubmitHandler,
        onChangeNumericInput,
        onChangeCheckbox,
        onChangeInput,
        onChangePercentShareInput
    } = useContext(SideBarContext);

    const [funnelButtons, setFunnelButtons] = useState(selectedType);
    const [values, setValues] = useState({ shareCount, countClients, countTimeIntervalNumber, countTimeIntervalType, evalTimeInterval });

    const mappedOptions = map(defaultCountTimeInterval, timeInterval => ({
        ...timeInterval,
        name: translator(`actionSidebars:${timeInterval.name}`)
    }) );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericCached = useCallback(onChangeNumericInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangePercentShareInput(setValues), []);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData({
        selectedType: funnelButtons,
        ...values
    }));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={title}/>

                <TabButtons
                    buttons={map(funnelButtons, button => ({ ...button, name: translator(`actionSidebars:${button.name}`) }) )}
                    setState={setFunnelButtons}
                />

                {some(funnelButtons,{ id: 'share', active: true }) &&
                <>
                    <Label htmlFor="shareCount" text={translator('actionSidebars:shareCount')}/>
                    <div className={cx(styles.percentInputContainer, { 'error': errors?.shareCount })}>
                        <InputField
                            type='number'
                            id='shareCount'
                            name='shareCount'
                            value={values?.shareCount?.value}
                            required={values?.shareCount?.required}
                            onChange={onChangeCached}
                        />
                        <p>%</p>
                    </div>
                    <ErrorMessageField active={!!errors?.shareCount} message={errors?.shareCount}/>
                </>
                }

                {some(funnelButtons,{ id: 'count', active: true }) &&
                <>
                    <Label htmlFor="countClients" text={translator('actionSidebars:countClients')}/>
                    <InputField
                        type='number'
                        id='countClients'
                        name='countClients'
                        value={values?.countClients?.value}
                        required={values?.countClients?.required}
                        error={!!errors?.countClients}
                        onChange={onChangeNumericCached}
                    />
                    <ErrorMessageField active={!!errors?.countClients} message={errors?.countClients}/>

                    <JointlyInputSelectOptions
                        inputName='countTimeIntervalNumber'
                        inputType='number'
                        inputLabel={translator('actionSidebars:countTimeIntervalNumber')}
                        inputValue={values?.countTimeIntervalNumber?.value}
                        inputRequired={values?.countTimeIntervalNumber?.required}
                        inputOnChange={onChangeNumericCached}
                        selectName='countTimeIntervalType'
                        selectOptions={mappedOptions}
                        selectValue={values?.countTimeIntervalType?.value}
                        selectRequired={values?.countTimeIntervalType?.required}
                        selectOnChange={onChangeInput(setValues)}
                        error={errors?.countTimeIntervalNumber}
                    />

                    <Checkbox
                        id='evalTimeInterval'
                        name='evalTimeInterval'
                        label={translator('actionSidebars:evalTimeInterval')}
                        checked={values?.evalTimeInterval}
                        onChange={onChangeCheckbox(setValues)}
                        isShowCheckboxLabelValue
                    />
                </>
                }

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
}
