import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmModal.module.css';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';

export const ConfirmModal = ({
    title,
    description,
    cancelButtonTitle,
    acceptButtonTitle,
    onClickCancel,
    onClickAccept
}) => (
    <div className={styles.container}>
        <h3>{title}</h3>

        <p>{description}</p>

        <div className={styles.buttonsContainer}>
            <LightGreenButton
                name={cancelButtonTitle}
                onClick={onClickCancel}
                className={styles.button}
            />
            <GreenButton
                name={acceptButtonTitle}
                onClick={onClickAccept}
                className={styles.button}
            />
        </div>
    </div>
);

ConfirmModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cancelButtonTitle: PropTypes.string.isRequired,
    acceptButtonTitle: PropTypes.string.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickAccept: PropTypes.func.isRequired,
};