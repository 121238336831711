import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/MonthDateSwitcher/MonthDateSwitcher.module.css';

export const MonthDateSwitcher = ({ months, handler }) => (
    <div className={styles.handlerMonth}>
        <button className={styles.handlerMonthBtnLeft} onClick={() => handler('left')}/>
        <span>{months}</span>
        <button className={styles.handlerMonthBtnRight} onClick={() => handler('right')}/>
    </div>
)
MonthDateSwitcher.propsTypes = {
    month: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
}
