import { createContext } from 'react';
import { remove, eq, map, toNumber, some, flatMap, find, isObject } from 'lodash';
import { ArrayUse } from 'lib/ArrayUse';
import { NumberUse } from 'lib/NumberUse';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';
import { defaultSimplifiedTimeIntervalSettings } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/simplified/simplifiedConditionSettings';
import {
    intervalTypeExtension
} from 'components/ScenarioSetup/ActionSidebars/settings/defaultConditionalSplitSettings';

export const jsonHeaderExample = {
    id: null,
    show: true,
    headerName: 'Header',
    headerKey: {
        value: '',
        required: true
    },
    headerValue: {
        value: '',
        required: true
    }
};
export const urlsExample = {
    id: null,
    show: true,
    url: {
        value: 'https://',
        required: true
    }
};
const defaultCountTimeInterval = [
    {
        value: 'minutes',
        name: 'minutesTimeInterval'
    },
    {
        value: 'hours',
        name: 'hoursTimeInterval'
    },
    {
        value: 'days',
        name: 'daysTimeInterval'
    },
    {
        value: 'weeks',
        name: 'weeksTimeInterval'
    },
    {
        value: 'month',
        name: 'monthTimeInterval'
    },
];

export const aggregationFunctionExample = ['COUNT', 'AVERAGE', 'SUM'];

// Show attribute filter or other dropdown
const onChangeDropdownShowStatus = (id, setState) => () => {
    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })
        return ArrayUse.replace(prev, index, { ...collection, show: !collection.show })
    });
};
// Show attribute filter for Multiple sidebar context or other dropdown
const onChangeDropdownShowStatusMultiple = (elemId, setState, id) => () => {
    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })
        const attributeFilterElement = ArrayUse.findCollection(collection._attributeFilter, { id: elemId })

        return ArrayUse.replace(prev, index, {
            ...collection,
            _attributeFilter: ArrayUse.replace(
                collection._attributeFilter,
                attributeFilterElement.index,
                { ...attributeFilterElement.data, show: !attributeFilterElement.data.show }
            )
        });
    })
};
// Delete attribute filter or other dropdown
const onClickRemoveDropdownItem = (id, setState) => () => {
    return setState(prev => ArrayUse.remove(prev, remove(prev, i => eq(i.id, id))));
};
// Delete attribute filter for Multiple sidebar context
const onClickRemoveDropdownItemMultiple = (elemId, setState, id) => () => {
    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, {id: id});

        return ArrayUse.replace(prev, index, {
            ...collection,
            _attributeFilter: ArrayUse.remove(collection._attributeFilter, remove(collection._attributeFilter, i => eq(i.id, elemId)))
        })
    })
}
// Create new attribute filter or other dropdown
const onClickCreateNewDropdown = (setState, example) => () => setState(prev => [...prev, {...example, id: prev.length + 1}]);

// Create new attribute filter for Multiple sidebar context
const onClickCreateNewDropdownMultiple = (setState, id, example) => () => {

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, {id: id})

        return ArrayUse.replace(prev, index, {
            ...collection,
            _attributeFilter: [
                ...collection._attributeFilter,
                { ...example, id: collection._attributeFilter.length + 1 }
            ]
        })
    })
};
// Func for attribute filters input for Multiple sidebar context
const onChangeDropdownInputMultiple = (itemId, setState, id) => event => {
    event.persist();

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id });
        const attributeFilterElement = ArrayUse.findCollection(collection._attributeFilter, { id: itemId } );

        return ArrayUse.replace(prev, index, {
            ...collection,
            _attributeFilter: ArrayUse.replace(
                collection._attributeFilter,
                attributeFilterElement.index, {...attributeFilterElement.data, [event.target.name]: {...attributeFilterElement.data[event.target.name], value: event.target.value}}
            )
        })
    })
};
const onChangeConditionDropdownMultiple = (itemId, setState, id) => {
    return (selectedValue, objectKey) => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id });
        const attributeFilterElement = ArrayUse.findCollection(collection._attributeFilter, { id: itemId } );

        return ArrayUse.replace(prev, index, {
            ...collection,
            _attributeFilter: ArrayUse.replace(
                collection._attributeFilter,
                attributeFilterElement.index, {
                    ...attributeFilterElement.data,
                    [objectKey]: {
                        ...attributeFilterElement.data[objectKey],
                        value: selectedValue
                    }
                }
            )
        })
    })
};
// Func for attribute filters input
const onChangeDropdownInput = (id, setState, fixedValue) => event => {
    event.persist();

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id });

        return ArrayUse.replace(prev, index, {
            ...collection,
            [event.target.name]: {
                ...collection[event.target.name],
                value: fixedValue || event.target.value
            }})
    })
};
// Func for attribute filters input
const onChangeConditionSegmentationFilterInput = (id, setState, key) => event => {
    event.persist();

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id });

        return ArrayUse.replace(prev, index, {
            ...collection,
            [key]: {
                ...collection[key],
                [event.target.name]: {
                    ...collection[key][event.target.name],
                    value: event.target.value
                }
            }
        })
    })
};
const onChangeConditionSegmentationFilterDropdown = (setState, id, key) => {
    return (selectedValue, objectKey) => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id });

        return ArrayUse.replace(prev, index, {
            ...collection,
            [key]: {
                ...collection[key],
                [objectKey]: {
                    ...collection[key][objectKey],
                    value: selectedValue
                }
            }
        })
    })
};
const onChangeInput = setState => event => {
    event.persist();

    return setState(prev => ({ ...prev, [event.target.name]: {
            ...prev[event.target.name],
            value: event.target.value,
        }
    }));
};

const onChangeDropdown = setState => {
    return (selectedValue, objectKey) => setState(prev => ({
        ...prev,
        [objectKey]: {
            ...prev[objectKey],
            value: selectedValue
        }
    }));
};

const onChangeInputMultiple = (setState, id) => event => {
    event.persist();

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, {id: id})

        return ArrayUse.replace(prev, index, {
            ...collection,
            _eventValues: {
                ...collection._eventValues,
                [event.target.name]: {
                    ...collection._eventValues[event.target.name],
                    value: event.target.value
                }
            }
        })
    })
};

const onChangeDropdownMultiple = (setState, id) => {
    return (selectedValue, objectKey) => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })

        return ArrayUse.replace(prev, index, {
            ...collection,
            _eventValues: {
                ...collection._eventValues,
                [objectKey]: {
                    ...collection._eventValues[objectKey],
                    value: selectedValue
                }
            }
        })
    })
};
const onChangeListedDropdown = (setState, id) => {
    return (selectedValue, objectKey) => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: id })

        return ArrayUse.replace(prev, index, {
            ...collection,
            [objectKey]: {
                ...collection[objectKey],
                value: selectedValue
            }})
        })
};

const onChangeNumericInputMultiple = (setState, id) => event => {
    event.persist();

    return setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, {id: id})

        return ArrayUse.replace(prev, index, {
            ...collection,
            _eventValues: {
                ...collection._eventValues,
                [event.target.name]: {
                    ...collection._eventValues[event.target.name],
                    value: NumberUse.transformToPositiveNumber(event.target.value)
                }
            }
        })
    })
};
const onClickCreateNewCondition = (setState, intervalTypeExtension) => () => {
    return setState(prev => ([
        ...prev,
        { ...conditionExample,
            id: prev.length + 1,
            _selectedTimeInterval: {
                ...conditionExample._selectedTimeInterval,
                _intervalType: intervalTypeExtension || conditionExample?._selectedTimeInterval?._intervalType,
                _attributeFilter: [
                        {...attributeFilterExample, id: 1}
                    ]
            }
        }
    ]))
};

// This method return required attribute for aggregationColumn input according to aggregationFunction current value
const aggregationColumnRequiredRuler = values => {
    const {
        aggregationColumn,
        aggregationFunction: { value: functionValue }
    } = values;

    return {
        ...values,
        aggregationColumn: {
            ...aggregationColumn,
            // if aggregationFunction current value is not equal to COUNT, return aggregationColumn required => true
            required: !eq(functionValue, 'COUNT')
        }
    }
};

const onChangeTime = setState => (event, value) => {
    const newTime = value.replace(/-/g, ':');
    const timer = newTime.substr(0, 5);

    return setState(timer);
};

const onChangeSelectedDay = setState => event => {
    const { name, checked } = event.target;

    return setState(prevState => map(prevState, item => eq(item.name.key, name) ? { ...item, isChecked: checked } : item))
};

const onChangeNumericInput = setState => event => {
    event.persist();

    return setState(prev => ({ ...prev, [event.target.name]: {
            ...prev[event.target.name],
            value: NumberUse.transformToPositiveNumber(event.target.value),
        }
    }));
};
const onChangePercentShareInput = setState => event => {
    event.persist();

    return setState(prev => ({ ...prev, [event.target.name]: {
            ...prev[event.target.name],
            value: NumberUse.maxCountLimitation(event.target.value, 100),
        }
    }));
};
const onSubmitHandler = submitCallback => event => {
    event.preventDefault();

    return submitCallback();
};

const onChangeCheckbox = setState => event => {
    const { id, checked } = event.target;

    return setState(prev => ({ ...prev, [id]: checked }));
};

const onClickChangeSidebarDifficultySettings = setState => {
    return () => setState(prev => {
        return { ...prev, simplified: !prev.simplified }
    });
};

const clearSidebarDifficultySettingsType = setState => {
    setState(prev => ({ ...prev, simplifiedType: null }));
};
const clearSimplifiedTimeInterval = setState => {
    setState(prev => ({
        countTimeIntervalNumber: {
            ...prev.countTimeIntervalNumber,
            value: '',
        },
        countTimeIntervalType: {
            ...prev.countTimeIntervalType,
            value: 'days',
        }
    }))
};

const onClickSelectSimplifiedButton = (setState, clickedButtonKeyToCompare = 'name') => {
    return event => {
        const { value } = event.target;

        setState(prev => {
            return map(prev, button => {
                return eq(value, button[clickedButtonKeyToCompare])
                    ? { ...button, selected: true }
                    : { ...button, selected: false }
            })
        })

        return Promise.resolve();
    }
};

const initSelectedSimplifiedSettings = (actionParams, silmplifiedSettins) => {
    return map(silmplifiedSettins, button => {
        return eq(actionParams.simplifiedType, button.name)
            ? { ...button, selected: true }
            : { ...button, selected: false }
    })
};
const initSelectedSegmentSimplifiedSettings = (segmentId, segmentsSilmplifiedSettins) => {
    return map(segmentsSilmplifiedSettins, button => {
        return eq(segmentId, button.id)
            ? { ...button, selected: true }
            : { ...button, selected: false }
    })
};

const turnOffSimplifiedSettingsButtons = setState => {
    setState(prev => map(prev, button => ({ ...button, selected: false })));
};

const initSimplifiedTimeIntervalSettings = (conditions, actionParams) => {
    const { countTimeIntervalNumber, countTimeIntervalType} = defaultSimplifiedTimeIntervalSettings;
    const { simplifiedType } = actionParams;

    // Check if action has condition. And we need add values to simplifiedTimeInterval on init state, only while action in simplified mode.
    if (conditions.length && simplifiedType) {
        const { _eventValues: { countDays, countHours } } = conditions[0];

        if (toNumber(countDays.value)) {
            return {
                countTimeIntervalNumber: {
                    ...countTimeIntervalNumber,
                    value: countDays.value,
                },
                countTimeIntervalType: {
                    ...countTimeIntervalType,
                    value: 'days',
                }
            }
        }
        if (toNumber(countHours.value)) {
            return {
                countTimeIntervalNumber: {
                    ...countTimeIntervalNumber,
                    value: countHours.value,
                },
                countTimeIntervalType: {
                    ...countTimeIntervalType,
                    value: 'hours',
                }
            }
        }
    }

    return defaultSimplifiedTimeIntervalSettings;
};
const extendedConditionTimeInterval = (isExtendedCondition, isAtScriptStartSelected) => {
    if (isExtendedCondition) {

        if (isAtScriptStartSelected) {
            return {
                _selectedTimeInterval: {
                    ...conditionExample._selectedTimeInterval,
                    _intervalType: map(intervalTypeExtension, button => {
                        return eq(button.id, 'atScriptStart')
                            ? { ...button, isChecked: true }
                            : { ...button, isChecked: false }
                    })
                }
            }
        }

        return {
            _selectedTimeInterval: {
                ...conditionExample._selectedTimeInterval,
                _intervalType: map(intervalTypeExtension, button => {
                    return eq(button.id, 'lastTime')
                        ? { ...button, isChecked: true }
                        : { ...button, isChecked: false }
                })
            }
        }
    }
    return {}
};
const updateSimplifiedSettingsCondition = (simplifiedSettings, simplifiedTimeInterval, simplifiedTimeIntervalType, isAtScriptStartSelected) => {
    const isExtendedCondition = !!simplifiedTimeIntervalType.length;
    // Now we need to update simplified settings condition by selected time interval (simplifiedTimeInterval) from state.
    if (simplifiedTimeInterval.countTimeIntervalNumber.value) {
        // Get type of time interval in simplified settings
        const isDaysSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'days');
        const isHoursSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'hours');

        return {
            ...simplifiedSettings.condition,
            ...extendedConditionTimeInterval(isExtendedCondition, isAtScriptStartSelected),
            _eventValues: {
                ...simplifiedSettings.condition._eventValues,
                countDays: {
                    ...simplifiedSettings.condition._eventValues.countDays,
                    value: isDaysSelected ? simplifiedTimeInterval.countTimeIntervalNumber.value : '0'
                },
                countHours: {
                    ...simplifiedSettings.condition._eventValues.countHours,
                    value: isHoursSelected ? simplifiedTimeInterval.countTimeIntervalNumber.value : '0'
                }
            }
        }
    }

    return {
        ...simplifiedSettings.condition,
        ...extendedConditionTimeInterval(isExtendedCondition, isAtScriptStartSelected),
    }
};
const initSimplifiedTimeIntervalType = (conditions, actionParams) => {
    const { simplifiedType } = actionParams;

    if (conditions.length && simplifiedType) {
        const { _selectedTimeInterval: { _intervalType } } = conditions[0];

        const isAtScriptStartSelected = some(_intervalType, { id: 'atScriptStart', isChecked: true });
        if (isAtScriptStartSelected) {
            return [
                {
                    id: 'atScriptStart',
                    name: 'AtScriptStartName',
                    selected: true,
                },
                {
                    id: 'lastTime',
                    name: 'LastTimeIntervalLabel',
                    selected: false,
                }
            ]
        }
    }
    return [
        {
            id: 'atScriptStart',
            name: 'AtScriptStartName',
            selected: false,
        },
        {
            id: 'lastTime',
            name: 'LastTimeIntervalLabel',
            selected: true,
        }
    ]
};
const getEventAttributeNames = (selectedEventValue, events, additionalArray) => {
    const eventAttributeNames = flatMap(map(find(events, { event_name: selectedEventValue })?.event_schema?.fields, field => {

        if (isObject(field.type) && eq(field.type.type, 'record')) {
            return map(field.type.fields, recordField => {
                return field.type.name + '.' + recordField.name
            })
        }
        return field.name
    }))
    if (eq(selectedEventValue, 'order_item_event')) {
        return [...additionalArray, ...eventAttributeNames];
    }

    return eventAttributeNames;
}
export const SideBarContext = createContext({
    jsonHeaderExample,
    aggregationFunctionExample,
    defaultCountTimeInterval,
    urlsExample,
    onClickRemoveDropdownItem,
    onClickRemoveDropdownItemMultiple,
    onClickCreateNewDropdown,
    onClickCreateNewDropdownMultiple,
    onClickCreateNewCondition,
    onChangeDropdownInputMultiple,
    onChangeDropdownInput,
    onChangeDropdownShowStatus,
    onChangeDropdownShowStatusMultiple,
    onChangeInputMultiple,
    onChangeDropdownMultiple,
    onChangeConditionSegmentationFilterInput,
    onChangeSelectedDay,
    onChangeNumericInput,
    onChangeNumericInputMultiple,
    onChangeInput,
    onChangeDropdown,
    onChangeCheckbox,
    onChangePercentShareInput,
    onChangeTime,
    onSubmitHandler,
    onClickChangeSidebarDifficultySettings,
    onClickSelectSimplifiedButton,
    aggregationColumnRequiredRuler,
    initSelectedSimplifiedSettings,
    clearSidebarDifficultySettingsType,
    clearSimplifiedTimeInterval,
    turnOffSimplifiedSettingsButtons,
    initSimplifiedTimeIntervalSettings,
    initSelectedSegmentSimplifiedSettings,
    updateSimplifiedSettingsCondition,
    extendedConditionTimeInterval,
    initSimplifiedTimeIntervalType,
    onChangeListedDropdown,
    onChangeConditionSegmentationFilterDropdown,
    onChangeConditionDropdownMultiple,
    getEventAttributeNames
});

export const ActionCodeEditorContext = createContext();

