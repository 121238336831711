import {
    SET_ALL_INTEGRATION_EVENTS,
    SET_FILTERED_ALL_INTEGRATION_EVENTS,
    PUT_CSV_TABLE_DATA,
    SET_CREATE_INTEGRATION_EVENT,
    SET_DELETE_INTEGRATION_EVENT,
    SET_UPDATE_EVENT_SCHEMA_FIELDS,
    SET_CSV_TABLE_COLUMN_COMPOUND,
    DELETE_CSV_TABLE_COLUMN_COMPOUND,
    RESET_DRAGGABLE_COMPOUNDS_KEYS,
    SET_CURRENT_EVENT,
    CLEAR_CURRENT_EVENT,
    SET_START_UPLOAD_CSV_TABLE,
    SET_FAILURE_START_UPLOAD_CSV_TABLE,
    SET_GET_UPLOAD_CSV_TABLE_HISTORY,
    SET_GET_CONFIG
} from 'store/types';

const draggableCompoundsKeys = [
    { value: 'Client ID', type: 'CLIENT_EVENTS' },
    { value: 'User ID', type: 'CLIENT_EVENTS' },
    { value: 'Email', type: 'CLIENT_EVENTS' },
    { value: 'Phone', type: 'CLIENT_EVENTS' },
    { value: 'Loyalty card number', type: 'CLIENT_EVENTS' },
    { value: 'User name', type: 'CLIENT_EVENTS' },
    { value: 'Product ID', type: 'PRODUCT_EVENTS' }
];

const initialState = {
    config: null,
    csvTableData: null,
    csvTableFile: null,
    csvTableUploadResult: null,
    draggableCompoundsKeys,
    csvTableColumnCompounds: [],
    allEvents: {
        base: [],
        searched: []
    },
    currentEvent: {
        event_schema: {}
    }
};

export default function integrations(store = initialState, { type, payload }) {
    switch (type) {
        case SET_GET_CONFIG: {
            return {
                ...store, config: payload.data
            }
        }
        case SET_ALL_INTEGRATION_EVENTS: {
            return {
                ...store,
                allEvents: { base: payload, searched: payload }
            }
        }
        case SET_FILTERED_ALL_INTEGRATION_EVENTS: {
            return {
                ...store,
                allEvents: { ...store.allEvents, searched: payload }
            }
        }
        case SET_CREATE_INTEGRATION_EVENT: {
            return {
                ...store,
                allEvents: { ...store.allEvents, base: payload }
            }
        }
        case SET_DELETE_INTEGRATION_EVENT: {
            return {
                ...store,
                allEvents: { ...store.allEvents, base: payload }
            }
        }
        case SET_UPDATE_EVENT_SCHEMA_FIELDS: {
            return {
                ...store,
                allEvents: { ...store.allEvents, base: payload.base },
                currentEvent: payload.currentEvent
            }
        }
        case SET_CURRENT_EVENT: {
            return {
                ...store,
                currentEvent: payload
            }
        }
        case CLEAR_CURRENT_EVENT: {
            return {
                ...store,
                currentEvent: {
                    event_schema: {}
                }
            }
        }
        case PUT_CSV_TABLE_DATA: {
            return {
                ...store,
                csvTableData: payload.result,
                csvTableFile: { name: payload.fileName, file: payload.file }
            }
        }
        case SET_CSV_TABLE_COLUMN_COMPOUND: {
            return {
                ...store,
                csvTableColumnCompounds: [...store.csvTableColumnCompounds, payload.compound],
                draggableCompoundsKeys: payload.filteredDraggableCompoundsKeys
            }
        }
        case DELETE_CSV_TABLE_COLUMN_COMPOUND: {
            return {
                ...store,
                csvTableColumnCompounds: payload.cleanedCompounds,
                draggableCompoundsKeys: payload.increasedDraggableCompoundsKeys
            }
        }
        case RESET_DRAGGABLE_COMPOUNDS_KEYS: {
            return {
                ...store,
                draggableCompoundsKeys,
                csvTableColumnCompounds: []
            }
        }
        case SET_START_UPLOAD_CSV_TABLE: {
            return {
                ...store,
                csvTableUploadResult: payload
            }
        }
        case SET_FAILURE_START_UPLOAD_CSV_TABLE: {
            return {
                ...store,
                csvTableUploadResult: payload
            }
        }
        case SET_GET_UPLOAD_CSV_TABLE_HISTORY: {
            return {
                ...store,
                csvTableUploadResult: payload
            }
        }
        default:
            return store
    }
}
