import { map, join, eq, toString, isPlainObject, isString } from 'lodash';
import { defaultConditionalSplitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultConditionalSplitSettings';
import { actionConditionDecompiler } from 'pages/Communications/ScenarioSetupPage/actionDecompilers/utils/actionConditionDecompiler';
import { productAttributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ProductFilter/settings/defaultProductFilterSettings';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';
import { StringUse } from 'lib/StringUse';

const {
    splitType,
    values,
    values: { actionName, subActionName },
    condition,
    condition: [ conditionExampleExtension ]
} = defaultConditionalSplitSettings();

const { _selectedTimeInterval: { _intervalType } } = conditionExampleExtension;

const productFilterTransformer = filter => map(filter, (item, index) => {

    const {
        product_id_col_name,
        products_table_col_name,
        trigger_event_col_name,
        operator
    } = item;

    const {
        filterName,
        columnNameOfProductFromEvent,
        columnNameFromEvent,
        columnComparisonOperator,
        columnNameFromCatalog
    } = productAttributeFilterExample;

    return {
        id: index + 1,
        show: true,
        filterName,
        columnNameOfProductFromEvent: {
            ...columnNameOfProductFromEvent,
            value: join(product_id_col_name, '.')
        },
        columnNameFromEvent: {
            ...columnNameFromEvent,
            value: join(trigger_event_col_name, '.')
        },
        columnComparisonOperator: {
            ...columnComparisonOperator,
            value: toString(operator)
        },
        columnNameFromCatalog: {
            ...columnNameFromCatalog,
            value: toString(products_table_col_name)
        }
    }
})

const eventFilterTransformer = filter => {

    if (!filter) return

    const [ item ] = filter;
    const {
        event_name,
        event_subname,
        conditions
    } = item;

    const attributeFilterConverter = map(conditions, (item, index) => {
        const { column_name, operator, values_to_include } = item;
        const { show, filterName, eventAttributeName, comparisonOperator, comparisonValue } = attributeFilterExample;

        return {
            id: index + 1,
            show,
            filterName,
            eventAttributeName: {
                ...eventAttributeName,
                value: join(column_name, '.')
            },
            comparisonOperator: {
                ...comparisonOperator,
                value: operator
            },
            comparisonValue: {
                ...comparisonValue,
                value: values_to_include
            }
        }
    })

    return {
        actionName: {
            ...actionName,
            value: StringUse.falseyReturnEmptyString(event_name)
        },
        subActionName: {
            ...subActionName,
            value: StringUse.falseyReturnEmptyString(event_subname)
        },
        attributeFilterConverter,
    }
}

export const conditionalSplitDecompiler = actionOptions => {

    const {
        event_filter,
        customer_segment,
        product_filter,
        segment_id, // TODO: segment_id need to remove after some time. Because when we start use segment_filter, we need to update all old ConditionalsSplit's which use segment_id
        segment_filter,
    } = actionOptions;

    // Choose Conditional Split sidebar type (event, client, product)
    const splitTypeSelected = map(splitType, item => {

        if (eq(item.id, 'event') && event_filter) return { ...item, active: true };

        if (eq(item.id, 'client') && customer_segment) return { ...item, active: true };
        // TODO: Remove "if(... && segment_id)" after some time. For future splitType will be determined by segment_filter, not segment_id
        if (eq(item.id, 'client') && segment_id) return { ...item, active: true };
        if (eq(item.id, 'client') && isPlainObject(segment_filter)) return { ...item, active: true };

        if (eq(item.id, 'product') && product_filter) return { ...item, active: true };

        return { ...item, active: false };
    })

    // For Conditional Split 'Event' type
    if (event_filter) {
        const { actionName, subActionName, attributeFilterConverter } = eventFilterTransformer(event_filter);

        return {
            splitType: splitTypeSelected,
            values: {
                actionName,
                subActionName
            },
            attributeFilter: [ ...attributeFilterConverter ],
            condition,
            productAttributeFilter: [
                { ...productAttributeFilterExample, id: 1 }
            ],
            segmentId: null
        }
    }

    // For Conditional Split 'Client' type
    if (customer_segment || isPlainObject(segment_filter) || segment_id) { // TODO: Remove (... || segment_id). For the same reason
        const clientOptions = actionConditionDecompiler(customer_segment, _intervalType);

        return {
            splitType: splitTypeSelected,
            values,
            attributeFilter: [],
            condition: clientOptions,
            productAttributeFilter: [
                { ...productAttributeFilterExample, id: 1 }
            ],
            segmentId: isPlainObject(segment_filter)
                ? segment_filter.segment_id
                : isString(segment_id) // TODO: Change isString(segment_id) for null after some time, for the same reason
                    ? segment_id
                    : null
        }
    }

    // For Conditional Split 'Product' type
    if (product_filter) {
        const productOptions = productFilterTransformer(product_filter);

        return {
            splitType: splitTypeSelected,
            values,
            attributeFilter: [],
            condition,
            productAttributeFilter: productOptions.length
                ? [ ...productOptions ]
                : [{ ...productAttributeFilterExample, id: 1 }],
            segmentId: null
        }
    }

}
