import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'assets/global/main.css';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import calendars from 'store/reducers/calendars.reducer';
import auth from 'store/reducers/auth.reducer';
import api from 'store/reducers/api.reducer';
import communications from 'store/reducers/communications.reducer';
import consentCategories from 'store/reducers/consentCategories.reducer';
import scenarios from './store/reducers/scenarios.reducer';
import scenariosActions from 'store/reducers/scenariosActions.reducer';
import templates from 'store/reducers/templates.reducer';
import images from 'store/reducers/images.reducer';
import recommendations from 'store/reducers/recommendations.reducer';
import segments from 'store/reducers/segments.reducer';
import analytics from 'store/reducers/analytics.reducer';
import frequencyPolicies from 'store/reducers/frequencyPolicies.reducer';
import { combineCheckpoints } from 'store/middlewares/combineCheckpoints.middleware';
import { apiMiddleware } from 'store/middlewares/api.middleware';
import { allApiMiddleware } from 'store/middlewares/apiAll.middleware';
import 'translations/i18n';
import integrations from 'store/reducers/integrations.reducer';

const rootReducer = combineReducers({
    api,
    auth,
    communications,
    consentCategories,
    scenarios,
    scenariosActions,
    calendars,
    templates,
    images,
    recommendations,
    segments,
    analytics,
    integrations,
    frequencyPolicies
});

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(
        thunk,
        apiMiddleware,
        combineCheckpoints,
        allApiMiddleware,
    )
));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <App/>
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
