import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { AsideDropdownButtonItemButton } from 'common/AsideDropdownButton/AsideDropdownButtonItemButton';
import { DeleteButton } from 'common/_Buttons/_DeleteButton/DeleteButton';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { includes, isEmpty, has, eq } from 'lodash';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { ComparisonOperatorSelectOptions } from 'components/SelectOptions/ComparisonOperatorSelectOptions';
import { AsideDropdownButtonSeparateLine } from 'common/AsideDropdownButton/AsideDropdownButtonSeparateLine';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { StringUse } from 'lib/StringUse';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';
import { useSelector } from 'react-redux';

const parsedErrors = (errors, id) => {
    const isProductAttributeFilterHasErrors = !isEmpty(errors) && has(errors, 'productAttributeFilter');

    if (isProductAttributeFilterHasErrors) {
        const {
            productAttributeFilter: {
                columnNameOfProductFromEvent,
                columnNameFromEvent,
                columnComparisonOperator,
                columnNameFromCatalog
            }
        } = errors;

        return {
            columnNameOfProductFromEvent: {
                hasError: includes(columnNameOfProductFromEvent.id, id),
                message: StringUse.falseyReturnEmptyString(columnNameOfProductFromEvent.error)
            },
            columnNameFromEvent: {
                hasError: includes(columnNameFromEvent.id, id),
                message: StringUse.falseyReturnEmptyString(columnNameFromEvent.error)
            },
            columnComparisonOperator: {
                hasError: includes(columnComparisonOperator.id, id),
                message: StringUse.falseyReturnEmptyString(columnComparisonOperator.error)
            },
            columnNameFromCatalog: {
                hasError: includes(columnNameFromCatalog.id, id),
                message: StringUse.falseyReturnEmptyString(columnNameFromCatalog.errors[id])
            }
        }
    }

    return {
        columnNameOfProductFromEvent: {
            hasError: false
        },
        columnNameFromEvent: {
            hasError: false
        },
        columnComparisonOperator: {
            hasError: false
        },
        columnNameFromCatalog: {
            hasError: false
        }
    }
};

export const ProductFilterMapped = ({ item, index, required }) => {
    const { t: translator } = useTranslation();

    const dropdownFilters = useSelector(store => store.segments.dropdownFilters);

    const {
        setProductAttributeFilter,
        onChangeDropdownShowStatus,
        onClickRemoveDropdownItem,
        onChangeDropdownInput,
        errors,
        onChangeListedDropdown
    } = useContext(SideBarContext);

    const { columnNameOfProductFromEvent, columnNameFromEvent, columnComparisonOperator, columnNameFromCatalog } = parsedErrors(errors, item.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeDropdownInput(item.id, setProductAttributeFilter), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeListedDropdown(setProductAttributeFilter, item.id), []);

    const isRequiredAtLeastOneCondition = !(required && (eq(index, 0)));

    return (
        <>
            <li key={item.id}>
                <div className={styles.filterItemContainer}>
                    <AsideDropdownButtonItemButton
                        name={item.columnNameOfProductFromEvent.value || translator(`actionSidebars:${item.filterName}`) + ' ' + (index + 1)}
                        onClick={onChangeDropdownShowStatus(item.id, setProductAttributeFilter)}
                        active={item.show}
                    />
                    {isRequiredAtLeastOneCondition && <DeleteButton onClick={onClickRemoveDropdownItem(item.id, setProductAttributeFilter)}/>}
                </div>

                {item.show && <div className={styles.filterContainer}>
                    <Label htmlFor='columnNameOfProductFromEvent' text={translator(`actionSidebars:${item.columnNameOfProductFromEvent.name}`)}/>
                    <InputField
                        type='text'
                        id='columnNameOfProductFromEvent'
                        name='columnNameOfProductFromEvent'
                        value={item.columnNameOfProductFromEvent.value}
                        required={item.columnNameOfProductFromEvent.required}
                        error={columnNameOfProductFromEvent.hasError}
                        onChange={onChangeCached}
                    />
                    <ErrorMessageField
                        active={columnNameOfProductFromEvent.hasError}
                        message={columnNameOfProductFromEvent.message}
                    />

                    <Label htmlFor="columnNameFromEvent" text={translator(`actionSidebars:${item.columnNameFromEvent.name}`)}/>
                    <InputField
                        type='text'
                        id='columnNameFromEvent'
                        name='columnNameFromEvent'
                        value={item.columnNameFromEvent.value}
                        required={item.columnNameFromEvent.required}
                        error={columnNameFromEvent.hasError}
                        onChange={onChangeCached}
                    />
                    <ErrorMessageField
                        active={columnNameFromEvent.hasError}
                        message={columnNameFromEvent.message}
                    />

                    <ComparisonOperatorSelectOptions
                        translator={translator}
                        item={item}
                        onChangeHandler={onChangeDropdownInput(item.id, setProductAttributeFilter)}
                        comparisonOperatorType='productAttribute'
                        error={columnComparisonOperator.hasError}
                    />
                    <ErrorMessageField
                        active={columnComparisonOperator.hasError}
                        message={columnComparisonOperator.message}
                    />

                    <CustomSelectDropdown
                        dropdownName='columnNameFromCatalog'
                        dropdownLabel={translator(`actionSidebars:${item.columnNameFromCatalog.name}`)}
                        defaultDropdownValue={item.columnNameFromCatalog.value}
                        dropdownOptions={
                            has(dropdownFilters, 'product_attribute')
                                ? dropdownFilters['product_attribute']
                                : []
                        }
                        onChangeDropdown={onChangeDropdownCached}
                        isErrorExist={columnNameFromCatalog.hasError}
                        isRequired={item.columnNameFromCatalog.required}
                    />

                    <AsideDropdownButtonSeparateLine
                        onClick={onChangeDropdownShowStatus(item.id, setProductAttributeFilter)}
                    />
                </div>}
            </li>
        </>
    )
};
ProductFilterMapped.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired
}
