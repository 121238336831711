export const defaultABTestSettings = () => ({
    controlGroup: {
        value: '30',
        rangeSliderValue: 30,
        required: true,
    },
    testGroup: {
        rangeSliderValue: 10,
        aGroupValue: '10',
        bGroupValue: '60',
        required: true,
    }
})
