import axios from 'axios';
import { eq, find } from 'lodash';
import { api } from 'store/actions/api.action';
import { apiStart, apiEnd } from 'store/actions/api.action';
import { logout } from 'store/actions/auth.action';
import { DateUse } from 'lib/DateUse';
import { CLEAR_API_REQUEST_FAIL_WITH_STATUS } from 'store/types';

export const apiMiddleware = ({ getState, dispatch }) => next => action => {
    next(action);

    if (!action.payload) return;

    const store = getState();

    if (store.auth.isAuthenticated && DateUse.isDateExpired(store.auth.user?.tokenExpireDate)) {
        console.log('Need logout or re-login by refresh token');
        dispatch(logout());
        return
    }

    const {
        url,
        method,
        data,
        extraOptions,
        accessToken,
        onSuccess,
        onAfterSuccess,
        onFailure,
        onFailureWithStatus,
        onFinally,
        label,
        lang,
        headersOverride
    } = action.payload;

    if (action.type !== api(label)) return;

    // Axios default instances
    axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_SERVER_URL_ALT || '';
    axios.defaults.headers.common["Content-Type"] = 'application/json';
    axios.defaults.headers.common["Authorization"] = `Bearer ${store.auth.user?.token || accessToken}`;
    axios.defaults.headers.common["Accept-Language"] = lang;

    if (eq(action.meta, 'axiosAll')) return;

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    if (label) dispatch(apiStart(label));

    const requestConfig = {
        url,
        method,
        [dataOrParams]: data
    };

    if (headersOverride) requestConfig.headers = headersOverride;

    axios
        .request(requestConfig)
        .then(response => {
            if (response.data)
                dispatch(onSuccess(response.data, extraOptions));
            else
                dispatch(onSuccess(extraOptions));

            return response;
        })
        .then(response => {
            if (response.data) {
                onAfterSuccess(response.data);
            }
            else {
                onAfterSuccess();
            }
        })
        .catch(error => {
            onFailure(error.response);

            if (error.response) {
                const detectedFailureWithStatus = find(onFailureWithStatus, { statusCode: error.response.status }) ?? null;

                if (detectedFailureWithStatus) {
                    new Promise(resolve => {
                        detectedFailureWithStatus.callback(dispatch, window.location.pathname, error.response.status);

                        resolve(dispatch)
                    }).then(dispatch => dispatch({ type: CLEAR_API_REQUEST_FAIL_WITH_STATUS }))
                }
            }
        })
        .finally(() => {
            if (label) {
                dispatch(apiEnd(label));
                onFinally();
            }
        })
};
