import { useContext, useEffect } from 'react';
import { eq, find } from 'lodash';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';

export const useSimplifiedCondition = props => {

    const {
        simplifiedSettingsButtons,
        isActionInSimplifiedMod,
        simplifiedTimeInterval,
        setSimplifiedTimeInterval,
        setCondition,
        setActionParams,
        setSegmentsButtons,
        simplifiedTimeIntervalType = [],
        isAtScriptStartSelected = false
    } = props;

    const {
        turnOffSimplifiedSettingsButtons,
        clearSimplifiedTimeInterval,
        extendedConditionTimeInterval
    } = useContext(SideBarContext);

    const isExtendedCondition = !!simplifiedTimeIntervalType.length;

    useEffect(() => {
        if (isActionInSimplifiedMod) {
            const simplifiedSettings = find(simplifiedSettingsButtons, 'selected');

            // Update condition in advanced settings
            // if simplifiedTimeInterval in simplified setting is exists, we need to update advanced condition in advanced settings
            if (simplifiedTimeInterval.countTimeIntervalNumber.value && !isAtScriptStartSelected) {
                // Get type of time interval in simplified settings
                const isDaysSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'days');
                const isHoursSelected = eq(simplifiedTimeInterval.countTimeIntervalType.value, 'hours');

                setCondition([{
                    ...simplifiedSettings.condition,
                    ...extendedConditionTimeInterval(isExtendedCondition, isAtScriptStartSelected),
                    _eventValues: {
                        ...simplifiedSettings.condition._eventValues,
                        countDays: {
                            ...simplifiedSettings.condition._eventValues.countDays,
                            value: isDaysSelected ? simplifiedTimeInterval.countTimeIntervalNumber.value : '0'
                        },
                        countHours: {
                            ...simplifiedSettings.condition._eventValues.countHours,
                            value: isHoursSelected ? simplifiedTimeInterval.countTimeIntervalNumber.value : '0'
                        }
                    }
                }])
            }
            else {
                // if simplifiedTimeInterval in simplified setting is empty, just update advanced condition by simplified button settings (condition)
                setCondition([{
                    ...simplifiedSettings.condition,
                    ...extendedConditionTimeInterval(isExtendedCondition, isAtScriptStartSelected)
                }])
            }

            // Update action params by selected simplified button
            setActionParams(prev => ({ ...prev, simplifiedType: simplifiedSettings.name }));

            // If simplified conditios selected we need to turn off all segmengs buttons in action
            turnOffSimplifiedSettingsButtons(setSegmentsButtons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionInSimplifiedMod, simplifiedSettingsButtons, simplifiedTimeInterval])


    useEffect(() => {
        // If condition is extended and used choose 'at script start' option in simplified condition settings.
        // We need to clear SimplifiedTimeInterval state
        if (isAtScriptStartSelected) {
            clearSimplifiedTimeInterval(setSimplifiedTimeInterval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAtScriptStartSelected])
}
