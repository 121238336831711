import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/AsideDropdownButton/AsideDropdownButton.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const AsideDropdownButtonItemButton = ({ name, onClick, active, alternativeColor, className }) => (
    <p onClick={onClick}
       className={cx(styles.dropdownItemButton, className, { 'dropdownItemButtonActive': active, 'alternativeColor': alternativeColor })}
    >
        {name}
    </p>
)
AsideDropdownButtonItemButton.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    alternativeColor: PropTypes.bool,
    className: PropTypes.string
}
AsideDropdownButtonItemButton.defaultProps = {
    name: 'Default dropdown',
    active: false,
    alternativeColor: false,
    className: null
}
