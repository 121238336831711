import { join, map } from 'lodash';
import { defaultTriggerEventSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultTriggerEventSettings';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';
import { StringUse } from 'lib/StringUse';

const {
    values: { actionName, actionSubName },
    attributeFilter
} = defaultTriggerEventSettings();

export const triggerEventDecompiler = actionOptions => {
    const {
        event_name = actionName?.value,
        event_subname = actionSubName?.value,
        conditions = attributeFilter
    } = actionOptions;

    const attributeFilterConverter = map(conditions, (item, index) => {
        const { column_name, operator, values_to_include } = item;
        const { show, filterName, eventAttributeName, comparisonOperator, comparisonValue } = attributeFilterExample;

        return {
            id: index + 1,
            show,
            filterName,
            eventAttributeName: {
                ...eventAttributeName,
                value: join(column_name, '.')
            },
            comparisonOperator: {
                ...comparisonOperator,
                value: operator
            },
            comparisonValue: {
                ...comparisonValue,
                value: values_to_include
            }
        }
    })

    return {
        values: {
            actionName: {
                ...actionName,
                value: StringUse.falseyReturnEmptyString(event_name),
            },
            actionSubName: {
                ...actionSubName,
                value: StringUse.falseyReturnEmptyString(event_subname),
            },
        },
        attributeFilter: [
            ...attributeFilterConverter,
            ...attributeFilter
        ]
    }
}
