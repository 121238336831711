import { snakeCase, toNumber } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';

export const abTestSplitCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        actionParams,
        title,
        position,
        settings: { controlGroup, testGroup: { aGroupValue, bGroupValue } }
    } = action;

    const [ aGroupArrows, controlGroupArrows, bGroupArrows ] = nextActionIdsCompiler(filteredArrows, actionType);

    return  {
        action_id: id,
        action_type: snakeCase(title), // Action type from "title" used only for ABTestSplit!
        action_params: actionParams,
        coordinates: position,
        action_options: {
            group_probabilities: [
                toNumber(controlGroup.value),
                toNumber(aGroupValue),
                toNumber(bGroupValue),
            ]
        },
        next_actions_ids: [
            controlGroupArrows,
            aGroupArrows,
            bGroupArrows
        ]
    }
}
