import React from 'react';
import styles from 'pages/Auth/Auth.module.css';
import { Route, Routes } from 'react-router-dom';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

export const Auth = () => {
    return (
        <main>
            <div className={styles.authContainer}>
                <Routes>
                    <Route path='/' element={ <SignIn/> }/>
                    <Route path='/sign-up' element={ <SignUp/> }/>
                    <Route path="*" element={ <SignIn/> }/>
                </Routes>
            </div>
        </main>
    )
};
