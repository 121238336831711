import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/SearchInput/SearchInput.module.css';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const changeFocus = setState => {
    return () => {
        if (setState) setState(prevState => !prevState);
    }
};

export const SearchInput = ({ onChangeCallback, setFocus, placeholder, className }) => {
    const { t: translator } = useTranslation();

    return (
        <div className={cx(styles.searchFieldContainer, className)}>
            <div className={styles.searchIcon}/>
            <input
                type='text'
                placeholder={translator(`searchInput:${placeholder}`)}
                onFocus={changeFocus(setFocus)}
                onBlur={changeFocus(setFocus)}
                onChange={onChangeCallback}
            />
        </div>
    )
};
SearchInput.propTypes = {
    onChangeCallback: PropTypes.func.isRequired,
    setFocus: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string
}
SearchInput.defaultProps = {
    placeholder: 'SearchInputPlaceholder',
    setFocus: null,
    className: null
};
