import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';
import { jsonHeadersControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/jsonHeadersControl';
import { isEmpty } from 'lodash';

const concatedValuesRules = {
    maxNumberOfRequests: 'required|integer|min:1',
    maxFrequencyNumber: 'required|integer|min:1',
    url: 'required|url'
};

const isJsonHeadersResultHasErros = errors => {
    if (isEmpty(errors)) return false;

    return errors;
};

export const customWebHookControl = action => {

    const {
        values: {
            maxNumberOfRequests,
            maxFrequencyNumber,
            url
        },
        jsonHeaders
    } = action.settings;

    const concatedValues = {
        maxNumberOfRequests: maxNumberOfRequests.value,
        maxFrequencyNumber: maxFrequencyNumber.value,
        url: url.value
    };

    const validation = Validation.checkSome(concatedValues, concatedValuesRules, {
        'required.maxFrequencyNumber': 'InputValidationRequiredMessage',
        'required.maxNumberOfRequests': 'InputValidationRequiredMessage',
        'required.url': 'InputValidationRequiredMessage',
        'min.maxFrequencyNumber': 'PositiveNumberInputValidationMessage',
        'min.maxNumberOfRequests': 'PositiveNumberInputValidationMessage',
        'url.url': 'UrlAddressInputValidationMessage'
    }, ['maxFrequencyNumber', 'maxNumberOfRequests', 'url']);

    const jsonHeadersResult = jsonHeadersControl(jsonHeaders);

    const isActionHasErrors = validation.fails || !isEmpty(jsonHeadersResult);

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                validation.firstErrors.maxFrequencyNumber,
                validation.firstErrors.maxNumberOfRequests,
                validation.firstErrors.url,
                isJsonHeadersResultHasErros(jsonHeadersResult)
            ], ['maxFrequencyNumber', 'maxNumberOfRequests', 'url', 'jsonHeaders'])
        }
    }
};
