import React, { useContext } from 'react';
import styles from 'components/ScenarioSetup/ActionIcons/ActionIcons.module.css';
import { CrossButtonIcon } from 'assets/icons/CrossButtonIcon';
import { EditIcon } from 'assets/icons/EditIcon';
import { TrashIcon } from 'assets/icons/TrashIcon';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ActionIconsContext } from 'components/ScenarioSetup/ActionIcons/context/ActionIconsContext';

const cx = classNames.bind(styles);

export const ActionTooltipWarnings = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();

    const {
        onClickSetEditAction,
        onClickDeleteAction,
        onClickCloseActionWarningTooltip,
        isShowWarningActionMessage,
        id,
        actionType,
        alternativeEditHandler
    } = useContext(ActionIconsContext);

    return (
        isShowWarningActionMessage &&
        <div className={cx(styles.actionInfoTooltip, styles.actionWarningTooltip)}>
            <p className={styles.actionWarningTooltipMessage}>
                <span className={styles.actionWarningTooltipMessageTitle}>{translator('default:ActionErrorTitle')}</span>
                {translator('default:ActionSaveErrorMessage')}
            </p>
            <button className={styles.closeButton} onClick={onClickCloseActionWarningTooltip(dispatch, id)}>
                <CrossButtonIcon color="currentColor"/>
            </button>

            <button onClick={onClickSetEditAction(dispatch, alternativeEditHandler, actionType, id)}>
                <EditIcon color="currentColor"/>
                <span>{translator('default:EditButtonTitle')}</span>
            </button>
            <button onClick={onClickDeleteAction(dispatch, id)}>
                <TrashIcon color="currentColor"/>
                <span>{translator('default:DeleteButtonTitle')}</span>
            </button>
        </div>
    )
}
