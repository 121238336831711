import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';

export const TemplatePreviewDefault = ({ onClickCallback }) => (
    <div onClick={onClickCallback} className={styles.templatePreviewDefault}/>
);

TemplatePreviewDefault.propTypes = {
    onClickCallback: PropTypes.func.isRequired
};
