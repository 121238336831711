import { map, isEmpty, compact } from 'lodash';
import { waitControl } from 'pages/Communications/ScenarioSetupPage/actionControls/waitControl';
import { funnelControl } from 'pages/Communications/ScenarioSetupPage/actionControls/funnelControl';
import { triggerEventControl } from 'pages/Communications/ScenarioSetupPage/actionControls/triggerEventControl';
import { tailoredWaitControl } from 'pages/Communications/ScenarioSetupPage/actionControls/tailoredWaitControl';
import { ABTestSplitControl } from 'pages/Communications/ScenarioSetupPage/actionControls/aBTestSplitControl';
import { sqlTriggerControl } from 'pages/Communications/ScenarioSetupPage/actionControls/sqlTriggerControl';
import { customWebHookControl } from 'pages/Communications/ScenarioSetupPage/actionControls/customWebHookControl';
import { sendContentControl } from 'pages/Communications/ScenarioSetupPage/actionControls/sendContentControl';
import { websiteBuilderControl } from 'pages/Communications/ScenarioSetupPage/actionControls/websiteBuilderControl';
import { repeatTriggerControl } from 'pages/Communications/ScenarioSetupPage/actionControls/repeatTriggerControl';
import { scheduleTriggerControl } from 'pages/Communications/ScenarioSetupPage/actionControls/scheduleTriggerControl';
import { conditionalSplitControl } from 'pages/Communications/ScenarioSetupPage/actionControls/conditionalSplitControl';
import { editSegmentControl } from 'pages/Communications/ScenarioSetupPage/actionControls/editSegmentControl';

export const actionChainControl = actionsList => {
    console.log('[Client Controler Props]: actionsList:', actionsList);

    // Action controls list
    const actionTypeControls = {
        TriggerEvent: triggerEventControl,
        RepeatTrigger: repeatTriggerControl,
        ScheduleTrigger: scheduleTriggerControl,
        SqlTrigger: sqlTriggerControl,
        Wait: waitControl,
        TailoredWait: tailoredWaitControl,
        ConditionalSplit: conditionalSplitControl,
        ABTestSplit: ABTestSplitControl,
        Funnel: funnelControl,
        SendEmail: sendContentControl,
        SendSms: sendContentControl,
        SendViber: sendContentControl,
        WebsiteBuilder: websiteBuilderControl,
        CustomWebHook: customWebHookControl,
        SendMobPush: sendContentControl,
        SendWebPush: sendContentControl,
        EditSegment: editSegmentControl
    };

    const actionsControlReport = map(actionsList, action => {
        return actionTypeControls[action.actionType](action)
    });

    const isActionsControlReportHasErrors = !!compact(map(actionsControlReport, action => !isEmpty(action.errors) ? action.errors : null)).length;

    map(actionsControlReport, action => console.log(`%c${action.actionType}:`, 'color: #bada55;', action.errors));

    console.log('%cActionsControlResult:', 'color: #4184ff;', actionsControlReport);

    return {
        data: actionsControlReport,
        hasErrors: isActionsControlReportHasErrors
    };
};
