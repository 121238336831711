import React, { useContext } from 'react';
import { TabButtons } from 'common/Buttons/TabButtons/TabButtons';
import { map } from 'lodash';
import { EventSection } from 'components/ScenarioSetup/ActionSidebars/ConditionalSplitSidebar/EventSection';
import { ClientSection } from 'components/ScenarioSetup/ActionSidebars/ConditionalSplitSidebar/ClientSection';
import { ProductSection } from 'components/ScenarioSetup/ActionSidebars/ConditionalSplitSidebar/ProductSection';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { useDropdownFilters } from 'hooks/useDropdownFilters';

export const ConditionalSplitAdvanced = () => {
    const { t: translator } = useTranslation();

    const { isEventSection, isClientSection, isProductSection, splitType, setSplitType } = useContext(SideBarContext);

    const splitTypeButtons = map(splitType, splitType => ({ ...splitType, name: translator(`actionSidebars:${splitType.name}`) }) );

    useDropdownFilters(['ATTRIBUTE', 'PRODUCT_ATTRIBUTE', 'METRIC']);

    return (
        <>
            <div>
                <TabButtons
                    buttons={splitTypeButtons}
                    setState={setSplitType}
                />
            </div>

            {isEventSection && <EventSection/>}
            {isClientSection && <ClientSection/>}
            {isProductSection && <ProductSection/>}
        </>
    )
};
