import { map, split, last, toNumber } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

export const websiteBuilderCompiler = (action, filteredArrows) => {
    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            values: {
                countTimeIntervalNumber,
                countTimeIntervalType,
                communicationsMaxCount,
                impressionsNumber,
                consentCategory
            },
            urls,
            displayDate,
            templateUniqueId
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const urlsArrayCompiler = urls => map(urls, ({ url }) => last(split(url.value, '//')));

    const localTime = DateUse.getCurrentLocalDateTime('HH:mm:ss');
    const utcDateTime = displayDate.value
        ? DateUse.convertLocalToUTC(`${displayDate.value} ${localTime}`, 'DD/MM/YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
        : null

    return {
        action_id: id,
        action_type: 'custom_website',
        action_params: actionParams,
        coordinates: position,
        action_options: {
            max_frequency: {
                period_type: StringUse.recast(countTimeIntervalType.value, { accepted: 'string', rejected: null }),
                period_number: StringUse.recast(countTimeIntervalNumber.value, { accepted: 'number', rejected: null }),
                n_communications: StringUse.recast(communicationsMaxCount.value, { accepted: 'number', rejected: null })
            },
            url_rules_list: [
                ...urlsArrayCompiler(urls)
            ],
            display_counter: impressionsNumber?.value ? toNumber(impressionsNumber?.value) : null,
            max_display_date: utcDateTime,
            template_unique_id: StringUse.recast(templateUniqueId, { accepted: 'string', rejected: null }),
            consent_category_id: StringUse.recast(consentCategory.value, { accepted: 'string', rejected: null }),
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ]
    }
}
