import {
    SET_FREQUENCY_POLICIES_LIST,
    TOGGLE_SIDEBAR_POLICIES,
    ADD_NEW_FREQUENCY_POLICY
} from 'store/types';

const initialState = {
    list: [],
    sidebar: {
        open: false,
        scenarios: [],
        type: ''
    },
};

export default function frequencyPolicies(store = initialState, { type, payload }) {
    switch (type) {
        case SET_FREQUENCY_POLICIES_LIST: {
            return {
                ...store,
                list: payload
            }
        }
        case TOGGLE_SIDEBAR_POLICIES: {
            return {
                ...store,
                sidebar: payload
            }
        }
        case ADD_NEW_FREQUENCY_POLICY: {
            return {
                ...store,
                list: [...store.list, payload],
            }
        }

        default: {
            return store
        }
    }
}
