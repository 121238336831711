import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { map, pull } from 'lodash';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { InputField } from 'common/InputField/InputField';
import { JsonHeaderDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/JsonHeader/JsonHeaderDropdown';
import { ActionCodeEditorContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { Label } from 'common/_Label/Label';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { JointlyInputSelectOptions } from 'common/JointlyInputSelectOptions/JointlyInputSelectOptions';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { useComponentUnmount } from 'hooks/useComponentUnmount';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataCustomWebHook } from 'store/selectors/scenariosActions.selectors'
import { Select } from 'components/SelectOptions/Select';
import { consentCategoriesMapped } from 'store/selectors/consentCategories.selector';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';
import { CommonMethods } from 'lib/CommonMethods';

const requestMethods = ['POST', 'GET', 'PUT', 'DELETE'];

export const CustomWebHookSidebar = () => {
    const dispatch = useDispatch();
    const context = useContext(SideBarContext);
    const {
        onSubmitHandler,
        onChangeNumericInput,
        onChangeInput,
        jsonHeaderExample,
        defaultCountTimeInterval
    } = context;

    const { t: translator } = useTranslation();

    const { actionCode, setActionCode } = useContext(ActionCodeEditorContext);

    const consentCategories = useSelector(consentCategoriesMapped);
    const { title, actionType, _values, _jsonHeaders, _jsonBody, errors } = useSelector(getStoreDataCustomWebHook);

    const [values, setValues] = useState(_values);
    const [jsonHeaders, setJsonHeaders] = useState(_jsonHeaders);

    const communicationsFrequency = map(pull(defaultCountTimeInterval, { value: 'minutes' }), timeInterval => {
        return {
            ...timeInterval,
            name: translator(`actionSidebars:${timeInterval.name}`)
        }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericInputCached = useCallback(onChangeNumericInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeInputCached = useCallback(onChangeInput(setValues), []);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData({
        values,
        jsonHeaders,
        jsonBody: actionCode
    }))

    useEffect(() => {
        setActionCode(CommonMethods.lineBreakJinja(_jsonBody))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useComponentUnmount(() => setActionCode(''));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <SideBarContext.Provider value={{
            ...context,
            jsonHeaders,
            setJsonHeaders,
            jsonHeaderExample,
            errors
        }}>
            <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
                <>
                    <SidebarGeneralTitle title={title}/>

                    <Label htmlFor="maxNumberOfRequests" text={translator(`actionSidebars:emailMaxCount`)}/>
                    <InputField
                        type='number'
                        id='maxNumberOfRequests'
                        name='maxNumberOfRequests'
                        value={values?.maxNumberOfRequests?.value}
                        required={values?.maxNumberOfRequests?.required}
                        error={!!errors.maxNumberOfRequests}
                        onChange={onChangeNumericInputCached}
                    />
                    <ErrorMessageField
                        active={!!errors.maxNumberOfRequests}
                        message={errors?.maxNumberOfRequests}
                    />

                    <JointlyInputSelectOptions
                        inputName='maxFrequencyNumber'
                        inputType='number'
                        inputLabel={translator('actionSidebars:countTimeIntervalNumber')}
                        inputValue={values?.maxFrequencyNumber?.value}
                        inputRequired={values?.maxFrequencyNumber?.required}
                        inputOnChange={onChangeNumericInputCached}
                        selectName='maxFrequencyType'
                        selectOptions={communicationsFrequency}
                        selectValue={values?.maxFrequencyType?.value}
                        selectRequired={values?.maxFrequencyType?.required}
                        selectOnChange={onChangeInputCached}
                        error={errors?.maxFrequencyNumber}
                    />

                    <Label htmlFor="consentCategory" text='Consent category *'/>
                    <Select
                        name='consentCategory'
                        value={values?.consentCategory?.value}
                        onChange={onChangeInputCached}
                        options={consentCategories}
                        className={styles.selectWithoutErrors}
                        required
                    />

                    <Label htmlFor="method" text='Request method *'/>
                    <Select
                        name='method'
                        value={values?.method?.value}
                        onChange={onChangeInputCached}
                        options={requestMethods}
                        className={styles.selectWithoutErrors}
                        required
                    />

                    <Label htmlFor="url" text='Request url *'/>
                    <InputField
                        type='text'
                        id='url'
                        name='url'
                        value={values?.url?.value}
                        required={values?.url?.required}
                        error={!!errors.url}
                        onChange={onChangeInputCached}
                    />
                    <ErrorMessageField
                        active={!!errors.url}
                        message={errors.url}
                    />

                    <JsonHeaderDropdown/>

                    <ActionSidebarButtons/>
                </>
            </ActionSidebarFormWrapper>
        </SideBarContext.Provider>
    );
};
