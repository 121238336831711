import { compact, find, map, toNumber, toString } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';
import { DateUse } from 'lib/DateUse';

export const sqlTriggerCompiler = (action, filteredArrows) => {
    const {
        id,
        actionType,
        actionParams,
        position,
        settings: {
            dayOfWeek = null,
            atTheDay = null,
            selectedType,
            time,
            sql
        }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    const sqlTriggerOptionsCompiler = {
        repeat: {
            scheduleOptions: () => {
                const utcTime = DateUse.convertLocalToUTC(time, 'HH:mm', 'HH:mm');

                return {
                    schedule: {
                        time: utcTime,
                        days_of_the_week: map(compact(map(dayOfWeek, (item, index) => {
                            const { isChecked } = item;
                            // needed to transform all indexes to string because lodash compact remove all false values of array (includes 0 index)
                            if (isChecked) return toString(index);
                            return null;
                        })), item => toNumber(item))
                    }
                }
            },
        },
        schedule: {
            scheduleOptions: () => {
                const utcDateTime = atTheDay.value
                    ? DateUse.convertLocalToUTC(`${atTheDay.value} ${time}`, 'DD/MM/YYYY HH:mm', 'YYYY-MM-DD HH:mm')
                    : null

                return {
                    datetime: utcDateTime, // '2020-01-01 12:00'
                }
            }
        }
    };

    return {
        trigger_id: id,
        trigger_type: 'sql_trigger',
        trigger_params: actionParams,
        coordinates: position,
        trigger_options: {
            ...sqlTriggerOptionsCompiler[find(selectedType, { active: true }).id].scheduleOptions(),
            customer_segment: null,
            bq_query: toString(sql)
        },
        next_actions_ids: [
            ...nextActionIdsCompiled
        ],
    }
}
