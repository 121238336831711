import React, { useRef } from 'react';
import styles from 'pages/Communications/ScenarioSetupPage/ScenarioSetupPage.module.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find, eq } from 'lodash';
import { changeActionPosition } from 'store/actions/scenariosActions.action';
import Draggable from 'react-draggable';
import { TriggerEvent } from 'components/ScenarioSetup/ActionIcons/instances/TriggerEvent';
import { RepeatTrigger } from 'components/ScenarioSetup/ActionIcons/instances/RepeatTrigger';
import { ScheduleTrigger } from 'components/ScenarioSetup/ActionIcons/instances/ScheduleTrigger';
import { SqlTrigger } from 'components/ScenarioSetup/ActionIcons/instances/SqlTrigger';
import { Wait } from 'components/ScenarioSetup/ActionIcons/instances/Wait';
import { TailoredWait } from 'components/ScenarioSetup/ActionIcons/instances/TailoredWait';
import { Funnel } from 'components/ScenarioSetup/ActionIcons/instances/Funnel';
import { SendEmail } from 'components/ScenarioSetup/ActionIcons/instances/SendEmail';
import { SendSms } from 'components/ScenarioSetup/ActionIcons/instances/SendSms';
import { SendVider } from 'components/ScenarioSetup/ActionIcons/instances/SendVider';
import { CustomWebhook } from 'components/ScenarioSetup/ActionIcons/instances/CustomWebhook';
import { ConditionalSplit } from 'components/ScenarioSetup/ActionIcons/instances/ConditionalSplit';
import { AbTestSplit } from 'components/ScenarioSetup/ActionIcons/instances/AbTestSplit';
import { WebsiteBuilder } from 'components/ScenarioSetup/ActionIcons/instances/WebsiteBuilder';
import { MobilePush } from 'components/ScenarioSetup/ActionIcons/instances/MobilePush';
import { WebPush } from 'components/ScenarioSetup/ActionIcons/instances/WebPush';
import { EditSegment } from 'components/ScenarioSetup/ActionIcons/instances/EditSegment';
import { GoogleAds } from 'components/ScenarioSetup/ActionIcons/instances/GoogleAds';
import { useXarrow } from 'react-xarrows';
import { FacebookAds } from 'components/ScenarioSetup/ActionIcons/instances/FacebookAds';

export const DraggableActionModel = props => {
    const { id, actionType, position, isOpenActionInfo} = props;

    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const { actionsList } = useSelector(store => store.scenariosActions);

    const updateXarrow = useXarrow();

    const chainItems = {
        TriggerEvent: TriggerEvent,
        RepeatTrigger: RepeatTrigger,
        ScheduleTrigger: ScheduleTrigger,
        SqlTrigger: SqlTrigger,
        Wait: Wait,
        TailoredWait: TailoredWait,
        ConditionalSplit: ConditionalSplit,
        ABTestSplit: AbTestSplit,
        Funnel: Funnel,
        SendEmail: SendEmail,
        SendSms: SendSms,
        SendViber: SendVider,
        WebsiteBuilder: WebsiteBuilder,
        CustomWebHook: CustomWebhook,
        SendMobPush: MobilePush,
        SendWebPush: WebPush,
        EditSegment: EditSegment,
        GoogleAds: GoogleAds,
        FacebookAds: FacebookAds
    };

    const Child = chainItems[actionType];
    const action = find(actionsList, elem => eq(elem.id, id));

    const updatePosition = (e, { x, y }) => {
        if (action.position.x !== x || !action.position.y !== y) {
            dispatch(changeActionPosition({ position: { x, y } }, { id: id }));
        }

        return updateXarrow
    };

    // You can use onStart or onStop functions if you needed
    const dragHandlers = {
        onDrag: updateXarrow,
        onStop: updatePosition
    };

    return (
        <Draggable bounds='parent'
                   nodeRef={nodeRef}
                   position={{ x: position.x, y: position.y, }}
                   {...dragHandlers}
        >
            <div className={styles.scenariosProcessItem}
                ref={nodeRef}
                style={isOpenActionInfo && { zIndex: 100 }}
            >
                <Child {...props}/>
            </div>
        </Draggable>
    );
};

DraggableActionModel.propTypes = {
    props: PropTypes.object.isRequired,
}
DraggableActionModel.defaultProps = {
    props: {}
}
