export const defaultTriggerEventSettings = () => ({
    values: {
        actionName: {
            value: '',
            required: true,
        },
        actionSubName: {
            value: '',
            required: false,
        },
    },
    attributeFilter: []
})
