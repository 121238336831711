import { eq, find, map, some, toNumber } from 'lodash';
import { attributeFilterCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/attributeFilterCompiler';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

export const conditionsCompiler = array => map(array, conditionItem => {

    const { _options } = conditionItem;
    const selectedConditionType = find(_options, { isChecked: true });

    // This is way to compile condition type "Event"
    if (eq(selectedConditionType.id, 'event')) {
        const {
            _eventValues: {
                actionName,
                actionSubName,
                aggregationFunction,
                aggregationColumn,
                aggregationComparisonOperator,
                aggregationComparisonValue,
                countDays,
                countHours
            },
            _selectedTimeInterval: {
                _intervalType,
                _intervalDate
            },
            _attributeFilter
        } = conditionItem;

        const attributeFilterCompiled = attributeFilterCompiler(_attributeFilter);

        const getDatetimeDynamicConditions = (countDays, countHours, intervalType) => {

            if (some(intervalType, { id: 'lastTime', isChecked: true })) {
                return {
                    days: toNumber(countDays.value),
                    hours: toNumber(countHours.value),
                    since_trigger_event: false
                }
            }

            if (some(intervalType, { id: 'atScriptStart', isChecked: true })) {
                return {
                    days: null,
                    hours: null,
                    since_trigger_event: true
                }
            }

            return null;
        }

        const getDatetimeStaticConditions = (intervalDate, intervalType) => {
            if (!some(intervalType, { id: 'currentTime', isChecked: true })) return null;

            const { startDate, endDate } = intervalDate;
            console.log('startDate, endDate', startDate, endDate);
            const localTime = DateUse.getCurrentLocalDateTime('HH:mm:ss');

            return {
                datetime_to: endDate
                    ? DateUse.format(`${endDate} ${localTime}`, 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') // '2020-10-10 10:10:10'
                    : null,
                datetime_from: startDate
                    ? DateUse.format(`${startDate} ${localTime}`, 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') // '2020-10-10 10:10:10'
                    : null
            }
        };

        return {
            client_option: selectedConditionType.id,
            event_condition: {
                conditions: [
                    ...attributeFilterCompiled
                ],
                datetime_dynamic_conditions: getDatetimeDynamicConditions(countDays, countHours, _intervalType),
                datetime_static_conditions: getDatetimeStaticConditions(_intervalDate, _intervalType),
                event_name: StringUse.recast(actionName.value,  { accepted: 'string', rejected: null }),
                event_subname: StringUse.recast(actionSubName.value, { accepted: 'string', rejected: null })
            },
            agg_function: StringUse.recast(aggregationFunction.value, { accepted: 'string', rejected: null }),
            agg_col: StringUse.recast(aggregationColumn.value, { accepted: 'string', rejected: null }),
            events_value: StringUse.recast(aggregationComparisonValue.value, { accepted: 'number', rejected: null }),
            events_comparision_operator: StringUse.recast(aggregationComparisonOperator.value, { accepted: 'string', rejected: null })
        }
    }

    // This is way to compile condition type "Attribute"
    if (eq(selectedConditionType.id,'attribute')) {

        const {
            _clientAttributeValues: {
                attributeName,
                comparisonOperator,
                comparisonValue
            }
        } = conditionItem;

        return {
            client_option: selectedConditionType.id,
            attribute_name: StringUse.recast(attributeName.value, { accepted: 'string', rejected: null }),
            attribute_comparision_operator: StringUse.recast(comparisonOperator.value, { accepted: 'string', rejected: null }),
            attribute_value: comparisonValue.value
        }
    }

    // This is way to compile condition type "Metric"
    if (eq(selectedConditionType.id,'metric')) {

        const {
            _metricAttributeValues: {
                attributeName,
                comparisonOperator,
                comparisonValue
            }
        } = conditionItem;

        return {
            client_option: selectedConditionType.id,
            metric_name: StringUse.recast(attributeName.value, { accepted: 'string', rejected: null }),
            metric_comparision_operator: StringUse.recast(comparisonOperator.value, { accepted: 'string', rejected: null }),
            metric_value: comparisonValue.value
        }
    }

});
