export const defaultFunnelSettings = () => ({
    selectedType: [
        {
            id: 'count',
            name: 'CountFunnelTabButton',
            active: true
        },
        {
            id: 'share',
            name: 'ShareFunnelTabButton',
            active: false
        }
    ],
    shareCount: {
        value: '',
        required: true
    },
    countClients: {
        value: '',
        required: true
    },
    countTimeIntervalNumber: {
        value: '',
        required: true
    },
    countTimeIntervalType: {
        value: 'minutes',
        required: true
    },
    evalTimeInterval: false
})
