import { map, find, eq, remove } from 'lodash';
import { apiRequestAction } from 'store/actions/api.action';
import { CommonMethods } from 'lib/CommonMethods';
import {
    FETCH_UPLOADED_IMAGES,
    SET_UPLOADED_IMAGES,
    FETCH_IMAGES_FOLDERS_LIST,
    SET_IMAGES_FOLDERS_LIST,
    FETCH_CREATE_IMAGES_FOLDER,
    SET_CREATE_IMAGES_FOLDER,
    CLEAR_SELECTED_IMAGE_DATA,
    CLEAR_IMAGES_LIST,
    SET_CURRENT_IMAGES_FOLDER,
    SET_CURRENT_IMAGES_FOLDER_FROM_LIST,
    CLEAR_CURRENT_IMAGES_FOLDER,
    SET_SELECTED_IMAGES_FROM_PC,
    REMOVE_SELECTED_IMAGE_FROM_PC,
    CLEAR_SELECTED_IMAGES_FROM_PC,
    FETCH_DELETE_IMAGE_FOLDER,
    SET_DELETE_IMAGE_FOLDER,
    FETCH_DELETE_IMAGE,
    SET_DELETE_IMAGE,
    FETCH_UPDATE_FOLDER_NAME,
    SET_UPDATE_FOLDER_NAME
} from 'store/types';
import { ObjectUse } from 'lib/ObjectUse';
import { ArrayUse } from 'lib/ArrayUse';

const setUploadedImages = (data, folderId) => (dispatch, getStore) => {
    console.log('[API Images Upload]: Response:', data);

    const currentFolder = getStore().images.currentFolder;
    const folders = getStore().images.folders;
    console.log('currentFolder', currentFolder);
    console.log('folders', folders);
    const { data: collection, index } = ArrayUse.findCollection(folders, { id: folderId });

    const updatedImagesList = [ ObjectUse.camelCaseObjectKeys(data.image), ...currentFolder.images ];

    dispatch({
        type: SET_UPLOADED_IMAGES,
        payload: {
            folders: ArrayUse.replace(folders, index, {
                ...collection,
                images: updatedImagesList,
                imagesCount: updatedImagesList.length
            }),
            currentFolder: {
                ...currentFolder,
                images: updatedImagesList,
                imagesCount: updatedImagesList.length
            }
        }
    })
};

export const uploadImages = (data, folderId, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('images/save'),
        method: 'POST',
        data,
        extraOptions: folderId,
        onSuccess: setUploadedImages,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_UPLOADED_IMAGES
    })
);

export const clearSelectedImageData = (data = { id: '', url: '' }) => dispatch =>
    dispatch({
        type: CLEAR_SELECTED_IMAGE_DATA,
        payload: { data }
    });

export const clearImagesList = () => dispatch => {
    dispatch({
        type: CLEAR_IMAGES_LIST
    })
};

const setImagesFoldersList = data => ({
    type: SET_IMAGES_FOLDERS_LIST,
    payload: {
        data: map(data, item => ObjectUse.camelCaseObjectKeys(item))
    }
});

export const getImagesFoldersList = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('images/folders/list'),
        method: 'GET',
        onSuccess: setImagesFoldersList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_IMAGES_FOLDERS_LIST
    })
);

const setCreateImagesFolder = data => ({
    type: SET_CREATE_IMAGES_FOLDER,
    payload: {
        data: { ...data, images: [], imagesCount: 0 }
    }
});

export const createImagesFolder = (name, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('images/folders'),
        method: 'POST',
        data: {
            folder_name: name
        },
        onSuccess: setCreateImagesFolder,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CREATE_IMAGES_FOLDER
    })
);
export const setCurrentImagesFolder = id => (dispatch, getStore) => {
    const findFolderFromList = find(getStore().images.folders, { id: id });

    dispatch({
        type: SET_CURRENT_IMAGES_FOLDER,
        payload: {
            data: findFolderFromList
        }
    })
};

const setCurrentImagesFolderFromRequestedFoldersList = (data, id) => {
    const folders = map(data, item => ObjectUse.camelCaseObjectKeys(item));

    return {
        type: SET_CURRENT_IMAGES_FOLDER_FROM_LIST,
        payload: {
            folders,
            currentFolder: find(folders, { id: id })
        }
    }
};

export const getCurrentImagesFolderFromRequestedFoldersList = id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('images/folders/list'),
        method: 'GET',
        extraOptions: id,
        onSuccess: setCurrentImagesFolderFromRequestedFoldersList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_IMAGES_FOLDERS_LIST
    })
);
export const clearCurrentImagesFolder = () => dispatch =>
    dispatch({
        type: CLEAR_CURRENT_IMAGES_FOLDER
    })

export const setSelectedImagesFromPc = images => dispatch =>
    dispatch({
        type: SET_SELECTED_IMAGES_FROM_PC,
        payload: {
            list: images,
            previewUrls: map(images, image => URL.createObjectURL(image))
        }
    });

export const removeSelectedImageFromPc = index => (dispatch, getStore) => {
    const selectedImagesFromPc = getStore().images.selectedImagesFromPc;
    const list = selectedImagesFromPc.list;
    const previewUrls = selectedImagesFromPc.previewUrls;

    dispatch({
        type: REMOVE_SELECTED_IMAGE_FROM_PC,
        payload: {
            list: ArrayUse.removeByIndex(list, index),
            previewUrls: ArrayUse.removeByIndex(previewUrls, index)
        }
    })
};

export const clearSelectedImagesFromPc = () => dispatch =>
    dispatch({
        type: CLEAR_SELECTED_IMAGES_FROM_PC
    })

const setDeleteImageFolder = id => (dispatch, getStore) => {
    const folders = getStore().images.folders;

    dispatch({
        type: SET_DELETE_IMAGE_FOLDER,
        payload: {
            folders: ArrayUse.remove(folders, remove(folders, elem => eq(elem.id, id)))
        }
    })
};
export const deleteImageFolder = (id, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`images/folders/${id}`),
        method: 'DELETE',
        extraOptions: id,
        onSuccess: setDeleteImageFolder,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_DELETE_IMAGE_FOLDER
    })
);

const setDeleteImage = id => (dispatch, getStore) => {
    const folder = getStore().images.currentFolder;

    dispatch({
        type: SET_DELETE_IMAGE,
        payload: {
            folder: {
                ...folder,
                images: ArrayUse.remove(folder.images, remove(folder.images, elem => eq(elem.imageId, id)))
            }
        }
    })
};

export const deleteImage = (id, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`image/${id}`),
        method: 'DELETE',
        extraOptions: id,
        onSuccess: setDeleteImage,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_DELETE_IMAGE
    })
);

const setUpdateFolderName = data => (dispatch, getStore) => {

    const folders = getStore().images.folders;
    const { data: collection, index } = ArrayUse.findCollection(folders, { id: data.id })

    dispatch({
        type: SET_UPDATE_FOLDER_NAME,
        payload: {
            folder: { ...collection, name: data.name },
            folders: ArrayUse.replace(folders, index, { ...collection, name: data.name })
        }
    })
};
export const updateFolderName = (id, newFolderName, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`images/folders/${id}`),
        method: 'PUT',
        data: { 'folder_name': newFolderName },
        onSuccess: setUpdateFolderName,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_UPDATE_FOLDER_NAME
    })
);