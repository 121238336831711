import { find, map } from 'lodash';
import { otherSegmentationTypeValidation } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/condition/otherSegmentationType';
import { eventSegmentationTypeValidation } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/condition/eventSegmentationType';

export const conditionControl = conditions => map(conditions, condition => {

    const selectedOptionsType = find(condition._options, 'isChecked');

    const validationTypes = {
        event: eventSegmentationTypeValidation(condition),
        attribute: otherSegmentationTypeValidation(condition, '_clientAttributeValues'),
        metric: otherSegmentationTypeValidation(condition, '_metricAttributeValues')
    };

    return {
        selectedOptionsType: selectedOptionsType.id,
        validation: validationTypes[selectedOptionsType.id]()
    }
})
