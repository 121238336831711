import React from 'react'
import styles from 'components/Metrics/ChartMetrics.module.css';
import { SkeletonWrapper } from 'common/Skeleton/SkeletonWrapper';
import { map } from 'lodash';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const validationConversionRateFill = conversionRate => conversionRate > 100 ? 100 : conversionRate;

export const TotalMetrics = ({
    loading,
    trendCards,
    conversionActions,
    conversionRate,
    rightCircleParams,
    leftCircleParams
}) => {
    const conversionRateFill = validationConversionRateFill(conversionRate);

    const { t: translator } = useTranslation();

    return (
      <div className={styles.totalMetricsMain}>
          <SkeletonWrapper isShow={!loading} width={280} height={197} style={{ marginRight: '175px' }}>

              <div className={styles.totalMetricsTrend}>
                  {map(trendCards, item => (
                      <div className={styles.totalMetricsTrendCard} key={item.action}>
                          <p>{translator(`metrics:${item.action}`)}</p>
                          <div>
                              <span className={styles.totalMetricsTrendCardCounter}>{item.count}</span>
                              <span className={cx(styles.totalMetricsTrendCardPercent,
                                  item.percent >= 0 ? 'totalMetricsTrendCardPercent_up' : 'totalMetricsTrendCardPercent_down',
                              )}>{item.percent}%</span>
                          </div>
                      </div>
                  ))}
              </div>
          </SkeletonWrapper>
          <div className={styles.totalMetricsConversion}>
              <SkeletonWrapper isShow={!loading} width={88} height={88} style={{ marginTop: '33px' }}>
                  <div className={cx(styles.totalMetricsConversionCircle, styles.totalMetricsConversionCircle_blue)}>
                      <span>{leftCircleParams}</span>
                  </div>

              </SkeletonWrapper>
              <SkeletonWrapper isShow={!loading} width={280} height={244} style={{ margin: '0 26px' }}>
                  <div className={styles.totalMetricsConversionState}>
                      <span className={styles.totalMetricsConversionStatePercent}>{conversionRate}%</span>
                      <div className={styles.totalMetricsConversionStateBar}>
                          <div style={{ width: `${conversionRateFill}%` }}/>
                      </div>
                      <ul>
                          {map(conversionActions, action => (
                              <li key={action}>
                                  {translator(`metrics:${action}`)}
                              </li>
                          ))}
                      </ul>
                  </div>
              </SkeletonWrapper>
              <SkeletonWrapper isShow={!loading} width={88} height={88} style={{ marginTop: '33px' }}>
                  <div className={cx(styles.totalMetricsConversionCircle, styles.totalMetricsConversionCircle_lightBlue)}>
                      <span>{rightCircleParams}</span>
                  </div>
              </SkeletonWrapper>
          </div>
      </div>
  )
};
