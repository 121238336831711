import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/AsideDropdownButton/AsideDropdownButton.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const AsideDropdownButton = ({ name, onClick, className, alternativeColor}) => (
    <p onClick={onClick} className={cx(styles.filterName, className, { 'filterGreen': alternativeColor })}>
        {name}
    </p>
);

AsideDropdownButton.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    alternativeColor: PropTypes.bool
}
AsideDropdownButton.defaultProps = {
    name: 'Default dropdown name',
    className: null,
    alternativeColor: false
}
