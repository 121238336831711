import { Validation } from 'validations/Validation';
import { size, includes, map, toNumber, eq, omit } from 'lodash';

const clientAttributeValuesRules = {
    attributeName: 'required',
    comparisonOperator: 'required'
};

const comparisonValueValidation = (comparisonValue, segmentationType) => {
    if (size(comparisonValue.value) && eq(segmentationType, '_metricAttributeValues')) {
        const isComparisonValuesIncludesLetterElements = includes(map(comparisonValue.value, item => toNumber(item)), NaN);

        return {
            passes: !isComparisonValuesIncludesLetterElements,
            fails: isComparisonValuesIncludesLetterElements,
            firstError: {
                comparisonValue: isComparisonValuesIncludesLetterElements ? 'InputTagsValuesOnlyNumericMessage' : false
            }
        }
    }

    return {
        firstError: {
            comparisonValue: false
        }
    }
};

export const otherSegmentationTypeValidation = (condition, segmentationType) => {
    return () => {
        const {
            [segmentationType]: {
                attributeName,
                comparisonOperator,
                comparisonValue
            }
        } = condition;

        const validation = Validation.checkSome({
            attributeName: attributeName.value,
            comparisonOperator: comparisonOperator.value
        }, clientAttributeValuesRules, {
            'required.attributeName': 'InputValidationRequiredMessage',
            'required.comparisonOperator': 'InputValidationRequiredMessage',
        }, ['attributeName', 'comparisonOperator']);

        const comparisonValueResult = comparisonValueValidation(comparisonValue, segmentationType);

        return {
            ...validation,
            ...omit(comparisonValueResult, ['firstError']),
            firstErrors: {
                ...validation.firstErrors,
                ...comparisonValueResult?.firstError
            }
        }
    }
};
