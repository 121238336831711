import { forEach } from 'lodash';
import { Validation } from 'validations/Validation';

const urlsRules = {
    value: 'required|url'
}

export const urlsControl = urls => {

    let urlsErrors = {
        url: {
            id: []
        }
    }

    forEach(urls, ({ id, url }) => {
        const validation = Validation.check(url, urlsRules, {
            'required.value': 'InputValidationRequiredMessage',
            'url.value': 'UrlAddressInputValidationMessage',
        }, 'value')

        if (validation.fails) {
            const urlError = validation.firstError;

            if (urlError) {
                urlsErrors.url = {
                    error: urlError,
                    id: [...urlsErrors.url.id, id]
                }
            }
        }
    });

    const isUrlsHasErrors = !!urlsErrors.url.id.length;

    return isUrlsHasErrors
        ? urlsErrors
        : {}
}
