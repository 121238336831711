import React from 'react';

export const PeopleCircleIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.75 2C6.75 3.10457 5.85457 4 4.75 4C3.64543 4 2.75 3.10457 2.75 2C2.75 0.89543 3.64543 0 4.75 0C5.85457 0 6.75 0.89543 6.75 2Z" fill="inherit"/>
        <path d="M4.75 4C3.09315 4 1.75 4.89543 1.75 6C1.75 7.10457 3.09315 8 4.75 8C6.40685 8 7.75 7.10457 7.75 6C7.75 4.89543 6.40685 4 4.75 4Z" fill="inherit"/>
        <path d="M15.75 16C16.8546 16 17.75 15.1046 17.75 14C17.75 12.8954 16.8546 12 15.75 12C14.6454 12 13.75 12.8954 13.75 14C13.75 15.1046 14.6454 16 15.75 16Z" fill="inherit"/>
        <path d="M15.75 16C17.4069 16 18.75 16.8954 18.75 18C18.75 19.1046 17.4069 20 15.75 20C14.0931 20 12.75 19.1046 12.75 18C12.75 16.8954 14.0931 16 15.75 16Z" fill="inherit"/>
        <path d="M9 1C9 0.585786 9.33579 0.25 9.75 0.25C15.1348 0.25 19.5 4.61522 19.5 10C19.5 10.4142 19.1642 10.75 18.75 10.75C18.3358 10.75 18 10.4142 18 10C18 5.44365 14.3063 1.75 9.75 1.75C9.33579 1.75 9 1.41421 9 1Z" fill="inherit"/>
        <path d="M0.75 9.25C1.16421 9.25 1.5 9.58579 1.5 10C1.5 14.5563 5.19365 18.25 9.75 18.25C10.1642 18.25 10.5 18.5858 10.5 19C10.5 19.4142 10.1642 19.75 9.75 19.75C4.36522 19.75 0 15.3848 0 10C0 9.58579 0.335786 9.25 0.75 9.25Z" fill="inherit"/>
    </svg>
);