import React from 'react';
import PropTypes from 'prop-types';
import Xarrow from 'react-xarrows';
import { useDispatch } from 'react-redux';
import { removeArrow } from 'store/actions/scenariosActions.action';
import { DisconnectLabel } from 'components/ScenarioSetup/ActionConnections/DisconnectLabel';

export const ActionChainConnection = ({ from, to }) => {
    const dispatch = useDispatch();

    if (from && to) {
        return (
            <Xarrow
                labels={{ middle: <DisconnectLabel onClick={() => dispatch(removeArrow({ from: from.id, to: to.id }))} /> }}
                start={from.id}
                end={to.id}
                headSize={0}
                color={from.color}
                strokeWidth={3}
                startAnchor="auto"
                endAnchor="auto"
                curveness={0.5}
                advanced= {{ extendSVGcanvas: 0 }}
            />
        );
    }

    return null;
};

ActionChainConnection.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
}
