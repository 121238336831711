import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    getStoreDataABTest,
    getStoreDataConditionalSplit,
    getStoreDataCustomWebHook,
    getStoreDataEditSegment,
    getStoreDataFunnel,
    getStoreDataPushAndSendContent,
    getStoreDataRepeatTrigger,
    getStoreDataScheduleTrigger,
    getStoreDataSqlTrigger,
    getStoreDataTailoredWait,
    getStoreDataTriggerEvent,
    getStoreDataWait,
    getStoreDataWebsiteBuilder,
} from 'store/selectors/scenariosActions.selectors';

const actionTypes = {
    TriggerEvent: getStoreDataTriggerEvent,
    RepeatTrigger: getStoreDataRepeatTrigger,
    ScheduleTrigger: getStoreDataScheduleTrigger,
    SqlTrigger: getStoreDataSqlTrigger,
    Wait: getStoreDataWait,
    TailoredWait: getStoreDataTailoredWait,
    ConditionalSplit: getStoreDataConditionalSplit,
    ABTestSplit: getStoreDataABTest,
    Funnel: getStoreDataFunnel,
    SendEmail: getStoreDataPushAndSendContent,
    SendSms: getStoreDataPushAndSendContent,
    SendViber: getStoreDataPushAndSendContent,
    CustomWebHook: getStoreDataCustomWebHook,
    WebsiteBuilder: getStoreDataWebsiteBuilder,
    SendMobPush: getStoreDataPushAndSendContent,
    SendWebPush: getStoreDataPushAndSendContent,
    EditSegment: getStoreDataEditSegment
};
export const useAutoSaveActionSetting = (callback, actionType) => {
    const { isAutoSaveActive } = useSelector(actionTypes[actionType]);

    useEffect(() => {
        if (isAutoSaveActive) callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutoSaveActive])
};
