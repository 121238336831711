import { map, compact } from 'lodash';
import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';
import { conditionControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/condition/conditionControl';

const dayOfWeekRules = {
    isChecked: 'accepted'
};

export const repeatTriggerControl = action => {

    const {
        dayOfWeek,
        condition
    } = action.settings;

    const dayOfWeekValidation = Validation.leastOneFromList(dayOfWeek, dayOfWeekRules, {
        'accepted.isChecked': 'CheckboxValidationRequired'
    }, 'isChecked');

    const conditionsResult = conditionControl(condition);

    const isConditionsHasErrors = !!compact(map(conditionsResult, report => report.validation.fails)).length;

    const isActionHasErrors = dayOfWeekValidation.fails || isConditionsHasErrors

    return {
        ...action,
        isShowWarningActionMessage: isActionHasErrors,
        errors: {
            ...ObjectUse.createSeveralObjects([
                dayOfWeekValidation.errorMessages,
                isConditionsHasErrors ? conditionsResult : null
            ], ['checkboxErrors', 'conditionsErrors']),
        }
    }
}
