import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-nice-dates/build/style.css';
import 'components/Calendars/Calendars.css';
import { format } from 'date-fns';
import { enGB, ru } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import { TransparentButton } from 'common/Buttons/TransparentButton/TransparentButton';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { clearDateCalendarData, toggleDateCalendar } from 'store/actions/calendars.action';
import { useTranslation } from 'react-i18next';
import { DateUse } from 'lib/DateUse';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

export const CustomDatePickerCalendar = () => {
    const dispatch = useDispatch();
    const { t: translator, i18n } = useTranslation();

    const calendarDate = useSelector(store => store.calendars.dateCalendar.date);

    const [date, setDate] = useState(!!calendarDate
        ? new Date(DateUse.checkValidFormatYearMonthDay(calendarDate))
        : null
    );

    const DatePickerCalendarRef = useRef(null);

    const selectLocale = {
        ru: ru,
        en: enGB
    }

    const closeCalendar = () => () => {
        return dispatch(toggleDateCalendar({
            open: false,
            date: null,
        }))
    };

    useOutsideAlerter(DatePickerCalendarRef, closeCalendar())

    useEffect(() => {
        return () => dispatch(clearDateCalendarData());
    }, [dispatch])
    console.log('calendarDate', calendarDate);
    console.log('date', date);
    return (
        <div className="custom-date-picker-calendar-container" ref={DatePickerCalendarRef}>
            <DatePickerCalendar
                date={date}
                onDateChange={setDate}
                locale={selectLocale[i18n.language]}
                month={null}
                weekdayFormat="EEEEEE"
            />
            <div className="custom-date-picker-calendar-footer">
                <div className="custom-date-picker-interval-container">
                    <p>
                        <span>{date && format(date, 'dd/MM/yyyy', { locale: selectLocale[i18n.language] })}</span>
                    </p>
                    <CalendarIcon/>
                </div>

                <TransparentButton
                    callback={() => dispatch(toggleDateCalendar({
                        open: false,
                        date: date ? format(date, 'dd/MM/yyyy', { locale: selectLocale[i18n.language] }) : null,
                    }))}
                    name={translator('default:SaveButtonTitle')}
                    height={35}
                    width={100}
                    extensionClass="button-green-text"
                />
            </div>
        </div>
    );
};
