import axios from 'axios';
import Cookies from 'js-cookie';
import { CommonMethods } from 'lib/CommonMethods';
import {
    LOGIN_USER,
    LOGOUT_USER
} from 'store/types';

export const login = (values, callbackErrors) => dispatch =>
    axios.post(CommonMethods.replaceUrlEndpoint('login'), values, {
        headers: { 'Content-Type': 'application/json' }
    })
        .then(({ data }) => {
            console.log('[Auth Server]: Login response:', 'Authorized!');
            if (data.token) {
                Cookies.set('auth', JSON.stringify({
                    username: values.username,
                    token: data.token,
                    tokenExpireDate: data.expiration_datetime,
                    sandboxMode: data.sandbox_mode,
                    retailerName: data.retailer_name,
                    retailerCurrency: data.retailer_currency,
                    userGivenName: data.user_given_name
                }), CommonMethods.tokenSecuritySettings());

                dispatch({
                    type: LOGIN_USER,
                    payload: {
                        data: {
                            username: values.username,
                            token: data.token,
                            tokenExpireDate: data.expiration_datetime,
                            sandboxMode: data.sandbox_mode,
                            retailerName: data.retailer_name,
                            retailerCurrency: data.retailer_currency,
                            userGivenName: data.user_given_name
                        }
                    }
                });
            }
        })
        .catch(error => {
            if (error.response) {
                const { message } = error.response.data;

                switch (message) {
                    case 'Unauthorized': callbackErrors(prev => ({
                        ...prev,
                        password: 'Unauthorized',
                    }));
                        break;
                    case 'User does not exist': callbackErrors(prev => ({
                        ...prev,
                        username: 'UserDoesNotExist',
                    }));

                        break;
                    default: console.log('[Auth Server]:', message);
                }
            }
        });

export const makeAuth = (data = {}) => dispatch =>
    dispatch({
        type: LOGIN_USER,
        payload: {
            data: {
                username: data.username,
                token: data.token,
                tokenExpireDate: data.tokenExpireDate,
                sandboxMode: data.sandboxMode,
                retailerName: data.retailerName,
                retailerCurrency: data.retailerCurrency,
                userGivenName: data.userGivenName
            }
        },
    })

export const logout = () => dispatch => {
    window.location.protocol && window.location.protocol === 'https:'
        ? Cookies.remove('auth', { domain: '.neucurrent.com' })
        : Cookies.remove('auth')

    dispatch({
        type: LOGOUT_USER,
        payload: {
            defaultUsernameStore: { username: null, token: null, tokenExpireDate: null },
            defaultExperimentsStore: { list: [], metrics: [] },
            defaultScenariosStore: { list: [], metrics: [] },
            defaultCommunicationsStore: { list: [] },
            defaultConsentCategoriesStore: { list: [] },
            defaultTemplatesStore: { list: {
                    SMS: [],
                    Viber: [],
                    Email: [],
                    PersonalPage: [],
                    MobPushNotify: [],
                    WebPushNotify: [],
                } },
            defaultImagesStore: { list: [] }
        }
    })

    return Promise.resolve();
};
