import { includes, join, map, eq, toString } from 'lodash';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

export const eventConditionOption = (item, intervalTypeExtension, index) => {
    const {
        show,
        conditionName,
        _options,
        _eventValues,
        _eventValues: { actionName, actionSubName, countDays, countHours, aggregationFunction, aggregationColumn, aggregationComparisonOperator, aggregationComparisonValue },
        _clientAttributeValues,
        _metricAttributeValues,
        _selectedTimeInterval
    } = conditionExample;

    const {
        client_option,
        event_condition: { event_name, event_subname, conditions, datetime_dynamic_conditions, datetime_static_conditions },
        agg_function,
        agg_col,
        events_value,
        events_comparision_operator
    } = item;

    const intervalTypeConverter = map(intervalTypeExtension, item => {

        if (datetime_dynamic_conditions) {
            const { since_trigger_event } = datetime_dynamic_conditions;

            if (eq(item.id, 'atScriptStart') && since_trigger_event) {
                return { ...item, isChecked: true }
            }
            if (eq(item.id, 'lastTime') && !since_trigger_event) {
                return { ...item, isChecked: true }
            }
        }


        if (eq(item.id, 'currentTime') && datetime_static_conditions) {
            return { ...item, isChecked: true }
        }

        return { ...item, isChecked: false }
    })

    const getDatetimeDynamicData = data => {
        if (data) {
            const { days, hours } = data;

            return { days, hours }
        }
        return { days: '', hours: '' }
    }

    const getDatetimeStaticData = (intervalDateExample, data) => {

        if (data) {
            const { datetime_from, datetime_to } = data;

            return {
                ...intervalDateExample,
                startDate: datetime_from ? DateUse.format(datetime_from, 'YYYY-MM-DD HH:mm:ss', 'YYYY/MM/DD') : '',
                endDate: datetime_to ? DateUse.format(datetime_to, 'YYYY-MM-DD HH:mm:ss', 'YYYY/MM/DD') : ''
            }
        }

        return {
            ...intervalDateExample
        }
    }

    const attributeFilterConverter = map(conditions, (item, index) => {
        const { column_name, operator, values_to_include } = item;
        const { show, filterName, eventAttributeName, comparisonOperator, comparisonValue } = attributeFilterExample;

        return {
            id: index + 1,
            show,
            filterName,
            eventAttributeName: {
                ...eventAttributeName,
                value: join(column_name, '.')
            },
            comparisonOperator: {
                ...comparisonOperator,
                value: operator
            },
            comparisonValue: {
                ...comparisonValue,
                value: values_to_include
            }
        }
    })

    return {
        id: index + 1,
        show,
        conditionName,
        _options: map(_options, option => includes(option, client_option) ? {...option, isChecked: true} : {...option, isChecked: false}),
        _eventValues: {
            ..._eventValues,
            actionName: {
                ...actionName,
                value: StringUse.falseyReturnEmptyString(event_name),
            },
            actionSubName: {
                ...actionSubName,
                value: StringUse.falseyReturnEmptyString(event_subname),
            },
            countDays: {
                ...countDays,
                value: toString(getDatetimeDynamicData(datetime_dynamic_conditions)?.days)
            },
            countHours: {
                ...countHours,
                value: toString(getDatetimeDynamicData(datetime_dynamic_conditions)?.hours)
            },
            aggregationFunction: {
                ...aggregationFunction,
                value: StringUse.falseyReturnEmptyString(agg_function),
            },
            aggregationColumn: {
                ...aggregationColumn,
                value: toString(agg_col),
            },
            aggregationComparisonOperator: {
                ...aggregationComparisonOperator,
                value: StringUse.falseyReturnEmptyString(events_comparision_operator)
            },
            aggregationComparisonValue: {
                ...aggregationComparisonValue,
                value: toString(events_value)
            }
        },
        _selectedTimeInterval: {
            ..._selectedTimeInterval,
            _intervalType: intervalTypeConverter,
            _intervalDate: getDatetimeStaticData(_selectedTimeInterval._intervalDate, datetime_static_conditions),
        },
        _attributeFilter: [
            ...attributeFilterConverter
        ],
        _clientAttributeValues: {
            ..._clientAttributeValues
        },
        _metricAttributeValues: {
            ..._metricAttributeValues
        }
    }
}
const attributeConditionOption = (item, index) => {

    const {
        _options,
        _clientAttributeValues: { attributeName, comparisonOperator, comparisonValue },
    } = conditionExample;

    const {
        attribute_comparision_operator,
        attribute_name,
        attribute_value,
        client_option
    } = item;


    return {
        ...conditionExample,
        id: index + 1,
        _options: map(_options, option => includes(option, client_option) ? {...option, isChecked: true} : {...option, isChecked: false}),
        _clientAttributeValues: {
            attributeName: {
                ...attributeName,
                value: StringUse.falseyReturnEmptyString(attribute_name)
            },
            comparisonOperator: {
                ...comparisonOperator,
                value: StringUse.falseyReturnEmptyString(attribute_comparision_operator)
            },
            comparisonValue: {
                ...comparisonValue,
                value: attribute_value
            }
        },
    }
}
const metricConditionOption = (item, index) => {
    const {
        _options,
        _metricAttributeValues: { attributeName, comparisonOperator, comparisonValue },
    } = conditionExample;

    const {
        metric_name,
        metric_comparision_operator,
        metric_value,
        client_option
    } = item;

    return {
        ...conditionExample,
        id: index + 1,
        _options: map(_options, option => includes(option, client_option) ? {...option, isChecked: true} : {...option, isChecked: false}),
        _metricAttributeValues: {
            attributeName: {
                ...attributeName,
                value: StringUse.falseyReturnEmptyString(metric_name)
            },
            comparisonOperator: {
                ...comparisonOperator,
                value: StringUse.falseyReturnEmptyString(metric_comparision_operator)
            },
            comparisonValue: {
                ...comparisonValue,
                value: metric_value
            }
        }
    }
}

export const actionConditionDecompiler = (condition, intervalTypeExtension) => map(condition, (item, index) => {
    const { client_option } = item;

    if (eq(client_option, 'event')) {
        return eventConditionOption(item, intervalTypeExtension, index);
    }

    if (eq(client_option, 'attribute')) {
        return attributeConditionOption(item, index);
    }

    if (eq(client_option, 'metric')) {
        return metricConditionOption(item, index);
    }

})
