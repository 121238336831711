import React from 'react';
import { ScheduleTriggerIcon } from 'assets/icons/ScheduleTriggerIcon';
import { ScheduleTriggerIconAlt } from 'assets/icons/ScheduleTriggerIconAlt';
import { SingleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/SingleChainActionIcon';

export const ScheduleTrigger = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: ScheduleTriggerIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#9088DF'
            }
        },
        opened: {
            icon: ScheduleTriggerIconAlt,
            iconProps: { borderColor: '#9088DF' }
        },
        warning: {
            icon: ScheduleTriggerIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }
    return <SingleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions
    }} />
}
