import { toString, toNumber } from 'lodash';
import { defaultABTestSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultABTestSettings';

const {
    controlGroup,
    controlGroup: { rangeSliderValue },
    testGroup,
    testGroup: { aGroupValue, bGroupValue },
} = defaultABTestSettings();

export const ABTestSplitDecompiler = actionOptions => {

    const {
        group_probabilities: [
            controlGroupValue = rangeSliderValue,
            aGroup = aGroupValue,
            bGroup = bGroupValue
        ]
    } = actionOptions;

    return {
        controlGroup: {
            ...controlGroup,
            value: toString(controlGroupValue),
            rangeSliderValue: controlGroupValue
        },
        testGroup: {
            ...testGroup,
            rangeSliderValue: toNumber(aGroup),
            aGroupValue: toString(aGroup),
            bGroupValue: toString(bGroup),
        }
    }
}
