import React from 'react';

export const SendSmsIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
         <rect x="47.5" y="0.5" width="47" height="47" rx="4.5" transform="rotate(90 47.5 0.5)" fill={bgColor} stroke={bgColor}/>
         <path d="M16.407 25.1667C18.3378 25.1667 19.1745 24.2015 19.1745 23.0738C19.1745 21.5906 17.7439 21.2459 16.5602 20.9607C15.7249 20.7594 15.0125 20.5877 15.0125 20.0665C15.0125 19.5585 15.4845 19.2233 16.2246 19.2233C16.9755 19.2233 17.7371 19.4671 18.2949 19.9954L19.0136 19.1115C18.3485 18.5019 17.4475 18.1667 16.3319 18.1667C14.7658 18.1667 13.7253 19.0302 13.7253 20.1478C13.7253 21.5724 15.081 21.8978 16.2469 22.1776C17.1265 22.3887 17.898 22.5739 17.898 23.1855C17.898 23.6326 17.4904 24.1101 16.4606 24.1101C15.463 24.1101 14.6907 23.6732 14.1972 23.1754L13.5 24.0897C14.1329 24.7298 15.0876 25.1667 16.407 25.1667Z" fill={color}/>
         <path d="M27.764 25.0448V18.2683H25.9941L24.0418 22.7284L22.1002 18.2683H20.3303V25.0448H21.5853V20.0157L23.7736 25.0448H24.3099L26.5089 20.0157V25.0448H27.764Z" fill={color}/>
         <path d="M34.5 23.0738C34.5 24.2015 33.6633 25.1667 31.7325 25.1667C30.4131 25.1667 29.4584 24.7298 28.8255 24.0897L29.5228 23.1754C30.0162 23.6732 30.7885 24.1101 31.7861 24.1101C32.8159 24.1101 33.2235 23.6326 33.2235 23.1855C33.2235 22.5739 32.452 22.3887 31.5724 22.1776C30.4065 21.8978 29.0508 21.5724 29.0508 20.1478C29.0508 19.0302 30.0913 18.1667 31.6574 18.1667C32.773 18.1667 33.674 18.5019 34.3391 19.1115L33.6204 19.9954C33.0626 19.4671 32.301 19.2233 31.5501 19.2233C30.81 19.2233 30.338 19.5585 30.338 20.0665C30.338 20.5877 31.0504 20.7594 31.8857 20.9607C33.0694 21.2459 34.5 21.5906 34.5 23.0738Z" fill={color}/>
         <path fillRule="evenodd" clipRule="evenodd" d="M35.6667 33.3333H21.8433L14.9902 37.902C14.8112 38.0214 14.5811 38.0325 14.3914 37.931C14.2017 37.8295 14.0833 37.6318 14.0833 37.4167V33.3333H12.3333C11.0447 33.3333 10 32.2887 10 31V12.3333C10 11.0447 11.0447 10 12.3333 10H35.6667C36.9553 10 38 11.0447 38 12.3333V31C38 32.2887 36.9553 33.3333 35.6667 33.3333ZM12.3333 11.1667H35.6667C36.311 11.1667 36.8333 11.689 36.8333 12.3333V31C36.8333 31.6443 36.311 32.1667 35.6667 32.1667H21.3485L20.5557 32.7895L15.25 36.3267V32.1667H12.3333C11.689 32.1667 11.1667 31.6443 11.1667 31V12.3333C11.1667 11.689 11.689 11.1667 12.3333 11.1667Z" fill={color}/>
    </svg>
);
