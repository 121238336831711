import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const concatedValuesRules = {
    controlGroup: 'integer',
    testGroupA: 'integer',
    testGroupB: 'integer'
};

export const ABTestSplitControl = action => {

    const {
        controlGroup,
        testGroup
    } = action.settings;

    const concatedValues = {
        controlGroup: controlGroup.value,
        testGroupA: testGroup.aGroupValue,
        testGroupB: testGroup.bGroupValue
    };

    const validation = Validation.checkSome(concatedValues, concatedValuesRules);

    return {
        ...action,
        isShowWarningActionMessage: validation.fails,
        errors: {
            ...ObjectUse.createSeveralObjects([
                validation.firstErrors.controlGroup,
                validation.firstErrors.testGroupA,
                validation.firstErrors.testGroupB
            ], ['controlGroup', 'testGroupA', 'testGroupB'])
        }
    }
}
