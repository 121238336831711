import React from 'react';
import styles from 'components/Cards/Cards.module.css';
import { eq, find } from 'lodash';
import PropTypes from 'prop-types';
import { CreativeCardFooter } from 'components/Cards/CreativeCards/CreativeCardFooter';
import { CreativeCardWrapper } from 'components/Cards/CreativeCards/CreativeCardWrapper';
import { CommonMethods } from 'lib/CommonMethods';

export const ViberCard = ({
    template,
    templateId,
    templateType,
    isShowChainButton,
    isShowUsage,
    redirectPath,
    onClick,
    containerClassName
}) => {
    const {
        template_unique_id,
        jinja_fields
    } = template;

    const jinjaImageObject = find(jinja_fields, { jinja_field_name: 'jinja_image_url' });
    const jinjaButtonObject = find(jinja_fields, { jinja_field_name: 'jinja_button_text' });
    const jinjaBodyObject = find(jinja_fields, { jinja_field_name: 'jinja_body' });
    const preview = jinjaBodyObject && CommonMethods.renderPreviewViberTemplate(jinjaBodyObject.value);

    return (
        <CreativeCardWrapper
            isSelected={eq(templateId, template_unique_id)}
            cardType={templateType}
            className={containerClassName}
        >
            <div className={styles.creativeCardPreview} onClick={onClick}>
                {!preview &&
                    <div className={styles.defaultCardPreview}/>
                }
                {preview &&
                    <div className={styles.previewTemplateViberCardWrapper}>
                        <pre className={styles.previewTemplateViberCard} dangerouslySetInnerHTML={{ __html: preview }}/>

                        {jinjaImageObject.value &&
                            <img className={styles.previewTemplateViberCardImage} src={jinjaImageObject.value} alt='viber_template_image'/>
                        }
                        {jinjaButtonObject.value && (
                            <div className={styles.previewTemplateViberCardButton}>
                                <p>{jinjaButtonObject.value}</p>
                            </div>
                        )}
                    </div>
                }
            </div>
            <CreativeCardFooter {...{ template, isShowChainButton, isShowUsage, redirectPath }}/>
        </CreativeCardWrapper>
    )
};

ViberCard.propTypes = {
    template: PropTypes.object.isRequired,
    templateId: PropTypes.string,
    templateType: PropTypes.string,
    isShowChainButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string
};
ViberCard.defaultProps = {
    isShowUsage: true,
    containerClassName: ''
};
