import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';

export const TemplatePreviewViber = ({ onClickCallback, preview, imageSource, buttonText, templateName }) => (
    <>
        <div className={styles.previewActionViberTemplateWrapper} onClick={onClickCallback}>
            <pre className={styles.previewActionViberTemplateBody} dangerouslySetInnerHTML={{ __html: preview }}/>

            {imageSource && <img className={styles.previewActionViberTemplateImage} src={imageSource} alt='viber_template_image'/>}
            {buttonText && (
                <div className={styles.previewActionViberTemplateButton}>
                    <p>{buttonText}</p>
                </div>
            )}
        </div>
        <p className={styles.previewActionViberTemplateTitle}>{templateName}</p>
    </>
);

TemplatePreviewViber.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    preview: PropTypes.string.isRequired,
    imageSource: PropTypes.string,
    buttonText: PropTypes.string,
    templateName: PropTypes.string.isRequired
}
TemplatePreviewViber.defaultProps = {
    imageSource: '',
    buttonText: '',
    templateName: ''
}
