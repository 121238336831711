import React from 'react';
import PropTypes from 'prop-types'
import { WebPushBody } from 'components/Cards/CreativeCards/CreativeCardPreviewBody/WebPushBody';
import styles from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/TemplatePreview/TemplatePreview.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const TemplatePreviewWebPush = ({ onClickCallback, jinja_fields, templateName }) => (
    <div onClick={onClickCallback} className={cx(styles.templatePreview, styles.webPushTemplatePreview)}>
        <div className={styles.previewActionWebPush}>
            <WebPushBody jinja_fields={jinja_fields}/>
        </div>
        <p className={styles.templatePreviewTitle}>{templateName}</p>
    </div>
);

TemplatePreviewWebPush.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    jinja_fields: PropTypes.array,
    templateName:  PropTypes.string.isRequired,
};
TemplatePreviewWebPush.defaultProps = {
    templateName: ''
};
