import { map, eq } from 'lodash';
import { conditionExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/settings/defaultConditionSettings';
import { attributeFilterExample } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/settings/defaultAttributeFilterSettings';

const {
    _options,
    _eventValues,
    _selectedTimeInterval,
    _selectedTimeInterval: {_intervalType },
    _attributeFilter,
    _clientAttributeValues,
    _metricAttributeValues
} = conditionExample;

const {
    eventAttributeName,
    comparisonValue,
    comparisonOperator
} = attributeFilterExample;

const updatedIntervalType = map(_intervalType, interval => {
    return eq(interval.id, 'lastTime')
        ? { ...interval, isChecked: true }
        : { ...interval, isChecked: false }
});

const updatedConditionOptions = map(_options, interval => {
    return eq(interval.id, 'event')
        ? { ...interval, isChecked: true }
        : { ...interval, isChecked: false }
});

export const simplifiedConditionSettings = [
    {
        name: 'MadePurchaseSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'order_item_event',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter,
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
    {
        name: 'AddToBasketSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'website_event',
                },
                actionSubName: {
                    ..._eventValues.actionSubName,
                    value: 'add',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter,
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
    {
        name: 'ViewedProductSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'website_event',
                },
                actionSubName: {
                    ..._eventValues.actionSubName,
                    value: 'detail',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter,
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
    {
        name: 'ReadEmailSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'communication_message_event',
                },
                actionSubName: {
                    ..._eventValues.actionSubName,
                    value: '',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter: [
                {
                    ...attributeFilterExample,
                    id: 1,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'message_type'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['email']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                },
                {
                    ...attributeFilterExample,
                    id: 2,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'status'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['opened']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                }
            ],
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
    {
        name: 'ReadViberSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'communication_message_event',
                },
                actionSubName: {
                    ..._eventValues.actionSubName,
                    value: '',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter: [
                {
                    ...attributeFilterExample,
                    id: 1,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'message_type'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['viber']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                },
                {
                    ...attributeFilterExample,
                    id: 2,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'status'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['opened']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                }
            ],
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
    {
        name: 'ReadSmsSilmplifiedSettings',
        selected: false,
        condition: {
            id: 1,
            show: true,
            conditionName: 'NewConditionName',
            _options: updatedConditionOptions,
            _eventValues: {
                ..._eventValues,
                actionName: {
                    ..._eventValues.actionName,
                    value: 'communication_message_event',
                },
                actionSubName: {
                    ..._eventValues.actionSubName,
                    value: '',
                },
                aggregationComparisonOperator: {
                    ..._eventValues.aggregationComparisonOperator,
                    value: '>=',
                },
                aggregationComparisonValue: {
                    ..._eventValues.aggregationComparisonValue,
                    value: '1',
                },
            },
            _selectedTimeInterval: {
                ..._selectedTimeInterval,
                _intervalType: updatedIntervalType
            },
            _attributeFilter: [
                {
                    ...attributeFilterExample,
                    id: 1,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'message_type'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['sms']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                },
                {
                    ...attributeFilterExample,
                    id: 2,
                    show: false,
                    eventAttributeName: {
                        ...eventAttributeName,
                        value: 'status'
                    },
                    comparisonValue: {
                        ...comparisonValue,
                        value: ['opened']
                    },
                    comparisonOperator: {
                        ...comparisonOperator,
                        value: 'EQUAL'
                    }
                }
            ],
            _clientAttributeValues,
            _metricAttributeValues
        }
    },
];

export const defaultSimplifiedTimeIntervalOptions = [
    {
        value: 'hours',
        name: 'hoursTimeInterval'
    },
    {
        value: 'days',
        name: 'daysTimeInterval'
    },
];

export const defaultSimplifiedTimeIntervalSettings = {
    countTimeIntervalNumber: {
        value: '',
        required: true
    },
    countTimeIntervalType: {
        value: 'days',
        required: true
    }
};
