import {
    TOGGLE_RANGE_CALENDAR,
    TOGGLE_DATE_CALENDAR,
    CLEAR_DATE_CALENDAR_DATA,
    TOGGLE_RANGE_CALENDAR_METRICS,
    CLOSE_RANGE_CALENDAR_METRICS,
    OPEN_RANGE_PICKER_CALENDAR,
    CLOSE_RANGE_PICKER_CALENDAR,
    UPDATE_RANGE_PICKER_CALENDAR_DATA,
    CLEAR_RANGE_PICKER_CALENDAR
} from 'store/types';

export const toggleRangeCalendar = data => dispatch =>
    dispatch({
        type: TOGGLE_RANGE_CALENDAR,
        payload: { data:  { open: true, startDate: null, endDate: null, ...data } }
    });

export const toggleRangeCalendarMetrics = data => dispatch =>
    dispatch({
        type: TOGGLE_RANGE_CALENDAR_METRICS,
        payload: { data:  { open: true, startDate: null, endDate: null, ...data } }
    });

export const closeRangeCalendarMetrics = () => ({
    type: CLOSE_RANGE_CALENDAR_METRICS
});

export const toggleDateCalendar = data => dispatch =>
    dispatch({
        type: TOGGLE_DATE_CALENDAR,
        payload: { data:  { open: true, date: null, ...data } }
    });
export const clearDateCalendarData = () => dispatch =>
    dispatch({
        type: CLEAR_DATE_CALENDAR_DATA,
        payload: { data:  { open: false, date: null } }
    });

export const openRangePickerCalendar = (data = {}) => dispatch =>
    dispatch({
        type: OPEN_RANGE_PICKER_CALENDAR,
        payload: {
            data
        }
    });

export const closeRangePickerCalendar = () => dispatch =>
    dispatch({
        type: CLOSE_RANGE_PICKER_CALENDAR,
    });

export const updateRangePickerCalendarData = (data = {}) => dispatch =>
    dispatch({
        type: UPDATE_RANGE_PICKER_CALENDAR_DATA,
        payload: { data }
    })

export const clearRangePickerCalendar = () => dispatch =>
    dispatch({
        type: CLEAR_RANGE_PICKER_CALENDAR,
    });

