import { map } from 'lodash';
import { defaultWebsiteBannerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultWebsiteBannerSettings';
import { urlsExample } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { StringUse } from 'lib/StringUse';
import { DateUse } from 'lib/DateUse';

const {
    values: { communicationsMaxCount, countTimeIntervalNumber, countTimeIntervalType, impressionsNumber, consentCategory },
    displayDate,
} = defaultWebsiteBannerSettings();

export const websiteBuilderDecompiler = actionOptions => {
    const {
        max_frequency,
        display_counter,
        max_display_date,
        template_unique_id,
        consent_category_id,
        url_rules_list
    } = actionOptions;

    const { show, url } = urlsExample;

    const urls = map(url_rules_list, (value, index) => {
        return {
            id: index + 1,
            show,
            url: {
                ...url,
                value: StringUse.contains(value, ['http://', 'https://'])
                    ? value
                    : 'https://' + value
            }
        }
    });

    const localDateTime = max_display_date
        ? DateUse.convertUTCToLocal(max_display_date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
        : null

    return {
        values: {
            communicationsMaxCount: {
                ...communicationsMaxCount,
                value: StringUse.falseyReturnEmptyString(max_frequency?.n_communications, 'string'),
            },
            countTimeIntervalNumber: {
                ...countTimeIntervalNumber,
                value: StringUse.falseyReturnEmptyString(max_frequency?.period_number, 'string'),
            },
            countTimeIntervalType: {
                ...countTimeIntervalType,
                value: max_frequency?.period_type ? max_frequency?.period_type : 'minutes',
            },
            impressionsNumber: {
                ...impressionsNumber,
                value: StringUse.falseyReturnEmptyString(display_counter, 'string')
            },
            consentCategory: {
                ...consentCategory,
                value: StringUse.falseyReturnEmptyString(consent_category_id, 'string')
            }
        },
        displayDate: {
            ...displayDate,
            value: localDateTime
        },
        urls,
        templateUniqueId: template_unique_id,
    }
};
