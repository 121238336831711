import { compact, find, forEach, isEmpty, map } from 'lodash';
import { Validation } from 'validations/Validation';
import { attributeFilterControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/attributeFilterControl';
import { ObjectUse } from 'lib/ObjectUse';
import { conditionControl } from 'pages/Communications/ScenarioSetupPage/actionControls/utils/condition/conditionControl';

const actionNameRules = {
    value: 'required'
};

const productAttributeFilterRules = {
    columnNameOfProductFromEvent: 'required',
    columnNameFromEvent: 'required',
    columnComparisonOperator: 'required',
    columnNameFromCatalog: 'required|regex:/^[a-z0-9]+$/i',
};

const eventValidation = (values, attributeFilter) => {
    return () => {
        const { actionName } = values;

        const actionNameResult = Validation.check(actionName, actionNameRules, {
            'required.value': 'InputValidationRequiredMessage',
        }, 'value');

        const attributeFilterResult = attributeFilterControl(attributeFilter);

        const isActionHasErrors = actionNameResult.fails || !isEmpty(attributeFilterResult);

        return {
            isShowWarningActionMessage: isActionHasErrors,
            errors: {
                ...ObjectUse.createSeveralObjects([
                    actionNameResult.firstError,
                    !isEmpty(attributeFilterResult) ? attributeFilterResult : null
                ], ['actionName', 'attributeFilter'])
            }
        }
    }
};

export const clientValidation = condition => {
    return () => {
        const conditionsResult = conditionControl(condition);

        const isConditionsHasErrors = !!compact(map(conditionsResult, report => report.validation.fails)).length;

        return {
            isShowWarningActionMessage: isConditionsHasErrors,
            errors: {
                ...ObjectUse.createObject(
                    isConditionsHasErrors ? conditionsResult : null,
                    // ArrayUse.recast(conditionsResult, { accepted: arr => arr, rejected: null }),
                    'conditionsErrors'
                )
            }
        }
    }
};

const productValidation = productAttributeFilter => {
    return () => {

        let productAttributeFilterErrors = {
            columnNameOfProductFromEvent: {
                id: []
            },
            columnNameFromEvent: {
                id: []
            },
            columnComparisonOperator: {
                id: []
            },
            columnNameFromCatalog: {
                errorNames: {

                },
                id: []
            }
        }

        forEach(productAttributeFilter, ({ id, columnNameOfProductFromEvent, columnNameFromEvent, columnComparisonOperator, columnNameFromCatalog }) => {
            const validation = Validation.checkSome({
                columnNameOfProductFromEvent: columnNameOfProductFromEvent.value,
                columnNameFromEvent: columnNameFromEvent.value,
                columnComparisonOperator: columnComparisonOperator.value,
                columnNameFromCatalog: columnNameFromCatalog.value
            }, productAttributeFilterRules, {
                'required.columnNameOfProductFromEvent': 'InputValidationRequiredMessage',
                'required.columnNameFromEvent': 'InputValidationRequiredMessage',
                'required.columnComparisonOperator': 'InputValidationRequiredMessage',
                'required.columnNameFromCatalog': 'InputValidationRequiredMessage',
                'regex.columnNameFromCatalog': 'InputUnacceptableSymbolsValidationMessage',
            }, ['columnNameOfProductFromEvent', 'columnNameFromEvent', 'columnComparisonOperator', 'columnNameFromCatalog'])

            if (validation.fails) {
                const columnNameOfProductFromEventError = validation.firstErrors.columnNameOfProductFromEvent;
                const columnNameFromEventError = validation.firstErrors.columnNameFromEvent;
                const columnComparisonOperatorError = validation.firstErrors.columnComparisonOperator;
                const columnNameFromCatalogError = validation.firstErrors.columnNameFromCatalog;

                if (columnNameOfProductFromEventError) {
                    productAttributeFilterErrors.columnNameOfProductFromEvent = {
                        error: columnNameOfProductFromEventError,
                        id: [...productAttributeFilterErrors.columnNameOfProductFromEvent.id, id]
                    }
                }

                if (columnNameFromEventError) {
                    productAttributeFilterErrors.columnNameFromEvent = {
                        error: columnNameFromEventError,
                        id: [...productAttributeFilterErrors.columnNameFromEvent.id, id]
                    }
                }

                if (columnComparisonOperatorError) {
                    productAttributeFilterErrors.columnComparisonOperator = {
                        error: columnComparisonOperatorError,
                        id: [...productAttributeFilterErrors.columnComparisonOperator.id, id]
                    }
                }

                if (columnNameFromCatalogError) {
                    productAttributeFilterErrors.columnNameFromCatalog = {
                        errors: {
                            ...productAttributeFilterErrors.columnNameFromCatalog.errors,
                            [id]: columnNameFromCatalogError
                        },
                        id: [...productAttributeFilterErrors.columnNameFromCatalog.id, id]
                    }
                }

            }
        });

        const isProductAttributeFilterHasErrors = !!compact([
            productAttributeFilterErrors.columnNameOfProductFromEvent.id.length,
            productAttributeFilterErrors.columnNameFromEvent.id.length,
            productAttributeFilterErrors.columnComparisonOperator.id.length,
            productAttributeFilterErrors.columnNameFromCatalog.id.length
        ]).length

        return {
            isShowWarningActionMessage: isProductAttributeFilterHasErrors,
            errors: {
                ...ObjectUse.createObject(productAttributeFilterErrors, 'productAttributeFilter')
            }
        }
    }
};


export const conditionalSplitControl = action => {

    const {
        attributeFilter,
        condition,
        productAttributeFilter,
        splitType,
        values
    } = action.settings;

    const controlTypes = {
        event: eventValidation(values, attributeFilter),
        client: clientValidation(condition),
        product: productValidation(productAttributeFilter)
    };

    const getSelectedType = find(splitType, { active: true });

    return {
        ...action,
        ...controlTypes[getSelectedType.id]()
    }
}
