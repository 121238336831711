import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.css';
import { eq, map } from 'lodash';
import { usePagination, DOTS } from './usePagination';
import { LeftFirstPageIcon } from 'assets/icons/LeftFirstPageIcon';
import { LeftPageIcon } from 'assets/icons/LeftPageIcon';
import { RightPageIcon } from 'assets/icons/RightPageIcon';
import { RightLastPageIcon } from 'assets/icons/RightLastPageIcon';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Pagination = ({
    onClickFirstPage,
    onClickPreviousPage,
    onClickNextPage,
    onClickLastPage,
    onClickSetCurrentPage,
    isDisabledLeftControlButtons,
    isDisabledRightControlButtons,
    currentPage,
    rowsCount,
    pageSize,
    siblingCount
}) => {

    const paginationRange = usePagination({
        currentPage,
        rowsCount,
        siblingCount,
        pageSize
    });

    if (eq(currentPage, 0) || paginationRange.length < 2) {
        return null;
    }

    return (
        <nav className={styles.container}>
            <button onClick={onClickFirstPage} disabled={isDisabledLeftControlButtons}>
                <LeftFirstPageIcon/>
            </button>
            <button onClick={onClickPreviousPage} disabled={isDisabledLeftControlButtons}>
                <LeftPageIcon/>
            </button>

            {map(paginationRange, ({ value, id }) =>
                eq(value, DOTS)
                    ? <button key={id} className={styles.dots} disabled>
                        &#8230;
                    </button>
                    : <button
                        key={id}
                        className={cx({ selected: eq(value, currentPage) })}
                        onClick={onClickSetCurrentPage(value)}
                    >
                        {value}
                    </button>
            )}

            <button onClick={onClickNextPage} disabled={isDisabledRightControlButtons}>
                <RightPageIcon/>
            </button>
            <button onClick={onClickLastPage} disabled={isDisabledRightControlButtons}>
                <RightLastPageIcon/>
            </button>
        </nav>
    );
};

Pagination.propTypes = {
    onClickFirstPage: PropTypes.func.isRequired,
    onClickPreviousPage: PropTypes.func.isRequired,
    onClickNextPage: PropTypes.func.isRequired,
    onClickLastPage: PropTypes.func.isRequired,
    onClickSetCurrentPage: PropTypes.func.isRequired,
    isDisabledLeftControlButtons: PropTypes.bool.isRequired,
    isDisabledRightControlButtons: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    rowsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    siblingCount: PropTypes.number,
};
Pagination.defaultProps = {
    siblingCount: 1
};
