// API
export const API_REQUEST_FAIL_WITH_STATUS = 'API_REQUEST_FAIL_WITH_STATUS';
export const CLEAR_API_REQUEST_FAIL_WITH_STATUS = 'CLEAR_API_REQUEST_FAIL_WITH_STATUS';
// Auth
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

// Calendars
export const TOGGLE_RANGE_CALENDAR = 'TOGGLE_RANGE_CALENDAR';
export const TOGGLE_RANGE_CALENDAR_METRICS = 'TOGGLE_RANGE_CALENDAR_METRICS';
export const TOGGLE_DATE_CALENDAR = 'TOGGLE_DATE_CALENDAR';
export const CLEAR_DATE_CALENDAR_DATA = 'CLEAR_DATE_CALENDAR_DATA';
export const CLOSE_RANGE_CALENDAR_METRICS = 'CLOSE_RANGE_CALENDAR_METRICS';
export const OPEN_RANGE_PICKER_CALENDAR = 'OPEN_RANGE_PICKER_CALENDAR';
export const CLOSE_RANGE_PICKER_CALENDAR = 'CLOSE_RANGE_PICKER_CALENDAR';
export const UPDATE_RANGE_PICKER_CALENDAR_DATA = 'UPDATE_RANGE_PICKER_CALENDAR_DATA';
export const CLEAR_RANGE_PICKER_CALENDAR = 'CLEAR_RANGE_PICKER_CALENDAR';

// Communications
export const FETCH_COMMUNICATION_LIST = 'FETCH_COMMUNICATION_LIST';
export const SET_COMMUNICATION_LIST = 'SET_COMMUNICATION_LIST';

export const FETCH_COMMUNICATION_PROVIDER_LIST = 'FETCH_COMMUNICATION_PROVIDER_LIST';
export const SET_COMMUNICATION_PROVIDER_LIST = 'SET_COMMUNICATION_PROVIDER_LIST';

// Consent categories
export const FETCH_CONSENT_CATEGORIES_LIST = 'FETCH_CONSENT_CATEGORIES_LIST';
export const SET_CONSENT_CATEGORIES_LIST = 'SET_CONSENT_CATEGORIES_LIST';
export const FETCH_DELETE_CONSENT_CATEGORY = 'FETCH_DELETE_CONSENT_CATEGORY';
export const SET_DELETE_CONSENT_CATEGORY = 'SET_DELETE_CONSENT_CATEGORY';
export const FETCH_CREATE_CONSENT_CATEGORY = 'FETCH_CREATE_CONSENT_CATEGORY';
export const SET_CREATE_CONSENT_CATEGORY = 'SET_CREATE_CONSENT_CATEGORY';
export const FETCH_UPDATE_CONSENT_CATEGORY = 'FETCH_UPDATE_CONSENT_CATEGORY';
export const SET_UPDATE_CONSENT_CATEGORY = 'SET_UPDATE_CONSENT_CATEGORY';
export const SET_SELECTED_CONSENT_CATEGORY = 'SET_SELECTED_CONSENT_CATEGORY';

// Images
export const FETCH_UPLOADED_IMAGES = 'FETCH_UPLOADED_IMAGES';
export const SET_UPLOADED_IMAGES = 'SET_UPLOADED_IMAGES';

export const FETCH_IMAGES_FOLDERS_LIST = 'FETCH_IMAGES_FOLDERS_LIST';
export const SET_IMAGES_FOLDERS_LIST = 'SET_IMAGES_FOLDERS_LIST';
export const SET_CURRENT_IMAGES_FOLDER_FROM_LIST = 'SET_CURRENT_IMAGES_FOLDER_FROM_LIST';

export const FETCH_CREATE_IMAGES_FOLDER = 'FETCH_CREATE_IMAGES_FOLDER';
export const SET_CREATE_IMAGES_FOLDER = 'SET_CREATE_IMAGES_FOLDER';

export const FETCH_DELETE_IMAGE_FOLDER = 'FETCH_DELETE_IMAGE_FOLDER';
export const SET_DELETE_IMAGE_FOLDER = 'SET_DELETE_IMAGE_FOLDER';
export const FETCH_DELETE_IMAGE = 'FETCH_DELETE_IMAGE';
export const SET_DELETE_IMAGE = 'SET_DELETE_IMAGE';
export const FETCH_UPDATE_FOLDER_NAME = 'FETCH_UPDATE_FOLDER_NAME';
export const SET_UPDATE_FOLDER_NAME = 'SET_UPDATE_FOLDER_NAME';
export const CLEAR_SELECTED_IMAGE_DATA = 'CLEAR_SELECTED_IMAGE_DATA';
export const CLEAR_IMAGES_LIST = 'CLEAR_IMAGES_LIST';
export const SET_CURRENT_IMAGES_FOLDER = 'SET_CURRENT_IMAGES_FOLDER';
export const CLEAR_CURRENT_IMAGES_FOLDER = 'CLEAR_CURRENT_IMAGES_FOLDER';
export const SET_SELECTED_IMAGES_FROM_PC = 'SET_SELECTED_IMAGES_FROM_PC';
export const REMOVE_SELECTED_IMAGE_FROM_PC = 'REMOVE_SELECTED_IMAGE_FROM_PC';
export const CLEAR_SELECTED_IMAGES_FROM_PC = 'CLEAR_SELECTED_IMAGES_FROM_PC';

// Recommendations
export const FETCH_RECOMMENDATION_UNIT_METRICS = 'FETCH_RECOMMENDATION_UNIT_METRICS';
export const SET_RECOMMENDATION_UNIT_METRICS = 'GET_RECOMMENDATION_UNIT_METRICS';

export const FETCH_RECOMMENDATION_UNIT_LIST = 'FETCH_RECOMMENDATION_UNIT_LIST';
export const FETCH_UPDATE_RECOMMENDATION_UNIT = 'FETCH_UPDATE_RECOMMENDATION_UNIT';
export const FETCH_CREATE_RECOMMENDATION_UNIT = 'FETCH_CREATE_RECOMMENDATION_UNIT';
export const FETCH_DELETE_RECOMMENDATION_UNIT = 'FETCH_DELETE_RECOMMENDATION_UNIT';

export const UPDATE_RECOMMENDATION_UNIT_LIST = 'UPDATE_RECOMMENDATION_UNIT_LIST';

export const FETCH_ADD_AB_GROUP_RECOMMENDATION_UNIT = 'FETCH_ADD_AB_GROUP_RECOMMENDATION_UNIT';
export const FETCH_DELETE_AB_GROUP_RECOMMENDATION_UNIT = 'FETCH_DELETE_AB_GROUP_RECOMMENDATION_UNIT';
export const FETCH_UPDATE_AB_GROUP_RECOMMENDATION_UNIT = 'FETCH_UPDATE_AB_GROUP_RECOMMENDATION_UNIT';

export const FETCH_RECOMMENDATION_PRODUCT = 'FETCH_RECOMMENDATION_PRODUCT';
export const SET_RECOMMENDATION_PRODUCT = 'SET_RECOMMENDATION_PRODUCT';

// Scenarios
export const FETCH_SCENARIOS_LIST = 'FETCH_SCENARIOS_LIST';
export const SET_SCENARIOS_LIST = 'SET_SCENARIOS_LIST';

export const FETCH_SCENARIO = 'FETCH_SCENARIO';
export const SET_SCENARIO = 'SET_SCENARIO';

export const FETCH_SCENARIOS_METRICS = 'FETCH_SCENARIOS_METRICS';
export const SET_SCENARIOS_METRICS = 'SET_SCENARIOS_METRICS';

export const FETCH_SCENARIO_METRIC = 'FETCH_SCENARIO_METRIC';
export const SET_SCENARIO_METRIC = 'SET_SCENARIO_METRIC';

export const FETCH_SCENARIO_START = 'FETCH_SCENARIO_START';
export const SET_SCENARIO_START = 'SET_SCENARIO_START';

export const FETCH_CURRENT_SCENARIO_START = 'FETCH_CURRENT_SCENARIO_START';
export const SET_CURRENT_SCENARIO_START = 'SET_CURRENT_SCENARIO_START';

export const FETCH_SCENARIO_STOP = 'FETCH_SCENARIO_STOP';
export const SET_SCENARIO_STOP = 'SET_SCENARIO_STOP';

export const FETCH_CURRENT_SCENARIO_STOP = 'FETCH_CURRENT_SCENARIO_STOP';
export const SET_CURRENT_SCENARIO_STOP = 'SET_CURRENT_SCENARIO_STOP';

export const FETCH_SCENARIO_COPY = 'FETCH_SCENARIO_COPY';
export const SET_SCENARIO_COPY = 'SET_SCENARIO_COPY';

export const FETCH_SCENARIO_DELETE = 'FETCH_SCENARIO_DELETE';
export const SET_SCENARIO_DELETE = 'SET_SCENARIO_DELETE';

export const FETCH_SCENARIOS_SEARCHED = 'FETCH_SCENARIOS_SEARCHED';
export const SET_SCENARIOS_SEARCHED = 'SET_SCENARIOS_SEARCHED';

export const FETCH_SCENARIOS_FILTERED = 'FETCH_SCENARIOS_FILTERED';
export const SET_SCENARIOS_FILTERED = 'SET_SCENARIOS_FILTERED';

export const FETCH_SCENARIO_CREATE = 'FETCH_SCENARIO_CREATE';
export const SET_SCENARIO_CREATE = 'SET_SCENARIO_CREATE';

export const FETCH_SCENARIO_UPDATE = 'FETCH_SCENARIO_UPDATE';
export const SET_SCENARIO_UPDATE = 'SET_SCENARIO_UPDATE';

export const FETCH_SILENT_SCENARIO_UPDATE = 'FETCH_SILENT_SCENARIO_UPDATE';

export const FETCH_SCENARIO_NAME_UPDATE = 'FETCH_SCENARIO_NAME_UPDATE';
export const SET_SCENARIO_NAME_UPDATE = 'SET_SCENARIO_NAME_UPDATE';
export const CLEAR_SCENARIO_DATA = 'CLEAR_SCENARIO_DATA';

export const SET_LIBRARY_SCENARIOS_LIST = 'SET_LIBRARY_SCENARIOS_LIST';
export const FETCH_LIBRARY_SCENARIOS_LIST = 'FETCH_LIBRARY_SCENARIOS_LIST';

// Scenarios actions
export const ADD_ARROW_CHAIN = 'ADD_ARROW_CHAIN';
export const ADD_ACTION = 'ADD_ACTION';
export const ADD_ACTION_OUTPUT_CHECKPOINT = 'ADD_ACTION_OUTPUT_CHECKPOINT';
export const CLEAR_ACTION_OUTPUT_CHECKPOINT = 'CLEAR_ACTION_OUTPUT_CHECKPOINT';
export const ADD_ACTION_INPUT_CHECKPOINT = 'ADD_ACTION_INPUT_CHECKPOINT';
export const CHANGE_ACTION_POSITION = 'CHANGE_ACTION_POSITION';
export const CHANGE_ACTION_MODAL_STATUS = 'CHANGE_ACTION_MODAL_STATUS';
export const REMOVE_ACTION_PROCESS = 'REMOVE_ACTION_PROCESS';
export const COPY_ACTION_PROCESS = 'COPY_ACTION_PROCESS';
export const REMOVE_ARROW_LINE = 'REMOVE_ARROW_LINE';
export const SET_ACTIONS_CHAIN_LIST = 'SET_ACTIONS_CHAIN_LIST';
export const SET_VALIDATED_ACTIONS_CHAIN_LIST = 'SET_VALIDATED_ACTIONS_CHAIN_LIST';
export const SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT = 'SET_ACTIONS_CHAIN_LIST_VALIDATION_REPORT';
export const SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT = 'SET_CURRENT_SCENARIO_ACTIONS_CHAIN_LIST_VALIDATION_REPORT';
export const SET_ACTIONS_CANCEL_CHANGES = 'SET_ACTIONS_CANCEL_CHANGES';
export const SET_ACTIONS_EDIT_BASE_COPY = 'SET_ACTIONS_EDIT_BASE_COPY';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SAVE_SIDEBAR_DATA = 'SAVE_SIDEBAR_DATA';
export const SET_AUTOSAVE_SIDEBAR_DATA = 'SET_AUTOSAVE_SIDEBAR_DATA';
export const PRE_SAVE_SIDEBAR_DATA = 'PRE_SAVE_SIDEBAR_DATA';
export const CLOSE_ACTION_LIST_WARNING = 'CLOSE_ACTION_LIST_WARNING';
export const SELECT_ACTION_TEMPLATE = 'SELECT_ACTION_TEMPLATE';
export const CLEAR_SCENARIO_ACTIONS_DATA = 'CLEAR_SCENARIO_ACTIONS_DATA';
export const RESET_SCENARIO_ACTION_ERRORS = 'RESET_SCENARIO_ACTION_ERRORS';
export const UPDATE_SCENARIO_ACTION = 'UPDATE_SCENARIO_ACTION';


// Templates
export const FETCH_TEMPLATES_LIST = 'FETCH_TEMPLATES_LIST';
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST';
export const FETCH_MASTERS_LIST = 'FETCH_MASTERS_LIST';
export const SET_MASTERS_LIST = 'SET_MASTERS_LIST';
export const CLEAR_CURRENT_MASTER = 'CLEAR_CURRENT_MASTER';
export const CLEAR_TEMPLATES_LIST = 'CLEAR_TEMPLATES_LIST';

export const FETCH_SCENARIO_TEMPLATE_METRICS = 'FETCH_SCENARIO_TEMPLATE_METRICS';
export const SET_SCENARIO_TEMPLATE_METRICS = 'SET_SCENARIO_TEMPLATE_METRICS';
export const CLEAR_CURRENT_SCENARIO_TEMPLATES = 'CLEAR_CURRENT_SCENARIO_TEMPLATES';

export const SET_SCENARIO_TEMPLATES_LIST = 'SET_SCENARIO_TEMPLATES_LIST';

export const CLEAR_CURRENT_EDIT_TEMPLATE = 'CLEAR_CURRENT_EDIT_TEMPLATE';
export const CLEAR_CURRENT_EDIT_TEMPLATE_METRICS = 'CLEAR_CURRENT_EDIT_TEMPLATE_METRICS';

export const CANCEL_CHANGES_TEMPLATE = 'CANCEL_CHANGES_TEMPLATE';

export const FETCH_TEMPLATE_UPDATE = 'FETCH_TEMPLATE_UPDATE';
export const SET_TEMPLATE_UPDATE = 'SET_TEMPLATE_UPDATE';

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_PREVIEW_TEMPLATE = 'SET_PREVIEW_TEMPLATE';

export const CHANGE_TEMPLATE_NAME = 'CHANGE_TEMPLATE_NAME';

export const FETCH_TEMPLATE_DELETE = 'FETCH_TEMPLATE_DELETE';
export const SET_TEMPLATE_DELETE = 'SET_TEMPLATE_DELETE';

export const SET_METRICS_TEMPLATE = 'SET_METRICS_TEMPLATE';
export const FETCH_METRICS_TEMPLATE = 'FETCH_METRICS_TEMPLATE';

export const FETCH_COPY_TEMPLATE = 'FETCH_COPY_TEMPLATE';
export const SET_COPY_TEMPLATE = 'SET_COPY_TEMPLATE';
export const SET_COPY_MASTER = 'SET_COPY_MASTER';

export const FETCH_TEMPLATE_CREATE = 'FETCH_TEMPLATE_CREATE';
export const SET_TEMPLATE_CREATE = 'SET_TEMPLATE_CREATE';

export const FETCH_MASTER_CREATE = 'FETCH_MASTER_CREATE';
export const SET_MASTER_CREATE = 'SET_MASTER_CREATE';

export const FETCH_CONSENT_TEMPLATE = 'FETCH_CONSENT_TEMPLATE';
export const SET_CONSENT_TEMPLATE = 'SET_CONSENT_TEMPLATE';
export const CANCEL_EDIT_CONSENT_TEMPLATE = 'CANCEL_EDIT_CONSENT_TEMPLATE';

export const FETCH_TEMPLATES_SEARCHED = 'FETCH_TEMPLATES_SEARCHED';
export const SET_TEMPLATES_SEARCHED = 'SET_TEMPLATES_SEARCHED';

export const FETCH_TEMPLATES_FILTERED = 'FETCH_TEMPLATES_FILTERED';
export const SET_TEMPLATES_FILTERED = 'SET_TEMPLATES_FILTERED';

export const SET_IS_TEMPLATE_HAS_CHANGES = 'SET_IS_TEMPLATE_HAS_CHANGES';
export const FETCH_TEMPLATE_CLIENTS_LIST = 'FETCH_TEMPLATE_CLIENTS_LIST';
export const SET_TEMPLATE_CLIENTS_LIST = 'SET_TEMPLATE_CLIENTS_LIST';
export const CLEAR_TEMPLATE_CLIENTS_LIST = 'CLEAR_TEMPLATE_CLIENTS_LIST';
export const FETCH_TEST_TEMPLATE = 'FETCH_TEST_TEMPLATE';
export const SET_TEST_TEMPLATE = 'SET_TEST_TEMPLATE';

// Segments
export const FETCH_SEGMENTS_LIST = 'FETCH_SEGMENTS_LIST';
export const FETCH_SILENT_UPDATE_SEGMENTS_LIST = 'FETCH_SILENT_UPDATE_SEGMENTS_LIST';
export const FETCH_CREATE_SEGMENT = 'FETCH_CREATE_SEGMENT';
export const FETCH_UPDATE_SEGMENT = 'FETCH_UPDATE_SEGMENT';
export const FETCH_SEGMENT = 'FETCH_SEGMENT';
export const FETCH_SEGMENT_CLIENTS_LIST = 'FETCH_SEGMENT_CLIENTS_LIST';
export const FETCH_SEGMENT_CLIENT = 'FETCH_SEGMENT_CLIENT';
export const FETCH_SEGMENT_CLIENT_EVENT = 'FETCH_SEGMENT_CLIENT_EVENT';
export const FETCH_SEGMENT_EVENTS_CLIENT_LIST = 'FETCH_SEGMENT_EVENTS_CLIENT_LIST';
export const FETCH_SEGMENTS_TEMPLATE_LIST = 'FETCH_SEGMENTS_TEMPLATE_LIST';
export const FETCH_UPDATE_CLIENT_CONSENTS = 'FETCH_UPDATE_CLIENT_CONSENTS';
export const SET_UPDATE_CLIENT_CONSENTS = 'SET_UPDATE_CLIENT_CONSENTS';

export const SET_SEGMENTS_LIST = 'SET_SEGMENTS_LIST';
export const SET_SILENT_UPDATE_SEGMENTS_LIST = 'SET_SILENT_UPDATE_SEGMENTS_LIST ';
export const SET_SEGMENTS_TEMPLATE = 'SET_SEGMENTS_TEMPLATE';
export const SET_SEGMENT = 'SET_SEGMENT';
export const SET_SEGMENT_CLIENTS_LIST = 'SET_SEGMENT_CLIENTS_LIST';
export const SET_SEGMENT_CLIENT = 'SET_SEGMENT_CLIENT';
export const SET_SEGMENT_CLIENT_EVENTS_LIST = 'SET_SEGMENT_CLIENT_EVENTS_LIST';
export const SET_SEGMENT_CLIENT_EVENT = 'SET_SEGMENT_CLIENT_EVENT';
export const SET_EDIT_CONDITION = 'SET_EDIT_CONDITION';
export const SET_UPDATED_CONDITION = 'SET_UPDATED_CONDITION';
export const SET_NEW_CONDITION = 'SET_NEW_CONDITION';
export const SET_CLIENT_BY_ATTRIBUTES = 'SET_CLIENT_BY_ATTRIBUTES';
export const SET_MANUAL_SEGMENTS_LIST = 'SET_MANUAL_SEGMENTS_LIST';
export const SET_AUTOSAVE_CONDITION_SIDEBAR_DATA = 'SET_AUTOSAVE_CONDITION_SIDEBAR_DATA';

export const CANCEL_EDIT_CONDITION = 'CANCEL_EDIT_CONDITION';

export const TOGGLE_CONDITION_SIDEBAR = 'TOGGLE_CONDITION_SIDEBAR';

export const CLEAR_SEGMENT_CLIENT = 'CLEAR_SEGMENT_CLIENT';
export const CLEAR_SEGMENT_CLIENTS_LIST = 'CLEAR_SEGMENT_CLIENTS_LIST';
export const CLEAR_SEGMENT = 'CLEAR_SEGMENT';
export const CLEAR_CLIENT_BY_ATTRIBUTES = 'CLEAR_CLIENT_BY_ATTRIBUTES';
export const FETCH_DROPDOWN_FILTERS = 'FETCH_DROPDOWN_FILTERS';
export const SET_DROPDOWN_FILTERS = 'SET_DROPDOWN_FILTERS';

// Analytics
export const FETCH_PROBLEMS = 'FETCH_PROBLEMS';
export const SET_PROBLEMS = 'SET_PROBLEMS';

export const FETCH_METRICS_VIEW_DATA = 'FETCH_METRICS_VIEW_DATA';
export const SET_METRICS_VIEW_DATA = 'SET_METRICS_VIEW_DATA';
export const FETCH_METRICS_MAIN_PAGE_DATA = 'FETCH_METRICS_MAIN_PAGE_DATA';
export const SET_METRICS_MAIN_PAGE_DATA = 'SET_METRICS_MAIN_PAGE_DATA';
export const CLEAR_METRICS_VIEW_DATA = 'CLEAR_METRICS_VIEW_DATA';

export const FETCH_CURRENT_PROBLEM = 'FETCH_CURRENT_PROBLEM';
export const SET_CURRENT_PROBLEM = 'SET_CURRENT_PROBLEM';
export const CLEAR_CURRENT_PROBLEM = 'CLEAR_CURRENT_PROBLEM';

// IntegrationEvents
export const FETCH_ALL_INTEGRATION_EVENTS = 'FETCH_ALL_INTEGRATION_EVENTS';
export const SET_ALL_INTEGRATION_EVENTS = 'SET_ALL_INTEGRATION_EVENTS';
export const FETCH_CREATE_INTEGRATION_EVENT = 'FETCH_CREATE_INTEGRATION_EVENT';
export const SET_CREATE_INTEGRATION_EVENT = 'SET_CREATE_INTEGRATION_EVENT';
export const SET_FILTERED_ALL_INTEGRATION_EVENTS = 'SET_FILTERED_ALL_INTEGRATION_EVENTS';
export const FETCH_DELETE_INTEGRATION_EVENT = 'FETCH_DELETE_INTEGRATION_EVENT';
export const SET_DELETE_INTEGRATION_EVENT = 'SET_DELETE_INTEGRATION_EVENT';
export const FETCH_UPDATE_EVENT_SCHEMA_FIELDS = 'FETCH_UPDATE_EVENT_SCHEMA_FIELDS';
export const SET_UPDATE_EVENT_SCHEMA_FIELDS = 'SET_UPDATE_EVENT_SCHEMA_FIELDS';
export const FETCH_START_UPLOAD_CSV_TABLE = 'FETCH_START_UPLOAD_CSV_TABLE';
export const SET_START_UPLOAD_CSV_TABLE = 'SET_START_UPLOAD_CSV_TABLE';
export const SET_FAILURE_START_UPLOAD_CSV_TABLE = 'SET_FAILURE_START_UPLOAD_CSV_TABLE';
export const FETCH_GET_UPLOAD_CSV_TABLE_HISTORY = 'FETCH_GET_UPLOAD_CSV_TABLE_HISTORY';
export const SET_GET_UPLOAD_CSV_TABLE_HISTORY = 'SET_GET_UPLOAD_CSV_TABLE_HISTORY';
export const FETCH_GET_CONFIG = 'FETCH_GET_CONFIG';
export const SET_GET_CONFIG = 'SET_GET_CONFIG';

export const PUT_CSV_TABLE_DATA = 'PUT_CSV_TABLE_DATA';
export const SET_CSV_TABLE_COLUMN_COMPOUND = 'SET_CSV_TABLE_COLUMN_COMPOUND';
export const DELETE_CSV_TABLE_COLUMN_COMPOUND = 'DELETE_CSV_TABLE_COLUMN_COMPOUND';
export const RESET_DRAGGABLE_COMPOUNDS_KEYS = 'RESET_DRAGGABLE_COMPOUNDS_KEYS';
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const CLEAR_CURRENT_EVENT = 'CLEAR_CURRENT_EVENT';

// Policies
export const FETCH_FREQUENCY_POLICIES_LIST = 'FETCH_FREQUENCY_POLICIES_LIST';
export const SET_FREQUENCY_POLICIES_LIST = 'SET_FREQUENCY_POLICIES_LIST';
export const TOGGLE_SIDEBAR_POLICIES = 'TOGGLE_SIDEBAR_POLICIES';
export const ADD_NEW_FREQUENCY_POLICY = 'ADD_NEW_FREQUENCY_POLICY';
export const FETCH_UPDATING_FREQUENCY_POLICIES = 'FETCH_UPDATING_FREQUENCY_POLICIES';
