import React, { useState } from 'react';
import { eq, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styles from 'pages/Communications/ScenarioSetupPage/ScenarioSetupPage.module.css';
import animations from 'animations/FadeIn.module.css';
import Sidebar from 'react-sidebar';
import { ActionCodeEditor } from 'components/ScenarioSetup/ActionCodeEditor/ActionCodeEditor';
import { ActionCodeEditorContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames/bind';
import { addActionToEditField, autoSaveSidebarData } from 'store/actions/scenariosActions.action';
import { TriggerEventSidebar } from 'components/ScenarioSetup/ActionSidebars/TriggerEventSidebar';
import { RepeatTriggerSidebar } from 'components/ScenarioSetup/ActionSidebars/RepeatTriggerSidebar';
import { ScheduleTriggerSidebar } from 'components/ScenarioSetup/ActionSidebars/ScheduleTriggerSidebar';
import { WaitSidebar } from 'components/ScenarioSetup/ActionSidebars/WaitSidebar';
import { ABTestSidebar } from 'components/ScenarioSetup/ActionSidebars/ABTestSidebar';
import { FunnelSidebar } from 'components/ScenarioSetup/ActionSidebars/FunnelSidebar';
import { TailoredWaitSidebar } from 'components/ScenarioSetup/ActionSidebars/TailoredWaitSidebar';
import { SendAndPushContentSidebar } from 'components/ScenarioSetup/ActionSidebars/SendAndPushContentSidebar';
import { CustomWebHookSidebar } from 'components/ScenarioSetup/ActionSidebars/CustomWebHookSidebar';
import { ConditionalSplitSideBar } from 'components/ScenarioSetup/ActionSidebars/ConditionalSplitSidebar';
import { SqlTriggerSidebar } from 'components/ScenarioSetup/ActionSidebars/SqlTriggerSidebar';
import { defaultWaitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultWaitSettings';
import { defaultTriggerEventSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultTriggerEventSettings';
import { defaultTailoredWaitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultTailoredWaitSettings';
import { defaultSendContentSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultSendContentSettings';
import { defaultABTestSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultABTestSettings';
import { defaultFunnelSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultFunnelSettings';
import { defaultCustomWebHookSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultCustomWebHookSettings';
import { defaultRepeatTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultRepeatTriggerSettings';
import { defaultConditionalSplitSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultConditionalSplitSettings';
import { defaultScheduleTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultScheduleTriggerSettings';
import { defaultSqlTriggerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultSqlTriggerSettings';
import { defaultPushContentSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultPushContentSettings';
import { CustomDatePickerCalendar } from 'components/Calendars/DatePickerCalendar';
import { ScenarioSetup } from 'components/ScenarioSetup/ScenarioSetup';
import { defaultWebsiteBannerSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultWebsiteBannerSettings';
import { WebsiteBuilderSidebar } from 'components/ScenarioSetup/ActionSidebars/WebsiteBuilderSidebar';
import { EditSegmentSidebar } from 'components/ScenarioSetup/ActionSidebars/EditSegmentSidebar';
import { defaultEditSegmentSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultEditSegmentSettings';
import { updateRangePickerCalendarData } from 'store/actions/calendars.action';
import { GoogleAdsSideBar } from 'components/ScenarioSetup/ActionSidebars/GoogleAdsSidebar';
import { defaultGoogleAdsSettings } from 'components/ScenarioSetup/ActionSidebars/settings/defaultGoogleAdsSettings';
import { FacebookAdsSideBar } from 'components/ScenarioSetup/ActionSidebars/FacebookAdsSideBar';
import { RangePickerCalendar } from 'components/Calendars/RangePickerCalendar/RangePickerCalendar';

const cx = classNames.bind(styles);

export const ScenarioSetupPage = () => {
    const dispatch = useDispatch();

    const { actionsList } = useSelector(store => store.scenariosActions);
    const { actionSidebar: sidebar } = useSelector(store => store.scenariosActions);
    const {
        dateCalendar: { open: isDateCalendarOpen },
        rangePickerCalendar: { open: isRangePickerCalendarOpen, data }
    } = useSelector(store => store.calendars);

    const [actionCode, setActionCode] = useState('');

    const sidebarsTypes = {
        TriggerEvent: { sidebar: TriggerEventSidebar, defaultSettings: defaultTriggerEventSettings },
        RepeatTrigger: { sidebar: RepeatTriggerSidebar, defaultSettings: defaultRepeatTriggerSettings },
        ScheduleTrigger: { sidebar: ScheduleTriggerSidebar, defaultSettings: defaultScheduleTriggerSettings },
        SqlTrigger: { sidebar: SqlTriggerSidebar, defaultSettings: defaultSqlTriggerSettings },
        Wait: { sidebar: WaitSidebar, defaultSettings: defaultWaitSettings },
        TailoredWait: { sidebar: TailoredWaitSidebar, defaultSettings: defaultTailoredWaitSettings },
        ConditionalSplit: { sidebar: ConditionalSplitSideBar, defaultSettings: defaultConditionalSplitSettings },
        ABTestSplit: { sidebar: ABTestSidebar, defaultSettings: defaultABTestSettings },
        Funnel: { sidebar: FunnelSidebar, defaultSettings: defaultFunnelSettings },
        SendEmail: { sidebar: SendAndPushContentSidebar, defaultSettings: defaultSendContentSettings },
        SendSms: { sidebar: SendAndPushContentSidebar, defaultSettings: defaultSendContentSettings },
        SendViber: { sidebar: SendAndPushContentSidebar, defaultSettings: defaultSendContentSettings },
        CustomWebHook: { sidebar: CustomWebHookSidebar, defaultSettings: defaultCustomWebHookSettings },
        WebsiteBuilder: { sidebar: WebsiteBuilderSidebar, defaultSettings: defaultWebsiteBannerSettings },
        SendMobPush: { sidebar: SendAndPushContentSidebar, defaultSettings: defaultPushContentSettings },
        SendWebPush: { sidebar: SendAndPushContentSidebar, defaultSettings: defaultPushContentSettings },
        EditSegment: { sidebar: EditSegmentSidebar, defaultSettings: defaultEditSegmentSettings },
        GoogleAds: { sidebar: GoogleAdsSideBar, defaultSettings: defaultGoogleAdsSettings },
        FacebookAds: { sidebar: FacebookAdsSideBar, defaultSettings: defaultGoogleAdsSettings }
    };

    const SidebarChildren = sidebarsTypes[sidebar.type]?.sidebar;

    const markAsDone = (type, item, position) => {
        const id = uuid();
        const { actionType, title } = item;

        dispatch(addActionToEditField({
            actionType,
            title,
            position,
            id,
            isOpenActionInfo: false,
            isShowWarningActionMessage: false,
            isAutoSaveActive: false,
            actionParams: { simplified: true, simplifiedType: null },
            errors: {},
            settings: sidebarsTypes[actionType]?.defaultSettings(actionType),
        }));
    };
    const afterCloseRangePickerCalendar = ({ calendarRangeStartDate, calendarRangeEndDate }) => {
        dispatch(updateRangePickerCalendarData({
            actionId: sidebar.id,
            calendarRangeStartDate,
            calendarRangeEndDate
        }))
    };

    const autoSaveData = id => {
        return () => dispatch(autoSaveSidebarData(id));
    };

    const isOpenSidebar = sidebar.open && actionsList.length;

    return (
        <ActionCodeEditorContext.Provider value={{ actionCode, setActionCode }}>
            <div className={styles.scenariosContainer}>

                <Routes>
                    <Route path='/' element={ <ScenarioSetup markAsDone={markAsDone}/> }/>
                    <Route path='custom-webhook' element={ <ActionCodeEditor/> }/>
                    <Route path='sql-trigger' element={ <ActionCodeEditor/> }/>
                </Routes>

                {isOpenSidebar &&
                    <Sidebar
                        docked={some([
                            eq(sidebar.type, 'CustomWebHook'),
                            eq(sidebar.type, 'SqlTrigger')
                        ])}
                        sidebar={<SidebarChildren/>}
                        open={sidebar.open}
                        pullRight={true}
                        shadow={false}
                        sidebarClassName={cx(styles.scenariosWorkplaceSidebar, animations.fadeIn200ms)}
                        overlayClassName={styles.scenariosWorkplaceOverlay}
                        onSetOpen={autoSaveData(sidebar.id)}
                        children={<></>}
                    />
                }

                {isRangePickerCalendarOpen && <RangePickerCalendar
                    applyChangesCallback={afterCloseRangePickerCalendar}
                    initialStartDate={data?.calendarRangeStartDate}
                    initialEndDate={data?.calendarRangeEndDate}
                />}

                {isDateCalendarOpen &&
                    <CustomDatePickerCalendar/>
                }
            </div>
        </ActionCodeEditorContext.Provider>
    )
};
