import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from 'components/DateSelectors/DateSelectors.module.css';
import { openRangePickerCalendar } from 'store/actions/calendars.action';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import classNames from 'classnames/bind';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { isEmpty } from 'lodash';

const cx = classNames.bind(styles);

const parsedErrors = errors => {
    if (isEmpty(errors)) {
        return {
            selectedDatesIntervalViewer: {
                hasError: false
            }
        }
    }

    const { startDate, endDate } = errors;

    return {
        selectedDatesIntervalViewer: {
            hasError: startDate.hasError || endDate.hasError,
            message: startDate.message || endDate.message
        }
    }
}

export const SelectedDatesIntervalViewer = ({ item, errors, label }) => {
    const dispatch = useDispatch();

    const { selectedDatesIntervalViewer } = parsedErrors(errors);

    const onClickOpenCalendar = () => dispatch(openRangePickerCalendar({
        conditionId: item.id,
        calendarRangeStartDate: item._selectedTimeInterval._intervalDate.startDate,
        calendarRangeEndDate: item._selectedTimeInterval._intervalDate.endDate
    }));

    return (
        <>
            <p className={styles.dateInputViewerLabel}>
                {label}
            </p>
            <div onClick={onClickOpenCalendar}
                 className={cx(styles.sidebarDateViewer, { 'error': selectedDatesIntervalViewer.hasError })}
            >
                <p>
                    <span>{item._selectedTimeInterval._intervalDate.startDate}</span>
                    <span>{item._selectedTimeInterval._intervalDate.startDate && item._selectedTimeInterval._intervalDate.endDate ? " - " : null}</span>
                    <span>{item._selectedTimeInterval._intervalDate.endDate}</span>
                </p>

                <CalendarIcon/>
            </div>
            <ErrorMessageField
                active={selectedDatesIntervalViewer.hasError}
                message={selectedDatesIntervalViewer.message}
            />
        </>
    )
};
SelectedDatesIntervalViewer.propTypes = {
    item: PropTypes.object.isRequired,
    errors: PropTypes.object,
    label: PropTypes.string.isRequired
}
SelectedDatesIntervalViewer.defaultProps = {
    label: ''
}
