import React, { useCallback, useContext, useEffect } from 'react';
import { find, isEqual, mapValues } from 'lodash';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { AttributeFilterDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/AttributeFilter/AttributeFilterDropdown';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useTranslation } from 'react-i18next';
import { CustomSelectDropdown } from 'components/_CustomSelectDropdown/CustomSelectDropdown';

export const TriggerEventAdvanced = () => {
    const { t: translator } = useTranslation();

    const {
        values,
        setValues,
        errors,
        attributeFilter,
        simplifiedSettingsButtons,
        setSimplifiedSettingsButtons,
        actionParams,
        isActionInSimplifiedMod,
        setActionParams,
        onChangeInput,
        onChangeDropdown,
        clearSidebarDifficultySettingsType,
        turnOffSimplifiedSettingsButtons,
        mappedEventNames
    } = useContext(SideBarContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInput(setValues), []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDropdownCached = useCallback(onChangeDropdown(setValues), []);

    const isNeedTurnOffSimplifiedButtons = isActionInSimplifiedMod && !actionParams.simplified;

    const compareValues = (values, buttons) => {
        const simplifiedSettings = find(buttons, 'selected');

        const extendedValues = mapValues(values, (value, key) => {
            return { ...value, ...simplifiedSettings.values[key] }
        });

        return isEqual(values, extendedValues);
    };

    useEffect(() => {
        if (isNeedTurnOffSimplifiedButtons) {
            const isNotEqualValues = !compareValues(values, simplifiedSettingsButtons);

            if (isNotEqualValues || !!attributeFilter.length) {
                turnOffSimplifiedSettingsButtons(setSimplifiedSettingsButtons);
                clearSidebarDifficultySettingsType(setActionParams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, attributeFilter])

    return (
        <>
            <CustomSelectDropdown
                dropdownName='actionName'
                dropdownLabel={translator('actionSidebars:actionName')}
                defaultDropdownValue={values?.actionName?.value}
                dropdownOptions={mappedEventNames}
                onChangeDropdown={onChangeDropdownCached}
                isErrorExist={!!errors?.actionName}
                isRequired={values?.actionName?.required}
            />

            <Label htmlFor="actionSubName" text={translator('actionSidebars:actionSubName')}/>
            <InputField
                type='text'
                id='actionSubName'
                name='actionSubName'
                value={values?.actionSubName?.value}
                required={values?.actionSubName?.required}
                isInputWithoutErrorField
                onChange={onChangeCached}
            />

            <AttributeFilterDropdown/>
        </>
    )
};
