import { lowerCase, toNumber } from 'lodash';
import { nextActionIdsCompiler } from 'pages/Communications/ScenarioSetupPage/actionCompilers/utils/nextActionIdsCompiler';

export const waitCompiler = (action, filteredArrows) => {

    const {
        id,
        actionType,
        position,
        actionParams,
        settings: { waitInterval }
    } = action;

    const nextActionIdsCompiled = nextActionIdsCompiler(filteredArrows, actionType);

    return {
        action_id: id,
        action_type: lowerCase(actionType),
        action_params: actionParams,
        coordinates: position,
        action_options: {
            minutes_to_wait: toNumber(waitInterval.value)
        },
        next_actions_ids: [
            nextActionIdsCompiled
        ]
    }
}
