import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEmailMaster, getEmailMastersList } from 'store/actions/templates.action';
import { map, head, isArray } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { StringUse } from 'lib/StringUse';

export const useEmailMasters = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { templateType: routerTemplateType } = useParams();

    const emailMasterTypes = ['Email masters', 'Email components'];
    const defaultEmailMasterType = head(emailMasterTypes);

    const isLoadingEmailMasters = useSelector(store => store.api.isLoadingEmailMasters);
    const emailMastersList = useSelector(store => store.templates.list[StringUse.toPascalCase(defaultEmailMasterType)]);

    const currentEditMaster = useSelector(store => store.templates.currentEditTemplate);

    const [masters, setMasters] = useState([]);
    const [masterType, setMasterType] = useState(defaultEmailMasterType);

    const masterRedirectPath = '/all-templates/email/master/';

    const redirectToNewMaster = data => {
        const { template: { template_unique_id } } = data;

        navigate(masterRedirectPath + template_unique_id);
    };

    const createNewEmailMaster = name => {
        return () => dispatch(createEmailMaster({
            new_template: {
                template_name: name,
                message_type_name: StringUse.toPascalCase(masterType),
                jinja_fields: [
                    { jinja_field_name: 'jinja_body', value: '' },
                    { jinja_field_name: 'jinja_css', value: '' },
                ],
            }
        }, redirectToNewMaster));
    };

    const onChangeEmailMasterType = type => setMasterType(type);

    useEffect(() => {
        if (isArray(emailMastersList) && !emailMastersList.length) {
            dispatch(getEmailMastersList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerTemplateType]);

    useEffect(() => {
        if (emailMastersList) {
            setMasters(map(emailMastersList, master => ({
                id: master.template_unique_id,
                name: master.template_name,
                previewImageUrl: master.preview_image_url
            })));
        }
    }, [emailMastersList]);

    return {
        emailMastersList,
        masters,
        isLoadingEmailMasters,
        currentEditMaster,
        createNewEmailMaster,
        masterRedirectPath,
        emailMasterTypes,
        masterType,
        onChangeEmailMasterType
    }
};