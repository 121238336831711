import React from 'react';

export const EditSegmentIconAlt = ({ borderColor }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V43C0.5 45.4853 2.51472 47.5 5 47.5H43C45.4853 47.5 47.5 45.4853 47.5 43V5C47.5 2.51472 45.4853 0.5 43 0.5Z" fill="white"/>
        <path d="M43 48H5C3.67441 47.9984 2.40356 47.4711 1.46622 46.5338C0.528882 45.5964 0.00158786 44.3256 0 43L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L43 0C44.3256 0.00158786 45.5964 0.528882 46.5338 1.46622C47.4711 2.40356 47.9984 3.67441 48 5V43C47.9984 44.3256 47.4711 45.5964 46.5338 46.5338C45.5964 47.4711 44.3256 47.9984 43 48ZM5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V43C1 44.0609 1.42143 45.0783 2.17157 45.8284C2.92172 46.5786 3.93913 47 5 47H43C44.0609 47 45.0783 46.5786 45.8284 45.8284C46.5786 45.0783 47 44.0609 47 43V5C47 3.93913 46.5786 2.92172 45.8284 2.17157C45.0783 1.42143 44.0609 1 43 1H5Z" fill={borderColor}/>
        <g clipPath="url(#clip0_17429_43084)">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8333 17.3704C12.8323 17.3704 12.0208 18.1819 12.0208 19.1829C12.0208 20.184 12.8323 20.9954 13.8333 20.9954C14.8343 20.9954 15.6458 20.184 15.6458 19.1829C15.6458 18.1819 14.8343 17.3704 13.8333 17.3704ZM10.8125 19.1829C10.8125 17.5146 12.165 16.1621 13.8333 16.1621C15.5017 16.1621 16.8542 17.5146 16.8542 19.1829C16.8542 20.8513 15.5017 22.2038 13.8333 22.2038C12.165 22.2038 10.8125 20.8513 10.8125 19.1829Z" fill={borderColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.9677 25.7426C10.8848 25.8255 10.8123 25.9662 10.8123 26.1914V35.2539H16.854V26.1914C16.854 25.9662 16.7816 25.8255 16.6987 25.7426C16.6158 25.6597 16.475 25.5872 16.2498 25.5872H11.4165C11.1913 25.5872 11.0506 25.6597 10.9677 25.7426ZM10.1133 24.8882C10.4533 24.5481 10.9167 24.3789 11.4165 24.3789H16.2498C16.7496 24.3789 17.2131 24.5481 17.5531 24.8882C17.8931 25.2282 18.0623 25.6916 18.0623 26.1914V36.4622H9.604V26.1914C9.604 25.6916 9.77324 25.2282 10.1133 24.8882Z" fill={borderColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.8079 13.974C22.8069 13.974 21.9954 14.7854 21.9954 15.7865C21.9954 16.7875 22.8069 17.599 23.8079 17.599C24.809 17.599 25.6204 16.7875 25.6204 15.7865C25.6204 14.7854 24.809 13.974 23.8079 13.974ZM20.7871 15.7865C20.7871 14.1181 22.1396 12.7656 23.8079 12.7656C25.4763 12.7656 26.8288 14.1181 26.8288 15.7865C26.8288 17.4548 25.4763 18.8073 23.8079 18.8073C22.1396 18.8073 20.7871 17.4548 20.7871 15.7865Z" fill={borderColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.9828 22.1195C20.8999 22.2024 20.8275 22.3432 20.8275 22.5684V35.2559H26.8691V22.5684C26.8691 22.3432 26.7967 22.2024 26.7138 22.1195C26.6309 22.0366 26.4902 21.9642 26.265 21.9642H21.4316C21.2064 21.9642 21.0657 22.0366 20.9828 22.1195ZM20.1284 21.2651C20.4684 20.9251 20.9318 20.7559 21.4316 20.7559H26.265C26.7648 20.7559 27.2282 20.9251 27.5682 21.2651C27.9082 21.6051 28.0775 22.0686 28.0775 22.5684V36.4642H19.6191V22.5684C19.6191 22.0686 19.7884 21.6051 20.1284 21.2651Z" fill={borderColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.7401 15.6107C32.7391 15.6107 31.9276 16.4222 31.9276 17.4232C31.9276 18.4242 32.7391 19.2357 33.7401 19.2357C34.7411 19.2357 35.5526 18.4242 35.5526 17.4232C35.5526 16.4222 34.7411 15.6107 33.7401 15.6107ZM30.7192 17.4232C30.7192 15.7548 32.0717 14.4023 33.7401 14.4023C35.4084 14.4023 36.7609 15.7548 36.7609 17.4232C36.7609 19.0915 35.4084 20.444 33.7401 20.444C32.0717 20.444 30.7192 19.0915 30.7192 17.4232Z" fill={borderColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M30.9052 23.8109C30.8223 23.8938 30.7498 24.0346 30.7498 24.2598V35.2556H36.7915V24.2598C36.7915 24.0346 36.7191 23.8938 36.6362 23.8109C36.5533 23.728 36.4125 23.6556 36.1873 23.6556H31.354C31.1288 23.6556 30.9881 23.728 30.9052 23.8109ZM30.0508 22.9565C30.3908 22.6165 30.8542 22.4473 31.354 22.4473H36.1873C36.6871 22.4473 37.1506 22.6165 37.4906 22.9565C37.8306 23.2965 37.9998 23.76 37.9998 24.2598V36.4639H29.5415V24.2598C29.5415 23.76 29.7107 23.2965 30.0508 22.9565Z" fill={borderColor}/>
        </g>
        <defs>
            <clipPath id="clip0_17429_43084">
                <rect width="29" height="29" fill="white" transform="translate(9 10)"/>
            </clipPath>
        </defs>
    </svg>
);
