import React from 'react';
import styles from 'common/Textarea/Textarea.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Textarea = ({
    id,
    name,
    value,
    required,
    error,
    isTextareaWithoutErrorField,
    placeholder,
    className,
    style,
    onChange
}) => <textarea
    id={id}
    name={name}
    value={value}
    required={required}
    className={cx(styles.textarea, className, { 'error': error, 'textareaWithoutErrorField': isTextareaWithoutErrorField } )}
    placeholder={placeholder}
    style={style}
    onChange={onChange}
/>

Textarea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    isTextareaWithoutErrorField: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
}
Textarea.defaultProps = {
    name: '',
    value: '',
    required: false,
    error: false,
    isTextareaWithoutErrorField: false,
    placeholder: '',
    className: null,
    style: {},
};
