import React, { useCallback, useContext, useState } from 'react';
import styles from 'components/ScenarioSetup/ActionSidebars/ActionSidebars.module.css';
import { saveSidebarData } from 'store/actions/scenariosActions.action';
import { useDispatch, useSelector } from 'react-redux';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { useTranslation } from 'react-i18next';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { getStoreDataTailoredWait } from 'store/selectors/scenariosActions.selectors'
import { Select } from 'components/SelectOptions/Select';
import { useAutoSaveActionSetting } from 'hooks/ScenarioSetup/useAutoSaveActionSetting';

const daysWaitingMetric = [
    '',
    'base_n_days_to_purchase_0',
    'base_n_days_to_purchase_1',
    'base_n_days_to_purchase_2',
    'base_n_days_to_purchase_3',
    'individ_n_days_to_purchase_0',
    'individ_n_days_to_purchase_1',
    'individ_n_days_to_purchase_2',
    'individ_n_days_to_purchase_3'
];

const hoursWaitingMetric = [
    '',
    'base_hour_of_purchase_0',
    'base_hour_of_purchase_1',
    'individ_hour_of_purchase_0',
    'individ_hour_of_purchase_1',
];

export const TailoredWaitSidebar = () => {
    const dispatch = useDispatch();
    const { title, actionType, waitToDate, dateOffset, waitToTime, timeOffset } = useSelector(getStoreDataTailoredWait);
    const { t: translator } = useTranslation();

    const {
        onSubmitHandler,
        onChangeInput
    } = useContext(SideBarContext);

    const [values, setValues] = useState({
        waitToDate,
        dateOffset,
        waitToTime,
        timeOffset
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeCached = useCallback(onChangeInput(setValues), []);

    const onClickSaveActionSettings = () => dispatch(saveSidebarData(values));

    useAutoSaveActionSetting(onClickSaveActionSettings, actionType);

    return (
        <ActionSidebarFormWrapper onSubmitCallback={onSubmitHandler(onClickSaveActionSettings)}>
            <>
                <SidebarGeneralTitle title={title}/>

                <Label htmlFor="waitToDate" text={translator('actionSidebars:waitToDate')}/>
                <Select
                    name='waitToDate'
                    value={values?.waitToDate?.value}
                    onChange={onChangeCached}
                    options={daysWaitingMetric}
                    className={styles.selectWithoutErrors}
                    required
                />

                <Label htmlFor="dateOffset" text={translator('actionSidebars:dateOffset')}/>
                <InputField
                    type='number'
                    id='dateOffset'
                    name='dateOffset'
                    value={values?.dateOffset?.value}
                    required={values?.dateOffset?.required}
                    isInputWithoutErrorField={true}
                    onChange={onChangeCached}
                />

                <Label htmlFor='waitToTime' text={translator('actionSidebars:waitToTime')}/>
                <Select
                    name='waitToTime'
                    value={values?.waitToTime?.value}
                    onChange={onChangeCached}
                    options={hoursWaitingMetric}
                    className={styles.selectWithoutErrors}
                    required
                />

                <Label htmlFor="timeOffset" text={translator('actionSidebars:timeOffset')}/>
                <InputField
                    type='number'
                    id='timeOffset'
                    name='timeOffset'
                    value={values?.timeOffset?.value}
                    required={values?.timeOffset?.required}
                    isInputWithoutErrorField={true}
                    onChange={onChangeCached}
                />

                <ActionSidebarButtons/>
            </>
        </ActionSidebarFormWrapper>
    );
};
