import {
    CANCEL_CHANGES_TEMPLATE,
    CLEAR_CURRENT_EDIT_TEMPLATE,
    CLEAR_CURRENT_SCENARIO_TEMPLATES,
    SET_TEMPLATE,
    SET_TEMPLATE_DELETE,
    CLEAR_TEMPLATES_LIST,
    SET_CONSENT_TEMPLATE,
    SET_COPY_TEMPLATE,
    SET_METRICS_TEMPLATE,
    SET_PREVIEW_TEMPLATE,
    SET_SCENARIO_TEMPLATE_METRICS,
    SET_SCENARIO_TEMPLATES_LIST,
    SET_TEMPLATE_CREATE,
    SET_TEMPLATE_UPDATE,
    CHANGE_TEMPLATE_NAME,
    SET_TEMPLATES_LIST,
    CANCEL_EDIT_CONSENT_TEMPLATE,
    SET_TEMPLATES_SEARCHED,
    SET_TEMPLATES_FILTERED,
    CLEAR_CURRENT_EDIT_TEMPLATE_METRICS,
    SET_IS_TEMPLATE_HAS_CHANGES,
    SET_TEST_TEMPLATE,
    CLEAR_SCENARIO_DATA,
    LOGOUT_USER,
    SET_TEMPLATE_CLIENTS_LIST,
    CLEAR_TEMPLATE_CLIENTS_LIST,
    SET_MASTER_CREATE,
    SET_MASTERS_LIST,
    CLEAR_CURRENT_MASTER,
    SET_COPY_MASTER,
} from 'store/types';

const initialState = {
    list: {
        SMS: [],
        Viber: [],
        Email: [],
        EmailMasters: [],
        EmailComponents: [],
        PersonalPage: [],
        MobPushNotify: [],
        WebPushNotify: [],
    },
    currentScenarioTemplates: {
        actionsList: [],
        metrics: {},
    },
    currentEditTemplate: {},
    currentEditTemplateCopy: {},
    isTemplateHasChanges: false,
    metricsTemplate: {},
    modifiedTemplateMetrics: {
        monthMetrics: {},
        lineBarChart: {}
    },
    consentTemplate: {},
    sidebarSendTest: {
        isOpen: false
    },
    searchedClients: [],
    templateTestResultMessage: null,
    previewTemplate: {}
};

export default function templates(store = initialState, { type, payload }) {
    switch (type) {
        case SET_IS_TEMPLATE_HAS_CHANGES:
            return {
                ...store,
                isTemplateHasChanges: payload
            };
        case CANCEL_EDIT_CONSENT_TEMPLATE:
            return {
                ...store,
                consentTemplate: {
                    ...store.consentTemplate,
                    base: {...store.consentTemplate.copy}
                }
            };
        case SET_CONSENT_TEMPLATE:
            return {
                ...store,
                consentTemplate: payload
            };
        case SET_TEMPLATE_CREATE:
            return {
                ...store,
                currentEditTemplate: payload.template,
                currentEditTemplateCopy: payload.template,
                list: {
                    ...store.list,
                    [payload.typeMessage]: [
                        payload.template, ...store.list[payload.typeMessage],
                    ],
                }
            };
        case SET_MASTER_CREATE:
            return {
                ...store,
                list: {
                    ...store.list,
                    EmailMasters: [
                        payload.template, ...store.list['EmailMasters'],
                    ],
                },
                currentEditTemplate: payload.template,
                currentEditTemplateCopy: payload.template,
            };
        case SET_COPY_TEMPLATE:
            return {
                ...store,
                list: {
                    ...store.list,
                    [payload.typeMessage]: [
                        ...payload.list
                    ],
                }
            };
        case SET_COPY_MASTER:
            return {
                ...store,
                list: {
                    ...store.list,
                    EmailMasters: [
                        ...payload.list
                    ]
                },
                currentEditTemplate: payload.template
            }
        case SET_MASTERS_LIST:
            return {
                ...store,
                list: {
                    ...store.list,
                    EmailMasters: payload.data
                }
            };
        case CLEAR_CURRENT_MASTER:
            return {
                ...store, currentEditTemplate: {}
            }
        case SET_TEMPLATES_LIST:
            return {
                ...store, list: {
                    ...store.list,
                    [payload.typeMessage]: payload.data
                }
            };
        case SET_TEMPLATES_SEARCHED:
            return {
                ...store, list: {
                    ...store.list,
                    [payload.typeMessage]: payload.data
                }
            };
        case SET_TEMPLATES_FILTERED:
            return {
                ...store, list: {
                    ...store.list,
                    [payload.typeMessage]: payload.data
                }
            };
        case SET_METRICS_TEMPLATE:
            return {
                ...store,
                metricsTemplate: payload.template_metrics,
                modifiedTemplateMetrics: payload.modifiedTemplateMetrics,
            };
        case CLEAR_TEMPLATES_LIST:
            return {
                ...store,
                list: {
                    ...store.list,
                    SMS: [],
                    Viber: [],
                    Email: [],
                    EmailMasters: [],
                    PersonalPage: [],
                    MobPushNotify: [],
                    WebPushNotify: [],
                }
            };
        case SET_TEMPLATE:
        case SET_TEMPLATE_UPDATE:
            return {
                ...store, currentEditTemplate: {...payload}, currentEditTemplateCopy: {...payload}, isTemplateHasChanges: false
            };
        case CLEAR_CURRENT_EDIT_TEMPLATE:
            return {
                ...store, currentEditTemplate: {}, currentEditTemplateCopy: {},
            };
        case CLEAR_CURRENT_EDIT_TEMPLATE_METRICS:
            return {
                ...store,
                metricsTemplate: {},
                modifiedTemplateMetrics: {
                    monthMetrics: {},
                    lineBarChart: {}
                },
            };
        case SET_SCENARIO_TEMPLATE_METRICS:
            return {
                ...store,
                currentScenarioTemplates: {
                    ...store.currentScenarioTemplates,
                    metrics: {...store.currentScenarioTemplates.metrics, [payload.action_id]: payload},
                },
            };
        case CLEAR_CURRENT_SCENARIO_TEMPLATES:
            return {
                ...store,
                currentScenarioTemplates: {
                    actionsList: [],
                    metrics: {},
                },
            };
        case CLEAR_SCENARIO_DATA:
            return {
                ...store, currentScenarioTemplates: payload.defaultCurrentScenarioTemplates
            };
        case SET_SCENARIO_TEMPLATES_LIST:
            return {
                ...store,
                currentScenarioTemplates: {
                    ...store.currentScenarioTemplates,
                    actionsList: [...payload]
                }
            };
        case SET_PREVIEW_TEMPLATE:
            return {
                ...store,
                previewTemplate: payload.template
            };
        case CANCEL_CHANGES_TEMPLATE:
            return {
                ...store,
                currentEditTemplate: { ...store.currentEditTemplateCopy },
                isTemplateHasChanges: false
            };
        case CHANGE_TEMPLATE_NAME:
            return {
                ...store,
                currentEditTemplate: {
                    ...store.currentEditTemplate,
                    template_name: payload.name
                }
            };
        case LOGOUT_USER:
            return {
                ...store,
                list: payload.defaultTemplatesStore.list
            };
        case SET_TEMPLATE_DELETE:
            return {
                ...store,
                list: {
                    ...store.list,
                    [payload.type]: [...payload.list]
                }
            };
        case SET_TEST_TEMPLATE:
            return {
                ...store, templateTestResultMessage: payload.data
            };
        case SET_TEMPLATE_CLIENTS_LIST:
            return {
                ...store, searchedClients: payload
            };
        case CLEAR_TEMPLATE_CLIENTS_LIST:
            return {
                ...store, searchedClients: []
            }
        default:
            return store;
    }
}
