import React from 'react';

export const WebPushIconAlt = ({ borderColor }) => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V43C0.5 45.4853 2.51472 47.5 5 47.5H43C45.4853 47.5 47.5 45.4853 47.5 43V5C47.5 2.51472 45.4853 0.5 43 0.5Z" fill="white"/>
        <path d="M43 48H5C3.67441 47.9984 2.40356 47.4711 1.46622 46.5338C0.528882 45.5964 0.00158786 44.3256 0 43L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L43 0C44.3256 0.00158786 45.5964 0.528882 46.5338 1.46622C47.4711 2.40356 47.9984 3.67441 48 5V43C47.9984 44.3256 47.4711 45.5964 46.5338 46.5338C45.5964 47.4711 44.3256 47.9984 43 48ZM5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V43C1 44.0609 1.42143 45.0783 2.17157 45.8284C2.92172 46.5786 3.93913 47 5 47H43C44.0609 47 45.0783 46.5786 45.8284 45.8284C46.5786 45.0783 47 44.0609 47 43V5C47 3.93913 46.5786 2.92172 45.8284 2.17157C45.0783 1.42143 44.0609 1 43 1H5Z" fill={borderColor}/>
        <path d="M15.4289 30.286H14.4759V20.857C14.4729 20.5435 14.5324 20.2325 14.6509 19.9422C14.7695 19.6519 14.9448 19.3882 15.1665 19.1665C15.3882 18.9448 15.6519 18.7696 15.9422 18.651C16.2324 18.5324 16.5434 18.4729 16.8569 18.476H25.8099L25.5099 19.429H16.8569C16.4811 19.4381 16.1232 19.5915 15.8573 19.8574C15.5915 20.1232 15.4381 20.4812 15.4289 20.857V30.286Z" fill={borderColor}/>
        <path d="M31.4288 25.333C27.7138 25.333 27.6188 24.762 27.5288 24.476C27.5063 24.2614 27.5376 24.0445 27.6198 23.8449C27.702 23.6454 27.8326 23.4695 27.9998 23.333C28.0948 23.238 28.1898 23.143 28.1898 23.048C28.2537 22.897 28.2876 22.735 28.2898 22.571C28.1698 21.9781 28.1756 21.3666 28.3069 20.7761C28.4382 20.1856 28.6919 19.6292 29.0518 19.143C29.3094 18.8152 29.6444 18.5565 30.0266 18.3902C30.4089 18.2239 30.8264 18.1551 31.2418 18.19C31.6702 18.1648 32.0987 18.2375 32.4948 18.4026C32.8909 18.5678 33.2442 18.821 33.5278 19.143C34.6708 20.381 34.3848 22.571 34.3848 22.667C34.3848 22.857 34.4798 23.238 34.4798 23.333L34.6708 23.524C34.839 23.6599 34.9705 23.8356 35.0537 24.0352C35.1368 24.2348 35.1688 24.4519 35.1468 24.667C35.1428 24.762 35.0478 25.333 31.4288 25.333ZM28.4758 24.19C29.4224 24.3218 30.3772 24.3857 31.3328 24.381C32.2884 24.382 33.2429 24.3182 34.1898 24.19L34.0948 24.09L33.8098 23.805C33.599 23.4275 33.4998 22.9978 33.5238 22.566C33.6422 21.565 33.4062 20.5541 32.8568 19.709C32.4347 19.3409 31.8935 19.138 31.3333 19.138C30.7732 19.138 30.232 19.3409 29.8098 19.709C29.3403 20.5483 29.172 21.5229 29.3328 22.471V22.571C29.3282 22.909 29.2295 23.239 29.0478 23.524C28.9446 23.7003 28.8162 23.8606 28.6668 24C28.5708 24 28.5708 24.1 28.4758 24.19Z" fill={borderColor}/>
        <path d="M31.2378 27.048C30.9601 27.056 30.6837 27.0072 30.4255 26.9044C30.1674 26.8017 29.933 26.6472 29.7368 26.4505C29.5405 26.2538 29.3866 26.019 29.2845 25.7607C29.1823 25.5023 29.1341 25.2257 29.1428 24.948H30.0998C30.0825 25.1023 30.0999 25.2585 30.1508 25.4052C30.2016 25.5518 30.2847 25.6853 30.3938 25.7957C30.5029 25.9062 30.6353 25.9908 30.7813 26.0435C30.9274 26.0961 31.0834 26.1154 31.2378 26.1C31.396 26.1146 31.5555 26.0958 31.706 26.0448C31.8564 25.9938 31.9945 25.9117 32.1112 25.804C32.2279 25.6962 32.3207 25.5651 32.3835 25.4192C32.4463 25.2733 32.4778 25.1158 32.4758 24.957H33.4288C33.4296 25.2403 33.3727 25.5209 33.2615 25.7815C33.1504 26.0421 32.9874 26.2774 32.7824 26.473C32.5774 26.6687 32.3348 26.8205 32.0693 26.9194C31.8037 27.0182 31.5208 27.062 31.2378 27.048Z" fill={borderColor}/>
        <path d="M32.8568 18.762L31.9048 18.571C31.9048 18.381 31.9998 17.81 31.7138 17.524C31.6188 17.424 31.6188 17.424 31.4288 17.424C31.1428 17.424 31.0478 17.524 31.0478 17.614C30.8687 17.8645 30.769 18.1631 30.7618 18.471L29.8098 18.571C29.8094 17.9968 29.9747 17.4346 30.2858 16.952C30.4344 16.7997 30.6123 16.679 30.8087 16.5972C31.0051 16.5154 31.216 16.4742 31.4288 16.476C31.6275 16.4739 31.8243 16.5154 32.0052 16.5977C32.1861 16.6799 32.3468 16.8009 32.4758 16.952C32.7829 17.5029 32.9157 18.134 32.8568 18.762Z" fill={borderColor}/>
        <path d="M33.9999 32.952H11.9049C11.4199 32.9394 10.9568 32.7475 10.605 32.4134C10.2532 32.0793 10.0376 31.6267 9.99994 31.143C9.99204 30.8614 10.0947 30.5879 10.2859 30.381C10.5349 30.1988 10.8345 30.0988 11.1429 30.095H13.1429C13.2655 30.1006 13.3813 30.1533 13.466 30.242C13.5508 30.3308 13.5981 30.4488 13.5981 30.5715C13.5981 30.6942 13.5508 30.8122 13.466 30.901C13.3813 30.9897 13.2655 31.0424 13.1429 31.048H11.1429C11.0429 31.048 10.9519 31.048 10.9519 31.148C10.9885 31.3784 11.1032 31.5893 11.2766 31.7453C11.4501 31.9013 11.6719 31.993 11.9049 32.005H33.9999C34.0635 32.0011 34.1271 32.0107 34.1867 32.0332C34.2462 32.0557 34.3003 32.0906 34.3453 32.1356C34.3903 32.1807 34.4252 32.2347 34.4478 32.2943C34.4703 32.3538 34.4799 32.4175 34.4759 32.481C34.4668 32.6037 34.4135 32.719 34.3261 32.8056C34.2386 32.8921 34.1228 32.9441 33.9999 32.952Z" fill={borderColor}/>
        <path d="M33.9998 31.071H13.1428C13.0102 31.071 12.883 31.0183 12.7893 30.9245C12.6955 30.8308 12.6428 30.7036 12.6428 30.571C12.6428 30.4384 12.6955 30.3112 12.7893 30.2174C12.883 30.1237 13.0102 30.071 13.1428 30.071H33.9998C34.1324 30.071 34.2596 30.1237 34.3534 30.2174C34.4471 30.3112 34.4998 30.4384 34.4998 30.571C34.4998 30.7036 34.4471 30.8308 34.3534 30.9245C34.2596 31.0183 34.1324 31.071 33.9998 31.071Z" fill={borderColor}/>
        <path d="M34.952 32.952H33.714C33.5878 32.952 33.4667 32.9018 33.3775 32.8126C33.2882 32.7233 33.238 32.6022 33.238 32.476C33.238 32.3498 33.2882 32.2287 33.3775 32.1394C33.4667 32.0501 33.5878 32 33.714 32H34.952C35.185 31.988 35.4068 31.8963 35.5803 31.7403C35.7538 31.5843 35.8684 31.3734 35.905 31.143C35.905 31.043 35.81 31.043 35.714 31.043H33.714C33.5914 31.0374 33.4757 30.9847 33.3909 30.896C33.3062 30.8072 33.2589 30.6892 33.2589 30.5665C33.2589 30.4438 33.3062 30.3258 33.3909 30.237C33.4757 30.1483 33.5914 30.0956 33.714 30.09H35.714C36.0225 30.0938 36.3221 30.1938 36.571 30.376C36.7623 30.5829 36.8649 30.8564 36.857 31.138C36.8205 31.6226 36.6055 32.0764 36.2536 32.4115C35.9016 32.7467 35.4378 32.9392 34.952 32.952Z" fill={borderColor}/>
        <path d="M32.4759 27.935V30.411C32.4759 30.5372 32.4258 30.6583 32.3365 30.7476C32.2472 30.8368 32.1262 30.887 31.9999 30.887C31.8737 30.887 31.7526 30.8368 31.6633 30.7476C31.5741 30.6583 31.5239 30.5372 31.5239 30.411V27.935" fill={borderColor}/>
        <path d="M31.333 28.881C29.9155 28.881 28.5299 28.4607 27.3512 27.6731C26.1726 26.8856 25.254 25.7663 24.7116 24.4567C24.1691 23.1471 24.0272 21.7061 24.3037 20.3158C24.5803 18.9255 25.2629 17.6485 26.2652 16.6462C27.2675 15.6438 28.5445 14.9613 29.9348 14.6847C31.3251 14.4082 32.7661 14.5501 34.0757 15.0926C35.3853 15.635 36.5046 16.5536 37.2922 17.7322C38.0797 18.9108 38.5 20.2965 38.5 21.714C38.4979 23.6142 37.7421 25.4359 36.3985 26.7795C35.0549 28.1231 33.2332 28.8789 31.333 28.881ZM31.333 15.548C30.1133 15.548 28.9209 15.9097 27.9067 16.5874C26.8925 17.2651 26.1021 18.2283 25.6354 19.3552C25.1686 20.4822 25.0466 21.7222 25.2846 22.9185C25.5226 24.1148 26.1101 25.2137 26.9726 26.0761C27.8352 26.9385 28.9342 27.5258 30.1305 27.7636C31.3269 28.0015 32.5669 27.8792 33.6937 27.4123C34.8206 26.9454 35.7837 26.1548 36.4612 25.1405C37.1387 24.1262 37.5002 22.9338 37.5 21.714C37.4982 20.0791 36.8478 18.5117 35.6916 17.3557C34.5355 16.1997 32.9679 15.5496 31.333 15.548Z" fill={borderColor}/>
    </svg>
);