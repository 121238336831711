import { SET_COMMUNICATION_LIST, SET_COMMUNICATION_PROVIDER_LIST } from 'store/types';
import { LOGOUT_USER } from 'store/types';
import { CLEAR_CURRENT_EDIT_TEMPLATE } from 'store/types';

const initialState = {
    list: [],
    providerList: []
}

export default function communications(store = initialState, { type, payload }) {
    switch (type) {
        case SET_COMMUNICATION_LIST:
            return {
                ...store, list: payload.communication_types
            };
        case SET_COMMUNICATION_PROVIDER_LIST:
            return {
                ...store, providerList: payload.communication_providers
            };
        case CLEAR_CURRENT_EDIT_TEMPLATE:
            return {
                ...store, providerList: []
            };
        case LOGOUT_USER:
            return {
                ...store,
                list: payload.defaultCommunicationsStore.list
            };
        default:
            return store;
    }
}
