import React from 'react';
import { DoubleChainActionIcon } from 'components/ScenarioSetup/ActionIcons/DoubleChainActionIcon';
import { TailoredWaitIcon } from 'assets/icons/TailoredWaitIcon';
import { TailoredWaitIconAlt } from 'assets/icons/TailoredWaitIconAlt';

export const TailoredWait = ({ id, actionType, title, isShowWarningActionMessage, isOpenActionInfo }) => {

    const actionIconConditions = {
        base: {
            icon: TailoredWaitIcon,
            iconProps: {
                width: '48px',
                height: '48px',
                color: '#FFFFFF',
                bgColor: '#00C6A2'
            }
        },
        opened: {
            icon: TailoredWaitIconAlt,
            iconProps: { borderColor: '#00C6A2' }
        },
        warning: {
            icon: TailoredWaitIconAlt,
            iconProps: { borderColor: '#FC4A54' }
        }
    }

    return <DoubleChainActionIcon {...{
        id,
        actionType,
        title,
        isShowWarningActionMessage,
        isOpenActionInfo,
        actionIconConditions,
    }} />
}
