import { apiRequestAction } from 'store/actions/api.action';
import { CommonMethods } from 'lib/CommonMethods';
import { chain, eq, filter, find, forEach, includes, join, map, round, some, startCase, values, toLower, findIndex } from 'lodash';
import { getTemplatesStatusExtension } from 'data/templateStatusExtension';
import { ArrayUse } from 'lib/ArrayUse';
import { DateUse } from 'lib/DateUse';
import {
    FETCH_TEMPLATES_LIST,
    FETCH_SCENARIO_TEMPLATE_METRICS,
    FETCH_TEMPLATE_UPDATE,
    FETCH_TEMPLATE,
    SET_TEMPLATE,
    FETCH_TEMPLATE_DELETE,
    FETCH_COPY_TEMPLATE,
    FETCH_TEMPLATE_CREATE,
    FETCH_CONSENT_TEMPLATE,
    FETCH_TEMPLATES_SEARCHED,
    FETCH_TEMPLATES_FILTERED,
    CANCEL_CHANGES_TEMPLATE,
    CLEAR_CURRENT_EDIT_TEMPLATE,
    CLEAR_CURRENT_SCENARIO_TEMPLATES,
    SET_TEMPLATE_DELETE,
    CLEAR_TEMPLATES_LIST,
    SET_CONSENT_TEMPLATE,
    SET_COPY_TEMPLATE,
    SET_METRICS_TEMPLATE,
    SET_PREVIEW_TEMPLATE,
    SET_SCENARIO_TEMPLATE_METRICS,
    SET_SCENARIO_TEMPLATES_LIST,
    SET_TEMPLATE_CREATE,
    SET_TEMPLATE_UPDATE,
    CHANGE_TEMPLATE_NAME,
    SET_TEMPLATES_LIST,
    CANCEL_EDIT_CONSENT_TEMPLATE,
    SET_TEMPLATES_SEARCHED,
    SET_TEMPLATES_FILTERED,
    CLEAR_CURRENT_EDIT_TEMPLATE_METRICS,
    SET_IS_TEMPLATE_HAS_CHANGES,
    FETCH_METRICS_TEMPLATE,
    FETCH_TEMPLATE_CLIENTS_LIST,
    SET_TEMPLATE_CLIENTS_LIST,
    CLEAR_TEMPLATE_CLIENTS_LIST,
    FETCH_TEST_TEMPLATE,
    SET_TEST_TEMPLATE,
    FETCH_MASTER_CREATE,
    SET_MASTER_CREATE,
    FETCH_MASTERS_LIST,
    SET_MASTERS_LIST,
    CLEAR_CURRENT_MASTER,
    SET_COPY_MASTER,
} from 'store/types';

const successSendTestTemplate = data => ({
    type: SET_TEST_TEMPLATE,
    payload: { data }
});

export const sendTestTemplate = (data, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/test'),
        method: 'POST',
        data,
        onAfterSuccess,
        onSuccess: successSendTestTemplate,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEST_TEMPLATE,
    })
);

const setClientByAttributes = data => ({
    payload: data,
    type: SET_TEMPLATE_CLIENTS_LIST
});

export const getClientByAttributes = (value = '') => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`clients/by_attributes?&client_attribute_value=${encodeURIComponent(value)}`),
        method: 'GET',
        onSuccess: setClientByAttributes,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATE_CLIENTS_LIST,
    })
);

export const clearClientsList = () => ({
    type: CLEAR_TEMPLATE_CLIENTS_LIST,
})

export const setIsTemplateHasChanges = boolean => ({
    type: SET_IS_TEMPLATE_HAS_CHANGES,
    payload: boolean
});

export const cancelEditConsentTemplate = () => ({
    type: CANCEL_EDIT_CONSENT_TEMPLATE
});

const setConsentTemplate = data => ({
        type: SET_CONSENT_TEMPLATE,
        payload: data.templates[0]
    }
);

export const getConsentTemplate = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: 'ConsentPage',
        },
        onSuccess: setConsentTemplate,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_CONSENT_TEMPLATE
    })
);

const setCopyTemplate = ({ template }, originalTemplateId) => (dispatch, getStore) => {
    const templatesList = getStore().templates.list[template.message_type.message_type_name];

    dispatch({
        type: SET_COPY_TEMPLATE,
        payload: {
            list: ArrayUse.insert(templatesList, findIndex(templatesList, { template_unique_id: originalTemplateId }), template),
            typeMessage: template.message_type.message_type_name,
        },
    })
};

export const copyTemplate = (templateId, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${templateId}/copy`),
        method: 'POST',
        onSuccess: setCopyTemplate,
        extraOptions: templateId,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_COPY_TEMPLATE
    })
);
const setCopyMaster = ({ template }, originalMasterId) => (dispatch, getStore) => {
    const mastersList = getStore().templates.list['EmailMasters'];

    dispatch({
        type: SET_COPY_MASTER,
        payload: {
            list: ArrayUse.insert(mastersList, findIndex(mastersList, { template_unique_id: originalMasterId }), template),
            template,
        },
    })
};
export const copyMaster = (templateId, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${templateId}/copy`),
        method: 'POST',
        onSuccess: setCopyMaster,
        extraOptions: templateId,
        onAfterSuccess: onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_COPY_TEMPLATE
    })
);

const setTemplate = data => {
    const template = data.template || data;
    const { template_status, template_status: { template_status_id }} = template;
    const statusExtension = getTemplatesStatusExtension(template_status_id);

    return {
        type: SET_TEMPLATE,
        payload: {
            ...template,
            template_status: {
                ...template_status,
                ...statusExtension
            }
        }
    }
};
export const getTemplate = (templateId, redirect) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${templateId}`),
        method: 'GET',
        onSuccess: setTemplate,
        onAfterSuccess: redirect,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATE
    })
);

export const getMetricsTemplate = (templateId, startDate, endDate) =>
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${templateId}/metrics`),
        method: 'POST',
        data: {
            // conversion_window: 2,
            date_from: `${DateUse.checkValidFormatYearMonthDay(startDate)} 00:00:01`,
            date_to: `${DateUse.checkValidFormatYearMonthDay(endDate)} 23:59:59`,
        },
        onSuccess: setMetricsTemplate,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_METRICS_TEMPLATE
    });

const setMetricsTemplate = ({ template_metrics }) => {
    const totalMetricsForPeriod = {
        msg_sent: 0,
        msg_delivered: 0,
        msg_unsubscribed: 0,
        msg_opened: 0,
        msg_clicked: 0,
        purchases: 0
    };
    const monthMetrics = {};

    forEach(template_metrics, (metricsObject) => {
        totalMetricsForPeriod.msg_sent += metricsObject.msg_sent;
        totalMetricsForPeriod.msg_delivered += metricsObject.msg_delivered;
        totalMetricsForPeriod.msg_unsubscribed += metricsObject.msg_unsubscribed;
        totalMetricsForPeriod.msg_clicked += metricsObject.msg_clicked;
        totalMetricsForPeriod.msg_opened += metricsObject.msg_opened;
        totalMetricsForPeriod.purchases += metricsObject.purchases;
        //created name for array year-month and just current day of month
        const [year_month, day] = chain(metricsObject.date).split('-').chunk(2).map(arr => join(arr, '-')).value();

        if (year_month in monthMetrics) {
            monthMetrics[year_month].push({
                ...metricsObject,
                day
            })
        } else {
            monthMetrics[year_month] = [{
                ...metricsObject,
                day
            }]
        }
    });

    // create data for linaBarChart
    const { msg_sent, msg_delivered, msg_opened, msg_clicked, purchases } = totalMetricsForPeriod;
    // params for header
    const dataHeader = {
        msg_sent,
        msg_delivered,
        msg_opened,
        msg_clicked,
        purchases,
    };

    // create params for AxisY. We need 5 points, start to 0
    const dataAxisY = [0];
    for (let i = 1; i <= 5; i++) {
        const pointAxisY = round((msg_sent / 5) * i);
        dataAxisY.push(pointAxisY < 1000 ? pointAxisY : pointAxisY / 1000 + 'K');
    }
    // height for lineBars in %
    const dataChart = map(values(dataHeader), num => num ? round(num / msg_sent * 100, 2) + '%' : '0%');
    // conversion between lineBars
    const dataConversions = [];
    map(values(dataHeader), (item, index, arr) => {
        if (!eq(arr[index + 1], undefined)) {
            const conversion = item ? round(arr[index + 1] / item * 100, 2) + '%' : '0%';
            dataConversions.push(conversion);
        }
    });

    return {
        type: SET_METRICS_TEMPLATE,
        payload: {
            template_metrics,
            modifiedTemplateMetrics: {
                monthMetrics,
                totalMetricsForPeriod,
                lineBarChart: {
                    dataHeader,
                    dataAxisY,
                    dataChart,
                    dataConversions,
                    dataBottom: []
                }
            },
        }
    }
};

export const clearTemplatesList = () => dispatch => {
    dispatch({
        type: CLEAR_TEMPLATES_LIST
    })
};

export const cancelChangesTemplate = () => dispatch => {
    dispatch({
        type: CANCEL_CHANGES_TEMPLATE
    })
};

const setTemplatesList = (data, typeMessage) => {
    return {
        type: SET_TEMPLATES_LIST,
        payload: { data: DateUse.sortByDataTime(data?.templates, 'last_modified_at'), typeMessage }
    }
};

export const getTemplatesList = typeMessage => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: typeMessage,
        },
        extraOptions: typeMessage,
        onSuccess: setTemplatesList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATES_LIST
    })
);

const setSearchedTemplatesList = (data, { typeMessage, searchQuery }) => {
    const searched = filter(data?.templates, ({ template_name }) => {
        return includes(toLower(template_name), searchQuery)
    });

    return ({
        type: SET_TEMPLATES_SEARCHED,
        payload: { data: searched, typeMessage }
    })
}

export const searchTemplates = (searchQuery, typeMessage) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: typeMessage,
        },
        extraOptions: { typeMessage, searchQuery },
        onSuccess: setSearchedTemplatesList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATES_SEARCHED
    })
);

const setFilteredTemplatesListByStatus = (data, { typeMessage, options }) => {
    const filtered = filter(data?.templates, ({ template_status: { template_status_name } }) => {
        return some(options, { id: template_status_name });
    });

    return ({
        type: SET_TEMPLATES_FILTERED,
        payload: { data: filtered, typeMessage }
    });
};

export const filterTemplatesByStatus = (options, typeMessage, sandboxMode) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: typeMessage,
            use_library: sandboxMode
        },
        extraOptions: { typeMessage, options },
        onSuccess: setFilteredTemplatesListByStatus,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATES_FILTERED
    })
);

const setFilteredTemplatesListByEmailCreationType = (data, { typeMessage, options }) => {
    const filtered = filter(data?.templates, template => {
        return some(options, option => {
            if (eq(option.id, 'Master')) {
                return eq(template.is_master_based, option.isChecked);
            } else if (eq(option.id, 'Code')) {
                return eq(template.is_master_based, !option.isChecked);
            }
            return false;
        });
    });

    return ({
        type: SET_TEMPLATES_FILTERED,
        payload: { data: filtered, typeMessage }
    });
};

export const filterTemplatesByEmailCreationType = (options, typeMessage, sandboxMode) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: typeMessage,
            use_library: sandboxMode
        },
        extraOptions: { typeMessage, options },
        onSuccess: setFilteredTemplatesListByEmailCreationType,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATES_FILTERED
    })
);

export const clearCurrentEditTemplate = () => dispatch => {
    dispatch({
        type: CLEAR_CURRENT_EDIT_TEMPLATE
    })
};

export const clearCurrentEditTemplateMetrics = () => dispatch => {
    dispatch({
        type: CLEAR_CURRENT_EDIT_TEMPLATE_METRICS
    })
};

export const setScenarioTemplatesList = data => dispatch => {
    const templateActionsList = chain(data)
        .filter(({ action_type }) => includes(action_type, 'send'))
        .map(({ action_id, action_options: { template_unique_id }, action_type }) => {
            return {
                action_id,
                template_unique_id,
                action_type
            }
        })
        .value();

    dispatch({
        type: SET_SCENARIO_TEMPLATES_LIST,
        payload: templateActionsList
    });
};

export const clearCurrentScenarioTemplates = () => ({
    type: CLEAR_CURRENT_SCENARIO_TEMPLATES,
});

export const getScenarioTemplateMetrics = (scenario_id, params, startDate, endDate) => {
    return apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${params.template_unique_id}/metrics`),
        method: 'POST',
        data: {
            scenario_id: scenario_id,
            action_id: params.action_id,
            conversion_window: 2,
            date_from: `${DateUse.checkValidFormatYearMonthDay(startDate)} 00:00:01`,
            date_to: `${DateUse.checkValidFormatYearMonthDay(endDate)} 23:59:59`,
        },
        extraOptions: params,
        onSuccess: setScenarioTemplateMetrics,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_SCENARIO_TEMPLATE_METRICS
    })
};

const setScenarioTemplateMetrics = (data, params) => {
    const { template_metrics, template_name } = data;

    const actionName = startCase(params.action_type);

    const modifyTemplateMetrics = {
        template_name,
        action_name: actionName,
        action_id: params.action_id,
        msg_sent: 0,
        msg_delivered: 0,
        msg_unsubscribed: 0,
        msg_opened: 0,
        revenue: 0,
        purchases: 0,
        msg_clicked: 0

    };

    template_metrics.length && forEach(template_metrics, item => {
        modifyTemplateMetrics.msg_sent += item.msg_sent;
        modifyTemplateMetrics.msg_delivered += item.msg_delivered;
        modifyTemplateMetrics.msg_unsubscribed += item.msg_unsubscribed;
        modifyTemplateMetrics.msg_opened += item.msg_opened;
        modifyTemplateMetrics.revenue += item.revenue;
        modifyTemplateMetrics.purchases += item.purchases;
        modifyTemplateMetrics.msg_clicked += item.msg_clicked;
    });

    modifyTemplateMetrics.msg_conversion = modifyTemplateMetrics.msg_sent > 0 ? +(modifyTemplateMetrics.purchases / modifyTemplateMetrics.msg_sent * 100).toFixed(2) + '%' : 0;

    return {
        type: SET_SCENARIO_TEMPLATE_METRICS,
        payload: modifyTemplateMetrics
    }
};

const setPreviewTemplate = data => ({
    type: SET_PREVIEW_TEMPLATE,
    payload: data
});

export const getPreviewTemplate = template_unique_id => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${template_unique_id}`),
        method: 'GET',
        onSuccess: setPreviewTemplate,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATE
    })
);

const setUpdateTemplate = ({ template }) => dispatch => {
    const { template_status, template_status: { template_status_id }} = template;
    const statusExtension = getTemplatesStatusExtension(template_status_id);

    dispatch({
        type: SET_TEMPLATE_UPDATE,
        payload: {
            ...template,
            template_status: {
                ...template_status,
                ...statusExtension
            }
        }
    });
};

export const updateTemplate = ({ onAfterSuccess, onFailure }, data, template_unique_id) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${template_unique_id}`),
        method: 'PUT',
        data: {
            edit_template: data
        },
        onSuccess: setUpdateTemplate,
        onAfterSuccess,
        onFailure,
        label: FETCH_TEMPLATE_UPDATE
    })
);
const setUpdateConsentTemplate = data => ({
    type: SET_CONSENT_TEMPLATE,
    payload: data.template,
});
export const updateTemplateConsent = ({ onAfterSuccess, onFailure }, data, template_unique_id) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${template_unique_id}`),
        method: 'PUT',
        data: {
            edit_template: data
        },
        onSuccess: setUpdateConsentTemplate,
        onAfterSuccess,
        onFailure,
        label: FETCH_TEMPLATE_UPDATE
    })
);

export const changeTemplateName = name => dispatch => {
    dispatch({
        type: CHANGE_TEMPLATE_NAME,
        payload: { name }
    });

    return Promise.resolve();
};

const setTemplateDelete = ({ id, type }) => (dispatch, getStore) => {
    const list = getStore().templates.list[type];

    dispatch({
        type: SET_TEMPLATE_DELETE,
        payload: {
            list: ArrayUse.remove(list, find(list, { template_unique_id: id })),
            type: type
        }
    })
};

export const deleteTemplate = (id, type, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint(`templates/${id}`),
        method: 'DELETE',
        extraOptions: { id, type },
        onSuccess: setTemplateDelete,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATE_DELETE,
    })
);

const setCreateTemplate = data => {
    const { template, template: { message_type } } = data;
    const { template_status, template_status: { template_status_id }} = template;
    const statusExtension = getTemplatesStatusExtension(template_status_id);

    return {
        type: SET_TEMPLATE_CREATE,
        payload: {
            template: {
                ...template,
                template_status: {
                    ...template_status,
                    ...statusExtension
                },
            },
            typeMessage: message_type.message_type_name,
        }
    }
};

export const createTemplate = (data, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates'),
        method: 'POST',
        data: data,
        onSuccess: setCreateTemplate,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_TEMPLATE_CREATE
    })
);

const setCreateEmailMaster = data => {
    const {
        template,
        template: {
            template_status,
            template_status: { template_status_id }
        }
    } = data;

    const statusExtension = getTemplatesStatusExtension(template_status_id);


    return {
        type: SET_MASTER_CREATE,
        payload: {
            template: {
                ...template,
                template_status: {
                    ...template_status,
                    ...statusExtension
                },
            }
        }
    }
};

export const createEmailMaster = (data, onAfterSuccess) => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates'),
        method: 'POST',
        data: data,
        onSuccess: setCreateEmailMaster,
        onAfterSuccess,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_MASTER_CREATE
    })
);

const setEmailMastersList = data => ({
    type: SET_MASTERS_LIST,
    payload: {
        data: DateUse.sortByDataTime(data?.templates, 'last_modified_at')
    }
});

export const getEmailMastersList = () => (
    apiRequestAction({
        url: CommonMethods.replaceUrlEndpoint('templates/list'),
        method: 'POST',
        data: {
            message_type_name: 'EmailMasters',
            use_retailer: true
        },
        onSuccess: setEmailMastersList,
        onFailure: data => console.log('%cRequest failure:', 'color:indianred', data),
        label: FETCH_MASTERS_LIST
    })
);

export const clearCurrentMaster = () => dispatch =>
    dispatch({
        type: CLEAR_CURRENT_MASTER
    })