import React from 'react';
import PropTypes from 'prop-types';
import { TagsInput } from 'components/TagsInput/TagsInput';
import { ArrayUse } from 'lib/ArrayUse';

export const ConditionSegmentationFilterTags = ({ conditionId, inputTagValues, inputTagName, setState, objKey, error }) => {

    const updateStateFromProps = array => setState(prev => {
        const { data: collection, index } = ArrayUse.findCollection(prev, { id: conditionId });

        return ArrayUse.replace(prev, index, {
            ...collection,
            [objKey]: {
                ...collection[objKey],
                [inputTagName]: {
                    ...collection[objKey][inputTagName],
                    value: array
                }
            }
        })
    });

    return <TagsInput
        values={inputTagValues}
        name={inputTagName}
        updateCallback={updateStateFromProps}
        error={error}
    />
}

ConditionSegmentationFilterTags.protoTypes = {
    conditionId: PropTypes.number.isRequired,
    inputTagValues: PropTypes.array.isRequired,
    inputTagName: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    objKey: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired
}
ConditionSegmentationFilterTags.defaultProps = {
    error: false
}
