import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from 'common/InputTimeField/InputTimeField.module.css';
import TimeField from 'react-simple-timefield';
import { TimePickerClockIcon } from 'assets/icons/TimePickerClockIcon';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const InputTimeField = memo(({ value, onChange, label, title, additionalClass }) => (
    <div className={cx(styles.wrapperTimeField, additionalClass)}>
        {title && (
            <p className={styles.sidebarTimeFieldLabel}>{title}</p>
        )}
        <div className={styles.sidebarTimerContainer}>
            <div className={styles.timePickerInputContainer}>
                <TimeField
                    className={styles.timePickerInput}
                    value={value}
                    onChange={onChange}
                    colon=":"
                    showSeconds={false}
                />
                <TimePickerClockIcon/>
            </div>

            {label && <p>{label}</p>}
        </div>
    </div>
));
InputTimeField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    title: PropTypes.string,
    additionalClass: PropTypes.string
}
InputTimeField.defaultProps = {
    label: '',
    title: null,
    additionalClass: ''
};
