export const defaultScheduleTriggerSettings = () => ({
    atTheDay: {
        value: null,
        required: true
    },
    time: '11:00',
    values: {
        maxAudienceSize: {
            value: '',
            required: false
        },
    },
    condition: [],
    segmentId: null
})
