import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { useTranslation } from 'react-i18next';
import { CommunicationsMaxCount } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/CommunicationsMaxCount';
import { FrequencyPoliciesSelectOptions } from 'components/SelectOptions/FrequencyPoliciesSelectOptions';

export const SeparateFieldsContentSideBar = ({
    values,
    onChange,
    onChangeNumeric,
    errors,
    type
}) => {
    const { t: translator } = useTranslation();

    const label = translator('actionSidebars:emailMaxCount');
    const sendPushNotificationLabel = translator('actionSidebars:MaxShowing');

    const receiverContactType = {
        SendEmail:
            <>
                <div>
                    <Label htmlFor='receiverContactEmail' text={values?.receiverContactEmail && translator(`actionSidebars:${values?.receiverContactEmail?.label}`)}/>
                    <InputField
                        type={values?.receiverContactEmail?.type}
                        id='receiverContactEmail'
                        name='receiverContactEmail'
                        value={values?.receiverContactEmail?.value}
                        required={values?.receiverContactEmail?.required}
                        onChange={onChange}
                        placeholder={values?.receiverContactEmail?.placeholder}
                        error={!!errors.receiverContactEmail}
                    />
                    <ErrorMessageField active={!!errors.receiverContactEmail} message={errors.receiverContactEmail}/>
                </div>
                <FrequencyPoliciesSelectOptions onChange={onChange} value={values.policyId.value}/>
                <CommunicationsMaxCount {...{ values, errors, onChangeNumeric, label }}/>
            </>,
        SendSms:
            <>
                <Label htmlFor='receiverContactPhone' text={values?.receiverContactPhone && translator(`actionSidebars:${values?.receiverContactPhone?.label}`)}/>
                <InputField
                    type={values?.receiverContactPhone?.type}
                    id='receiverContactPhone'
                    name='receiverContactPhone'
                    value={values?.receiverContactPhone?.value}
                    required={values?.receiverContactPhone?.required}
                    onChange={onChange}
                    placeholder={values?.receiverContactPhone?.placeholder}
                    error={!!errors.receiverContactPhone}
                />
                <ErrorMessageField active={!!errors.receiverContactPhone} message={errors.receiverContactPhone}/>
                <FrequencyPoliciesSelectOptions onChange={onChange} value={values.policyId.value}/>
                <CommunicationsMaxCount {...{ values, errors, onChangeNumeric, label }}/>
            </>,
        SendViber:
            <>
                <Label htmlFor='receiverContactPhone' text={values?.receiverContactPhone && translator(`actionSidebars:${values?.receiverContactPhone?.label}`)}/>
                <InputField
                    type={values?.receiverContactPhone?.type}
                    id='receiverContactPhone'
                    name='receiverContactPhone'
                    value={values?.receiverContactPhone?.value}
                    required={values?.receiverContactPhone?.required}
                    onChange={onChange}
                    placeholder={values?.receiverContactPhone?.placeholder}
                    error={!!errors.receiverContactPhone}
                />
                <ErrorMessageField active={!!errors.receiverContactPhone} message={errors.receiverContactPhone}/>
                <FrequencyPoliciesSelectOptions onChange={onChange} value={values.policyId.value}/>
                <CommunicationsMaxCount {...{ values, errors, onChangeNumeric, label }}/>
            </>,
        SendWebPush:
            <>
                <FrequencyPoliciesSelectOptions onChange={onChange} value={values.policyId.value}/>
                <CommunicationsMaxCount {...{ values, errors, onChangeNumeric, label: sendPushNotificationLabel }}/>
            </>,
        SendMobPush:
            <>
                <FrequencyPoliciesSelectOptions onChange={onChange} value={values.policyId.value}/>
                <CommunicationsMaxCount {...{ values, errors, onChangeNumeric, label: sendPushNotificationLabel }}/>
            </>
    };

    return receiverContactType[type]
};

SeparateFieldsContentSideBar.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeNumeric : PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};
