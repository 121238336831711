import { Validation } from 'validations/Validation';
import { ObjectUse } from 'lib/ObjectUse';

const countTimeIntervalNumberRules = {
    value: 'required|integer|min:1'
};

const webActionTypeConcatedValuesRules = {
    receiverContactEmail: 'email',
    communicationsMaxCount: 'required|integer|min:1',
};

const phoneActionTypeConcatedValuesRules = {
    receiverContactPhone: 'between:7,14',
    communicationsMaxCount: 'required|integer|min:1',
};

const pushNotificationRules = {
    value: 'required|integer|min:1'
};

const isActionTemplateHasError = templateUniqueId => {
    return !templateUniqueId ? 'TemplateValidationMessage' : null
};

const emailActionTypeValidation = (receiverContactEmail, communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate) => {
    return () => {

        const webActionTypeConcatedValues = {
            receiverContactEmail: receiverContactEmail.value,
            communicationsMaxCount: communicationsMaxCount.value,
        }

        const validation = Validation.checkSome(webActionTypeConcatedValues, webActionTypeConcatedValuesRules, {
            'required.communicationsMaxCount': 'InputValidationRequiredMessage',
            'min.communicationsMaxCount': 'PositiveNumberInputValidationMessage',
            'email.receiverContactEmail': 'EmailAddressInputValidationMessage'
        }, ['communicationsMaxCount', 'receiverContactEmail'])

        const isActionHasErrors = validation.fails || countTimeIntervalNumberResult.fails || !!isSelectedActionTemplate;

        return {
            isShowWarningActionMessage: isActionHasErrors,
            errors: {
                ...ObjectUse.createSeveralObjects([
                    validation.firstErrors.communicationsMaxCount,
                    validation.firstErrors.receiverContactEmail,
                    countTimeIntervalNumberResult.firstError,
                    isSelectedActionTemplate
                ], ['communicationsMaxCount', 'receiverContactEmail', 'countTimeIntervalNumber', 'templateUniqueId']),
            }
        }
    }
}

const phoneActionTypeValidation = (receiverContactPhone, communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate) => {
    return () => {

        const phoneActionTypeConcatedValues = {
            receiverContactPhone: receiverContactPhone.value,
            communicationsMaxCount: communicationsMaxCount.value,
        }

        const validation = Validation.checkSome(phoneActionTypeConcatedValues, phoneActionTypeConcatedValuesRules, {
            'required.communicationsMaxCount': 'InputValidationRequiredMessage',
            'min.communicationsMaxCount': 'PositiveNumberInputValidationMessage',
            'between.receiverContactPhone': 'PhoneInputValidationMessage'
        }, ['communicationsMaxCount', 'receiverContactPhone'])

        const isActionHasErrors = validation.fails || countTimeIntervalNumberResult.fails || !!isSelectedActionTemplate;

        return {
            isShowWarningActionMessage: isActionHasErrors,
            errors: {
                ...ObjectUse.createSeveralObjects([
                    validation.firstErrors.communicationsMaxCount,
                    validation.firstErrors.receiverContactPhone,
                    countTimeIntervalNumberResult.firstError,
                    isSelectedActionTemplate
                ], ['communicationsMaxCount', 'receiverContactPhone', 'countTimeIntervalNumber', 'templateUniqueId']),
            }
        }
    }
}

const pushNotificationActionTypeValidation = (communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate) => {
    return () => {
        const validation = Validation.check(communicationsMaxCount, pushNotificationRules, {
            'required.value': 'InputValidationRequiredMessage',
            'min.value': 'PositiveNumberInputValidationMessage',
        }, 'value')

        const isActionHasErrors = validation.fails || countTimeIntervalNumberResult.fails || !!isSelectedActionTemplate;

        return {
            isShowWarningActionMessage: isActionHasErrors,
            errors: {
                ...ObjectUse.createSeveralObjects([
                    validation.firstError,
                    countTimeIntervalNumberResult.firstError,
                    isSelectedActionTemplate
                ], ['communicationsMaxCount', 'countTimeIntervalNumber', 'templateUniqueId']),
            }
        }
    }
}

export const sendContentControl = action => {

    const {
        values: {
            communicationsMaxCount,
            countTimeIntervalNumber,
            receiverContactEmail,
            receiverContactPhone,
        },
        templateUniqueId
    } = action.settings;

    const isSelectedActionTemplate = isActionTemplateHasError(templateUniqueId);

    const countTimeIntervalNumberResult = Validation.check(countTimeIntervalNumber, countTimeIntervalNumberRules, {
        'required.value': 'InputValidationRequiredMessage',
        'integer.value': 'InputTagsValuesOnlyNumericMessage',
        'min.value': 'PositiveNumberInputValidationMessage',
    }, 'value');

    const controlTypes = {
        SendEmail: emailActionTypeValidation(receiverContactEmail, communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate),
        SendSms: phoneActionTypeValidation(receiverContactPhone, communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate),
        SendViber: phoneActionTypeValidation(receiverContactPhone, communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate),
        SendMobPush: pushNotificationActionTypeValidation(communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate),
        SendWebPush: pushNotificationActionTypeValidation(communicationsMaxCount, countTimeIntervalNumberResult, isSelectedActionTemplate),
    }

    const getSelectedType = controlTypes[action.actionType]();

    return {
        ...action,
        ...getSelectedType
    }
}
