import React from 'react';
import styles from 'components/Cards/Cards.module.css';
import { eq } from 'lodash';
import PropTypes from 'prop-types';
import { CreativeCardFooter } from 'components/Cards/CreativeCards/CreativeCardFooter';
import { CreativeCardWrapper } from 'components/Cards/CreativeCards/CreativeCardWrapper';
import { MobilePushBody } from 'components/Cards/CreativeCards/CreativeCardPreviewBody/MobilePushBody'
import { ArrayUse } from 'lib/ArrayUse';

export const MobilePushCard = ({
    template,
    templateId,
    templateType,
    isShowChainButton,
    isShowUsage,
    redirectPath,
    onClick,
    containerClassName
}) => {
    const { template_unique_id, jinja_fields } = template;

    const jinjaBodyObj = ArrayUse.findCollection(jinja_fields, { jinja_field_name: 'jinja_body' });
    const jinjaMsgTitleObj = ArrayUse.findCollection(jinja_fields, { jinja_field_name: 'jinja_msg_title' });

    const setDefaultPreviewContent = obj => {
        const { data } = obj;

        if (!data.value) return { ...obj, data: { ...data, value: 'Default message' } }

        return obj
    };

    const modifiedJinjaFields = ArrayUse.replaceGroup(jinja_fields, [
        setDefaultPreviewContent(jinjaBodyObj),
        setDefaultPreviewContent(jinjaMsgTitleObj)
    ]);

    return (
        <CreativeCardWrapper
            isSelected={eq(templateId, template_unique_id)}
            cardType={templateType}
            className={containerClassName}
        >
            <div className={styles.mobilePushCard} onClick={onClick}>
                <MobilePushBody jinja_fields={modifiedJinjaFields}/>
            </div>
            <CreativeCardFooter {...{ template, isShowChainButton, isShowUsage, redirectPath }}/>
        </CreativeCardWrapper>
    );
};

MobilePushCard.propTypes = {
    template: PropTypes.object.isRequired,
    templateId: PropTypes.string,
    templateType: PropTypes.string,
    isShowChainButton: PropTypes.bool,
    isShowUsage: PropTypes.bool,
    redirectPath: PropTypes.string,
    onClick: PropTypes.func,
    containerClassName: PropTypes.string
};
MobilePushCard.defaultProps = {
    isShowUsage: true,
    containerClassName: ''
};
