import React from 'react';

export const ABTestSplitIcon = ({ color, bgColor, width, height }) => (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
        <rect width="32px" height="32px" rx="3.5" fill={bgColor}/>
        <mask id="mask0" masktype="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="20">
            <path d="M6 6H26V26H6V6Z" fill={color}/>
        </mask>
        <g mask="url(#mask0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3917 15.4107C10.0452 15.4107 9.7643 15.1298 9.7643 14.7833L9.7643 11.6464H6.62742C6.3594 11.6464 6.12098 11.4761 6.034 11.2226C5.94701 10.9691 6.03068 10.6883 6.24224 10.5238L11.8886 6.13216C12.1152 5.95595 12.4324 5.95595 12.659 6.13216L18.3054 10.5238C18.5169 10.6883 18.6006 10.9691 18.5136 11.2226C18.4266 11.4761 18.1882 11.6464 17.9202 11.6464H14.7833L14.7833 14.7833C14.7833 14.9497 14.7172 15.1093 14.5996 15.2269C14.4819 15.3446 14.3223 15.4107 14.1559 15.4107H10.3917ZM14.1559 11.019H17.9202L12.2738 6.62738L6.62742 11.019H10.3917L10.3917 14.7833H14.1559L14.1559 11.019Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7833 20.4297V17.6065C14.7833 17.0868 14.362 16.6654 13.8423 16.6654H10.7054C10.1856 16.6654 9.7643 17.0868 9.7643 17.6065L9.7643 20.4297H6.99517C6.07508 20.4297 5.70174 21.6141 6.45551 22.1417L11.7243 25.8299C12.0626 26.0667 12.5158 26.0553 12.8418 25.8018L17.5837 22.1136C18.291 21.5635 17.902 20.4297 17.006 20.4297H14.7833ZM14.1559 20.7434C14.1559 20.9166 14.2964 21.0571 14.4696 21.0571H17.006C17.3046 21.0571 17.4343 21.435 17.1985 21.6184L12.4566 25.3065C12.3479 25.3911 12.1969 25.3949 12.0841 25.3159L6.81529 21.6277C6.56403 21.4519 6.68848 21.0571 6.99517 21.0571H10.078C10.2512 21.0571 10.3917 20.9166 10.3917 20.7434L10.3917 17.6065C10.3917 17.4332 10.5321 17.2928 10.7054 17.2928H13.8423C14.0155 17.2928 14.1559 17.4332 14.1559 17.6065V20.7434Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.4297 13.5285L17.6065 13.5285C17.0868 13.5285 16.6655 13.9499 16.6655 14.4696V17.6065C16.6655 18.1262 17.0868 18.5476 17.6065 18.5476L20.4297 18.5476V20.7299C20.4297 21.6383 21.5895 22.0195 22.1284 21.2882L25.8088 16.2933C26.0646 15.9462 26.0517 15.4697 25.7776 15.1368L22.0972 10.6678C21.536 9.98622 20.4297 10.3831 20.4297 11.266V13.5285ZM17.6065 14.1559L20.7434 14.1559C20.9167 14.1559 21.0571 14.0155 21.0571 13.8422V11.266C21.0571 10.9717 21.4258 10.8394 21.6129 11.0666L25.2933 15.5357C25.3847 15.6466 25.389 15.8054 25.3037 15.9211L21.6233 20.916C21.4437 21.1598 21.0571 21.0327 21.0571 20.7299V18.2339C21.0571 18.0606 20.9167 17.9202 20.7434 17.9202L17.6065 17.9202C17.4333 17.9202 17.2928 17.7797 17.2928 17.6065V14.4696C17.2928 14.2963 17.4333 14.1559 17.6065 14.1559Z" fill={color}/>
            <path d="M13.609 10.7053L12.6416 8.19454H11.9716L11.0004 10.7053H11.6102L11.7683 10.2799H12.8449L12.9992 10.7053H13.609ZM12.6981 9.80941H11.9151L12.3066 8.72906L12.6981 9.80941Z" fill={color}/>
            <path d="M22.8301 17.3806C23.3835 17.3806 23.6997 17.0757 23.8804 16.7633L23.4211 16.5412C23.3157 16.7482 23.0899 16.9063 22.8301 16.9063C22.3747 16.9063 22.0434 16.5563 22.0434 16.082C22.0434 15.6077 22.3747 15.2576 22.8301 15.2576C23.0899 15.2576 23.3157 15.4194 23.4211 15.6227L23.8804 15.3969C23.7034 15.0844 23.3835 14.7833 22.8301 14.7833C22.0773 14.7833 21.4938 15.3103 21.4938 16.082C21.4938 16.8536 22.0773 17.3806 22.8301 17.3806Z" fill={color}/>
            <path d="M12.6954 23.9569C13.1735 23.9569 13.4257 23.6558 13.4257 23.2794C13.4257 22.9632 13.2111 22.7034 12.9439 22.662C13.181 22.6131 13.3767 22.4023 13.3767 22.0861C13.3767 21.7511 13.1321 21.4462 12.654 21.4462H11.3327V23.9569H12.6954ZM12.5373 22.4512H11.8673V21.9054H12.5373C12.718 21.9054 12.8309 22.0221 12.8309 22.1802C12.8309 22.3383 12.718 22.4512 12.5373 22.4512ZM12.5561 23.4939H11.8673V22.9105H12.5561C12.7669 22.9105 12.8799 23.046 12.8799 23.2041C12.8799 23.3848 12.7594 23.4939 12.5561 23.4939Z" fill={color}/>
            <path d="M16.0381 16.038C16.0381 16.3845 15.7572 16.6654 15.4107 16.6654C15.0642 16.6654 14.7833 16.3845 14.7833 16.038C14.7833 15.6916 15.0642 15.4107 15.4107 15.4107C15.7572 15.4107 16.0381 15.6916 16.0381 16.038Z" fill={color}/>
        </g>
    </svg>
);
