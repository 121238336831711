import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/AsideDropdownButton/AsideDropdownButton.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const AsideDropdownButtonSeparateLine = ({ onClick, alternativeColor }) => (
    <div onClick={onClick}
         className={cx(styles.separateLine, { 'green': alternativeColor })}
    />
)
AsideDropdownButtonSeparateLine.propTypes = {
    onClick: PropTypes.func.isRequired,
    alternativeColor: PropTypes.bool
}
AsideDropdownButtonSeparateLine.defaultProps = {
    alternativeColor: false
}
