import React from 'react';
import PropTypes from 'prop-types';
import styles from './TemplatesListLayout.module.css';
import { TemplatesTuningSection } from 'components/TuningSections/TemplatesTuningSection';
import { SelectContentLayout } from 'layouts/_SelectContentLayout/SelectContentLayout';

export const TemplatesListLayout = ({ creativesType, sectionTitle, creativesListArray, newTemplateButton }) => (
    <div className={styles.templatesListLayout}>
        <TemplatesTuningSection typeMessage={creativesType}/>

        <SelectContentLayout
            sectionTitle={sectionTitle}
            childrenArrayList={creativesListArray}
            actionButton={newTemplateButton}
        />
    </div>
);

TemplatesListLayout.propTypes = {
    creativesType: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    creativesListArray: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]),
    newTemplateButton: PropTypes.node.isRequired
};
TemplatesListLayout.defaultProps = {
    creativesType: '',
    sectionTitle: '',
    creativesListArray: []
};

