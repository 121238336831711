import React from 'react';
import styles from 'components/ScenarioSetup/ScenarioSetup.module.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Xwrapper } from 'react-xarrows';
import { DraggableActionContext } from 'components/ScenarioSetup/context/DraggableActionContext';
import { map } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DraggableActionModel } from 'components/ScenarioSetup/DraggableActionModel';
import { ScenarioSetupDropField } from 'components/ScenarioSetup/ScenarioSetupDropField';
import { ActionChainConnections } from 'components/ScenarioSetup/ActionConnections/ActionChainConnections';
import { ActionChainPotentialConnections } from 'components/ScenarioSetup/ActionConnections/ActionChainPotentialConnections';
import { ActionsListSidebar } from 'components/ScenarioSetup/ActionsListSidebar/ActionsListSidebar';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const ScenarioSetup = ({ markAsDone }) => {
    const { actionsList } = useSelector(store => store.scenariosActions);
    const { actionSidebar: sidebar } = useSelector(store => store.scenariosActions);

    return (
        <DndProvider backend={HTML5Backend}>
            <DraggableActionContext.Provider value={{ markAsDone }}>
                <ActionsListSidebar/>

                <div style={sidebar.open ? { overflow: 'hidden' } : { overflow: 'auto' }}
                     className={cx(styles.scenariosWrapperContainer, 'customGreenScrollbar')}
                >
                    <ScenarioSetupDropField>
                        <div className={styles.scenariosWrapperWorkplace}>
                            <Xwrapper>
                                <ActionChainPotentialConnections/>

                                <ActionChainConnections/>

                                {map(actionsList, action => <DraggableActionModel key={action.id} {...action} />)}
                            </Xwrapper>
                        </div>
                    </ScenarioSetupDropField>
                </div>
            </DraggableActionContext.Provider>
        </DndProvider>
    );
};

ScenarioSetup.propTypes = {
    markAsDone: PropTypes.func.isRequired
};
