import React from 'react';
import PropTypes from 'prop-types';
import styles from 'common/_Buttons/_DefaultButton/DefaultButton.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const DefaultButton = ({ type, name, value, className, disabled, onClick }) => (
    <button
        type={type}
        name={name}
        value={value}
        className={cx(styles.defaultButton, className, { 'disabledButton': disabled })}
        disabled={disabled}
        onClick={onClick}
    >
        {name}
    </button>
);

DefaultButton.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};
DefaultButton.defaultProps = {
    type: 'button',
    value: '',
    className: null,
    disabled: false,
    onClick: () => {}
};
