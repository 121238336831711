import React from 'react';
import { useDispatch } from 'react-redux';
import styles from 'pages/Auth/Auth.module.css';
import animations from 'animations/FadeIn.module.css';
import classNames from 'classnames/bind';
import { AuthSubmitButton } from './AuthSubmitButton';
import { useForm } from '../../hooks/useForm';
import validateLogin from 'validations/LoginFormValidationRules';
import { login } from 'store/actions/auth.action';
import { useTranslation } from 'react-i18next';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';

const cx = classNames.bind(styles);

export const SignIn = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();

    const loginHandler = () => {
        dispatch(login(values, setErrors));
        console.log('[Auth Client]: User tries to login as:', values?.username);
    };

    const {
        values,
        errors,
        setErrors,
        handleChange,
        handleSubmit,
    } = useForm(loginHandler, validateLogin);

    return (
        <div className={cx(styles.signInContainer, animations.fadeIn)}>
            <div className={styles.logo}/>

            <div className={styles.googleAuth}>
                <div className={styles.googleLogo}/>
                <p>{translator('auth:GoogleAuthMessage')}</p>
            </div>
            <p className={styles.orContainer}>Или</p>
            <form onSubmit={handleSubmit} noValidate>
                <Label htmlFor="username" text={translator('auth:Login')}/>
                <InputField
                    type="text"
                    id='username'
                    name="username"
                    value={values.username || ''}
                    onChange={handleChange}
                    error={!!errors.username}
                    required={true}
                />
                <ErrorMessageField
                    active={!!errors.username}
                    message={errors.username}
                />


                <Label htmlFor="password" text={translator('auth:Password')}/>

                <InputField
                    type="password"
                    id='password'
                    name="password"
                    value={values.password || ''}
                    onChange={handleChange}
                    error={!!errors.password}
                    required={true}
                />
                <ErrorMessageField
                    active={!!errors.password}
                    message={errors.password}
                />

                <a href="https://neucurrent.com/contact">{translator('auth:RegistrationLink')}</a>
                <AuthSubmitButton/>
            </form>

            <div className={styles.noticeContainer}>
                <p>{translator('auth:BrowserListMessage')}</p>
                <div className={styles.noticeBrowsers}>
                    <div className={cx(styles.browserIcon, styles.chrome)}/>
                    <div className={cx(styles.browserIcon, styles.mozilla)}/>
                    <div className={cx(styles.browserIcon, styles.opera)}/>
                </div>
            </div>
        </div>
    )
};
