import React from 'react'
import { ActionSidebarFormWrapper } from 'common/Forms/ActionSidebarFormWrapper';
import { SidebarGeneralTitle } from 'common/SidebarGeneral/SidebarGeneralTitle';
import { ActionSidebarButtons } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/ActionSidebarButtons';

export const FacebookAdsSideBar = () => {
    return (
        <ActionSidebarFormWrapper onSubmitCallback={() => {}}>
            <SidebarGeneralTitle title={'Facebook ads'}/>

            <ActionSidebarButtons/>
        </ActionSidebarFormWrapper>
    )
};
