import React, { useCallback, useContext } from 'react';
import { Label } from 'common/_Label/Label';
import { InputField } from 'common/InputField/InputField';
import { ErrorMessageField } from 'common/ErrorMessageField/ErrorMessageField';
import { AsideDropdownButton } from 'common/AsideDropdownButton/AsideDropdownButton';
import { ConditionDropdown } from 'components/ScenarioSetup/ActionSidebars/SidebarComponents/Condition/advanced/ConditionDropdown';
import { useTranslation } from 'react-i18next';
import { SideBarContext } from 'components/ScenarioSetup/ActionSidebars/context/SideBarContext';
import { useCompareConditions } from 'hooks/ScenarioSetup/useCompareConditions';
import { useDropdownFilters } from 'hooks/useDropdownFilters';

export const ScheduleTriggerAdvanced = () => {
    const { t: translator } = useTranslation();

    const {
        values,
        setValues,
        errors,
        onClickCreateNewCondition,
        onChangeNumericInput,
        setCondition
    } = useContext(SideBarContext);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeNumericCached = useCallback(onChangeNumericInput(setValues),[]);

    useDropdownFilters(['ATTRIBUTE', 'PRODUCT_ATTRIBUTE', 'METRIC']);

    useCompareConditions();

    return (
        <>
            <Label htmlFor="maxAudienceSize" text={translator('actionSidebars:maxAudienceSize')}/>
            <InputField
                type='number'
                id='maxAudienceSize'
                name='maxAudienceSize'
                value={values?.maxAudienceSize?.value}
                required={values?.maxAudienceSize?.required}
                error={!!errors?.values?.maxAudienceSize}
                onChange={onChangeNumericCached}
            />
            <ErrorMessageField active={!!errors?.values?.maxAudienceSize} message={errors?.values?.maxAudienceSize}/>

            <AsideDropdownButton
                name={translator('actionSidebars:NewConditionButton')}
                onClick={onClickCreateNewCondition(setCondition)}
                alternativeColor
            />
            <ConditionDropdown/>
        </>
    )
}
