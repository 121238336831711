import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-nice-dates/build/style.css';
import './RangePickerCalendar.css';
import { ru, enGB, pl, uk } from 'date-fns/locale';
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates';
import { DateUse } from 'lib/DateUse';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import LightGreenButton from 'common/_Buttons/_GreenButtons/LightGreenButton';
import GreenButton from 'common/_Buttons/_GreenButtons/GreenButton';
import { closeRangePickerCalendar } from 'store/actions/calendars.action';
import { isDate } from 'lodash';

export const RangePickerCalendar = ({ applyChangesCallback, initialStartDate, initialEndDate, format }) => {
    const dispatch = useDispatch();
    const { t: translator, i18n } = useTranslation();

    const ref = useRef(null);

    const [startDate, setStartDate] = useState(DateUse.isDateHasValidFormat(initialStartDate)
        ? new Date(initialStartDate)
        : null
    );

    const [endDate, setEndDate] = useState(DateUse.isDateHasValidFormat(initialEndDate)
        ? new Date(initialEndDate)
        : null
    );

    const [month, setMonth] = useState(DateUse.isDateHasValidFormat(initialStartDate)
        ? new Date(initialStartDate)
        : new Date()
    );

    const [focus, setFocus] = useState(DateUse.isDateHasValidFormat(initialStartDate) || DateUse.isDateHasValidFormat(initialEndDate)
        ? null
        : START_DATE
    );

    const [duration, setDuration] = useState(null);

    const selectLocale = {
        en: enGB,
        ru: ru,
        ua: uk,
        pl: pl
    };

    const isDisabledApplyButton = !isDate(startDate) || !isDate(endDate);

    const onChangeMonth = date => setMonth(new Date(date));

    const onClickClearButton = () => {
        setStartDate(null)
        setEndDate(null)
        setDuration(null)
        setFocus(START_DATE)
    };

    const closeCalendar = () => dispatch(closeRangePickerCalendar());

    const onClickApplyChanges = () => {
        new Promise(resolve => {
            closeCalendar();

            return resolve({
                calendarRangeStartDate: DateUse.format(startDate, null, format),
                calendarRangeEndDate: DateUse.format(endDate, null, format)
            })
        }).then(data => applyChangesCallback(data))
    };

    const onClickSetAllTime = () => {
        new Promise(resolve => {
            setStartDate(null)
            setEndDate(null)
            setDuration(null)

            resolve({ calendarRangeStartDate: '', calendarRangeEndDate: '' })
        })
            .then(data => applyChangesCallback(data))
            .then(() => closeCalendar())
    };

    const onClickSetCurrentYear = () => {
        const { yearStart, yearEnd } = DateUse.getCurrentYear();

        setStartDate(new Date(yearStart));
        setEndDate(new Date(yearEnd));
        setMonth(new Date(yearStart))
    };
    const onClickSetCurrentMonth = () => {
        const { monthStart, monthEnd } = DateUse.getCurrentMonth();

        setStartDate(new Date(monthStart));
        setEndDate(new Date(monthEnd));
        setMonth(new Date(monthStart))
    };
    const onClickSetCurrentWeek = () => {
        const { weekStart, weekEnd } = DateUse.getCurrentWeek();

        setStartDate(new Date(weekStart));
        setEndDate(new Date(weekEnd));
        setMonth(new Date(weekStart))
    };

    useEffect(() => {
        if (startDate && endDate) {
            setDuration(DateUse.getDurationTime({
                start: {
                    value: DateUse.format(startDate, null, format),
                    format: format
                },
                end: {
                    value: DateUse.format(endDate, null, format),
                    format: format
                }
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate])

    useOutsideAlerter(ref, closeCalendar);

    return (
        <div className='range-picker-calendar-wrapper'>
            <div className='range-picker-calendar-container' ref={ref}>
                <DateRangePickerCalendar
                    startDate={startDate}
                    endDate={endDate}
                    focus={focus}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    onFocusChange={setFocus}
                    locale={selectLocale[i18n.language]}
                    weekdayFormat='EEEEEE'
                    month={month}
                    onMonthChange={onChangeMonth}
                />

                <div className='range-picker-calendar-buttons'>
                    <button className='range-picker-calendar-button' onClick={onClickSetAllTime}>{translator('calendar:AllTimeButton')}</button>
                    <button className='range-picker-calendar-button' onClick={onClickSetCurrentYear}>{translator('calendar:ThisYearButton')}</button>
                    <button className='range-picker-calendar-button' onClick={onClickSetCurrentMonth}>{translator('calendar:ThisMonthButton')}</button>
                    <button className='range-picker-calendar-button' onClick={onClickSetCurrentWeek}>{translator('calendar:ThisWeekButton')}</button>
                </div>
                <div className='range-picker-calendar-footer'>
                    <LightGreenButton
                        name={translator('calendar:ClearButton')}
                        className='range-picker-calendar-footer-button'
                        onClick={onClickClearButton}
                    />

                    <p className='range-picker-calendar-footer-selected-interval'>
                        {duration
                            ? `${translator('calendar:Selected')} ${duration} ${translator('calendar:Days')}`
                            : ''
                        }
                    </p>

                    <GreenButton
                        name={translator('calendar:AppleButton')}
                        className='range-picker-calendar-footer-button'
                        onClick={onClickApplyChanges}
                        disabled={isDisabledApplyButton}
                    />
                </div>
            </div>
        </div>
    );
};

RangePickerCalendar.propTypes = {
    applyChangesCallback: PropTypes.func.isRequired,
    initialStartDate: PropTypes.string,
    initialEndDate: PropTypes.string,
    format: PropTypes.string
};
RangePickerCalendar.defaultProps = {
    format: 'YYYY/MM/DD'
};
